import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

@Component({
  selector: 'app-confirm-delete-optimizer-task',
  templateUrl: './confirm-delete-optimizer-task.component.html',
  styleUrls: ['./confirm-delete-optimizer-task.component.scss'],
})
export class ConfirmDeleteOptimizerTaskComponent {
  submitBtnDanger = true;

  @Input() client!: Client;
  @Input() job!: Job;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Delete';
  get modalTitle() {
    return `Delete Optimizer Search '${this.job.name ?? ''}'`;
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.httpClient
      .delete('/api/clients/' + this.client!.id + '/jobs/' + this.job.id, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (result: any) => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.operationSuccess.emit();
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to delete optimizer search',
          };
        }
      );
  }
}
