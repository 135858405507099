import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-core-alert',
  templateUrl: './core-alert.component.html',
  styleUrls: ['./core-alert.component.css'],
})
export class CoreAlertComponent {
  @Input() message: string | TemplateRef<void> | null = null;
  @Input() description: string | TemplateRef<void> | null = null;
  @Input() type: 'success' | 'info' | 'warning' | 'error' = 'info';
  @Input() closeable?: boolean;
  @Input() showIcon?: boolean;
  @Input() banner?: boolean;

  @Output() alertOnClose = new EventEmitter<boolean>();

  constructor() {}

  nzOnClose(event: boolean): void {
    this.alertOnClose.emit(event);
  }
}
