import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { AddClientFormData } from './add-client-type';

@Component({
  selector: 'app-add-client-modal',
  templateUrl: './add-client-modal.component.html',
  styleUrls: ['./add-client-modal.component.scss'],
})
export class AddClientModalComponent {
  clientFormData?: AddClientFormData;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<Client>();

  clientAddApiCall: AsyncOpData<void>;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.clientAddApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAddClient() {
    this.cancelOperation.emit();
  }

  okAddClient() {
    this.clientAddApiCall = {
      state: AsyncOpState.Inprogress,
    };

    const newClient: Client = {
      alias: this.clientFormData?.data.clientAlias,
      firstName: this.clientFormData?.data.clientFirstName,
      lastName: this.clientFormData?.data.clientLastName,
      middleName: this.clientFormData?.data.clientMiddleName,
      emailAddress: this.clientFormData?.data.clientEmail,
    };
    this.httpClient
      .post<{ result: number; message?: string; client: Client }>('/api/clients', newClient, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (result) => {
          if (result.result !== 0) {
            this.clientAddApiCall = {
              state: AsyncOpState.Failed,
              error: 'Error! ' + result.message,
            };
          } else {
            this.clientAddApiCall = {
              state: AsyncOpState.Completed,
            };

            this.operationSuccess.emit(result.client);
          }
        },
        (err) => {
          this.clientAddApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error! Failed to add a new client. Please try again',
          };
        }
      );
  }

  onClientFormDataChange(newData: AddClientFormData) {
    this.clientFormData = newData;
  }

  get submitBtnDisabled() {
    return !(this.clientFormData && this.clientFormData.valid);
  }

  get isAddInProgress() {
    return this.clientAddApiCall.state === AsyncOpState.Inprogress;
  }

  get addErrorMessage() {
    return this.clientAddApiCall.error;
  }

  get showAddErrorMessage() {
    return this.clientAddApiCall.state === AsyncOpState.Failed;
  }
}
