import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { Client } from 'src/app/models/client';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  DefaultSimulationName,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-confirm-copy-current-plan',
  templateUrl: './confirm-copy-current-plan.component.html',
  styleUrls: ['./confirm-copy-current-plan.component.scss'],
})
export class ConfirmCopyCurrentPlanComponent {
  submitBtnDanger = false;

  @Input() client!: Client;
  @Input() analysisPlan!: AnalysisPlan;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<AnalysisPlan>();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Copy Current Plan';
  modalTitle = 'Copy and Compare Current Plan';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };
    const newInputs = Object.assign(
      {},
      this.client!.plan?.inputs ?? {}
    ) as PlanInputs;
    newInputs.simulationName =
      newInputs.simulationName || DefaultSimulationName;
    const newResults = Object.assign({}, this.client!.plan?.results);
    const newAnalysisPlan = {
      id: generateUuidV4(),
      inputs: newInputs,
      results: newResults,
    } as AnalysisPlan;

    this.httpClient
      .post('/api/clients/' + this.client!.id + '/analysis', newAnalysisPlan, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.operationSuccess.emit(newAnalysisPlan);
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to copy current analysis plan',
          };
        }
      );
  }
}
