<div>
  <nz-form-item>
    <nz-form-label>Monthly Income Goal</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.monthlyIncomeGoal"
        (ngModelChange)="handleDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Monthly Contribution Amount</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.monthlyContributionAmount"
        (ngModelChange)="handleDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="showStartDateField">
    <nz-form-label>Income Plan Start Date</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-date-picker
        [(ngModel)]="inputs.startDate"
        (ngModelChange)="handleDataChange()"
        style="margin-bottom: 0px"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <label
        nz-checkbox
        [(ngModel)]="inputs.clientAdvanced"
        (ngModelChange)="handleDataChange()"
      >
        <span>Enable Advanced Settings</span>
      </label>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="inputs.clientAdvanced">
    <nz-form-label>Inflation Rate for Income Goal</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.inflationRate"
        (ngModelChange)="handleDataChange()"
        [nzMin]="0"
        [nzMax]="100"
        [nzStep]="1"
        [nzFormatter]="formatterPercent"
        [nzParser]="parserPercent"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="inputs.clientAdvanced">
    <nz-form-label>Years to Simulate</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.yearsToSimulate"
        (ngModelChange)="handleDataChange()"
        [nzMin]="1"
        [nzMax]="100"
        [nzStep]="1"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
</div>
