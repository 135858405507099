import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanInputs } from 'src/app/models/plan-inputs';
import {
  formatterDollar,
  formatterPercent,
  parserDollar,
  parserPercent,
} from 'src/app/utils/app-common';

@Component({
  selector: 'app-step-client-goals',
  templateUrl: './step-client-goals.component.html',
  styleUrls: ['./step-client-goals.component.scss'],
})
export class StepClientGoalsComponent implements OnInit {
  @Input() inputs!: PlanInputs;

  @Output() validityChange = new EventEmitter<boolean>();

  @Input() showStartDateField = false;

  clientAdvanced = false;

  constructor() {}

  formatterDollar = formatterDollar;
  parserDollar = parserDollar;
  formatterPercent = formatterPercent;
  parserPercent = parserPercent;

  ngOnInit(): void {
    this.validityChange.emit(true);
  }

  handleDataChange() {}
}
