import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { getClientFullName } from 'src/app/components/home/client-utils';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import { OptimizerResult } from 'src/app/models/optimizer-result';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  NotificationDurationMs,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-confirm-add-to-analysis',
  templateUrl: './confirm-add-to-analysis.component.html',
  styleUrls: ['./confirm-add-to-analysis.component.scss'],
})
export class ConfirmAddToAnalysisComponent {
  @Input() result!: OptimizerResult;
  @Input() job!: Job;
  @Input() client!: Client;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Add Result to Analysis';
  modalTitle = 'Confirm Adding Optimizer Result to Analysis';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private notification: NzNotificationService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };
    const { job, result } = this;
    if (result && job && result.inputs) {
      let inputs: PlanInputs = {
        simulationName: job.inputs?.simulationName,
        startDate: job.inputs?.startDate as Date,
        monthlyIncomeGoal: result.inputs.incomeGoal,
        monthlyContributionAmount: result.inputs.monthlyContribution,
        inflationRate: (result.inputs.inflationRateForPassiveIncome ?? 0) * 100,
        yearsToSimulate: result.inputs.numYearsToSimulate,
        loansRateOfReturn: (result.inputs.loansReturn ?? 0) * 100,
        lineOfCreditSize: result.inputs.locDrawAmount,
        lineOfCreditInterestRate: (result.inputs.locInterestRate ?? 0) * 100,
        loansTerm: result.inputs.loansNumberofYears,
        growthCapital: result.inputs.growthCapital,
        monthsToPayOffLOC: result.inputs.monthsToPayoffLOC,
        monthsToPayOffLOCPhase3: result.inputs.monthsToPayoffLOCPhase3,
        keepAdditionalIncomeInSystem:
          result.inputs.bKeepAdditionalIncomeInSystem,
        enableAutoIncrement: result.inputs.autoIncrementEnable,
        autoIncrementAmount: result.inputs.autoIncrementAmount,
        autoIncrementNumFlips: result.inputs.autoIncrementNumFlips,
        autoIncrementMax: result.inputs.autoIncrementMax,
        autoIncrementOneTimeMaxAmount:
          result.inputs.autoIncrementOneTimeMaxAmount,
        methodToUse: result.inputs.methodOfAdjustingLOC,
        scheduledTransactions: this.client!.transactions,
        deposits: this.client!.deposits,
        clientAdvanced: result.inputs.clientAdvanced,
        lendingAdvanced: result.inputs.lendingAdvanced,
        algorithmAdvanced: result.inputs.algorithmAdvanced,
        optimizerAdvanced: result.inputs.optimizerAdvanced,
      };
      this.httpClient
        .post('/engine/plan', inputs, {
          headers: new HttpHeaders().append('timeout', '60000'),
        })
        .subscribe(
          (results: any) => {
            let optimizerPlan = { inputs: inputs, results: results };

            let newInputs = Object.assign({}, optimizerPlan.inputs);
            let newResults = Object.assign({}, optimizerPlan.results);
            let newAnalysisPlan = {
              id: generateUuidV4(),
              inputs: newInputs,
              results: newResults,
            };
            if (!this.client!.analysis) {
              this.client!.analysis = [];
            }
            this.client!.analysis?.push(newAnalysisPlan);
            this.httpClient
              .post(
                '/api/clients/' + this.client!.id + '/analysis',
                newAnalysisPlan,
                {
                  headers: toApiAuthHeaders(this.authService),
                }
              )
              .subscribe(
                (result: any) => {
                  this.actionSubmitApiCall = {
                    state: AsyncOpState.Completed,
                  };

                  this.notification.create(
                    'success',
                    'Success! Added optimizer result to analysis',
                    `Your action to add an optimizer result to analysis section for client ${getClientFullName(
                      this.client
                    )} is done.`,
                    {
                      nzDuration: NotificationDurationMs,
                    }
                  );

                  this.operationSuccess.emit();
                },
                (err) => {
                  this.actionSubmitApiCall = {
                    state: AsyncOpState.Failed,
                    error: 'Failed to add optimizer result to analysis.',
                  };
                }
              );
          },
          (err) => {
            this.actionSubmitApiCall = {
              state: AsyncOpState.Failed,
              error: 'Failed to add optimizer result to analysis.',
            };
          }
        );
    }
  }
}
