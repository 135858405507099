import { Client } from 'src/app/models/client';
import {
  PlanOptimizer,
  PlanOptimizerLevel,
} from 'src/app/models/optimizer-settings';

export const getClientFullName = (client: Client) => {
  return `${client.firstName ?? ''}${
    client.middleName ? ` ${client.middleName} ` : ' '
  }${client.lastName ?? ''}`;
};

export const extractPlanOptimizerState = (
  optimizer?: PlanOptimizer,
  optimizerLevel?: PlanOptimizerLevel
) => {
  if (!optimizer) {
    return {
      color: 'red',
      text: 'Plan Not Optimized',
      tooltipMessage:
        'This plan has not been optimized. Run the Optimizer on this income plan to optimize.',
    };
  }

  if (optimizer === 'QUICK') {
    if (optimizerLevel === 'HIGH') {
      return {
        color: 'green',
        text: 'Plan is Quick Optimized: High',
        tooltipMessage: `This income plan has been optimized in the 'quick mode' with a large enough sample size to give a good result.`,
      };
    } else if (optimizerLevel === 'MEDIUM') {
      return {
        color: 'yellow',
        text: 'Plan is Quick Optimized: Medium',
        tooltipMessage: `This income plan has been optimized in the 'quick mode' with an acceptable sample size to give a good result.`,
      };
    } else if (optimizerLevel === 'LOW') {
      return {
        color: 'red',
        text: 'Plan is Quick Optimized: Low',
        tooltipMessage: `This income plan has been optimized in the 'quick mode' with an small sample size and the results might not be good enough.`,
      };
    }
  }

  if (optimizer === 'THOROUGH') {
    return {
      color: 'green',
      text: 'Plan is Optimized',
      tooltipMessage: 'This income plan has been highly optimized',
    };
  }
  return undefined;
};

export const getAlgorithmMethodName = (index?: number) => {
  if (index == 0) {
    return 'One';
  } else if (index == 1) {
    return 'Two';
  } else if (index == 2) {
    return 'Three';
  } else if (index == 3) {
    return 'Four';
  } else if (index == 4) {
    return 'Five';
  } else if (index == 5) {
    return 'Six';
  } else if (index == 6) {
    return 'Seven';
  }

  return 'N/A';
};

export const checkIfClientHasIncomePlan = (client: Client): boolean => {
  return !!(client.plan && client.plan.inputs);
};
