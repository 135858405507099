<nz-card
  [nzBorderless]="true"
  style="
    height: 425px;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
  "
>
  <h5>Financial Freedom in</h5>
  <div class="c2-freedom-date">
    {{ clientFreedomIn }}
  </div>
  <div
    style="
      display: flex;
      width: 100%;
      align-items: flex-end;
      color: inherit;
    "
  >
    <div style="flex-grow: 1">
      <div class="c2-date-label">
        <span *ngIf="todayAfterStart; else today">Start Date</span>
      </div>
      <div class="c2-date-value">
        <span *ngIf="todayAfterStart; else todayD">{{
          clientPlanStartDate | date: "mediumDate"
        }}</span>
      </div>
      <div class="bar-phase-1"></div>
    </div>
    <ng-template #today> Today </ng-template>
    <ng-template #todayD>
      {{ todayDate | date: "mediumDate" }}
    </ng-template>
    <div style="flex-grow: 1">
      <div class="c2-date-label">
        <span *ngIf="!todayAfterStart; else today">Start Date</span>
      </div>
      <div class="c2-date-value">
        <span *ngIf="!todayAfterStart; else todayD">{{
          clientPlanStartDate | date: "mediumDate"
        }}</span>
      </div>
      <div class="bar-phase-2"></div>
    </div>
    <div style="flex-grow: 1">
      <div class="c2-date-label">Freedom Date</div>
      <div class="c2-date-value">
        {{ clientFreedomDate | date: "mediumDate" }}
        <span *ngIf="unsustained">(Unsustained)</span>
      </div>
      <div
        [ngClass]="{
          'bar-phase-3': !unsustained && !partlyUnsustained,
          'bar-phase-3-partlyunsustained': partlyUnsustained, 
          'bar-phase-3-unsustained': unsustained
        }"
      ></div>
    </div>
  </div>
</nz-card>
