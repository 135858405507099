<ng-container *ngIf="client">
  <div class="results-header">
    <h3 class="results-header__title">Income Plan</h3>
    <a *ngIf="!editPlanModalOpen" style="margin-left: auto" (click)="openEditPlanModal()">Edit</a>
  </div>
  <div>
    <!--app-plan-settings [client]="client"></app-plan-settings-->
    <app-create-or-update-plan-modal
      *ngIf="editPlanModalOpen"
      [client]="client"
      (cancelOperation)="closeEditPlanModal()"
      (operationSubmitted)="onEditPlanSuccess()"
      [updateMode]="clientHasPlan"
    >
    </app-create-or-update-plan-modal>
    <div *ngIf="client.plan && client.plan.results && client.plan.results.msgs && !editPlanModalOpen" class="results-tiles">
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/Calendar.svg" />
        <nz-statistic
          nz-col
          [nzValue]="(startDate | date: 'MMM d YYYY')!"
          [nzTitle]="'Plan Start Date'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/Passive.svg" />
        <nz-statistic
          [nzValue]="
            (client.plan.inputs!.monthlyIncomeGoal! | currency: 'USD':'$':'0.0-2')!
          "
          [nzTitle]="'Passive Income'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/Contribution.svg" />
        <nz-statistic
          [nzValue]="
            (client.plan.inputs!.monthlyContributionAmount! | currency: 'USD':'$':'0.0-2')!
          "
          [nzTitle]="'Monthly Contribution'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/SizeOfLoc.svg" />
        <nz-statistic
          [nzValue]="(client.plan.inputs!.lineOfCreditSize! | currency: 'USD':'$':'0.0-2')!"
          [nzTitle]="'Starting LOC Size'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/PlanDuration.svg" />
        <nz-statistic
          [nzValue]="
            (planDuration)! + ' Years'
          "
          [nzTitle]="'Plan Duration'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
      <div class="results-tiles__tile">
        <img src="/assets/img/plan-settings/Streams.svg" />
        <nz-statistic
          [nzValue]="
            (incomeStreams)!
          "
          [nzTitle]="'Income Streams'"
          [nzValueStyle]="{ color: '#130F26' }"
        ></nz-statistic>
      </div>
  
      <button
        class="results-tiles__more"
        nz-button
        nzType="text"
        (click)="openInfo()"
      >
        ...
      </button>
    </div>
    

    <ng-container *ngIf="clientHasPlan && !editPlanModalOpen">
      <div nz-row nzGutter="16">
        <div nz-col [nzMd]="{span : 24 }">
          <app-flow-of-money [client]="client"></app-flow-of-money>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
        >
          <app-setup-transactions [client]="client"></app-setup-transactions>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
        >
          <app-setup-deposits [client]="client"></app-setup-deposits>
        </div>
      </div>
      <div style="margin-top: 36px">
        <app-setup-peertopeer [client]="client"></app-setup-peertopeer>
      </div>
      <!--app-setup-loans [client]="client"></app-setup-loans-->

      <!--app-setup-revolving-debt-stacks
        [client]="client"
      ></app-setup-revolving-debt-stacks-->
      <!--app-setup-income-stacks [client]="client"></app-setup-income-stacks-->
    </ng-container>
  </div>
</ng-container>

<nz-modal
  [nzStyle]="{ top: '20px' }"
  [(nzVisible)]="isVisibleInfo"
  nzTitle="Info"
  (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()"
>
  <ng-container *nzModalContent>
    <h5>Overview</h5>
    <table *ngIf="client && client.plan && client.plan.inputs" style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Passive Income Goal</b>
        </td>
        <td>{{ client.plan.inputs!.monthlyIncomeGoal | currency }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Monthly Contribution</b>
        </td>
        <td>{{ client.plan.inputs!.monthlyContributionAmount | currency }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Plan Start Date</b>
        </td>
        <td>{{ client.plan.inputs!.startDate | date:"mediumDate"}}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Enable Advanced</b>
        </td>
        <td>{{ (client.plan.inputs!.clientAdvanced?'Yes':'No') }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Inflation Rate</b>
        </td>
        <td>{{ (+client.plan.inputs!.inflationRate! / 100) | percent}}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Simulation Years</b>
        </td>
        <td>{{ client.plan.inputs!.yearsToSimulate }} years</td>
      </tr>
      <!--tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>
          In {{ results.msgs["freedom_in"] }} (in
          {{ results.msgs["freedom_months"] }} months)
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>Date {{ freedomDate | date: "mediumDate" }}</td>
      </tr-->
    </table>
    <h5 style="margin-top: 20px">Line Of Credit</h5>
    <table *ngIf="client && client.plan && client.plan.inputs" style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Size of Line Of Credit</b>
        </td>
        <td>{{ client.plan.inputs!.lineOfCreditSize | currency }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Line Of Credit Interest Rate</b>
        </td>
        <td>{{ (+client.plan.inputs!.lineOfCreditInterestRate! / 100) | percent}}</td>
      </tr>
      <!--tr *ngIf="results.msgs['freedom_in']">
        <td style="width: 50%">
          <b>Financial Freedom Day UPB</b>
        </td>
        <td>
          {{ results.msgs["freedom_upb"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day Assets Created</b>
        </td>
        <td>
          {{
            results.msgs["freedom_assets_created"] | currency: "USD":"$":"0.0-2"
          }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day LOC Size</b>
        </td>
        <td>
          {{ results.msgs["freedom_loc_size"] | currency: "USD":"$":"0.0" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day Monthly Cash Flow</b>
        </td>
        <td>
          {{ results.msgs["total_cf_for_month"] | currency: "USD":"$":"0.0" }}
        </td>
      </tr-->

      <!-- else -->
      <!--tr *ngIf="!results.msgs['freedom_in']">
        <td>
          <b>Financial Freedom Day</b>
        </td>
        <td>Cannot be achieved in the time frame specified.</td>
      </tr-->
    </table>
    <h5 style="margin-top: 20px">Income Streams</h5>
    <table *ngIf="client && client.plan && client.plan.inputs" style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Loans Rate of Return</b>
        </td>
        <td>{{ (+client.plan.inputs!.loansRateOfReturn! / 100) | percent}}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Loans Term</b>
        </td>
        <td>{{ client.plan.inputs!.loansTerm }} years</td>
      </tr>
      <!--tr
        *ngIf="
          results.msgs['freedom_in'] && results.msgs['freedom_total_months']
        "
      >
        <td style="width: 50%">
          <b>Starting LOC Size</b>
        </td>
        <td>
          {{ results.msgs["loc_size"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr
        *ngIf="
          results.msgs['freedom_in'] && results.msgs['freedom_total_months']
        "
      >
        <td style="width: 50%">
          <b>Duration of Passive Income</b>
        </td>
        <td>
          {{ results.msgs["freedom_total_years"] }} ({{
            results.msgs["freedom_total_months"]
          }}
          months)
        </td>
      </tr>

      <tr>
        <td>
          <b>Passive Income</b>
        </td>
        <td>
          <span *ngIf="results.msgs['passive_income']">
            {{ results.msgs["passive_income"] | currency: "USD":"$":"0.0-2" }}
            <span
              *ngIf="results.msgs['inflation'] && results.msgs['inflation'] > 0"
              >(inflation-adjusted to {{ results.msgs["inflation"] * 100 }} %
              )</span
            >

            <span
              *ngIf="
                results.msgs['inflation'] && results.msgs['inflation'] == 0
              "
              >(not accounting for inflation)</span
            >
          </span>

          <span *ngIf="!results.msgs['passive_income']"> None </span>
        </td>
      </tr>
      <tr>
        <td>
          <span *ngIf="results.msgs['freedom_in']">
            <b>Principal Invested</b> (by Financial Freedom Day)
          </span>
          <span *ngIf="!results.msgs['freedom_in']">
            <b>Principal Invested</b> (during
            {{ results.msgs["sim_years"] }} -year simulation)
          </span>
        </td>
        <td>
          {{ results.msgs["principal_invested"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends In</b>
        </td>
        <td>
          {{ results.msgs["contribution_in"] }}
        </td>
      </tr>
      <tr *ngIf="results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends On</b>
        </td>
        <td>
          {{ results.msgs["contribution_date"] }}
        </td>
      </tr>
      <tr *ngIf="!results.msgs['contribution_in']">
        <td>
          <b>Contribution Ends In</b>
        </td>
        <td>Doesn't end in the time frame specified.</td>
      </tr>

      <tr>
        <td>
          <b>Cash Flow End Date</b>
        </td>
        <td>
          <span *ngIf="results.msgs['end_in']">
            {{ results.msgs["end_in"] }} (date:
            {{ results.msgs["end_date"] }}
          </span>
          <span *ngIf="!results.msgs['end_in']">
            The cash flow does NOT end within the time frame specified.
          </span>
        </td>
      </tr>
      <tr *ngIf="results.msgs['final_loan_upb']">
        <td><b>Final Net Worth</b> (Final UPB - LOC Balance)</td>
        <td>
          {{ results.msgs["final_loan_upb"] | currency: "USD":"$":"0.0-2" }}
          -
          {{ results.msgs["final_loc_size"] | currency: "USD":"$":"0.0-2" }}
          =
          {{ results.msgs["final_net_worth"] | currency: "USD":"$":"0.0-2" }}
        </td>
      </tr-->
    </table>

    <h5 style="margin-top: 20px">Algorithm Settings</h5>
    <table *ngIf="client && client.plan && client.plan.inputs"  style="width: 100%">
      <tr>
        <td style="width: 50%">
          <b>Enable Advanced</b>
        </td>
        <td>{{ (client.plan.inputs!.algorithmAdvanced?'Yes':'No') }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Method</b>
        </td>
        <td>{{ client.plan.inputs!.methodToUse }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>GR</b>
        </td>
        <td>{{ client.plan.inputs!.growthCapital | currency }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>MGR</b>
        </td>
        <td>{{ client.plan.inputs!.monthsToPayOffLOC }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>MP3</b>
        </td>
        <td>{{ client.plan.inputs!.monthsToPayOffLOCPhase3 }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Keep Additional Income In System</b>
        </td>
        <td>{{ (client.plan.inputs!.keepAdditionalIncomeInSystem?'Yes':'No') }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>Enable Auto Increment</b>
        </td>
        <td>{{ (client.plan.inputs!.enableAutoIncrement?'Yes':'No') }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>AA</b>
        </td>
        <td>{{ client.plan.inputs!.autoIncrementAmount | currency }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>NumFlips</b>
        </td>
        <td>{{ client.plan.inputs!.autoIncrementNumFlips }}</td>
      </tr>
      <tr>
        <td style="width: 50%">
          <b>MAX</b>
        </td>
        <td>{{ client.plan.inputs!.autoIncrementMax | currency }}</td>
      </tr>
      <!--tr>
        <td style="width: 50%">
          <b>Flip Count</b> (during {{ results.msgs["sim_years"] }}-year
          simulation)
        </td>
        <td>
          {{ results.msgs["flip_count"] }}
        </td>
      </tr>
      <tr>
        <td>
          <b>For internal use only (PCounters)</b>
        </td>
        <td>
          {{ results.msgs["phase1_count"] }},
          {{ results.msgs["phase2_count"] }},
          {{ results.msgs["phase3_count"] }}
        </td>
      </tr-->
    </table>
  </ng-container>
</nz-modal>
<app-empty-state-section
*ngIf="!editPlanModalOpen && !clientHasPlan"
title="No Plan"
description="No Plan Has Been Configured Yet"
[placeholderImage]="emptyPlaceholderImage"
>
<app-core-button
  emptyStateActions
  type="primary"
  (click)="openEditPlanModal()"
  >Setup Income Plan</app-core-button
>
</app-empty-state-section>

<ng-template #emptyPlaceholderImage>
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 500 500"
  style="width: 210px; height: auto"
>
  <g id="freepik--background-simple--inject-70">
    <path
      d="M249.77,81a102.07,102.07,0,0,1,78.5,36.14c22.53,26.6,25.42,57.12,26.49,90.52.31,9.62,1.16,19.41,4.82,28.41,6.83,16.77,23.15,26.55,33.82,40.51a54.44,54.44,0,0,1,3.3,60.57C385,356.64,362.65,366.43,340.49,367c-27.28.67-52.23-11.86-76.69-22.28-25.53-10.88-48-12.72-74.42-4.31-22.86,7.28-46.82,18.26-71.25,12C72.91,340.75,58,286.76,68.57,245.83A104.55,104.55,0,0,1,91.88,202.4c12.18-13.61,28.79-22.84,43.22-33.89A57.22,57.22,0,0,0,147,155.68c6.17-8.86,10.6-18.81,16.44-27.89C183.29,96.92,216.45,81.05,249.77,81Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M249.77,81a102.07,102.07,0,0,1,78.5,36.14c22.53,26.6,25.42,57.12,26.49,90.52.31,9.62,1.16,19.41,4.82,28.41,6.83,16.77,23.15,26.55,33.82,40.51a54.44,54.44,0,0,1,3.3,60.57C385,356.64,362.65,366.43,340.49,367c-27.28.67-52.23-11.86-76.69-22.28-25.53-10.88-48-12.72-74.42-4.31-22.86,7.28-46.82,18.26-71.25,12C72.91,340.75,58,286.76,68.57,245.83A104.55,104.55,0,0,1,91.88,202.4c12.18-13.61,28.79-22.84,43.22-33.89A57.22,57.22,0,0,0,147,155.68c6.17-8.86,10.6-18.81,16.44-27.89C183.29,96.92,216.45,81.05,249.77,81Z"
      style="fill: #fff; opacity: 0.9"
    ></path>
  </g>
  <g id="freepik--Shadow--inject-70">
    <ellipse
      id="freepik--path--inject-70"
      cx="250"
      cy="416.24"
      rx="193.89"
      ry="11.32"
      style="fill: #f5f5f5"
    ></ellipse>
  </g>
  <g id="freepik--Plant--inject-70">
    <path
      d="M101,380.92h0a.4.4,0,0,1-.35-.44c0-.07.06-.84.12-2.17.11-2,.27-6.15.21-11.72a1.91,1.91,0,0,0,0-.24c-.08-6.22-.41-12.34-1-18.21-.54-5.47-1.31-10.78-2.29-15.78a126.45,126.45,0,0,0-3.19-12.76.06.06,0,0,1,0,0h0c-.19-.62-.39-1.23-.59-1.85s-.34-1-.52-1.51c-.45-1.31-.87-2.56-1.23-3.72-.8-2.55-1.5-5.1-2.1-7.57-.36-1.49-.68-2.95-1-4.35a95.19,95.19,0,0,1-1.81-16.07c-.06-2.13,0-4.25.09-6.3a60.7,60.7,0,0,1,.69-6.45c0-.15,0-.29.07-.42l0-.2a.46.46,0,0,1,.11-.27.4.4,0,0,1,.56,0,.43.43,0,0,1,.11.39h0l0,.21c0,.14,0,.29-.07.44a58.83,58.83,0,0,0-.68,6.35c-.12,2-.15,4.14-.09,6.24a93.68,93.68,0,0,0,1.8,15.94c.26,1.39.58,2.84.94,4.31.59,2.46,1.29,5,2.09,7.53.35,1.15.76,2.39,1.22,3.69.18.51.35,1,.52,1.52s.4,1.24.59,1.86l0,0h0a126.24,126.24,0,0,1,3.21,12.85c1,5,1.76,10.37,2.3,15.87.59,5.88.92,12,1,18.26v.28c.06,5.57-.11,9.75-.21,11.74-.06,1.35-.11,2.16-.12,2.23A.41.41,0,0,1,101,380.92Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M88.52,271.25v0l0,0a12.13,12.13,0,0,1,2.18-1.67,11.66,11.66,0,0,1,2.74-1.17"
      style="fill: #407bff"
    ></path>
    <path
      d="M88.52,271.64a.29.29,0,0,1-.16,0,.38.38,0,0,1-.2-.51.25.25,0,0,1,.08-.12.08.08,0,0,1,0,0h0a11.89,11.89,0,0,1,2.24-1.72A12.37,12.37,0,0,1,93.37,268a.39.39,0,0,1,.48.27.4.4,0,0,1-.27.49,11.17,11.17,0,0,0-2.66,1.14,12.25,12.25,0,0,0-2.1,1.6h0A.41.41,0,0,1,88.52,271.64Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M87.74,278.59a.35.35,0,0,1-.25-.09l-2.65-2.2a.39.39,0,0,1,.5-.6L88,277.9a.39.39,0,0,1,0,.55A.38.38,0,0,1,87.74,278.59Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M92.5,312.79a.42.42,0,0,1-.39-.41h0a10.76,10.76,0,0,1,2-4.89,10.31,10.31,0,0,1,1.59-1.72.39.39,0,0,1,.51.6A9.6,9.6,0,0,0,94.74,308a9.7,9.7,0,0,0-1.35,2.54,9.2,9.2,0,0,0-.5,1.92.37.37,0,0,1-.37.38Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M94.85,319.86a.4.4,0,0,1-.36-.24,6.63,6.63,0,0,0-3.93-3.55.41.41,0,0,1-.25-.5.4.4,0,0,1,.5-.25,7.45,7.45,0,0,1,4.4,4,.39.39,0,0,1-.2.52A.29.29,0,0,1,94.85,319.86Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M100.86,355c-.9-1-1.72-4.89-2.16-7-.11-.5-.19-.93-.27-1.24"
      style="fill: #407bff"
    ></path>
    <path
      d="M100.86,355.34a.39.39,0,0,1-.3-.13c-.89-1-1.63-4.22-2.25-7.16-.1-.5-.19-.92-.26-1.23a.4.4,0,0,1,.77-.18c.07.31.16.74.27,1.24.4,1.91,1.23,5.89,2.06,6.8a.4.4,0,0,1,0,.56A.37.37,0,0,1,100.86,355.34Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M90.41,305.23a.39.39,0,0,1-.39-.36C90,304.23,89,289,97.35,283a.4.4,0,0,1,.55.08.39.39,0,0,1-.09.55c-8,5.82-7,21.07-7,21.22a.39.39,0,0,1-.37.42Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M91.11,338.28c-1,3.68,7.16,10.25,7.16,10.25s10.3-1.75,11.26-5.43-1.92-9.5-7-10.83S92.07,334.6,91.11,338.28Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M80.5,294.61c1.16,3.81,8.69,6.23,8.69,6.23s4.9-6.21,3.74-10-5.45-7.88-8.88-6.83S79.34,290.8,80.5,294.61Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M100.57,306.1a10.1,10.1,0,0,1-3.1,1.94,3.37,3.37,0,0,1-3.43-.65c-1.05-1.11-.86-2.89-.29-4.3,1.12-2.8,5.5-7.64,9.08-6.47C108,298.33,102.76,304.32,100.57,306.1Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M90.47,313.54c.7.79,1.46,1.81,1.13,2.82a2.12,2.12,0,0,1-2.29,1.25,6.34,6.34,0,0,1-2.57-1.12c-1.84-1.17-5.64-3.62-3.4-6.17S89,311.86,90.47,313.54Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M104,278.08a1.85,1.85,0,0,1,1.74,1.29c.19.88-.51,1.7-1.2,2.28-1.73,1.45-6.92,5.39-8.27,1.87C94.94,280.19,101.58,277.93,104,278.08Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M93.57,266.76c1.11.05,2.19-.39,3.3-.37s2.38.86,2.18,1.95-1.41,1.4-2.43,1.51a20.43,20.43,0,0,1-5.24-.13,1.65,1.65,0,0,1-1.08-.49c-.48-.64.19-1.56,1-1.83a10.38,10.38,0,0,0,2.29-.65Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M83.1,274.83c-1.1.08-2.2-.34-3.31-.3s-2.36.91-2.14,2,1.45,1.37,2.47,1.46a19.91,19.91,0,0,0,5.23-.25,1.62,1.62,0,0,0,1.08-.51c.46-.65-.23-1.55-1-1.8a11,11,0,0,1-2.31-.6Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M88.21,272.72h0a.4.4,0,0,1-.36-.42c.15-2.16-.08-4.23-.49-4.35a.4.4,0,1,1,.22-.76c1.25.36,1.15,3.73,1,5.16A.39.39,0,0,1,88.21,272.72Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M88.94,267.65a9.51,9.51,0,0,0-2.9-3.92c-1.37-.91-3.51-.89-4.42.48-1,1.54.16,3.75,1.86,4.47s3.63.4,5.44.06c0-.27,0-.54,0-.81Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M102.23,394.2h-.07a.4.4,0,0,1-.32-.46c2.64-14.1,10.66-20.25,10.75-20.31a.39.39,0,1,1,.47.63c-.08.06-7.86,6.05-10.44,19.82A.4.4,0,0,1,102.23,394.2Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M110.52,376.36a.39.39,0,0,1-.14-.76,8.72,8.72,0,0,1,4.15-.15.4.4,0,0,1-.13.78,8,8,0,0,0-3.74.11A.34.34,0,0,1,110.52,376.36Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M108.74,378.57a.39.39,0,0,1-.39-.38,17.17,17.17,0,0,1,2.7-9.74.39.39,0,0,1,.55-.1.4.4,0,0,1,.1.55,16.64,16.64,0,0,0-2.56,9.25.4.4,0,0,1-.38.42Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M105.92,383.18a.39.39,0,0,1-.27-.11.4.4,0,0,1,0-.56,5.56,5.56,0,0,1,5.45-1.33.4.4,0,0,1,.26.49.39.39,0,0,1-.49.26,4.86,4.86,0,0,0-4.66,1.13A.38.38,0,0,1,105.92,383.18Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M104.48,386.37a.4.4,0,0,1-.36-.22,13.1,13.1,0,0,1-.84-6.68.4.4,0,0,1,.79.1,12.51,12.51,0,0,0,.76,6.24.39.39,0,0,1-.35.56Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M103,389.56c0,.06-.29.36.84.44,2.78.18,7.68.12,9.46-2.24,2.53-3.36-3.53-3.2-3-5.08s7.1-.17,8.3-2.84-3.54-2.26-3.36-3.64,5.21.54,6.77-2.29-2.75-1.4-2.08-2.57,6-6,3.78-7.13-7.31,3.87-9,4.38-2.24-4.56-4.44-2.93-.5,9.32-3.09,10.2c-1.94.66-1-2.05-3.36-2C100.59,373.91,99.83,384.6,103,389.56Z"
      style="fill: #407bff"
    ></path>
    <g style="opacity: 0.30000000000000004">
      <path
        d="M103,389.56c0,.06-.29.36.84.44,2.78.18,7.68.12,9.46-2.24,2.53-3.36-3.53-3.2-3-5.08s7.1-.17,8.3-2.84-3.54-2.26-3.36-3.64,5.21.54,6.77-2.29-2.75-1.4-2.08-2.57,6-6,3.78-7.13-7.31,3.87-9,4.38-2.24-4.56-4.44-2.93-.5,9.32-3.09,10.2c-1.94.66-1-2.05-3.36-2C100.59,373.91,99.83,384.6,103,389.56Z"
        style="fill: #fff"
      ></path>
    </g>
    <path
      d="M101.93,394.55a.39.39,0,0,1-.38-.3,43.1,43.1,0,0,0-11.26-19.17.4.4,0,1,1,.53-.59,44,44,0,0,1,11.49,19.56.39.39,0,0,1-.28.48Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M95.05,380.76H95l-.09,0,0,0a10.73,10.73,0,0,0-4.31-.65.39.39,0,1,1,0-.78,11.6,11.6,0,0,1,4.63.69l0,0a.39.39,0,0,1-.11.77Zm.11-.77.09,0Zm0,0Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M96.63,382.68h0a.39.39,0,0,1-.38-.41A14.56,14.56,0,0,0,94,374.15a.39.39,0,1,1,.64-.45A15,15,0,0,1,97,382.31.39.39,0,0,1,96.63,382.68Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M98.28,385.26a.4.4,0,0,1-.27-.11,4.34,4.34,0,0,0-4.08-.82.39.39,0,0,1-.49-.26.4.4,0,0,1,.26-.49,5.11,5.11,0,0,1,4.85,1,.39.39,0,0,1-.27.68Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M100.42,389.53a.33.33,0,0,1-.17,0,.39.39,0,0,1-.19-.52,11.08,11.08,0,0,0,.66-5.5.4.4,0,0,1,.35-.44.39.39,0,0,1,.44.34,11.5,11.5,0,0,1-.74,5.93A.37.37,0,0,1,100.42,389.53Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M101.71,392.55c-2.14.14-5.89-1.69-7.42-3.57s2.62-.86,2.13-2.39-5.51-.16-6.54-2.33,2.65-1.82,2.45-2.95-4-.73-5.29-3,2.07-1.12,1.51-2.08-4.92-4.87-3.21-5.8,5.81,3.17,7.14,3.59,1.51-2.55,3.27-1.21.76,7.56,2.81,8.29c1.52.54.76-2.26,2.51-1.62C104.14,380.57,102.58,392.5,101.71,392.55Z"
      style="fill: #407bff"
    ></path>
    <g style="opacity: 0.2">
      <path
        d="M101.71,392.55c-2.14.14-5.89-1.69-7.42-3.57s2.62-.86,2.13-2.39-5.51-.16-6.54-2.33,2.65-1.82,2.45-2.95-4-.73-5.29-3,2.07-1.12,1.51-2.08-4.92-4.87-3.21-5.8,5.81,3.17,7.14,3.59,1.51-2.55,3.27-1.21.76,7.56,2.81,8.29c1.52.54.76-2.26,2.51-1.62C104.14,380.57,102.58,392.5,101.71,392.55Z"
        style="fill: #fff"
      ></path>
    </g>
    <path
      d="M118.84,396.72c0,10.39,4.59,18.82-18.82,18.82-20.8,0-18.81-8.43-18.81-18.82s8.42-13.34,18.81-13.34S118.84,386.32,118.84,396.72Z"
      style="fill: #263238"
    ></path>
    <rect
      x="79.22"
      y="383.18"
      width="41.66"
      height="10.31"
      style="fill: #263238"
    ></rect>
    <rect
      x="79.22"
      y="383.18"
      width="41.66"
      height="10.31"
      style="fill: #fff; opacity: 0.2; isolation: isolate"
    ></rect>
  </g>
  <g id="freepik--Screen--inject-70">
    <path
      d="M336.55,181V329.19a2.72,2.72,0,0,1-2.72,2.72H119.06a2.72,2.72,0,0,1-2.72-2.72V181a2.72,2.72,0,0,1,2.72-2.72H333.83A2.72,2.72,0,0,1,336.55,181Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M336.55,187.47V329.19a2.72,2.72,0,0,1-2.72,2.72H119.06a2.72,2.72,0,0,1-2.72-2.72V187.47Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <path
      d="M336.55,323.16v6a2.72,2.72,0,0,1-2.72,2.72H119.06a2.72,2.72,0,0,1-2.72-2.72v-6Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <circle
      cx="331.1"
      cy="183.11"
      r="1.88"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></circle>
    <circle
      cx="323.98"
      cy="183.11"
      r="1.88"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></circle>
    <path
      d="M318.74,183.11a1.88,1.88,0,1,1-1.88-1.88A1.88,1.88,0,0,1,318.74,183.11Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <g style="opacity: 0.5; isolation: isolate">
      <path
        d="M144.29,202.36v6.07h-.71l-3.64-4.52v4.52h-.86v-6.07h.71l3.64,4.53v-4.53Z"
      ></path>
      <path
        d="M150.12,206.41H146.4a1.55,1.55,0,0,0,1.66,1.35,1.74,1.74,0,0,0,1.33-.56l.46.54a2.33,2.33,0,0,1-1.82.74,2.3,2.3,0,0,1-2.47-2.35,2.25,2.25,0,0,1,2.31-2.34,2.22,2.22,0,0,1,2.27,2.37A2.29,2.29,0,0,1,150.12,206.41Zm-3.72-.6h2.94a1.48,1.48,0,0,0-2.94,0Z"
      ></path>
      <path
        d="M158.16,203.84l-1.72,4.59h-.8L154.32,205,153,208.43h-.8l-1.7-4.59h.79l1.33,3.66,1.39-3.66h.7l1.36,3.67,1.37-3.67Z"
      ></path>
      <path
        d="M165.46,206.41h-3.73a1.55,1.55,0,0,0,1.66,1.35,1.74,1.74,0,0,0,1.33-.56l.46.54a2.33,2.33,0,0,1-1.81.74,2.3,2.3,0,0,1-2.47-2.35,2.25,2.25,0,0,1,2.3-2.34,2.22,2.22,0,0,1,2.27,2.37A2.18,2.18,0,0,1,165.46,206.41Zm-3.73-.6h3a1.49,1.49,0,0,0-3,0Z"
      ></path>
      <path
        d="M171,205.79v2.64h-.83v-2.55c0-.9-.45-1.34-1.24-1.34a1.36,1.36,0,0,0-1.45,1.53v2.36h-.84v-4.59h.8v.69a2,2,0,0,1,1.66-.74A1.78,1.78,0,0,1,171,205.79Z"
      ></path>
      <path
        d="M175.11,208.16a1.55,1.55,0,0,1-1,.32,1.31,1.31,0,0,1-1.45-1.44v-2.52h-.78v-.68h.78v-1h.83v1h1.32v.68h-1.32V207a.68.68,0,0,0,.72.77,1.06,1.06,0,0,0,.65-.22Z"
      ></path>
      <path
        d="M178.58,203.79v.81h-.19a1.38,1.38,0,0,0-1.45,1.55v2.29h-.83v-4.59h.8v.77A1.75,1.75,0,0,1,178.58,203.79Z"
      ></path>
      <path
        d="M179.46,202.42a.54.54,0,0,1,.56-.55.54.54,0,1,1-.56.55Zm.14,1.42h.84v4.59h-.84Z"
      ></path>
      <path
        d="M186.15,206.41h-3.72a1.55,1.55,0,0,0,1.66,1.35,1.74,1.74,0,0,0,1.33-.56l.46.54a2.33,2.33,0,0,1-1.81.74,2.3,2.3,0,0,1-2.47-2.35,2.24,2.24,0,0,1,2.3-2.34,2.22,2.22,0,0,1,2.27,2.37A2.29,2.29,0,0,1,186.15,206.41Zm-3.72-.6h2.94a1.48,1.48,0,0,0-2.94,0Z"
      ></path>
      <path
        d="M186.74,208l.35-.66a3,3,0,0,0,1.61.47c.77,0,1.09-.23,1.09-.62,0-1-2.9-.14-2.9-2,0-.82.74-1.38,1.91-1.38a3.37,3.37,0,0,1,1.68.42l-.36.66a2.35,2.35,0,0,0-1.32-.36c-.73,0-1.09.26-1.09.63,0,1.08,2.91.2,2.91,2,0,.84-.76,1.36-2,1.36A3.35,3.35,0,0,1,186.74,208Z"
      ></path>
    </g>
    <path
      d="M135,205.89a3.9,3.9,0,1,1-3.89-3.89A3.89,3.89,0,0,1,135,205.89Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M131.06,208.16a.5.5,0,0,1-.5-.5v-3.54a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v3.54A.5.5,0,0,1,131.06,208.16Z"
      style="fill: #fff"
    ></path>
    <path
      d="M132.83,206.39h-3.54a.5.5,0,1,1,0-1h3.54a.5.5,0,1,1,0,1Z"
      style="fill: #fff"
    ></path>
    <rect
      x="274.3"
      y="225.33"
      width="49.44"
      height="32.09"
      rx="2.96"
      style="opacity: 0.2; isolation: isolate"
    ></rect>
    <path
      d="M312.86,233.75h-4.67a1.47,1.47,0,0,1-1.36-.91l-1-2.34a1.46,1.46,0,0,0-1.35-.91h-11a1.47,1.47,0,0,0-1.35.91l-1,2.34a1.47,1.47,0,0,1-1.35.91h-4.68a2.67,2.67,0,0,0-2.67,2.67v13.45a2.68,2.68,0,0,0,2.67,2.67h27.69a2.67,2.67,0,0,0,2.67-2.67V236.42A2.66,2.66,0,0,0,312.86,233.75Zm-13.84,14a4.48,4.48,0,1,1,4.47-4.47A4.48,4.48,0,0,1,299,247.77Z"
      style="fill: #fff"
    ></path>
    <g style="opacity: 0.5; isolation: isolate">
      <path d="M292.9,220.36h.4v2.79h-.4Z"></path>
      <path
        d="M297.6,221.93v1.22h-.39V222c0-.42-.2-.62-.54-.62a.61.61,0,0,0-.64.7v1.09h-.38V222c0-.42-.2-.62-.55-.62a.62.62,0,0,0-.64.7v1.09h-.38V221h.37v.31a.84.84,0,0,1,.73-.33.76.76,0,0,1,.73.39,1,1,0,0,1,.82-.39A.81.81,0,0,1,297.6,221.93Z"
      ></path>
      <path
        d="M300,221.87v1.28h-.37v-.28a.75.75,0,0,1-.69.31c-.48,0-.79-.26-.79-.63s.22-.62.86-.62h.6v-.07c0-.33-.18-.51-.56-.51a1.09,1.09,0,0,0-.68.22l-.16-.28a1.4,1.4,0,0,1,.88-.27C299.66,221,300,221.29,300,221.87Zm-.39.64v-.3H299c-.38,0-.49.15-.49.33s.18.34.47.34A.61.61,0,0,0,299.6,222.51Z"
      ></path>
      <path
        d="M302.74,221v1.83a1,1,0,0,1-1.1,1.08,1.54,1.54,0,0,1-1-.31l.18-.3a1.31,1.31,0,0,0,.82.28c.5,0,.73-.23.73-.71v-.17a1,1,0,0,1-.77.33,1,1,0,1,1,0-2,1,1,0,0,1,.79.34V221Zm-.38,1a.68.68,0,0,0-.72-.69.69.69,0,1,0,0,1.38A.68.68,0,0,0,302.36,222Z"
      ></path>
      <path
        d="M305.38,222.22h-1.72a.72.72,0,0,0,.77.62.79.79,0,0,0,.61-.25l.21.24a1,1,0,0,1-.83.35,1.06,1.06,0,0,1-1.14-1.09,1,1,0,0,1,1.06-1.07,1,1,0,0,1,1.05,1.09S305.38,222.19,305.38,222.22Zm-1.72-.27H305a.68.68,0,0,0-1.36,0Z"
      ></path>
    </g>
    <rect
      x="127.16"
      y="225.33"
      width="139.57"
      height="11.47"
      rx="2.77"
      style="fill: #fff"
    ></rect>
    <g style="opacity: 0.5; isolation: isolate">
      <path d="M129.52,220.7h-1v-.34h2.31v.34h-1v2.45h-.4Z"></path>
      <path
        d="M131.14,220.38a.26.26,0,1,1,.26.25A.25.25,0,0,1,131.14,220.38Zm.07.66h.38v2.11h-.38Z"
      ></path>
      <path
        d="M133.52,223a.75.75,0,0,1-.47.15.61.61,0,0,1-.67-.67v-1.16H132V221h.36v-.47h.39V221h.6v.31h-.6v1.15c0,.23.11.35.33.35a.45.45,0,0,0,.3-.1Z"
      ></path>
      <path
        d="M135.12,223a.73.73,0,0,1-.46.15.61.61,0,0,1-.67-.67v-1.16h-.36V221H134v-.47h.38V221H135v.31h-.61v1.15a.31.31,0,0,0,.33.35.45.45,0,0,0,.3-.1Z"
      ></path>
      <path d="M135.59,220.19H136v3h-.38Z"></path>
      <path
        d="M138.61,222.22h-1.72a.72.72,0,0,0,.77.62.81.81,0,0,0,.61-.25l.21.24a1,1,0,0,1-.84.35,1.06,1.06,0,0,1-1.13-1.09,1,1,0,0,1,1.06-1.07,1,1,0,0,1,1.05,1.09S138.61,222.19,138.61,222.22Zm-1.72-.27h1.36a.68.68,0,0,0-1.36,0Z"
      ></path>
    </g>
    <rect
      x="127.16"
      y="245.95"
      width="139.57"
      height="11.47"
      rx="2.77"
      style="fill: #fff"
    ></rect>
    <g style="opacity: 0.5; isolation: isolate">
      <path
        d="M128.74,242.37a1.41,1.41,0,0,1,1.48-1.43,1.39,1.39,0,0,1,1.07.44l-.26.25a1,1,0,0,0-.79-.33,1.08,1.08,0,1,0,.79,1.81l.26.25a1.39,1.39,0,0,1-1.07.44A1.41,1.41,0,0,1,128.74,242.37Z"
      ></path>
      <path
        d="M133.46,242.49v1.28h-.37v-.28a.78.78,0,0,1-.69.3c-.48,0-.79-.25-.79-.62s.22-.62.85-.62h.61v-.07c0-.33-.19-.52-.57-.52a1.08,1.08,0,0,0-.67.23l-.16-.29a1.46,1.46,0,0,1,.88-.27C133.13,241.63,133.46,241.91,133.46,242.49Zm-.39.63v-.29h-.59c-.38,0-.49.15-.49.33s.18.34.47.34A.6.6,0,0,0,133.07,243.12Z"
      ></path>
      <path
        d="M135.36,243.65a.72.72,0,0,1-.46.14.6.6,0,0,1-.67-.66V242h-.36v-.32h.36v-.46h.38v.46h.61V242h-.61v1.15a.31.31,0,0,0,.33.35.51.51,0,0,0,.3-.1Z"
      ></path>
      <path
        d="M137.66,242.84h-1.72a.72.72,0,0,0,.77.62.77.77,0,0,0,.61-.26l.21.25a1.15,1.15,0,0,1-2-.74,1,1,0,0,1,1.06-1.08,1,1,0,0,1,1,1.09S137.66,242.8,137.66,242.84Zm-1.72-.28h1.36a.66.66,0,0,0-.68-.6A.65.65,0,0,0,135.94,242.56Z"
      ></path>
      <path
        d="M140.23,241.65v1.83a1,1,0,0,1-1.1,1.09,1.55,1.55,0,0,1-1-.32l.18-.29a1.28,1.28,0,0,0,.82.27c.5,0,.72-.23.72-.71v-.17a.93.93,0,0,1-.76.33,1,1,0,1,1,0-2.05,1,1,0,0,1,.78.35v-.33Zm-.38,1a.73.73,0,0,0-1.46,0,.73.73,0,0,0,1.46,0Z"
      ></path>
      <path
        d="M140.77,242.71a1.1,1.1,0,1,1,1.1,1.08A1.05,1.05,0,0,1,140.77,242.71Zm1.81,0a.69.69,0,0,0-.71-.74.7.7,0,0,0-.72.74.71.71,0,0,0,.72.75A.7.7,0,0,0,142.58,242.71Z"
      ></path>
      <path
        d="M144.64,241.63V242h-.09a.63.63,0,0,0-.67.71v1.06h-.38v-2.12h.37V242A.8.8,0,0,1,144.64,241.63Z"
      ></path>
      <path
        d="M145,241a.26.26,0,0,1,.26-.26.25.25,0,0,1,.26.25.24.24,0,0,1-.26.25A.25.25,0,0,1,145,241Zm.07.65h.39v2.12h-.39Z"
      ></path>
      <path
        d="M148.14,242.84h-1.72a.71.71,0,0,0,.76.62.79.79,0,0,0,.62-.26l.21.25a1.08,1.08,0,0,1-.84.34,1.06,1.06,0,0,1-1.14-1.08,1,1,0,0,1,1.07-1.08,1,1,0,0,1,1,1.09Zm-1.72-.28h1.36a.66.66,0,0,0-.68-.6A.65.65,0,0,0,146.42,242.56Z"
      ></path>
      <path
        d="M148.41,243.55l.16-.31a1.35,1.35,0,0,0,.74.22c.36,0,.51-.11.51-.29,0-.47-1.34-.06-1.34-.9,0-.38.34-.64.88-.64a1.51,1.51,0,0,1,.77.19l-.16.31a1.07,1.07,0,0,0-.61-.17c-.34,0-.5.12-.5.29,0,.5,1.34.09,1.34.92,0,.38-.35.62-.92.62A1.52,1.52,0,0,1,148.41,243.55Z"
      ></path>
    </g>
    <rect
      x="127.16"
      y="268.68"
      width="196.57"
      height="27.47"
      rx="2.77"
      style="fill: #fff"
    ></rect>
    <g style="opacity: 0.5; isolation: isolate">
      <path
        d="M129,263.71h1.18a1.4,1.4,0,1,1,0,2.8H129Zm1.15,2.45a1.05,1.05,0,1,0,0-2.1h-.75v2.1Z"
      ></path>
      <path
        d="M134.12,265.58h-1.71a.71.71,0,0,0,.76.62.79.79,0,0,0,.61-.26l.22.25a1.1,1.1,0,0,1-.84.34,1.06,1.06,0,0,1-1.14-1.08,1,1,0,0,1,1.07-1.08,1,1,0,0,1,1,1.09A.53.53,0,0,1,134.12,265.58Zm-1.71-.28h1.35a.65.65,0,0,0-.67-.61A.66.66,0,0,0,132.41,265.3Z"
      ></path>
      <path
        d="M135.86,266.38a.75.75,0,0,1-.47.15.6.6,0,0,1-.67-.66V264.7h-.36v-.31h.36v-.47h.38v.47h.61v.31h-.61v1.15a.32.32,0,0,0,.34.36.51.51,0,0,0,.3-.1Z"
      ></path>
      <path
        d="M138,265.23v1.28h-.36v-.28a.8.8,0,0,1-.7.3c-.48,0-.78-.25-.78-.62s.22-.62.85-.62h.61v-.08c0-.32-.19-.51-.57-.51a1.06,1.06,0,0,0-.67.23l-.16-.29a1.37,1.37,0,0,1,.87-.27C137.67,264.37,138,264.65,138,265.23Zm-.38.63v-.3H137c-.38,0-.48.15-.48.33s.17.35.46.35A.64.64,0,0,0,137.62,265.86Z"
      ></path>
      <path
        d="M138.65,263.73a.26.26,0,1,1,.25.25A.25.25,0,0,1,138.65,263.73Zm.06.66h.39v2.12h-.39Z"
      ></path>
      <path d="M139.83,263.54h.38v3h-.38Z"></path>
      <path
        d="M140.68,266.28l.16-.3a1.32,1.32,0,0,0,.74.22c.36,0,.5-.11.5-.29,0-.47-1.33-.06-1.33-.91,0-.38.34-.63.88-.63a1.54,1.54,0,0,1,.77.19l-.16.3a1.17,1.17,0,0,0-.62-.16c-.33,0-.5.12-.5.29,0,.5,1.35.09,1.35.91,0,.39-.35.63-.92.63A1.5,1.5,0,0,1,140.68,266.28Z"
      ></path>
    </g>
    <rect
      x="116.34"
      y="187.47"
      width="220.21"
      height="8.91"
      style="opacity: 0.2; isolation: isolate"
    ></rect>
    <rect
      x="289.78"
      y="305.12"
      width="34.37"
      height="10.29"
      rx="2.45"
      style="fill: #407bff"
    ></rect>
    <path
      d="M299.21,311.89l.22-.47a2.2,2.2,0,0,0,1.39.49c.73,0,1-.28,1-.65,0-1-2.55-.38-2.55-1.94,0-.65.51-1.2,1.61-1.2a2.57,2.57,0,0,1,1.35.37l-.2.48a2.19,2.19,0,0,0-1.15-.34c-.71,0-1,.3-1,.67,0,1,2.55.38,2.55,1.92,0,.64-.52,1.2-1.63,1.2A2.52,2.52,0,0,1,299.21,311.89Z"
      style="fill: #fff"
    ></path>
    <path
      d="M306,311.32h-2.23l-.46,1.05h-.62l1.9-4.2h.59l1.91,4.2h-.63Zm-.21-.48-.91-2.06-.9,2.06Z"
      style="fill: #fff"
    ></path>
    <path
      d="M311.14,308.17l-1.84,4.2h-.6l-1.84-4.2h.64l1.52,3.45,1.52-3.45Z"
      style="fill: #fff"
    ></path>
    <path
      d="M314.72,311.85v.52h-3v-4.2h3v.52h-2.37V310h2.11v.51h-2.11v1.36Z"
      style="fill: #fff"
    ></path>
    <rect
      x="241.37"
      y="305.12"
      width="44.37"
      height="10.29"
      rx="2.45"
      style="opacity: 0.4; isolation: isolate"
    ></rect>
    <path
      d="M249.77,308.17h1.77a2.11,2.11,0,1,1,0,4.2h-1.77Zm1.73,3.68a1.58,1.58,0,1,0,0-3.16h-1.13v3.16Z"
      style="fill: #fff"
    ></path>
    <path d="M254.72,308.17h.6v4.2h-.6Z" style="fill: #fff"></path>
    <path
      d="M256.2,311.89l.22-.47a2.18,2.18,0,0,0,1.39.49c.72,0,1-.28,1-.65,0-1-2.54-.38-2.54-1.94,0-.65.51-1.2,1.61-1.2a2.57,2.57,0,0,1,1.35.37l-.2.48a2.21,2.21,0,0,0-1.15-.34c-.71,0-1,.3-1,.67,0,1,2.54.38,2.54,1.92,0,.64-.51,1.2-1.62,1.2A2.52,2.52,0,0,1,256.2,311.89Z"
      style="fill: #fff"
    ></path>
    <path
      d="M260,310.27a2.13,2.13,0,0,1,2.22-2.15,2.1,2.1,0,0,1,1.6.66l-.39.37a1.56,1.56,0,0,0-1.18-.49,1.61,1.61,0,1,0,1.18,2.72l.39.38a2.1,2.1,0,0,1-1.6.66A2.13,2.13,0,0,1,260,310.27Z"
      style="fill: #fff"
    ></path>
    <path
      d="M267.22,311.32H265l-.46,1.05h-.62l1.9-4.2h.59l1.91,4.2h-.63Zm-.21-.48-.9-2.06-.91,2.06Z"
      style="fill: #fff"
    ></path>
    <path
      d="M271.74,312.37l-.9-1.29h-1.3v1.28h-.6v-4.2h1.63c1.1,0,1.76.55,1.76,1.46a1.33,1.33,0,0,1-.93,1.33l1,1.41Zm0-2.74c0-.6-.41-.94-1.17-.94h-1v1.89h1C271.32,310.58,271.73,310.23,271.73,309.63Z"
      style="fill: #fff"
    ></path>
    <path
      d="M273.3,308.17h1.77a2.11,2.11,0,1,1,0,4.2H273.3Zm1.73,3.68a1.58,1.58,0,1,0,0-3.16H273.9v3.16Z"
      style="fill: #fff"
    ></path>
    <path
      d="M124.3,192.33h-4a.5.5,0,0,1,0-1h4a.5.5,0,0,1,0,1Z"
      style="fill: #fff"
    ></path>
    <path
      d="M121.48,194a.51.51,0,0,1-.36-.15l-1.62-1.62a.5.5,0,0,1,0-.7l1.62-1.63a.51.51,0,0,1,.71,0,.5.5,0,0,1,0,.71l-1.27,1.27,1.27,1.27a.48.48,0,0,1,0,.7A.5.5,0,0,1,121.48,194Z"
      style="fill: #fff"
    ></path>
    <path
      d="M332.48,190.33h-4.25a.5.5,0,0,1,0-1h4.25a.5.5,0,0,1,0,1Z"
      style="fill: #fff"
    ></path>
    <path
      d="M332.48,192.33h-4.25a.5.5,0,0,1,0-1h4.25a.5.5,0,0,1,0,1Z"
      style="fill: #fff"
    ></path>
    <path
      d="M332.48,194.33h-4.25a.5.5,0,0,1,0-1h4.25a.5.5,0,0,1,0,1Z"
      style="fill: #fff"
    ></path>
  </g>
  <g id="freepik--Character--inject-70">
    <path
      d="M374.44,282.62s-13.9-11.14-22,6.38c-4.58,9.89-.91,30.76,8.12,37.83S388.37,318,388.37,318s4.15-20.16-2.19-28.64C378.06,278.52,374.44,282.62,374.44,282.62Z"
      style="fill: #263238"
    ></path>
    <path
      d="M355,325.62a.24.24,0,0,1-.22-.14c-.1-.19-9.36-19.49-4.78-34,1.34-4.21,3.66-7.12,6.92-8.63,5.89-2.74,12.87.09,12.94.12a.24.24,0,0,1,.13.33.25.25,0,0,1-.32.13c-.07,0-6.86-2.78-12.55-.13-3.12,1.46-5.35,4.26-6.64,8.33-4.52,14.28,4.65,33.4,4.74,33.59a.25.25,0,0,1-.11.34A.22.22,0,0,1,355,325.62Z"
      style="fill: #263238"
    ></path>
    <path
      d="M355.39,333.11s-2.46,22.15-3.26,24.79-12.08,16.74-12.08,16.74l3.2,1.66s12-12.33,13.91-16,4.76-22.76,4.76-22.76Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M366.42,342.86l-.62-.1L357,341.32l-4.69-.76s.41-13.45,5.34-17.3l4.74,4.39Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M365.8,342.76,357,341.32c-.69-6.14,2.36-12.67,2.36-12.67C363.29,331.3,365.48,337.69,365.8,342.76Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M374,362.88l-7.47,11.83c-14.83-3.45-62.36-10.7-68.19,5.6-7.26,20.27,45.17,36.42,74.18,37.41s52.29-15.52,33.95-57.82Z"
      style="fill: #263238"
    ></path>
    <path
      d="M374,362.88l-7.47,11.83c-14.83-3.45-62.36-10.7-68.19,5.6-7.26,20.27,45.17,36.42,74.18,37.41s52.29-15.52,33.95-57.82Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M305,407.78a2.63,2.63,0,0,1-.91-1.31,1,1,0,0,1,.35-.93.59.59,0,0,1,.58-.17c1,.29,1.93,3.08,2,3.4a.14.14,0,0,1-.05.18.18.18,0,0,1-.21,0A10.57,10.57,0,0,1,305,407.78Zm.16-1.92a.71.71,0,0,0-.22-.13.2.2,0,0,0-.23.07.62.62,0,0,0-.25.63,4.19,4.19,0,0,0,2.07,2A8.17,8.17,0,0,0,305.11,405.86Z"
      style="fill: #263238"
    ></path>
    <path
      d="M306.69,409l0-.08c-.34-.86-.43-3.12.27-3.62a.62.62,0,0,1,.78.08,1.06,1.06,0,0,1,.46.7c.22,1.14-1.12,2.83-1.21,2.91a.17.17,0,0,1-.18.05A.18.18,0,0,1,306.69,409Zm.85-3.28-.07-.05c-.2-.14-.29-.08-.33-.05-.41.28-.48,1.87-.26,2.83a3.92,3.92,0,0,0,.89-2.32.73.73,0,0,0-.23-.41Z"
      style="fill: #263238"
    ></path>
    <path
      d="M358.72,415.61a2.63,2.63,0,0,1,.16,1.59,1,1,0,0,1-.76.65.63.63,0,0,1-.59-.13c-.7-.73-.19-3.64-.12-4a.16.16,0,0,1,.13-.14.19.19,0,0,1,.19.1A11,11,0,0,1,358.72,415.61Zm-1.07,1.61a.71.71,0,0,0,.13.22.22.22,0,0,0,.23.05.64.64,0,0,0,.53-.44,4.14,4.14,0,0,0-.85-2.74A8.13,8.13,0,0,0,357.65,417.22Z"
      style="fill: #263238"
    ></path>
    <polygon
      points="338.74 399.04 350.68 403.29 356.41 405.33 354.94 411.6 349.39 410.42 335.83 407.53 338.74 399.04"
      style="fill: #ebb376"
    ></polygon>
    <path
      d="M353.38,411.9l1-7.4a.74.74,0,0,1,.86-.63l3.65.61s5,.46,5.95,1.74c2.89,3.72,1.09,20.15-.84,20.73-.7.21-1.45-.76-1.75-1.42-1-2.12-2.69-7.66-3.52-9.41-1-2.05-3.64-3.06-4.84-3.42A.72.72,0,0,1,353.38,411.9Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M357.77,413.74l0,.08c-.12.92-1.14,2.94-2,3a.62.62,0,0,1-.64-.43,1.11,1.11,0,0,1-.07-.84c.36-1.11,2.35-1.94,2.47-2a.15.15,0,0,1,.18,0A.18.18,0,0,1,357.77,413.74Zm-2.34,2.45,0,.08c.11.23.21.21.26.21.5-.05,1.33-1.4,1.61-2.35-.57.27-1.69.9-1.91,1.59a.75.75,0,0,0,0,.47Z"
      style="fill: #263238"
    ></path>
    <polygon
      points="338.74 399.04 350.68 403.29 349.39 410.42 335.83 407.53 338.74 399.04"
      style="opacity: 0.2; isolation: isolate"
    ></polygon>
    <polygon
      points="347.27 411.49 343.84 411.74 346.14 399.93 350.03 402.08 347.27 411.49"
      style="fill: #407bff"
    ></polygon>
    <polygon
      points="347.27 411.49 343.84 411.74 346.14 399.93 350.03 402.08 347.27 411.49"
      style="fill: #fff; opacity: 0.6000000000000001; isolation: isolate"
    ></polygon>
    <path
      d="M397.07,365.6l-17.88,19.82s-19.77-.44-43.77,11.29c0,0-25.75-10.63-28.12-19.47-3.1-11.58,59.22-2.53,59.22-2.53Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M313.21,379.6v2.94c0,.75.89,1.06,1.63,1.06h58.71c.75,0,1.66-.31,1.66-1.06V379.6Z"
      style="fill: #263238"
    ></path>
    <path
      d="M313.21,379.6v2.94c0,.75.89,1.06,1.63,1.06h58.71c.75,0,1.66-.31,1.66-1.06V379.6Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <path
      d="M313.21,379.6v2.94c0,.75.89,1.06,1.63,1.06h38.72c.75,0,1.65-.31,1.65-1.06V379.6Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M303.39,349.1l7,31.7a2.39,2.39,0,0,0,2.27,1.8h38.18a1.53,1.53,0,0,0,1.43-2l-7-31.46A2.16,2.16,0,0,0,343,347.6H304.83C303.8,347.6,303.16,348.06,303.39,349.1Z"
      style="fill: #263238"
    ></path>
    <path
      d="M303.39,349.1l7,31.7a2.39,2.39,0,0,0,2.27,1.8h36.88a1.55,1.55,0,0,0,1.45-2l-7-31.46a2.17,2.17,0,0,0-2.27-1.55H304.83C303.8,347.6,303.16,348.06,303.39,349.1Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <path
      d="M322.21,365.21h0c0,2.71,2.91,4.92,5,4.92s3.4-2.21,2.79-4.92-2.73-4.83-4.82-4.91c.61,2.71-.69,4.91-2.82,4.91A1,1,0,0,1,322.21,365.21Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <polygon
      points="330.45 412.62 317.95 414.67 311.95 415.66 310.19 409.46 315.63 407.81 328.89 403.77 330.45 412.62"
      style="fill: #ebb376"
    ></polygon>
    <path
      d="M311.42,408.45l2.7,7a.74.74,0,0,1-.44,1l-3.49,1.24s-4.57,2-6.05,1.36c-4.33-1.86-10.71-17.11-9.3-18.55.51-.52,1.63,0,2.22.4,1.87,1.38,6.05,5.39,7.63,6.52,1.85,1.33,4.67.92,5.89.65A.73.73,0,0,1,311.42,408.45Z"
      style="fill: #407bff"
    ></path>
    <polygon
      points="330.45 412.62 317.95 414.67 315.63 407.81 328.89 403.77 330.45 412.62"
      style="opacity: 0.2; isolation: isolate"
    ></polygon>
    <path
      d="M317.78,406.51s51-28.65,68.56-27.84,20.13,16.33,12.87,26-20.66,12.07-20.66,12.07,9.13-12.89,7.81-16.52-6.48,1.43-6.48,1.43S347.81,412,319.76,414.42Z"
      style="fill: #263238"
    ></path>
    <polygon
      points="316.96 405.73 319.84 403.79 323.55 415.17 319.1 415.17 316.96 405.73"
      style="fill: #407bff"
    ></polygon>
    <polygon
      points="316.96 405.73 319.84 403.79 323.55 415.17 319.1 415.17 316.96 405.73"
      style="fill: #fff; opacity: 0.6000000000000001; isolation: isolate"
    ></polygon>
    <path
      d="M406.46,359.9l-32.47,3s-5.09-10.54-9-14.65-7.36-25-7.36-25a69.34,69.34,0,0,1,8.14-3.32,80.68,80.68,0,0,1,24.84-4.18h.18c2.42,0,3.82.12,3.82.12a6.9,6.9,0,0,1,2.31,2.63c3.48,6,6.25,20,7.89,30.05C405.88,355.05,406.46,359.9,406.46,359.9Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M404.82,348.56c-7.16-3.28-16.93-8.83-16.42-14.46.55-6.18,5.56-12.4,8.53-15.59C400.41,324.51,403.18,338.51,404.82,348.56Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M386.05,344.46c1.64,4.71-.55,9.23-4.88,10.08s-9.17-2.27-10.8-7,.54-9.23,4.88-10.09S384.42,339.75,386.05,344.46Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <polygon
      points="372.1 353.21 374.14 336.34 387.59 350.91 372.1 353.21"
      style="fill: #407bff"
    ></polygon>
    <path
      d="M390.8,315.76a24.51,24.51,0,0,1-5.59,10.93,13.94,13.94,0,0,1-2,1.77,12.85,12.85,0,0,1-6.89,2.42c-4.47.27-6.86-1.68-8.26-4.12a17.47,17.47,0,0,1-1.63-4.38c-.23-.91-.41-1.76-.6-2.44l1.57-.51c.79-.25,1.56-.48,2.33-.7A82.61,82.61,0,0,1,384.93,316l2.1-.14c1.36-.07,2.56-.1,3.59-.11Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M371.34,317.88c-.06,2-1.21,2.51-1.21,2.51a4.11,4.11,0,0,0,4.45,4.73c6.37-.65,8.38-8.32,8.38-8.32-4.63-1.84-5.68-7-5.87-10a17,17,0,0,1,0-2.39l-8.66,5.46a30.32,30.32,0,0,1,1.93,3.79A10.8,10.8,0,0,1,371.34,317.88Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M369,309.2a31.37,31.37,0,0,1,1.54,4,10.8,10.8,0,0,1,.54,4.3c4.87.38,6.8-10.38,6.8-10.38l.05-.06a16.26,16.26,0,0,1,.24-2.37Z"
      style="opacity: 0.2; isolation: isolate"
    ></path>
    <path
      d="M361.9,287.56c-8.38,2.55-9.71,7.86-7.69,16.68,2.52,11,7.25,18,17,12.46C384.5,309.23,375.81,283.32,361.9,287.56Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M359.48,302.12a18.63,18.63,0,0,1-1.37,4.22,2.39,2.39,0,0,0,2.49.27Z"
      style="fill: #d58745"
    ></path>
    <path
      d="M364.4,299.87c.2.69.73,1.15,1.19,1s.68-.79.48-1.49-.72-1.15-1.18-1S364.21,299.18,364.4,299.87Z"
      style="fill: #263238"
    ></path>
    <path
      d="M365.09,298.36l-1.67-.17S364.52,299.25,365.09,298.36Z"
      style="fill: #263238"
    ></path>
    <path
      d="M355.75,302.14c.19.69.72,1.15,1.18,1s.68-.8.49-1.49-.73-1.15-1.19-1S355.55,301.44,355.75,302.14Z"
      style="fill: #263238"
    ></path>
    <path
      d="M356.43,300.63l-1.67-.17S355.87,301.51,356.43,300.63Z"
      style="fill: #263238"
    ></path>
    <path
      d="M366.74,294.58a.36.36,0,0,1-.2-.06,4.24,4.24,0,0,0-3.67-.13.36.36,0,1,1-.3-.66,4.9,4.9,0,0,1,4.37.19.36.36,0,0,1,.1.5A.34.34,0,0,1,366.74,294.58Z"
      style="fill: #263238"
    ></path>
    <path
      d="M353.4,297.63a.32.32,0,0,1-.23-.08.35.35,0,0,1-.05-.51,4.43,4.43,0,0,1,3.43-1.84.36.36,0,0,1,.34.38.38.38,0,0,1-.38.35h0a3.65,3.65,0,0,0-2.83,1.57A.37.37,0,0,1,353.4,297.63Z"
      style="fill: #263238"
    ></path>
    <path
      d="M375.36,298.88s-6-5.68-7-11.18c0,0-18.67-1.72-14.35,15.38,0,0-6.19-14.46,8.11-19.19s20.66,16.36,15.14,24.91C377.22,308.8,379.09,302.45,375.36,298.88Z"
      style="fill: #263238"
    ></path>
    <path
      d="M374.53,301.07a3.83,3.83,0,0,1,2.93-4.56c2.68-.51,5.55,4.44-.52,7.69C376.07,304.67,375.27,303.92,374.53,301.07Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M362.53,310.39a.37.37,0,0,1,0-.73,3.85,3.85,0,0,0,3.93-3.3.36.36,0,0,1,.4-.31.37.37,0,0,1,.32.4,4.65,4.65,0,0,1-4.63,3.94Z"
      style="fill: #263238"
    ></path>
    <path
      d="M387.55,307.82a.24.24,0,0,1-.24-.2c0-.05-.93-4.63-9.75-7.2-9.17-2.67-9.8-13.67-9.8-13.78a.25.25,0,0,1,.24-.26.25.25,0,0,1,.26.24c0,.11.62,10.76,9.44,13.32,9.15,2.67,10.07,7.39,10.1,7.59a.26.26,0,0,1-.2.29Z"
      style="fill: #263238"
    ></path>
    <path
      d="M390.8,326.82s-1.06,21.37-2.86,26.16-20.69,22.49-20.69,22.49-5.27-1.18-6.14-.83-5.9,5-5.9,5h14.53l1.27-2.25s19.54-14.47,21.69-17.93c1.59-2.55,6.7-28.51,6.7-28.51Z"
      style="fill: #ebb376"
    ></path>
    <path
      d="M394.62,315.88c13.15,3.23,7,23,7,23l-13.68-3.35S387.94,314.24,394.62,315.88Z"
      style="fill: #407bff"
    ></path>
    <path
      d="M385.21,326.69a13.94,13.94,0,0,1-2,1.77A21.78,21.78,0,0,1,384.93,316l2.1-.14A25.23,25.23,0,0,0,385.21,326.69Z"
      style="fill: #263238"
    ></path>
    <path
      d="M369.68,318.73a23.8,23.8,0,0,0-1.67,8,17.47,17.47,0,0,1-1.63-4.38,21.72,21.72,0,0,1,1-2.95C368.14,319.18,368.91,319,369.68,318.73Z"
      style="fill: #263238"
    ></path>
    <path
      d="M385.21,326.69a13.94,13.94,0,0,1-2,1.77A21.78,21.78,0,0,1,384.93,316l2.1-.14A25.23,25.23,0,0,0,385.21,326.69Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
    <path
      d="M369.68,318.73a23.8,23.8,0,0,0-1.67,8,17.47,17.47,0,0,1-1.63-4.38,21.72,21.72,0,0,1,1-2.95C368.14,319.18,368.91,319,369.68,318.73Z"
      style="fill: #fff; opacity: 0.4; isolation: isolate"
    ></path>
  </g>
</svg>
</ng-template>
