<!-- <div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 75vh;
  "
>
  <nz-card> </nz-card>
</div> -->

<div class="login">
  <div class="login-form">
    <div class="login-form__branch__logo">
      <img
        class="login-form__branch__logo__img"
        src="/assets/img/logo-black-font.png"
        alt="Iris Logo"
      />
    </div>

    <h2 class="login-form__title">Login to Iris</h2>

    <form class="login-form__form" (ngSubmit)="login()">
      <nz-form-item>
        <nz-form-control
          nzErrorTip="Please input your Email!"
          [nzValidateStatus]="validation.email.status"
          [nzErrorTip]="validation.email.message"
        >
          <nz-input-group nzPrefixIcon="inbox" nzSize="large">
            <input
              nz-input
              name="email"
              type="email"
              placeholder="Email"
              [(ngModel)]="email"
              (ngModelChange)="checkValidationStatus()"
              nzSize="large"
              [disabled]="loginInProgress"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control
          [nzValidateStatus]="validation.password.status"
          [nzErrorTip]="validation.password.message"
        >
          <nz-input-group nzPrefixIcon="lock" nzSize="large">
            <input
              nz-input
              name="password"
              type="password"
              placeholder="Password"
              [(ngModel)]="password"
              (ngModelChange)="checkValidationStatus()"
              [disabled]="loginInProgress"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <button
        nz-button
        nzType="primary"
        class="login-form-button"
        nzBlock
        nzSize="large"
        [nzLoading]="loginInProgress"
        type="submit"
      >
        Login
      </button>

      <ng-container *ngIf="loginFailed && loginErrorMessage">
        <nz-alert
          nzType="error"
          [nzMessage]="loginErrorMessage"
          nzShowIcon
        ></nz-alert>
      </ng-container>
    </form>
  </div>
</div>
