import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-cou-step-finish',
  templateUrl: './cou-step-finish.component.html',
  styleUrls: ['./cou-step-finish.component.scss'],
})
export class COUStepFinishComponent implements OnInit {
  @Input() client?: Client;

  @Output() validityChange = new EventEmitter<boolean>();

  @Input() updateMode = false;

  constructor() {}

  ngOnInit() {
    this.validityChange.emit(true);
  }

  get finishTitle() {
    const { updateMode } = this;
    return `Click Submit to ${updateMode ? 'Update' : 'Setup'} Income Plan`;
  }

  get finishDescription() {
    const { updateMode } = this;
    return `* ${
      updateMode ? 'Updating' : 'Setting up'
    } income plan settings will redirect you to 'Plan Results' tab to review the new plan`;
  }
}
