<nz-card style="height: 100%; margin-top: 20px" nzTitle="Shares" [nzExtra]="extraTemplate">
    <div *ngIf="!shares || shares.length === 0">
        No Shares
    </div>
    <table *ngIf="shares && shares.length > 0" style="width: 100%">
        <tr *ngFor="let share of shares">
            <td><span *ngIf="share._user && share._user.name">{{ share._user.name }}</span><span *ngIf="share._user && !share._user.name">{{ share._user.firstName }} {{ share._user.lastName }}</span></td>
            <td *ngIf="client && authService.user && client.user === authService.user.id" style="text-align:right"><button nz-button nzType="primary" (click)="deleteShare(share)">Delete</button></td>
        </tr>
    </table>
</nz-card>
<ng-template #extraTemplate>
    <a *ngIf="client && authService.user && client.user === authService.user.id" (click)="addShare()">Add Share</a>
</ng-template>
<nz-modal
  [(nzVisible)]="addShareVisible"
  nzTitle="Add Share"
  [nzFooter]="customFooter"
  (nzOnCancel)="cancelAddShare()"
  (nzOnOk)="okAddShare()"
>
  <ng-container *nzModalContent>
    <nz-form-item>
      <nz-form-label>Email</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <input nz-input type="text" [(ngModel)]="shareEmail" />
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</nz-modal>
<ng-template #customFooter>
  <button nz-button nzType="default" (click)="cancelAddShare()">Cancel</button>
  <button nz-button nzType="primary" (click)="okAddShare()">OK</button>
</ng-template>

<nz-modal
  [(nzVisible)]="deleteShareVisible"
  nzTitle="Delete deleteShare"
  (nzOnCancel)="cancelDeleteShare()"
  (nzOnOk)="okDeleteShare()"
  nzOkText="Yes, Delete"
  nzOkDanger
>
  <ng-container *nzModalContent>
    <p>
      Are you sure you want to delete this share?
    </p>
  </ng-container>
</nz-modal>
