<nz-card style="margin-top: 20px">
    <div class="flow-of-money">
        <div class="scheduled-expenses">
            Draws
        </div>
        <div style="padding-top: 20px; width: 105px; position: relative">
            <div style="position: absolute; left: 25px; top: 190px; padding: 3px; border-radius: 10px; border: 2px solid white; background-color: #D7E5F6; color: #11467C">100%</div>
            <svg width="105" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                <!--path stroke-width="20" fill="#ffaaaa" d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/-->
                <!--marker id="triangle1"
                    viewBox="0 0 10 10" refX="6" refY="5" 
                    fill="red"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker-->
                <marker id="triangle1"
                    viewBox="0 0 10 20" refX="10" refY="10" 
                    fill="transparent"
                    stroke="#FF1700"
                    stroke-width="3"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 1 L 10 10 L 0 19" />
                </marker>
                <path class="path" stroke="#FF1700" stroke-width="5" fill="transparent" stroke-dasharray="2.5,10,7.5,0" d="M 105,12 l -102,0" marker-end="url(#triangle1)"/>
            </svg>
            <svg width="105" height="140" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="margin-top: 20px">
                <marker id="triangle"
                    viewBox="0 0 10 20" refX="10" refY="10" 
                    fill="transparent"
                    stroke="#1798F3"
                    stroke-width="3"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 1 L 10 10 L 0 19" />
                </marker>
                <path class="path" stroke="#1798F3" fill="transparent" stroke-dasharray="2.5,10,7.5,0" stroke-width="5" d="M 105,20 l -80,0 s -20,0 -20,20 l 0,65 s 0,20 20,20 l 75,0" marker-end="url(#triangle)"/>
            </svg>
        </div>
        <div class="main">
            <div class="line-of-credit">
                IRIS Line of Credit {{ client!.plan!.inputs!.lineOfCreditSize! | currency: 'USD':'$':'0.0-2' }}
            </div>
            <div class="income-source">
                {{ client!.plan!.inputs!.loansTerm }} Years @ {{ (+client!.plan!.inputs!.loansRateOfReturn! / 100) | percent }} 
                <div class="income-streams">
                    <div class="income-stream">
                        P2P
                    </div>
                </div>
            </div>
        </div>
        <div style="padding-top: 20px; width: 80px">
            <svg width="80" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
                <!--path stroke-width="20" fill="#ffaaaa" d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/-->
                <!--marker id="triangle2"
                    viewBox="0 0 10 10" refX="6" refY="5" 
                    fill="#84BA2F"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker-->
                <marker id="triangle2"
                    viewBox="0 0 10 20" refX="6" refY="10" 
                    fill="transparent"
                    stroke="#84BA2F"
                    stroke-width="3"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 1 L 10 10 L 0 19" />
                </marker>
                <path class="path" fill="#84BA2F" stroke="#84BA2F" fill="transparent" stroke-dasharray="2.5,10,7.5,0" stroke-width="5" d="M 80,12 l -73,0" marker-end="url(#triangle2)"/>
            </svg>
            <svg width="80" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="margin-top: 15px">
                <!--path stroke-width="20" fill="#ffaaaa" d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/-->
                <!--marker id="triangle3"
                    viewBox="0 0 10 10" refX="6" refY="5" 
                    fill="#84BA2F"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker-->
                <marker id="triangle3"
                    viewBox="0 0 10 20" refX="6" refY="10" 
                    fill="transparent"
                    stroke="#84BA2F"
                    stroke-width="3"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 1 L 10 10 L 0 19" />
                </marker>
                <path class="path" fill="#84BA2F" stroke="#84BA2F" fill="transparent" stroke-dasharray="2.5,10,7.5,0" stroke-width="5" d="M 80,12 l -73,0" marker-end="url(#triangle3)"/>
            </svg>
            <svg width="85" height="140" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style="margin-top: 5px">
                <!--marker id="triangle4"
                    viewBox="0 0 10 10" refX="6" refY="5" 
                    fill="#84BA2F"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 0 L 10 5 L 0 10 z" />
                </marker-->
                <marker id="triangle4"
                    viewBox="0 0 10 20" refX="6" refY="10" 
                    fill="transparent"
                    stroke="#84BA2F"
                    stroke-width="3"
                    markerUnits="strokeWidth"
                    markerWidth="5" markerHeight="5"
                    orient="auto">
                    <path d="M 0 1 L 10 10 L 0 19" />
                </marker>
                <path class="path" stroke="#84BA2F" fill="transparent" stroke-dasharray="2.5,10,7.5,0" stroke-width="5" d="M 0,105 l 60,0 s 20,0 20,-20 l 0,-45 s 0,-20 -20,-20 l -53,0" marker-end="url(#triangle4)"/>
            </svg>
        </div>
        <div>
            <div class="contributions">
                Contributions
            </div>
            <div class="power-boosters">
                Boosters
            </div>
        </div>
    </div>
</nz-card>