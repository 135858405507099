<header class="main-header">
  <div class="main-header__content">
    <a routerLink="/">
      <img
        class="main-header__content__logo"
        src="/assets/img/logo.png"
        alt="Iris Logo"
      />
    </a>
    <ul class="top-nav">
      <li *ngIf="authService.loggedIn" class="top-nav__link-item">
        <a
          routerLink="/"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
          >My Clients</a
        >
      </li>
    </ul>
    <div class="main-header__spacer"></div>
    <ul class="top-nav__actions">
      <!-- <li *ngIf="authService.loggedIn" class="top-nav__link-item">
        <a routerLink="/help" routerLinkActive="active-link">Help</a>
      </li>
      <li *ngIf="authService.loggedIn" class="top-nav-divider"></li> -->
      <li *ngIf="authService.loggedIn" class="top-nav__link-item">
        <a nz-dropdown [nzDropdownMenu]="menu" class="settings"
          >{{ userName }}
          <div class="settings-icon"></div
        ></a>
      </li>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="settings()">
            <i nz-icon [nzType]="'setting'"></i>Settings
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="authService.logout()">
            <i nz-icon [nzType]="'logout'"></i>Logout
          </li>
        </ul>
      </nz-dropdown-menu>
    </ul>
  </div>
</header>
