<nz-card style="height: 100%; margin-top: 20px" nzTitle="Revolving Debt" [nzExtra]="extraTemplate">
    <p>Credit Cards, Lines of Credit (HELOC)</p>
    <div *ngIf="!client.revolvingDebtStacks || client.revolvingDebtStacks.length == 0">
        No Revolving Debt
    </div>
    <table *ngIf="client.revolvingDebtStacks && client.revolvingDebtStacks.length > 0" style="width: 100%">
        <thead>
            <th>Title</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Payment</th>
            <th>Payments</th>
        </thead>
        <tr *ngFor="let stack of client.revolvingDebtStacks">
            <td>{{ stack.title }}</td>
            <td>
                <nz-tag *ngIf="stack.type == 'CC'" nzColor="#060A2B">
                    Credit Card
                </nz-tag>
                <nz-tag *ngIf="stack.type == 'LOC'" nzColor="#06A7F5">
                    Line of Credit
                </nz-tag>
            </td>
            <td>{{ stack.amount | currency }}</td>
            <td>
                {{ stack.payment | currency: "USD":"$":"1.0-2" }}
            </td>
            <td>
                {{ stack.payments | number }}
            </td>
            <td style="text-align: right">
                <button nz-button nzType="primary" (click)="editStack(stack)">
                    Edit
                </button>
            </td>
        </tr>
    </table>
</nz-card>
<ng-template #extraTemplate>
    <a (click)="addStack()">Add Revolving Debt</a>
</ng-template>
<nz-modal [(nzVisible)]="addStackVisible" nzTitle="Revolving Debt" [nzFooter]="customFooter" (nzOnCancel)="cancelAddStack()"
    (nzOnOk)="okAddStack()">
    <ng-container *nzModalContent>
        <nz-form-item>
            <nz-form-label>Title</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input nz-input type="text" [(ngModel)]="stackTitle" />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Type</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-select [(ngModel)]="stackType" style="width: 120px">
                    <nz-option nzValue="CC" nzLabel="Credit Card"></nz-option>
                    <nz-option nzValue="LOC" nzLabel="Line of Credit"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Amount to Paydown</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number [(ngModel)]="stackAmount" [nzFormatter]="formatterDollar" [nzParser]="parserDollar">
                </nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Monthly Payment</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number [(ngModel)]="stackPayment" [nzFormatter]="formatterDollar" [nzParser]="parserDollar">
                </nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Months Left</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number [(ngModel)]="stackPayments"></nz-input-number>
            </nz-form-control>
        </nz-form-item>
    </ng-container>
</nz-modal>
<ng-template #customFooter>
    <button *ngIf="isEdittingStack" nz-button nzType="primary" nzDanger (click)="deleteStack()">
        Delete
    </button>
    <button nz-button nzType="default" (click)="cancelAddStack()">Cancel</button>
    <button nz-button nzType="primary" (click)="okAddStack()">OK</button>
</ng-template>