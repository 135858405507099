import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';

const DefaultLocalForPipe = 'en-US';

export function transformPercentToDisplayString(
  percent: number
): string | null {
  return new PercentPipe(DefaultLocalForPipe).transform(percent, '1.0-2');
}

export function transformNumberToDisplayString(value: number): string | null {
  return new DecimalPipe(DefaultLocalForPipe).transform(value, '1.0-2');
}

export function transformCurrency(
  amount: number,
  currency: string,
  excludeDecimals = false
): string | null {
  return new CurrencyPipe(DefaultLocalForPipe).transform(
    amount,
    currency,
    'symbol-narrow',
    excludeDecimals ? '1.0-0' : '1.2-2'
  );
}

export function transformUSDCurrency(
  amount: number,
  excludeDecimals = false
): string | null {
  return transformCurrency(amount, 'USD', excludeDecimals);
}

export function transformUSDCurrencyNoDecimals(amount: number): string | null {
  return transformCurrency(amount, 'USD', true);
}

export function toShortUSDCurrency(value: number): string | null {
  const absoluteValue = Math.abs(value);
  const prefix = value < 0 ? '-' : '';
  if (absoluteValue >= 1e9) {
    return (
      prefix + '$' + transformNumberToDisplayString(absoluteValue / 1e9) + 'b'
    );
  }
  if (absoluteValue >= 1e6) {
    return (
      prefix + '$' + transformNumberToDisplayString(absoluteValue / 1e6) + 'm'
    );
  }
  if (absoluteValue >= 1e3) {
    return (
      prefix + '$' + transformNumberToDisplayString(absoluteValue / 1e3) + 'k'
    );
  }
  return transformUSDCurrency(value, true);
}
