<div class="ojc" [class.ojc--expanded]="expanded">
  <div class="ojc__header">
    <div class="ojc__header__title">
      <span class="ojc__header__title__name">{{ jobNameText }}</span>

      <nz-tag
        [nzColor]="planOptimizerState(job)?.color"
        [nzTooltipTitle]="planOptimizerState(job)?.tooltipMessage"
        nz-tooltip
      >
        {{ planOptimizerState(job)?.text }}
      </nz-tag>
    </div>

    <span class="ojc__header__spacer"></span>

    <div class="ojc__header__progress-bar-wrapper" *ngIf="isJobInProgress">
      <nz-progress
        class="ojc__header__progress-bar-wrapper__progress-bar"
        [nzPercent]="jobProgressPercent"
      ></nz-progress>
      <span
        class="ojc__header__progress-bar-wrapper__eta"
        [nzTooltipTitle]="jobTimelineTooltipMessage"
        nz-tooltip
        >{{ jobETAText }}</span
      >
    </div>

    <div
      class="ojc__header__status"
      [ngClass]="jobStatusTextCssClass"
      *ngIf="!isJobInProgress"
    >
      <span class="ojc__header__status__text">{{ jobStatusText }}</span>
      <span
        class="ojc__header__status__time"
        [nzTooltipTitle]="jobTimelineTooltipMessage"
        nz-tooltip
        >{{ jobLastUpdateTimeText }}</span
      >
    </div>
  </div>
  <div class="ojc__sub-header">
    <div class="ojc__sub-header__metrics" *ngIf="subHeaderProperties">
      <div
        *ngFor="let metric of subHeaderProperties"
        class="ojc__sub-header__metrics__item"
      >
        <span class="ojc__sub-header__metrics__item__value">
          {{ metric.value }}
        </span>
        <span class="ojc__sub-header__metrics__item__label">
          {{ metric.label }}
          <app-tooltip-icon
            *ngIf="metric.tooltipMessage"
            [tooltipMessage]="metric.tooltipMessage"
          ></app-tooltip-icon>
        </span>
      </div>
    </div>
    <div class="ojc__sub-header__actions">
      <div>
        <button nz-button nzType="link" (click)="toggleExpandedView()">
          <ng-container *ngIf="!expanded">
            <i
              style="font-size: 12px"
              nz-icon
              nzType="down"
              nzTheme="outline"
            ></i>
            Expand Details
          </ng-container>
          <ng-container *ngIf="expanded">
            <i
              style="font-size: 12px"
              nz-icon
              nzType="up"
              nzTheme="outline"
            ></i>
            Collapse Details
          </ng-container>
        </button>
      </div>
      <div>
        <a
          [href]="'/workers/' + job.id + '.txt'"
          target="_blank"
          nz-button
          nzType="link"
          >View logs</a
        >
        <button nz-button (click)="archiveJobClick()" *ngIf="showDeleteBtn">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
          Delete
        </button>
        <button nz-button (click)="stopJobClick()" *ngIf="showStopBtn">
          <i nz-icon nzType="stop" nzTheme="outline"></i>
          Stop
        </button>
      </div>
    </div>
  </div>
  <div class="ojc__body" *ngIf="expanded">
    <app-job-detail
      [client]="client"
      [job]="job"
      *ngIf="showResults && optimizerResultsWithAnalysis"
      [optimizerResultsWithAnalysis]="optimizerResultsWithAnalysis"
      [timelineSectionLabels]="timelineSectionLabels"
      [freedomDaySectionLabels]="freedomDaySectionLabels"
      [numberSectionLabels]="numberSectionLabels"
      [otherSectionLabels]="otherSectionLabels"
    ></app-job-detail>

    <div class="ojc__body__debug-info">
      <nz-collapse nzGhost>
        <nz-collapse-panel nzHeader="Debug Info">
          <div class="ojc__body__inputs">
            <h4 class="ojc__body__inputs__heading">
              Optimizer Search Input Spec
            </h4>
            <pre class="ojc__body__inputs__json-code-content">{{
              jobInputs | json
            }}</pre>
            <h4 *ngIf="jobSettings" class="ojc__body__inputs__heading">
              Optimizer Search Settings
            </h4>
            <pre *ngIf="jobSettings" class="ojc__body__inputs__json-code-content">{{
              jobSettings | json
            }}</pre>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</div>
