<footer class="main-footer">
  <div class="main-footer__content">
    <img
      class="main-footer__content__logo"
      src="/assets/img/logo.png"
      alt="Iris Logo"
    />
    <p class="main-footer__content__copyright">
      Copyright &copy;2021 Fynanc LLC. All rights reserved.
    </p>
  </div>
</footer>
