import { isNumberValid } from '../utils/number-utils';
import { OptimizerResults } from './optimizer-results';
import { OptimizerSettings, PlanOptimizer, PlanOptimizerLevel } from './optimizer-settings';
import { PlanInputs } from './plan-inputs';

export interface Job {
  id: string;
  name?: string;

  started?: Date | string;
  completed?: Date | string;
  progress?: number;
  expectedIterations?: number;
  outOfDate?: boolean;
  cancelled?: boolean;

  optimizer?: PlanOptimizer;
  optimizerLevel?: PlanOptimizerLevel;
  results?: OptimizerResults;
  inputs?: PlanInputs;
  settings?: OptimizerSettings
}

export const jobInProgress = (job: Job) => {
  return (
    !jobCancelled(job) &&
    job.started &&
    isNumberValid(job.progress) &&
    job.progress < 100
  );
};
export const jobSuccess = (job: Job) => {
  return !jobInProgress(job) && !jobOutOfDate(job) && !jobCancelled(job);
};
export const jobOutOfDate = (job: Job) => {
  return job.outOfDate === true;
};
export const jobCancelled = (job: Job) => {
  return job.cancelled === true;
};
