<form nz-form [formGroup]="addClientFormGroup">
  <div class="add-client-form__alias-group">
    <nz-form-item>
      <nz-form-label
        [nzSm]="6"
        [nzXs]="24"
        nzFor="clientAlias"
        nzRequired
        [nzTooltipTitle]="clientAliasTooltip"
      >
        <span>{{ clientAliasLabel }} </span>
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="clientAliasFieldError"
      >
        <input
          nz-input
          id="clientAlias"
          formControlName="clientAlias"
          placeholder="Required"
        />
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="add-client-form__details-group">
    <h3 class="add-client-form__details-group__header">Client Information</h3>
    <div class="add-client-form__details-group__content">
      <nz-form-item>
        <nz-form-label
          [nzSm]="6"
          [nzXs]="24"
          nzFor="clientFirstName"
          nzRequired
        >
          <span>First Name</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          [nzErrorTip]="clientFirstNameFieldError"
        >
          <input
            nz-input
            id="clientFirstName"
            formControlName="clientFirstName"
            placeholder="Required"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="clientMiddleName">
          <span>Middle Name</span>
        </nz-form-label>
        <nz-form-control [nzSm]="14" [nzXs]="24">
          <input
            nz-input
            id="clientMiddleName"
            formControlName="clientMiddleName"
            placeholder="Optional"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="clientLastName" nzRequired>
          <span>Last Name</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          [nzErrorTip]="clientLastNameFieldError"
        >
          <input
            nz-input
            id="clientLastName"
            formControlName="clientLastName"
            placeholder="Required"
          />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="clientEmail" nzRequired
          >E-mail</nz-form-label
        >
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          [nzErrorTip]="clientEmailFieldError"
        >
          <input
            nz-input
            formControlName="clientEmail"
            id="clientEmail"
            placeholder="Required"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
