import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import { QuestionCircleOutline } from '@ant-design/icons-angular/icons';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CashflowChartComponent } from './charts/cashflow-chart/cashflow-chart.component';
import { LoanUnpaidBalanceChartComponent } from './charts/loan-unpaid-balance-chart/loan-unpaid-balance-chart.component';
import { LoansCreatedChartComponent } from './charts/loans-created-chart/loans-created-chart.component';
import { CollapsibleComponent } from './collapsible/collapsible.component';
import { CoreAlertComponent } from './core-alert/core-alert.component';
import { CoreButtonComponent } from './core-button/core-button.component';
import { CoreModalComponent } from './core-modal/core-modal.component';
import { KeyMetricBadgeComponent } from './key-metric-badge/key-metric-badge.component';
import { TooltipIconComponent } from './tooltip-icon/tooltip-icon.component';

const CommonIrisComponents = [
  LoanUnpaidBalanceChartComponent,
  LoansCreatedChartComponent,
  CashflowChartComponent,
  CoreModalComponent,
  CoreButtonComponent,
  CoreAlertComponent,
  TooltipIconComponent,
  CollapsibleComponent,
  KeyMetricBadgeComponent,
];

const icons: IconDefinition[] = [QuestionCircleOutline];

@NgModule({
  declarations: CommonIrisComponents,
  exports: CommonIrisComponents,
  imports: [
    CommonModule,
    NzButtonModule,
    NzModalModule,
    NzAlertModule,
    NzToolTipModule,
    NzIconModule.forChild(icons),
  ],
})
export class IrisComponentsModule {}
