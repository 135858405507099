import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.scss']
})
export class GenerateComponent implements OnInit {

  sfaActive = false;
  
  keyuri?: string;

  constructor(private httpClient: HttpClient, private message: NzMessageService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.user!.sfaActive) {
      this.sfaActive = true;
    } else {
      this.httpClient.get('/api/generate2fa', { headers: new HttpHeaders().set('Content-Type', 'application/json').set('X-Access-Token', `Bearer ${this.authService.token}`) }).subscribe((response: any) => {
        
        this.keyuri = response.keyuri;
      
      });
    }
  }

  activate() {
    this.httpClient.post('/api/activate2fa', {}, { headers: new HttpHeaders().set('Content-Type', 'application/json').set('X-Access-Token', `Bearer ${this.authService.token}`) }).subscribe((response: any) => {
      this.authService.user!.sfaActive = true;
      this.message.create('success', 'You have activated Second Factor Authentication (2FA) for your account');
    });
  }

}
