import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-dashboard-data',
  templateUrl: './dashboard-data.component.html',
  styleUrls: ['./dashboard-data.component.scss'],
})
export class DashboardDataComponent implements OnInit {
  @Input() client?: Client;
  @Input() pageSize = 3;

  dataItems?: any[];

  constructor() {}

  ngOnInit(): void {
    if (this.client!.plan && this.client!.plan.results) {
      let results: any = this.client!.plan!.results;
      //let freedomMonth = results.msgs!.freedom_months;
      let currentDate = new Date();
      let month = '' + (currentDate.getMonth() + 1);
      let day = '' + currentDate.getDate();
      let year = currentDate.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let currentDateString = [year, month, day].join('-');
      //let simMonths = results.msgs!.sim_years * 12;
      //let passiveIncome = results.msgs!.passive_income;
      let index = 0;
      let mode = -1;
      let counter = 0;
      let dataItems = [];
      while (index < results.data.length) {
        let data = results.data[index];
        let date = data.date;

        if (mode === -1 && date > currentDateString) {
          mode = 0;
        } else if (mode === 0 && counter == this.pageSize) {
          mode = 1;
        }
        if (mode == 0) {
          dataItems.push(data);
          counter++;
        }
        index++;
      }
      this.dataItems = dataItems;
    }
  }
}
