import { HttpHeaders } from '@angular/common/http';
import { Client } from '../models/client';
import { AuthService } from '../services/auth.service';
import { CompareAndRank } from './common-app-types';
import {
  dateIsInPast,
  formatDifferenceForDate,
  toMMMDDYYYYDateFormat,
} from './date-utils';

export const toApiAuthHeaders = (
  authService: AuthService,
  setContentTypeJson = true
): HttpHeaders => {
  const headers = new HttpHeaders().set(
    'X-Access-Token',
    `Bearer ${authService.token}`
  );
  if (setContentTypeJson) {
    headers.set('Content-Type', 'application/json');
  }
  return headers;
};

export const formatterDollar = (value: number) => `$ ${value}`;
export const parserDollar = (value: string) => value.replace('$ ', '');
export const formatterPercent = (value: number) => `${value} %`;
export const parserPercent = (value: string) => value.replace(' %', '');

export const toCompareAndRankDisplayText = (compareAndRank: CompareAndRank) => {
  return `Rank ${compareAndRank.rank}`;
};

export const DefaultSimulationName = 'My Freedom Plan';

export const toClientPlanStartedFormatted = (client: Client) => {
  const planStartDateString = client.plan?.inputs?.startDate;
  if (planStartDateString) {
    const planStartDate = new Date(planStartDateString);
    return `Client Income Plan ${
      dateIsInPast(planStartDate) ? 'has started' : ' is starting'
    } ${formatDifferenceForDate(planStartDate)} (${toMMMDDYYYYDateFormat(
      planStartDate
    )})`;
  } else {
    return undefined;
  }
};

export const NotificationDurationMs = 5000;
