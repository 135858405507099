import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-clear',
  templateUrl: './clear.component.html',
  styleUrls: ['./clear.component.scss'],
})
export class ClearComponent {
  constructor(
    private httpClient: HttpClient,
    private message: NzMessageService,
    private authService: AuthService
  ) {}

  clear() {
    this.httpClient
      .post(
        '/api/clear2fa',
        {},
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        }
      )
      .subscribe((response: any) => {
        this.authService.user!.sfaActive = false;
        this.message.create(
          'success',
          'You have cleared Second Factor Authentication (2FA) from your account'
        );
      });
  }
}
