<nz-modal
  nzVisible="true"
  [nzClosable]="closable"
  [nzTitle]="title"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="modalSize"
  [nzMaskClosable]="closable && maskClosable"
  [nzBodyStyle]="modalBodyStyle"
  [nzAutofocus]="modalAutofocus"
>
  <div *nzModalContent>
    <ng-content select="[izcModalBody]"></ng-content>
  </div>
  <div *nzModalFooter>
    <ng-content select="[izcModalFooter]"></ng-content>
  </div>
</nz-modal>
