<ng-container *ngIf="client">
  <h3 class="client-sub-page-header">Income Plan Reports</h3>
  <div
    *ngIf="fetchListItemsApiCall.data && fetchListItemsApiCall.data.length > 0"
    style="margin-bottom: 20px"
  >
    <app-core-button type="primary" (click)="openGenerateReportModal()">{{
      generateLatestReportBtnTxt
    }}</app-core-button>
  </div>

  <nz-spin
    class="reporting__fetch-spinner"
    *ngIf="listItemsFetchInProgress"
    nzSimple
    [nzSize]="'large'"
  ></nz-spin>

  <div
    class="client-page__fetch-failed-alert-wrapper"
    *ngIf="listItemsFetchFailed && listItemsFetchErrorMessage"
  >
    <nz-alert
      nzType="error"
      [nzMessage]="listItemsFetchErrorMessage"
      nzShowIcon
    ></nz-alert>
  </div>

  <ng-container *ngIf="listItemsFetchSuccess">
    <nz-table
      *ngIf="
        fetchListItemsApiCall.data && fetchListItemsApiCall.data.length > 0
      "
      #basicTable
      nzShowSizeChanger
      [nzPageSizeOptions]="pageSizeOptions"
      [nzData]="fetchListItemsApiCall.data"
      [nzFooter]="footerMessage"
      [nzPageSize]="DefaultTablePageSize"
    >
      <thead>
        <tr>
          <th nzWidth="50px">#</th>
          <th nzWidth="200px">Report Title</th>
          <th nzWidth="300px">Report Summary</th>
          <th nzWidth="100px">Report Date</th>
          <th nzWidth="100px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of basicTable.data; index as reportIndex">
          <td>
            {{
              (basicTable.nzPageIndex - 1) * basicTable.nzPageSize +
                reportIndex +
                1
            }}
          </td>
          <td>
            {{ report.title }}
          </td>
          <td>
            {{ report.summary }}
          </td>
          <td>
            <span
              [nzTooltipTitle]="toReportFullDateFormatted(report.date)"
              nz-tooltip
            >
              {{ toReportDateDiffFormatted(report.date) }}
            </span>
          </td>
          <td>
            <div class="reporting-table__item__more-actions">
              <button
                nz-button
                nzType="default"
                nzShape="circle"
                nzTooltipTitle="Download"
                nz-tooltip
                (click)="downloadReport(report)"
              >
                <i nz-icon nzType="download" nzTheme="outline"></i>
              </button>
              <button
              nz-button
              nzType="default"
              nzShape="circle"
              nzTooltipTitle="Email"
              nz-tooltip
              (click)="sendReport(report)"
            >
              <i nz-icon nzType="paper-clip" nzTheme="outline"></i>
            </button>
              <button
                nz-button
                nzType="default"
                nzShape="circle"
                nzTooltipTitle="Delete"
                nz-tooltip
                nzDanger
                (click)="openDeleteListItemConfirmModal(report)"
              >
                <i nz-icon nzType="delete"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <app-empty-state-section
      *ngIf="
        !(fetchListItemsApiCall.data && fetchListItemsApiCall.data.length > 0)
      "
      [title]="emptyListPlaceholderTitle"
      [description]="emptyListPlaceholderDescription"
      [placeholderImage]="emptyPlaceholderImage"
    >
      <app-core-button
        emptyStateActions
        type="primary"
        (click)="openGenerateReportModal()"
        >{{ generateLatestReportBtnTxt }}</app-core-button
      >
    </app-empty-state-section>
    <ng-template #emptyPlaceholderImage>
      <svg
        style="width: 210px; height: auto"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
      >
        <g id="freepik--background-simple--inject-99">
          <path
            d="M169.1,307.44c19.65,5.06,35.54,20.89,53.63,29.52s38.52,14.78,58.48,15.78C330,355.21,347,318.89,369,284.91c17.43-27,63.63-56.22,48-92.12-15.8-36.36-69.35-56-104.38-68.39C280.24,113,223.83,94.25,195.2,119.75c-32.67,29.1,10.48,85.49-39.75,95.48-40.13,8-86.95,28.52-77.93,75C87.09,339.43,136.66,299.08,169.1,307.44Z"
            style="fill: #407bff"
          />
          <path
            d="M169.1,307.44c19.65,5.06,35.54,20.89,53.63,29.52s38.52,14.78,58.48,15.78C330,355.21,347,318.89,369,284.91c17.43-27,63.63-56.22,48-92.12-15.8-36.36-69.35-56-104.38-68.39C280.24,113,223.83,94.25,195.2,119.75c-32.67,29.1,10.48,85.49-39.75,95.48-40.13,8-86.95,28.52-77.93,75C87.09,339.43,136.66,299.08,169.1,307.44Z"
            style="fill: #fff; opacity: 0.9"
          />
        </g>
        <g id="freepik--Shadow--inject-99">
          <ellipse
            id="freepik--path--inject-99"
            cx="250"
            cy="416.24"
            rx="193.89"
            ry="11.32"
            style="fill: #f5f5f5"
          />
        </g>
        <g id="freepik--Newsletter--inject-99">
          <path
            d="M365.55,221.33a15.05,15.05,0,0,0-15.23-16.93h62.36a15.05,15.05,0,0,1,15.24,16.93Z"
            style="fill: #407bff"
          />
          <path
            d="M365.55,221.33a15.05,15.05,0,0,0-15.23-16.93h62.36a15.05,15.05,0,0,1,15.24,16.93Z"
            style="opacity: 0.2"
          />
          <path
            d="M350.32,204.4h62.36a19.14,19.14,0,0,0-18.61,16.93l-5.4,54.08a19.13,19.13,0,0,1-18.61,16.92H341.54a15,15,0,0,1-15.23-16.92l5.39-54.08A19.15,19.15,0,0,1,350.32,204.4Z"
            style="fill: #407bff"
          />
          <path
            d="M354.83,275.41a15,15,0,0,0,15.23,16.92H307.69a15,15,0,0,1-15.23-16.92Z"
            style="fill: #407bff"
          />
          <path
            d="M354.83,275.41a15,15,0,0,0,15.23,16.92H307.69a15,15,0,0,1-15.23-16.92Z"
            style="opacity: 0.2"
          />
          <path
            d="M339.89,240.33h42.2a1.51,1.51,0,0,0,1.46-1.33l0-.14a1.19,1.19,0,0,0-1.2-1.33H340.16a1.5,1.5,0,0,0-1.45,1.33l0,.14A1.19,1.19,0,0,0,339.89,240.33Z"
            style="fill: #fff"
          />
          <path
            d="M339,249.06h42.21a1.51,1.51,0,0,0,1.46-1.33l0-.14a1.19,1.19,0,0,0-1.2-1.33H339.29a1.51,1.51,0,0,0-1.46,1.33v.14A1.18,1.18,0,0,0,339,249.06Z"
            style="fill: #fff"
          />
          <path
            d="M338.14,257.79h42.21a1.51,1.51,0,0,0,1.46-1.32v-.15a1.18,1.18,0,0,0-1.19-1.33H338.42a1.51,1.51,0,0,0-1.46,1.33v.15A1.18,1.18,0,0,0,338.14,257.79Z"
            style="fill: #fff"
          />
          <path
            d="M347,266.52h32.45a1.5,1.5,0,0,0,1.46-1.32v-.15a1.17,1.17,0,0,0-1.19-1.32H347.31a1.5,1.5,0,0,0-1.46,1.32v.15A1.17,1.17,0,0,0,347,266.52Z"
            style="fill: #fff"
          />
          <path
            d="M341.63,222.87h42.21a1.51,1.51,0,0,0,1.46-1.33v-.15a1.18,1.18,0,0,0-1.19-1.32H341.91a1.51,1.51,0,0,0-1.46,1.32l0,.15A1.19,1.19,0,0,0,341.63,222.87Z"
            style="fill: #fff"
          />
          <path
            d="M340.76,231.6H383a1.5,1.5,0,0,0,1.45-1.33l0-.14a1.19,1.19,0,0,0-1.19-1.33H341a1.51,1.51,0,0,0-1.46,1.33l0,.14A1.19,1.19,0,0,0,340.76,231.6Z"
            style="fill: #fff"
          />
          <path
            d="M210.93,206.14l-3.81,38.16A5,5,0,0,0,212.2,250h86.86a6.41,6.41,0,0,0,6.21-5.65l3.81-38.16a5.25,5.25,0,0,0-.21-2.11,5,5,0,0,0-4.88-3.54H217.14a6.43,6.43,0,0,0-5.59,3.53A6,6,0,0,0,210.93,206.14Z"
            style="fill: #407bff"
          />
          <path
            d="M211.55,204l40.32,30.18a9.32,9.32,0,0,0,10.66,0L308.87,204a5,5,0,0,0-4.88-3.54H217.14A6.43,6.43,0,0,0,211.55,204Z"
            style="opacity: 0.30000000000000004"
          />
          <path
            d="M230.62,209.64h46.7a8.52,8.52,0,0,0,8.27-7.52l6.75-67.56a6.71,6.71,0,0,0-6.77-7.52h-46.7a8.52,8.52,0,0,0-8.27,7.52l-6.74,67.56A6.69,6.69,0,0,0,230.62,209.64Z"
            style="fill: #407bff"
          />
          <path
            d="M230.62,209.64h46.7a8.52,8.52,0,0,0,8.27-7.52l6.75-67.56a6.71,6.71,0,0,0-6.77-7.52h-46.7a8.52,8.52,0,0,0-8.27,7.52l-6.74,67.56A6.69,6.69,0,0,0,230.62,209.64Z"
            style="fill: #fff; opacity: 0.5"
          />
          <path
            d="M238.62,163.38h39.95a1.91,1.91,0,0,0,1.85-1.69h0A1.5,1.5,0,0,0,278.9,160H239a1.91,1.91,0,0,0-1.86,1.68h0A1.51,1.51,0,0,0,238.62,163.38Z"
            style="fill: #fff"
          />
          <path
            d="M237.57,173.91h39.95a1.91,1.91,0,0,0,1.85-1.69h0a1.5,1.5,0,0,0-1.52-1.68h-40a1.9,1.9,0,0,0-1.85,1.68h0A1.51,1.51,0,0,0,237.57,173.91Z"
            style="fill: #fff"
          />
          <rect
            x="235"
            y="181.06"
            width="43.32"
            height="3.37"
            rx="1.69"
            style="fill: #fff"
          />
          <path
            d="M244.89,195h30.52a1.91,1.91,0,0,0,1.86-1.69h0a1.51,1.51,0,0,0-1.52-1.69H245.23a1.91,1.91,0,0,0-1.86,1.69h0A1.51,1.51,0,0,0,244.89,195Z"
            style="fill: #fff"
          />
          <path
            d="M240.72,142.32h40a1.91,1.91,0,0,0,1.85-1.69h0A1.5,1.5,0,0,0,281,139H241.06a1.92,1.92,0,0,0-1.86,1.68h0A1.51,1.51,0,0,0,240.72,142.32Z"
            style="fill: #fff"
          />
          <path
            d="M239.67,152.85h40a1.91,1.91,0,0,0,1.85-1.69h0a1.5,1.5,0,0,0-1.52-1.68H240a1.91,1.91,0,0,0-1.86,1.68h0A1.51,1.51,0,0,0,239.67,152.85Z"
            style="fill: #fff"
          />
          <path
            d="M267.19,316.92l2.53-25.32a5.27,5.27,0,0,0-5.31-5.9h-53a6.69,6.69,0,0,0-6.49,5.9l-2.53,25.32a5.26,5.26,0,0,0,5.31,5.9h53A6.68,6.68,0,0,0,267.19,316.92Z"
            style="fill: #407bff"
          />
          <path
            d="M235.48,309.85a1.2,1.2,0,0,0,.65-.19l29-17.24a1.25,1.25,0,0,0,.53-1.62,1.12,1.12,0,0,0-1.59-.37l-28.29,16.85-24.93-16.85a1.24,1.24,0,0,0-1.66.37,1.12,1.12,0,0,0,.21,1.62l25.51,17.24A1,1,0,0,0,235.48,309.85Z"
            style="fill: #fff"
          />
          <path
            d="M401.07,159.25,404.29,127a6.71,6.71,0,0,0-6.77-7.52H330a8.54,8.54,0,0,0-8.27,7.52l-3.22,32.26a6.71,6.71,0,0,0,6.77,7.52H392.8A8.54,8.54,0,0,0,401.07,159.25Z"
            style="fill: #407bff"
          />
          <path
            d="M401.07,159.25,404.29,127a6.71,6.71,0,0,0-6.77-7.52H330a8.54,8.54,0,0,0-8.27,7.52l-3.22,32.26a6.71,6.71,0,0,0,6.77,7.52H392.8A8.54,8.54,0,0,0,401.07,159.25Z"
            style="opacity: 0.5"
          />
          <path
            d="M360.66,150.24a1.59,1.59,0,0,0,.83-.24l36.9-22a1.59,1.59,0,0,0,.67-2.06,1.43,1.43,0,0,0-2-.47L361,147,329.23,125.5a1.57,1.57,0,0,0-2.11.46,1.43,1.43,0,0,0,.26,2.07l32.5,22A1.38,1.38,0,0,0,360.66,150.24Z"
            style="fill: #fff"
          />
        </g>
        <g id="freepik--Character--inject-99">
          <path
            d="M146.2,158.59c-2.52,4.43-5.06,9-7.5,13.55s-4.81,9.12-6.91,13.7c-.5,1.13-1,2.28-1.49,3.41l-.34.85-.18.42,0,.11v-.11a3.2,3.2,0,0,0,0-1.23,3.78,3.78,0,0,0-1.21-2,2.27,2.27,0,0,0-1-.53c-.13,0-.09,0,0,0a4.27,4.27,0,0,0,1.09.13,27,27,0,0,0,3.31-.21c4.77-.6,9.95-1.75,15-2.81l2.08,5.36a74.55,74.55,0,0,1-15.22,6.45,28.21,28.21,0,0,1-4.51.94,15.29,15.29,0,0,1-2.86.09,10.42,10.42,0,0,1-1.94-.35,7.12,7.12,0,0,1-2.72-1.45,6.65,6.65,0,0,1-2.17-3.7,7.68,7.68,0,0,1,.06-3.19l.14-.55.08-.27,0-.12.16-.51.33-1c.43-1.37.93-2.64,1.4-3.95,2-5.14,4.25-10.05,6.66-14.86s5-9.46,7.8-14.13Z"
            style="fill: #ffc3bd"
          />
          <path
            d="M175.76,124.43c-.16.6,0,1.17.43,1.27s.83-.31,1-.91,0-1.16-.43-1.26S175.91,123.83,175.76,124.43Z"
            style="fill: #263238"
          />
          <path
            d="M175.74,125.58a22.39,22.39,0,0,0,1.56,5.85,3.54,3.54,0,0,1-3-.19Z"
            style="fill: #ed847e"
          />
          <path
            d="M174.41,120.55a.35.35,0,0,1-.18-.2.36.36,0,0,1,.2-.47,3.64,3.64,0,0,1,3.31.26.37.37,0,0,1,.08.51.36.36,0,0,1-.5.08h0a2.89,2.89,0,0,0-2.62-.17A.36.36,0,0,1,174.41,120.55Z"
            style="fill: #263238"
          />
          <path
            d="M157.33,127.66c-1.57,5.7-.89,13.72-5.55,17.46,0,0,.42,5.69,10.83,5.69,11.46,0,6.6-5.69,6.6-5.69-6-1.49-4.89-6.13-2.94-10.49Z"
            style="fill: #ffc3bd"
          />
          <path
            d="M151.37,146.38c-.83-1.1-1.74-2.35.15-2.74,2.07-.44,13.41-.88,17.32-.07s2.81,3,2.81,3Z"
            style="fill: #263238"
          />
          <path
            d="M118.14,405.05a2.48,2.48,0,0,0,1.91-.1.74.74,0,0,0,.18-.84.84.84,0,0,0-.47-.54c-1-.49-3.41.48-3.51.52a.2.2,0,0,0-.12.16.18.18,0,0,0,.11.17A9.47,9.47,0,0,0,118.14,405.05Zm1.25-1.22.21.07a.48.48,0,0,1,.28.31c.1.33,0,.43-.06.46-.42.35-1.95,0-3-.4A5.88,5.88,0,0,1,119.39,403.83Z"
            style="fill: #407bff"
          />
          <path
            d="M116.28,404.43h.09c.86-.25,2.72-1.57,2.74-2.44a.6.6,0,0,0-.46-.59,1,1,0,0,0-.81.07c-1,.52-1.67,2.64-1.7,2.73a.17.17,0,0,0,0,.18A.11.11,0,0,0,116.28,404.43Zm2.17-2.71.08,0c.22.07.22.18.22.23,0,.52-1.24,1.55-2.14,2a4.4,4.4,0,0,1,1.4-2.16A.63.63,0,0,1,118.45,401.72Z"
            style="fill: #407bff"
          />
          <path
            d="M173.32,408.81c1,0,1.94-.14,2.26-.58a.68.68,0,0,0,0-.79A.9.9,0,0,0,175,407c-1.28-.35-4,1.18-4.07,1.25a.17.17,0,0,0-.09.18.17.17,0,0,0,.14.15A13.19,13.19,0,0,0,173.32,408.81Zm1.22-1.49a1.25,1.25,0,0,1,.35,0,.58.58,0,0,1,.36.26c.12.22.08.33,0,.4-.36.49-2.29.5-3.72.3A8.27,8.27,0,0,1,174.54,407.32Z"
            style="fill: #407bff"
          />
          <path
            d="M171,408.6l.07,0c.93-.42,2.76-2.07,2.62-2.94,0-.2-.18-.46-.68-.51a1.32,1.32,0,0,0-1,.31c-1,.8-1.18,2.87-1.19,3a.17.17,0,0,0,.07.16A.21.21,0,0,0,171,408.6Zm1.87-3.11H173c.32,0,.35.17.35.21.09.52-1.16,1.82-2.1,2.39a4.28,4.28,0,0,1,1-2.37A1,1,0,0,1,172.88,405.49Z"
            style="fill: #407bff"
          />
          <polygon
            points="162.58 408.41 170.25 408.41 171.01 390.67 163.35 390.67 162.58 408.41"
            style="fill: #ffc3bd"
          />
          <polygon
            points="107.73 402.4 115.22 404.02 122.75 387.49 115.25 385.87 107.73 402.4"
            style="fill: #ffc3bd"
          />
          <path
            d="M115.94,403.27l-8.41-1.82a.65.65,0,0,0-.75.37l-2.92,6.34a1.13,1.13,0,0,0,.81,1.57c3,.58,4.41.72,8.11,1.52,2.28.49,7.42,1.74,10.56,2.42s4.23-2.33,3-2.89c-5.49-2.52-7.79-4.73-9.28-6.79A1.84,1.84,0,0,0,115.94,403.27Z"
            style="fill: #263238"
          />
          <path
            d="M170.2,407.53h-8.37a.67.67,0,0,0-.66.52l-1.51,6.81a1.11,1.11,0,0,0,1.1,1.36c3-.05,7.38-.22,11.18-.22,4.43,0,8.27.24,13.47.24,3.15,0,4-3.18,2.7-3.47-6-1.31-10.89-1.45-16.07-4.65A3.58,3.58,0,0,0,170.2,407.53Z"
            style="fill: #263238"
          />
          <path
            d="M139.22,146.85c-7.18,1.65-11.54,14.72-11.54,14.72L140,175.33a154.82,154.82,0,0,0,7.78-13.56C152,152.81,146.56,145.17,139.22,146.85Z"
            style="fill: #407bff"
          />
          <path
            d="M142.43,159.13c-2,2.84-3.84,7-1.77,15.08,1.62-2.6,3.21-5.26,4.58-7.67h0C145.61,159.9,144.34,156.84,142.43,159.13Z"
            style="opacity: 0.30000000000000004"
          />
          <path
            d="M184,151.9c1.84,7,3.68,23.85-1.41,61.58h-41c-.28-6.29,3.67-37-2.4-66.63a106.75,106.75,0,0,1,13.72-1.73,151.16,151.16,0,0,1,17.43,0,69.8,69.8,0,0,1,7.81,1.2A7.63,7.63,0,0,1,184,151.9Z"
            style="fill: #407bff"
          />
          <path
            d="M184.28,153h0c-6.77-6.2-11.77-6.5-13.13.31-2.85,14.27,13.94,29.64,14.43,30.08C186.45,167.26,185.43,158.07,184.28,153Z"
            style="opacity: 0.30000000000000004"
          />
          <path
            d="M180,149.49c2.2,2.44,4.27,4.92,6.38,7.4s4.15,5,6.16,7.56,4,5.13,6,7.77,3.88,5.31,5.74,8.15L201,178.24c1.65.42,3.31.87,5,1.22s3.43.71,5.17,1,3.51.59,5.29.86,3.59.5,5.3.76l.36,5.74c-2,.45-3.83.7-5.76,1s-3.84.34-5.78.41a67.4,67.4,0,0,1-11.73-.66l-1.82-.27-1.41-1.86c-1.85-2.44-3.88-4.88-5.88-7.33l-6.15-7.31-12.42-14.64Z"
            style="fill: #ffc3bd"
          />
          <path
            d="M246.38,132.94a21.23,21.23,0,0,0-24.51,13c-3.59,9.67,1.56,19.55,11.5,22.06a21.23,21.23,0,0,0,24.51-13A16.34,16.34,0,0,0,246.38,132.94Zm-12,32.26a13.76,13.76,0,0,1-9.68-18.57,17.88,17.88,0,0,1,20.63-10.92A13.76,13.76,0,0,1,255,154.28,17.89,17.89,0,0,1,234.4,165.2Z"
            style="fill: #263238"
          />
          <path
            d="M234.86,164A12.6,12.6,0,0,1,226,147a16.38,16.38,0,0,1,18.9-10,12.61,12.61,0,0,1,8.88,17A16.38,16.38,0,0,1,234.86,164Z"
            style="fill: #407bff; opacity: 0.30000000000000004"
          />
          <g style="opacity: 0.5">
            <path
              d="M244.89,136.94l-.26-.06-18.55,9.81L226,147a13.05,13.05,0,0,0-.22,8.83l26.47-14A13,13,0,0,0,244.89,136.94Z"
              style="fill: #fafafa"
            />
            <path
              d="M253.21,143.32l-26.73,14.14a11.67,11.67,0,0,0,1.75,2.55l26.07-13.8A11.86,11.86,0,0,0,253.21,143.32Z"
              style="fill: #fafafa"
            />
          </g>
          <path
            d="M234.38,169.91l-3.16-.8a.8.8,0,0,1-.56-1.07h0a1,1,0,0,1,1.19-.63l3.16.8a.8.8,0,0,1,.56,1.07h0A1,1,0,0,1,234.38,169.91Z"
            style="fill: #263238"
          />
          <path
            d="M234.47,168.52c-.2,1.39-.49,2.75-.82,4.11s-.7,2.69-1.1,4-.84,2.66-1.3,4-.46,2.76-2.63,3.65a2.4,2.4,0,0,1-2.11-.33,1.38,1.38,0,0,1-.27-.27c-1.2-1.74-.09-2.9.29-4.24s1.05-2.6,1.63-3.89,1.17-2.58,1.82-3.85,1.33-2.54,2.11-3.78a1.43,1.43,0,0,1,1.55-.24C234.18,167.86,234.51,168.21,234.47,168.52Z"
            style="fill: #263238"
          />
          <path
            d="M220,188l5.64.5-1.14-8.78s-4.33.08-6.49,3.09Z"
            style="fill: #ffc3bd"
          />
          <polygon
            points="233.06 186.44 231.17 180.76 224.46 179.71 225.6 188.5 233.06 186.44"
            style="fill: #ffc3bd"
          />
          <path
            d="M170.23,152.83C167.28,163,182.77,177,182.77,177l13.91-13.62s-4.59-5.72-7.81-9.49C179.84,143.38,172.59,144.74,170.23,152.83Z"
            style="fill: #407bff"
          />
          <polygon
            points="171.01 390.67 170.62 399.82 162.95 399.82 163.34 390.67 171.01 390.67"
            style="opacity: 0.2"
          />
          <polygon
            points="115.25 385.87 122.74 387.5 118.86 396.01 111.37 394.39 115.25 385.87"
            style="opacity: 0.2"
          />
          <path
            d="M156.1,118.09c-1.42,7.47-2.63,11.77.13,16.49,4.14,7.1,14.08,6,17.7-.86,3.26-6.21,5-17.39-1.43-22.14A10.36,10.36,0,0,0,156.1,118.09Z"
            style="fill: #ffc3bd"
          />
          <path
            d="M169,124.61c1.56-.61,11.89-13.78-10.44-13.94-9.72-.07-8.65,19.21-1.77,23S170.65,129.67,169,124.61Z"
            style="fill: #263238"
          />
          <path
            d="M156.31,113c12.62,5.09,25.06,5.83,27.95-1.15,2.28-5.5-4.67-1.49-4.67-1.49s4.07-6-3.12-6.19-3.75,4-3.75,4S151,101.93,156.31,113Z"
            style="fill: #263238"
          />
          <path
            d="M156.32,110.83s-3.67-.23-4.54,3c1.08-1.25,2.4-2.3,3.31-1.33C156.4,111.49,156.32,110.83,156.32,110.83Z"
            style="fill: #263238"
          />
          <path
            d="M167.71,123.65a7.37,7.37,0,0,0,.43,4.67c.88,1.92,2.58,1.44,3.4-.3.73-1.56,1.17-4.44-.22-5.79S168.19,121.78,167.71,123.65Z"
            style="fill: #ffc3bd"
          />
          <path
            d="M168.07,213.48s-8.7,58.57-13.47,80.51C149.37,318,122.73,395,122.73,395l-13.72-3s19.61-72.12,22.6-95.9c3.23-25.82,10-82.69,10-82.69Z"
            style="fill: #263238"
          />
          <path
            d="M126,390.46s-2.56,5-2.56,5L108,392.08l1.31-4.64Z"
            style="fill: #407bff"
          />
          <path
            d="M162.57,234.35c-10.06,2-9,39.47-7.78,58.76,2.9-13.63,7.14-39.87,10-58.49A3.43,3.43,0,0,0,162.57,234.35Z"
            style="opacity: 0.30000000000000004"
          />
          <path
            d="M182.61,213.48s1,57.3.22,80.67c-.87,24.31-9.36,103.9-9.36,103.9h-13s-.48-78.17-1.05-102c-.63-26-3.78-82.53-3.78-82.53Z"
            style="fill: #263238"
          />
          <path
            d="M176,392.88c.06,0-.73,5.39-.73,5.39H159.45l-.43-4.81Z"
            style="fill: #407bff"
          />
        </g>
      </svg>
    </ng-template>
  </ng-container>

  <app-confirm-generate-plan-report
    *ngIf="generateReportModalOpen"
    [client]="client"
    (cancelOperation)="closeGenerateReportModal()"
    (operationSuccess)="onGenerateReportSuccess($event)"
  ></app-confirm-generate-plan-report>

  <app-confirm-delete-plan-report
    *ngIf="deleteListItemConfirmModal.open && deleteListItemConfirmModal.data"
    [client]="client"
    [report]="deleteListItemConfirmModal.data"
    (cancelOperation)="closeDeleteListItemConfirmModal()"
    (operationSuccess)="onDeleteListItemSuccess()"
  ></app-confirm-delete-plan-report>
</ng-container>
