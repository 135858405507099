import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Client } from 'src/app/models/client';
import {
  Job,
  jobCancelled,
  jobInProgress,
  jobOutOfDate,
  jobSuccess,
} from 'src/app/models/job';
import { OptimizerResults } from 'src/app/models/optimizer-results';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import {
  formatDifferenceForDate,
  formateDurationSeconds,
  toFullDateFormat,
} from 'src/app/utils/date-utils';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformUSDCurrencyNoDecimals } from 'src/app/utils/transform-currency';
import { extractPlanOptimizerState } from '../../home/client-utils';
import {
  calculateCompareAndRankResultsForOptimizerResults,
  OptimizerResultWithAnalysis,
} from './job-detail/job-result-ranking-util';

const AdvanceSettingLabel = 'Advanced';
const DefaultSettingLabel = 'Default';

@Component({
  selector: 'app-optimizer-job-card',
  templateUrl: './optimizer-job-card.component.html',
  styleUrls: ['./optimizer-job-card.component.scss'],
})
export class OptimizerJobCardComponent implements OnChanges {
  @Input() job!: Job;

  @Input() client!: Client;

  @Input() results?: OptimizerResults;

  @Output() archiveJob = new EventEmitter();
  @Output() stopJob = new EventEmitter();

  expanded = false;

  subHeaderProperties?: Array<{
    label: string;
    value: string | null;
    tooltipMessage?: string;
  }>;

  timelineSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  freedomDaySectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  numberSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  otherSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;

  optimizerResultsWithAnalysis?: OptimizerResultWithAnalysis[];

  constructor() {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (
      (simpleChanges.job && simpleChanges.job.firstChange) ||
      (simpleChanges.results &&
        simpleChanges.results.previousValue !==
          simpleChanges.results.currentValue)
    ) {
      this.setUpCalculatedData();
    }
  }

  setUpCalculatedData(): void {
    const firstJobResult = this.results?.topresults
      ? this.results.topresults[0]
      : undefined;
    const inputs = this.job.inputs;

    const monthlyContribution = inputs
      ? +(inputs.monthlyContributionAmount ?? 0)
      : firstJobResult?.inputs?.monthlyContribution;

    const passiveMonthlyIncome = inputs
      ? +(inputs.monthlyIncomeGoal ?? 0)
      : firstJobResult?.inputs?.incomeGoal;

    const planDurationYears = inputs
      ? +(inputs?.yearsToSimulate ?? 0)
      : firstJobResult?.inputs?.numYearsToSimulate;

    this.subHeaderProperties = [
      {
        label: 'Monthly Contribution',
        value: isNumberValid(monthlyContribution)
          ? transformUSDCurrencyNoDecimals(monthlyContribution)
          : MissingValuePlaceholderText,
        tooltipMessage:
          'Monthly contribution made by the client during the initial phase',
      },
      {
        label: 'Passive Monthly Income',
        value: isNumberValid(passiveMonthlyIncome)
          ? transformUSDCurrencyNoDecimals(passiveMonthlyIncome)
          : MissingValuePlaceholderText,
        tooltipMessage:
          'Target goal of monthly passive income for the client during financial freedom.',
      },
      {
        label: 'Plan Duration',
        value: isNumberValid(planDurationYears)
          ? `${planDurationYears} Years`
          : MissingValuePlaceholderText,
        tooltipMessage:
          'Plan Duration is the total time of the income plan including contribution and income phase.',
      },
      {
        label: 'Client Settings',
        value:
          this.job.inputs?.clientAdvanced === true
            ? AdvanceSettingLabel
            : DefaultSettingLabel,
        tooltipMessage: 'Client settings used is default',
      },
      {
        label: 'Lending Settings',
        value:
          this.job.inputs?.lendingAdvanced === true
            ? AdvanceSettingLabel
            : DefaultSettingLabel,
        tooltipMessage: 'Lending settings used is default',
      },
      {
        label: 'Algorithm Settings',
        value:
          this.job.inputs?.algorithmAdvanced === true
            ? AdvanceSettingLabel
            : DefaultSettingLabel,
        tooltipMessage: 'Algorithm settings used is default',
      },
      {
        label: 'Optimizer Settings',
        value:
          this.job.inputs?.optimizerAdvanced === true
            ? AdvanceSettingLabel
            : DefaultSettingLabel,
        tooltipMessage: 'Optimizer settings used is default',
      },
    ];
    this.optimizerResultsWithAnalysis =
      calculateCompareAndRankResultsForOptimizerResults(this.job);
    if (this.optimizerResultsWithAnalysis?.length > 0) {
      this.timelineSectionLabels =
        this.optimizerResultsWithAnalysis[0].scores.timeline.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.freedomDaySectionLabels =
        this.optimizerResultsWithAnalysis[0].scores.ffd.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.numberSectionLabels =
        this.optimizerResultsWithAnalysis[0].scores.numbers.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.otherSectionLabels =
        this.optimizerResultsWithAnalysis[0].scores.others.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
    }
  }

  get showDeleteBtn() {
    return this.isJobCancelled || this.isJobSuccess || this.isJobOutOfDate;
  }

  get showStopBtn() {
    return this.isJobInProgress;
  }

  get jobNameText() {
    return this.job.name;
  }

  get jobInputs() {
    return this.job.inputs;
  }

  get jobSettings() {
    return this.job.settings;
  }

  get isJobInProgress() {
    return jobInProgress(this.job);
  }
  get isJobSuccess() {
    return jobSuccess(this.job);
  }
  get isJobOutOfDate() {
    return jobOutOfDate(this.job);
  }
  get isJobCancelled() {
    return jobCancelled(this.job);
  }

  get showResults() {
    return this.isJobSuccess || this.isJobOutOfDate;
  }

  get jobStatusText() {
    return this.isJobInProgress
      ? 'Running'
      : this.isJobSuccess
      ? 'Completed'
      : this.isJobCancelled
      ? 'Cancelled'
      : this.isJobOutOfDate
      ? 'Out of Date'
      : '';
  }

  get jobStatusTextCssClass() {
    return this.isJobInProgress
      ? 'running'
      : this.isJobSuccess
      ? 'completed'
      : this.isJobCancelled || this.isJobOutOfDate
      ? 'failed'
      : '';
  }

  get jobProgressPercent() {
    return this.job.progress;
  }

  get jobLastUpdateTimeText() {
    const jobStartTime = this.job.started
      ? new Date(this.job.started)
      : undefined;
    const jobStartTimeTxt = jobStartTime
      ? `Started ${formatDifferenceForDate(jobStartTime)}`
      : undefined;

    const jobCompletionTime = this.job.completed
      ? new Date(this.job.completed)
      : undefined;
    const jobCompletionTimeTxt = jobCompletionTime
      ? `Finished ${formatDifferenceForDate(jobCompletionTime)}`
      : undefined;

    const jobDurationTxt =
      jobStartTime && jobCompletionTime
        ? `Took ${formateDurationSeconds(
            (jobCompletionTime.getTime() - jobStartTime.getTime()) / 1000
          )}`
        : undefined;

    if (this.isJobInProgress) {
      return jobStartTimeTxt ?? '';
    } else {
      return `${jobCompletionTimeTxt ?? jobStartTimeTxt ?? ''}${
        jobDurationTxt ? `, ${jobDurationTxt}` : ''
      }`;
    }
  }

  toggleExpandedView() {
    this.expanded = !this.expanded;
  }

  get jobFinishTimeRemaining() {
    if (
      typeof this.job.started === 'string' ||
      this.job.started instanceof String
    ) {
      this.job.started = new Date(this.job.started);
    }
    if (
      this.job.started &&
      isNumberValid(this.job.progress) &&
      this.job.progress !== 0
    ) {
      const currentDate = new Date();
      const elapsedSeconds =
        (currentDate.getTime() - this.job.started.getTime()) / 1000;
      const remaining = Math.floor(
        (elapsedSeconds / this.job.progress) * (100 - this.job.progress)
      );

      return `${formateDurationSeconds(remaining)} remaining`;
    }

    return 'Unknown ETA';
  }

  get jobETAText() {
    const jobStartTime = this.job.started
      ? new Date(this.job.started)
      : undefined;
    const jobStartTimeTxt = jobStartTime
      ? `Running since ${formatDifferenceForDate(jobStartTime)}`
      : undefined;
    return `${jobStartTimeTxt ?? ''}, ${this.jobFinishTimeRemaining}`;
  }

  get jobTimelineTooltipMessage() {
    const jobStartTime = this.job.started
      ? new Date(this.job.started)
      : undefined;
    const jobCompletionTime = this.job.completed
      ? new Date(this.job.completed)
      : undefined;

    return `${
      jobStartTime ? `Start Time - ${toFullDateFormat(jobStartTime)}` : ''
    }${
      jobCompletionTime
        ? ` End Time - ${toFullDateFormat(jobCompletionTime)}`
        : ''
    }${
      jobStartTime && jobCompletionTime
        ? ` Execution Time (in seconds) -
          ${Math.round(
            (jobCompletionTime.getTime() - jobStartTime.getTime()) / 1000
          )}
        `
        : ''
    }`;
  }

  planOptimizerState(job: Job) {
    return extractPlanOptimizerState(job.optimizer, job.optimizerLevel);
  }

  archiveJobClick() {
    this.archiveJob.emit();
  }

  stopJobClick() {
    this.stopJob.emit();
  }
}
