import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
})
export class AuthenticateComponent {
  secret = false;
  token?: string;

  constructor(
    private router: Router,
    private message: NzMessageService,
    private authService: AuthService
  ) {}

  authenticate() {
    if (this.token) {
      this.authService.authenticate(this.token).then((result: any) => {
        if (result == 0) {
          if (this.authService.redirectUrl) {
            this.router.navigate(['/' + this.authService.redirectUrl])
          } else {
            this.router.navigate(['/']);
          }
        } else if (result == 1) {
          this.message.create('error', 'Your 2FA code does not match');
        } else {
          this.router.navigate(['/login']);
        }
      });
    }
  }
}
