<nz-modal
  nzVisible="true"
  [nzTitle]="modalTitle"
  (nzOnCancel)="cancelAction()"
  (nzOnOk)="submitAction()"
  [nzOkText]="submitBtnText"
  [nzOkDanger]="submitBtnDanger"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isSubmitInProgress"
  [nzCancelDisabled]="isSubmitInProgress"
  [nzClosable]="!isSubmitInProgress"
  nzMaskClosable="false"
>
  <ng-container *nzModalContent>
    <p>
      You can quickly compare current income plan with other potential income
      plans
    </p>
    <p>
      <b>Note: </b>After you copy the plan, any future changes to the plan will
      not reflect in the analysis.
    </p>
    <ng-container *ngIf="showSubmitFailErrorMessage && submitFailErrorMessage">
      <nz-alert
        nzType="error"
        [nzMessage]="submitFailErrorMessage"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </ng-container>
</nz-modal>
