<nz-spin
  *ngIf="optimizationSetupInProgress"
  class="fop-set-optimizer-settings__fetch-spinner"
  nzSimple
></nz-spin>

<ng-container *ngIf="optimizationSetupInSuccess">
  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">Search Name</div>
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 18 }">
      <input
        nz-input
        type="text"
        [(ngModel)]="searchName"
        (ngModelChange)="emitOptimizerDataChange()"
      />
    </div>
  </div>
  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
      Perform thorough search
    </div>
    <div div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 18 }">
      <nz-switch
        [(ngModel)]="performThoroughSearch"
        (ngModelChange)="emitOptimizerDataChange()"
      ></nz-switch>
    </div>
  </div>
  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
      Number of Results
    </div>
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 18 }">
      <nz-input-number
        [(ngModel)]="numberOfResults"
        (ngModelChange)="emitOptimizerDataChange()"
      ></nz-input-number>
    </div>
  </div>

  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
      Sample Size
    </div>
    <div
      *ngIf="performThoroughSearch"
      nz-col
      [nzXs]="{ span: 24 }"
      [nzLg]="{ span: 18 }"
    >
      {{ iterations() }}
    </div>
    <div
      *ngIf="!performThoroughSearch"
      nz-col
      [nzXs]="{ span: 24 }"
      [nzLg]="{ span: 18 }"
    >
      <nz-input-number
        [(ngModel)]="numberOfIterations"
        (ngModelChange)="emitOptimizerDataChange()"
      ></nz-input-number>

      <span style="margin-left: 20px"
        >Suggested range of {{ minRecommendedIterations }} to
        {{ maxRecommendedIterations }}</span
      >
    </div>
  </div>

  <div nz-row [nzGutter]="[16, 16]">
    <nz-form-item>
      <nz-form-control>
        <label
          nz-checkbox
          [(ngModel)]="inputs.optimizerAdvanced"
          (ngModelChange)="emitOptimizerDataChange()"
        >
          <span>Enable Advanced Settings</span>
        </label>
      </nz-form-control>
    </nz-form-item>
  </div>

  <ng-container *ngIf="inputs.optimizerAdvanced">
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }">&nbsp;</div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }">Starting Value</div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }">Ending Value</div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }">Increment</div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="label-and-switch">
          LOC
          <nz-switch
            [(ngModel)]="locSet"
            (ngModelChange)="emitOptimizerDataChange()"
          ></nz-switch>
        </div>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="locStartingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!locSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="locEndingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!locSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="locIncrement"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!locSet"
        ></nz-input-number>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="label-and-switch">
          GR
          <nz-switch
            [(ngModel)]="growthCapitalSet"
            (ngModelChange)="emitOptimizerDataChange()"
          ></nz-switch>
        </div>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="growthCapitalStartingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!growthCapitalSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="growthCapitalEndingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!growthCapitalSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="growthCapitalIncrement"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!growthCapitalSet"
        ></nz-input-number>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="label-and-switch">
          AA
          <nz-switch
            [(ngModel)]="autoIncrementSet"
            (ngModelChange)="emitOptimizerDataChange()"
          ></nz-switch>
        </div>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="autoIncrementStartingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!autoIncrementSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="autoIncrementEndingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!autoIncrementSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="autoIncrementIncrement"
          (ngModelChange)="emitOptimizerDataChange()"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
          [disabled]="!autoIncrementSet"
        ></nz-input-number>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="label-and-switch">
          MG
          <nz-switch
            [(ngModel)]="monthsToFlipDuringGrowthSet"
            (ngModelChange)="emitOptimizerDataChange()"
          ></nz-switch>
        </div>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="monthsToFlipDuringGrowthStartingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [disabled]="!monthsToFlipDuringGrowthSet"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
        <nz-input-number
          [(ngModel)]="monthsToFlipDuringGrowthEndingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [disabled]="!monthsToFlipDuringGrowthSet"
        ></nz-input-number>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <div class="label-and-switch">
          MP3
          <nz-switch
            [(ngModel)]="monthsToFlipDuringPhase3Set"
            (ngModelChange)="emitOptimizerDataChange()"
          ></nz-switch>
        </div>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <nz-input-number
          [(ngModel)]="monthsToFlipDuringPhase3StartingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [disabled]="!monthsToFlipDuringPhase3Set"
        ></nz-input-number>
      </div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 12 }">
        <nz-input-number
          [(ngModel)]="monthsToFlipDuringPhase3EndingValue"
          (ngModelChange)="emitOptimizerDataChange()"
          [disabled]="!monthsToFlipDuringPhase3Set"
        ></nz-input-number>
      </div>
    </div>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">Algorithms</div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodOne"
          (ngModelChange)="emitOptimizerDataChange()"
          >One</label
        >
      </div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 0 }"></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodTwo"
          (ngModelChange)="emitOptimizerDataChange()"
          >Two</label
        >
      </div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 0 }"></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodThree"
          (ngModelChange)="emitOptimizerDataChange()"
          >Three</label
        >
      </div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }"></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodFour"
          (ngModelChange)="emitOptimizerDataChange()"
          >Four</label
        >
      </div>
      <div
        *ngIf="inputs.optimizerAdvanced"
        nz-col
        [nzXs]="{ span: 0 }"
        [nzLg]="{ span: 0 }"
      ></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodFive"
          (ngModelChange)="emitOptimizerDataChange()"
          >Five</label
        >
      </div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 0 }"></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <label
          nz-checkbox
          [(ngModel)]="methodSix"
          (ngModelChange)="emitOptimizerDataChange()"
          >Six</label
        >
      </div>
      <div nz-col [nzXs]="{ span: 0 }" [nzLg]="{ span: 6 }"></div>
      <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 18 }">
        <label
          nz-checkbox
          [(ngModel)]="methodSeven"
          (ngModelChange)="emitOptimizerDataChange()"
          >Seven</label
        >
      </div>
    </div>
  </ng-container>
</ng-container>

<div
  *ngIf="optimizationSetupFailed && optimizationSetupErrorMessage"
  class="fop-set-optimizer-settings__fetch-failed-alert-wrapper"
>
  <nz-alert
    nzType="error"
    [nzMessage]="optimizationSetupErrorMessage"
    nzShowIcon
  ></nz-alert>
</div>
