<div>
  <div style="margin-bottom: 1rem">
    <nz-alert nzType="info" [nzMessage]="infoAlertMessageTemplate" nzShowIcon>
    </nz-alert>
    <ng-template #infoAlertMessageTemplate>
      <b>Tip</b> - Lending settings are for advanced users. Ignore these
      settings and process if you are not aware of them.
    </ng-template>
  </div>
  <nz-form-item>
    <nz-form-label>Size of Line of Credit (LOC)</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.lineOfCreditSize"
        (ngModelChange)="handleDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Line of Credit Interest Rate</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="inputs.lineOfCreditInterestRate"
        (ngModelChange)="handleDataChange()"
        [nzMin]="0"
        [nzMax]="100"
        [nzStep]="1"
        [nzFormatter]="formatterPercent"
        [nzParser]="parserPercent"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <!--nz-form-item>
    <nz-form-control>
      <label
        nz-checkbox
        [(ngModel)]="inputs.lendingAdvanced"
        (ngModelChange)="handleDataChange()"
      >
        <span>Enable Advanced Settings</span>
      </label>
    </nz-form-control>
  </nz-form-item-->

</div>
