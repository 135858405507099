import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { monthsInQuarter } from 'date-fns';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-setup-peertopeer',
  templateUrl: './setup-peertopeer.component.html',
  styleUrls: ['./setup-peertopeer.component.scss']
})
export class SetupPeertopeerComponent implements OnInit {
  @Input() client!: Client;

  startDate?: Date;
  todayDate = new Date();

  addStackVisible = false;
  isAddingStack = false;
  isEdittingStack = false;
  stackId?: string;
  stackTitle = '';
  stackPlatform = '';
  stackType = 'PPL';
  stackAmount = '10000';
  stackPaymentType = 'INTEREST_RATE';
  stackInterestRate = '5';
  stackPayment = '';
  stackLoanConstant = '';
  stackFrequency = '1';
  stackFrequencyUnits = 'months';
  stackPayments = 36;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.stackPaymentType == 'INTEREST_RATE') {
      this.calculatePayment();
      this.calculateLoanConstant();
    }
  }

  addStack() {
    if (!this.isEdittingStack) {
      this.isAddingStack = true;
      this.addStackVisible = true;
    }
  }

  editStack(stack: any) {
    if (!this.isAddingStack) {
      this.isEdittingStack = true;
      this.stackId = stack.id;
      this.stackTitle = stack.title;
      this.stackType = stack.type;
      this.stackAmount = stack.amount;
      this.stackPayment = stack.payment;
      this.stackInterestRate = stack.interestRate;
      this.stackLoanConstant = stack.loanConstant;
      this.stackPayments = stack.payments;
      this.addStackVisible = true;
    }
  }

  cancelAddStack() {
    this.addStackVisible = false;
    this.isAddingStack = false;
    this.isEdittingStack = false;
  }

  okAddStack() {
    if (this.isAddingStack) {
      this.addStackVisible = false;
      this.isAddingStack = false;
      let stack = {
        id: generateUuidV4(),
        title: this.stackTitle,
        type: this.stackType,
        amount: this.stackAmount,
        interestRate: this.stackInterestRate,
        paymentType: this.stackPaymentType,
        payment: this.stackPayment,
        loanConstant: this.stackLoanConstant,
        frequency: this.stackFrequency,
        frequencyUnits: this.stackFrequencyUnits,
        payments: this.stackPayments,
      };
      this.httpClient
        .post('/api/clients/' + this.client!.id + '/peertopeerstacks', stack, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        })
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            if (!this.client!.peerToPeerStacks) {
              this.client!.peerToPeerStacks = [];
            }
            this.client!.peerToPeerStacks.push(result.stack);
          }
        });
    } else if (this.isEdittingStack) {
      this.addStackVisible = false;
      this.isEdittingStack = false;
      let stack = {
        id: this.stackId,
        title: this.stackTitle,
        type: this.stackType,
        amount: this.stackAmount,
        paymentType: this.stackPaymentType,
        interestRate: this.stackInterestRate,
        payment: this.stackPayment,
        loanConstant: this.stackLoanConstant,
        frequency: this.stackFrequency,
        frequencyUnits: this.stackFrequencyUnits,
        payments: this.stackPayments,
      };
      this.httpClient
        .post(
          '/api/clients/' + this.client!.id + '/peertopeerstacks/' + stack.id,
          stack,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let s of this.client!.peerToPeerStacks!) {
              if (s.id && s.id === stack.id) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.peerToPeerStacks!.splice(found, 1, stack);
            }
          }
        });
    }
  }

  deleteStack() {
    if (this.isEdittingStack) {
      this.addStackVisible = false;
      this.isEdittingStack = false;
      this.httpClient
        .delete(
          '/api/clients/' + this.client!.id + '/peertopeerstacks/' + this.stackId,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let s of this.client!.peerToPeerStacks!) {
              if (s.id && s.id === this.stackId) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.peerToPeerStacks!.splice(found, 1);
            }
          }
        });
    }
  }

  updateStackAmount() {
    if (this.stackPaymentType == 'INTEREST_RATE') {
      this.calculatePayment();
      this.calculateLoanConstant();
    }
  }

  updateStackInterestRate() {
    if (this.stackPaymentType == 'INTEREST_RATE') {
      this.calculatePayment();
      this.calculateLoanConstant();
    }
  }

  updateStackPayment() {
    if (this.stackPaymentType == 'PAYMENT') {
      this.calculateLoanConstant();
    }
  }

  updateStackLoanConstant() {
    if (this.stackPaymentType == 'LOAN_CONSTANT') {
      this.calculatePayment();
    }
  }

  updateTerm() {
    this.calculatePayment();
    this.calculateLoanConstant();
  }

  updateFrequency() {
    if (this.stackPaymentType == 'PAYMENT') {
      this.calculateLoanConstant();
    }
    if (this.stackPaymentType == 'LOAN_CONSTANT') {
      this.calculatePayment();
    }
  }

  getFrequencyInMonths() {
    if (this.stackFrequency == '') {
      return 1;
    }
    if (this.stackFrequencyUnits == 'years') {
      return +this.stackFrequency * 12;
    }

    return +this.stackFrequency;
  }

  calculateLoanConstant() {
    if (this.stackPaymentType == 'INTEREST_RATE') {
      let monthlyInterestRate = +this.stackInterestRate / (100 * (12 / this.getFrequencyInMonths() ));
      let payment = +this.stackAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, - +this.stackPayments));
      this.stackLoanConstant =
        '' +
        (payment / this.getFrequencyInMonths() / +this.stackAmount) *
          100;
    } else if (this.stackPaymentType == 'PAYMENT') {
      console.log('Frequency in months is ' + this.getFrequencyInMonths());
      this.stackLoanConstant =
        '' +
        (+this.stackPayment / this.getFrequencyInMonths() / +this.stackAmount) *
          100;
    }
  }

  calculateInterestRate() {
    //TODO Calculate interest rate from the payment or loan constant
  }

  calculatePayment() {
    if (this.stackPaymentType == 'INTEREST_RATE') {
      let monthlyInterestRate = +this.stackInterestRate / (100 * (12 / this.getFrequencyInMonths() )) ;
      this.stackPayment = '' + +this.stackAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, - +this.stackPayments));
    } else if (this.stackPaymentType == 'LOAN_CONSTANT') {
      console.log('Frequency in months is ' + this.getFrequencyInMonths());
      this.stackPayment =
        '' +
        this.getFrequencyInMonths() *
          (+this.stackLoanConstant / 100) *
          +this.stackAmount;
    }
  }

  clearPlanOptimization() {
    if (this.client!.plan) {
      this.client!.plan!.optimizer = undefined;
      this.client!.plan!.optimizerLevel = undefined;
    }
    if (this.client!.jobs) {
      for (let job of this.client!.jobs) {
        job.outOfDate = true;
      }
    }
  }

  get stackFrequencyYearsLabel() {
    return +this.stackFrequency > 1 ? 'Years' : 'Year';
  }
  get stackFrequencyMonthsLabel() {
    return +this.stackFrequency > 1 ? 'Months' : 'Month';
  }
  get stackFrequencyGreaterThanZero() {
    return +this.stackFrequency > 0;
  }

}
