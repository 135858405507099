export class PlanResultsUtil {
    static planOptimizerStatus(client: any) {
        let message;
        let color;
        let progress;
        if (client.plan && !client.plan.optimizer) {
            color = 'red';
            message = 'Plan Not Optimized';
            progress = 20;
        } else if (client.plan && client.plan.optimizer === 'QUICK') {
            color = 'yellow';
            message = 'Quick Optimized';
            progress = 20;
        } else if (client.plan && client.plan.optimizer === 'THOROUGH') {
            color = 'green';
            message = 'Fully Optimized';
            progress = 100;
        } else if (client.plan && client.plan.optimizerLevel == 'HIGH') {
            color = 'yellow';
            message = 'Quick Optimized: High';
            progress = 80;
        } else if (client.plan && client.plan.optimizerLevel === 'MEDIUM') {
            color = 'yellow';
            message = 'Quick Optimized: Medium';
            progress = 60;
        } else if (client.plan && client.plan.optimizerLevel === 'LOW') {
            color = 'yellow';
            message = 'Quick Optimized: Low';
            progress = 40;
        }

        return {message: message, color: color, progress: progress };
    }
}