<div>
  <div style="margin-bottom: 1rem">
    <nz-alert nzType="info" [nzMessage]="infoAlertMessageTemplate" nzShowIcon>
    </nz-alert>
    <ng-template #infoAlertMessageTemplate>
      <b>Tip</b> - Algorithm settings are for advanced users. Ignore these
      settings and process if you are not aware of them.
    </ng-template>
  </div>
  <nz-form-item>
    <nz-form-control>
      <label
        nz-checkbox
        [(ngModel)]="inputs.algorithmAdvanced"
        (ngModelChange)="handleDataChange()"
      >
        <span>Enable Advanced Algorithm Settings</span>
      </label>
    </nz-form-control>
  </nz-form-item>
</div>

<div *ngIf="inputs.algorithmAdvanced" nz-row nzGutter="16">
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 24 }" [nzLg]="{ span: 24 }">
    <nz-form-item>
      <nz-form-label>Algorithm (TY)</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-select [(ngModel)]="inputs.methodToUse" style="width: 200px">
          <nz-option [nzValue]="0" nzLabel="One"></nz-option>
          <nz-option [nzValue]="1" nzLabel="Two"></nz-option>
          <nz-option [nzValue]="2" nzLabel="Three"></nz-option>
          <nz-option [nzValue]="3" nzLabel="Four"></nz-option>
          <nz-option [nzValue]="4" nzLabel="Five"></nz-option>
          <nz-option [nzValue]="5" nzLabel="Six"></nz-option>
          <nz-option [nzValue]="6" nzLabel="Seven"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item>
      <nz-form-label>GR</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.growthCapital"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item>
      <nz-form-label>MGR</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.monthsToPayOffLOC"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item>
      <nz-form-label>MP3</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.monthsToPayOffLOCPhase3"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div
    nz-col
    [nzXs]="{ span: 24 }"
    [nzMd]="{ span: 12 }"
    [nzLg]="{ span: 8 }"
    style="margin: 10px 0"
  >
    Keep Additional Income in System
    <nz-switch
      [(ngModel)]="inputs.keepAdditionalIncomeInSystem"
      style="margin-left: 20px"
    ></nz-switch>
  </div>
  <div
    nz-col
    [nzXs]="{ span: 24 }"
    [nzMd]="{ span: 24 }"
    [nzLg]="{ span: 24 }"
    style="margin: 10px 0"
  >
    Enable Auto Increment
    <nz-switch
      [(ngModel)]="inputs.enableAutoIncrement"
      style="margin-left: 20px"
    ></nz-switch>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item *ngIf="inputs.enableAutoIncrement">
      <nz-form-label>AA</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.autoIncrementAmount"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item *ngIf="inputs.enableAutoIncrement">
      <nz-form-label>NumFlips</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.autoIncrementNumFlips"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item *ngIf="inputs.enableAutoIncrement">
      <nz-form-label>Max</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.autoIncrementMax"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12 }" [nzLg]="{ span: 8 }">
    <nz-form-item *ngIf="inputs.enableAutoIncrement">
      <nz-form-label>One-Time Max Amount</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="inputs.autoIncrementOneTimeMaxAmount"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </div>
</div>
