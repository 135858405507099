import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client, PlanReport } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import {
  formatDifferenceForDate,
  toFullDateFormat,
} from 'src/app/utils/date-utils';

@Component({
  selector: 'app-confirm-delete-plan-report',
  templateUrl: './confirm-delete-plan-report.component.html',
  styleUrls: ['./confirm-delete-plan-report.component.scss'],
})
export class ConfirmDeletePlanReportComponent {
  submitBtnDanger = true;

  @Input() client!: Client;
  @Input() report!: PlanReport;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Delete';
  modalTitle = 'Delete Income Plan Report';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.httpClient
      .delete(
        '/api/clients/' + this.client!.id + '/reports/' + this.report.id,
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (result: any) => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.operationSuccess.emit();
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to delete income plan report',
          };
        }
      );
  }

  get reportTitle() {
    return this.report.title;
  }

  get reportSummary() {
    return this.report.summary ?? MissingValuePlaceholderText;
  }

  get reportGenerationDateFormatted() {
    const { date } = this.report;
    if (date) {
      return `${toFullDateFormat(new Date(date))} (${formatDifferenceForDate(
        new Date(date)
      )})`;
    } else {
      return 'Unknown Date';
    }
  }
}
