import { DatePipe } from '@angular/common';
import { getAlgorithmMethodName } from 'src/app/components/home/client-utils';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import {
  rankAndColorNumbers,
  RankingMode,
} from 'src/app/utils/color-ranking-util';
import { CompareAndRank } from 'src/app/utils/common-app-types';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import { toMMMDDYYYYDateFormat } from 'src/app/utils/date-utils';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformUSDCurrencyNoDecimals } from 'src/app/utils/transform-currency';

export interface AnalysisPlanCompareAndRankResult {
  summary: {
    financialFreedomDay?: CompareAndRank;
    netWorthAtEndOfPlan?: CompareAndRank;
    contributionSummary?: CompareAndRank;
    lendingStat?: CompareAndRank;
    monthlyContribution?: CompareAndRank;
    algorithmAdvanced?: CompareAndRank;
  };
  timeline: {
    planStartDate?: CompareAndRank;
    durationOfPassiveIncome?: CompareAndRank;
    contributionEndsOn?: CompareAndRank;
    cashflowEndsOn?: CompareAndRank;
  };
  financialFreedomDayNumbers: {
    ffdLocSize?: CompareAndRank;
    ffdUpb?: CompareAndRank;
    ffdTotalCashflow?: CompareAndRank;
    ffdTotalAssetsCreated?: CompareAndRank;
  };
  numbers: {
    principalInvested?: CompareAndRank;
    locSize?: CompareAndRank;
    flipCount?: CompareAndRank;
    netWorth?: CompareAndRank;
  };
}

export interface AnalysisPlanWithCompareAndScores {
  analysisPlan: AnalysisPlan;
  compareAndRankResult: AnalysisPlanCompareAndRankResult;
  scores: {
    allScores: OptimizerResultScore[];

    ffd: OptimizerResultScore[];
    collapsed_ffd: OptimizerResultScore[];
    timeline: OptimizerResultScore[];
    collapsed_timeline: OptimizerResultScore[];
    numbers: OptimizerResultScore[];
    collapsed_numbers: OptimizerResultScore[];
    others: OptimizerResultScore[];

    collapsed_client_goal: OptimizerResultScore[];
    collapsed_algorithm: OptimizerResultScore[];
    collapsed_lending: OptimizerResultScore[];
  };
}

export type StatusColorType =
  | 'green'
  | 'red'
  | 'yellow'
  | 'dollar-amount'
  | 'normal-text';

export interface OptimizerResultScore {
  label: string;
  value: string | null | undefined;
  color?: StatusColorType;
  compareAndRank?: CompareAndRank | undefined;
  tooltipMessage?: string;
  placesToDisplay: Partial<
    Record<
      | 'numbers'
      | 'timeline'
      | 'ffd'
      | 'others'
      | 'collapsed_client_goal'
      | 'collapsed_lending'
      | 'collapsed_algorithm'
      | 'collapsed_timeline'
      | 'collapsed_numbers'
      | 'collapsed_ffd',
      number
    >
  >;
}

const rankInvestmentParameter = (
  optimizerResultsWithAnalysis: AnalysisPlanWithCompareAndScores[],
  valueExtractor: (analysisPlan: AnalysisPlan) => number | undefined,
  rankAssigner: (
    compareAndRankResult: AnalysisPlanCompareAndRankResult,
    rank: CompareAndRank
  ) => void,
  rankingMode: RankingMode
) => {
  const jobResultsWithValue = optimizerResultsWithAnalysis.filter((o) =>
    isNumberValid(valueExtractor(o.analysisPlan))
  );
  return rankAndColorNumbers(
    jobResultsWithValue.map((i) => valueExtractor(i.analysisPlan) as number),
    rankingMode
  ).forEach((r, index) => {
    rankAssigner(jobResultsWithValue[index].compareAndRankResult, r);
  });
};

const calculateResultScores = (
  analysisPlan: AnalysisPlan,
  compareAndRankResult: AnalysisPlanCompareAndRankResult
): OptimizerResultScore[] => {
  const arrayMsgs = analysisPlan.results.msgs;
  const financialFreedomDay = arrayMsgs.freedom_date;
  const financialFreedomIn = arrayMsgs.freedom_in;

  const monthlyContributionAmount =
    analysisPlan.inputs.monthlyContributionAmount !== undefined
      ? +analysisPlan.inputs.monthlyContributionAmount
      : undefined;

  const freedomTotalMonths = arrayMsgs.freedom_total_months;
  const freedomTotalYears = arrayMsgs.freedom_total_years;
  const contributionIn = arrayMsgs.contribution_in;
  const contributionDate = arrayMsgs.contribution_date;

  const ffdLocSize = arrayMsgs.freedom_loc_size;
  const ffdUpb = arrayMsgs.freedom_upb;
  const ffdCashflow = arrayMsgs.total_cf_for_month;
  const ffdAssetsCreated = arrayMsgs.freedom_assets_created;
  const locSize = arrayMsgs.loc_size;
  const principalInvested = arrayMsgs.principal_invested;
  const flipCount = arrayMsgs.flip_count;
  const planStartDate = analysisPlan.inputs.startDate
    ? new Date(analysisPlan.inputs.startDate)
    : undefined;

  const finalLoanUpb = arrayMsgs.final_loan_upb;
  const finalLocSize = arrayMsgs.final_loc_size;
  const finalNetWorth =
    isNumberValid(finalLoanUpb) && isNumberValid(finalLocSize)
      ? finalLoanUpb - finalLocSize
      : undefined;

  const algorithmMethod = getAlgorithmMethodName(
    analysisPlan.inputs?.methodOfAdjustingLOC
  );

  const pcCounters = `${arrayMsgs.phase1_count}, ${arrayMsgs.phase2_count}, ${arrayMsgs.phase3_count}`;

  const algorithmAdvanced = analysisPlan.inputs.algorithmAdvanced === true;

  const scores: OptimizerResultScore[] = [
    {
      label: 'Plan Start Date',
      value: planStartDate
        ? toMMMDDYYYYDateFormat(planStartDate)
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'The day when you this income plan starts',
      placesToDisplay: { timeline: 1 },
      compareAndRank: compareAndRankResult.timeline.planStartDate,
    },
    {
      label: 'Financial Freedom Day',
      value: financialFreedomDay
        ? `${financialFreedomDay} (${financialFreedomIn})`
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'The day when you can retire',
      placesToDisplay: { timeline: 2, collapsed_timeline: 1 },
      compareAndRank: compareAndRankResult.summary.financialFreedomDay,
    },
    {
      label: 'Duration of Passive Income',
      value:
        freedomTotalYears && isNumberValid(freedomTotalMonths)
          ? `${freedomTotalYears} (${freedomTotalMonths}
        months)`
          : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Duration of retirement that this plan supports',
      placesToDisplay: { timeline: 3 },
      compareAndRank: compareAndRankResult.timeline.durationOfPassiveIncome,
    },
    {
      label: 'Contribution Ends On',
      value:
        contributionIn && contributionDate
          ? `${contributionDate} (${contributionIn})`
          : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Date at which contribution ends',
      placesToDisplay: { timeline: 4 },
      compareAndRank: compareAndRankResult.timeline.contributionEndsOn,
    },
    {
      label: 'Cashflow Ends On',
      value: freedomTotalYears
        ? freedomTotalYears
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Date at which cashflow ends on',
      placesToDisplay: { timeline: 5 },
      compareAndRank: compareAndRankResult.timeline.cashflowEndsOn,
    },

    {
      label: 'Financial Freedom Day LOC Size',
      value: isNumberValid(ffdLocSize)
        ? transformUSDCurrencyNoDecimals(ffdLocSize)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 1 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdLocSize,
    },
    {
      label: 'Financial Freedom Day UPB',
      value: isNumberValid(ffdUpb)
        ? transformUSDCurrencyNoDecimals(ffdUpb)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 2, collapsed_ffd: 1 },
      compareAndRank: compareAndRankResult.financialFreedomDayNumbers.ffdUpb,
    },
    {
      label: 'Financial Freedom Day Cashflow',
      value: isNumberValid(ffdCashflow)
        ? transformUSDCurrencyNoDecimals(ffdCashflow)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 3 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdTotalCashflow,
    },
    {
      label: 'Financial Freedom Day Assets Created',
      value: isNumberValid(ffdAssetsCreated)
        ? transformUSDCurrencyNoDecimals(ffdAssetsCreated)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 4 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdTotalAssetsCreated,
    },
    {
      label: 'Principal Invested',
      value: isNumberValid(principalInvested)
        ? transformUSDCurrencyNoDecimals(principalInvested)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { numbers: 1, collapsed_numbers: 1 },
      compareAndRank: compareAndRankResult.numbers.principalInvested,
    },
    {
      label: 'Monthly Contribution',
      value: isNumberValid(monthlyContributionAmount)
        ? transformUSDCurrencyNoDecimals(monthlyContributionAmount)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'Monthly Contribution amount',
      placesToDisplay: { collapsed_client_goal: 1 },
      compareAndRank: compareAndRankResult.summary.monthlyContribution,
    },
    {
      label: 'Starting LOC Size',
      value: isNumberValid(locSize)
        ? transformUSDCurrencyNoDecimals(locSize)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'Starting Line of Credit Size',
      placesToDisplay: { numbers: 2, collapsed_lending: 1 },
      compareAndRank: compareAndRankResult.numbers.locSize,
    },
    {
      label: 'Flip Count',
      value: isNumberValid(flipCount)
        ? `${flipCount}`
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { numbers: 3 },
      compareAndRank: compareAndRankResult.numbers.flipCount,
    },
    {
      label: 'Final Net worth',
      value: isNumberValid(finalNetWorth)
        ? transformUSDCurrencyNoDecimals(finalNetWorth)
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: `TODO // TODO Final Net Worth = (Final UPB - LOC Balance) i.e, ${
        isNumberValid(finalNetWorth)
          ? transformUSDCurrencyNoDecimals(finalNetWorth)
          : MissingValuePlaceholderText
      } = ${
        isNumberValid(finalLoanUpb)
          ? transformUSDCurrencyNoDecimals(finalLoanUpb)
          : MissingValuePlaceholderText
      } - ${
        isNumberValid(finalLocSize)
          ? transformUSDCurrencyNoDecimals(finalLocSize)
          : MissingValuePlaceholderText
      }`,
      placesToDisplay: { numbers: 6 },
      compareAndRank: compareAndRankResult.numbers.netWorth,
    },
    {
      label: 'Algorithm Method',
      value: algorithmMethod ? algorithmMethod : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { others: 1 },
      compareAndRank: undefined,
    },
    {
      label: 'Algorithm type',
      value: algorithmAdvanced === true ? 'Advanced' : 'Default',
      color: 'normal-text',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { collapsed_algorithm: 1 },
      compareAndRank: compareAndRankResult.summary.algorithmAdvanced,
    },
    {
      label: 'PCounters',
      value: pcCounters ? pcCounters : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: '(For internal use only)',
      placesToDisplay: { others: 2 },
      compareAndRank: undefined,
    },
  ];
  return scores;
};

export const calculateCompareAndRankResultsForAnalysisPlans = (
  analysisPlans: AnalysisPlan[]
): AnalysisPlanWithCompareAndScores[] => {
  const optimizerResultsWithAnalysis: AnalysisPlanWithCompareAndScores[] =
    analysisPlans.map((analysisPlan) => {
      return {
        analysisPlan,
        compareAndRankResult: {
          timeline: {},
          summary: {},
          numbers: {},
          financialFreedomDayNumbers: {},
        },
        scores: {
          allScores: [],

          ffd: [],
          collapsed_ffd: [],
          timeline: [],
          collapsed_timeline: [],
          numbers: [],
          collapsed_numbers: [],
          others: [],

          collapsed_algorithm: [],
          collapsed_client_goal: [],
          collapsed_lending: [],
        },
      };
    });

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      analysisPlan.results.msgs.freedom_date
        ? new Date(analysisPlan.results.msgs.freedom_date).getTime()
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.financialFreedomDay = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      analysisPlan.inputs.startDate
        ? new Date(analysisPlan.inputs.startDate).getTime()
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.planStartDate = r),
    'less-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      isNumberValid(analysisPlan.results.msgs.final_loan_upb) &&
      isNumberValid(analysisPlan.results.msgs.final_loc_size)
        ? analysisPlan.results.msgs.final_loan_upb -
          analysisPlan.results.msgs.final_loc_size
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.netWorthAtEndOfPlan = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.contribution_months,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.contributionSummary = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.flip_count,
    (compareAndRankResult, r) => (compareAndRankResult.summary.lendingStat = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.final_loc_size,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdLocSize = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => (analysisPlan.inputs.algorithmAdvanced ? 1 : 0),
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.algorithmAdvanced = r),
    'more-is-bluer'
  );
  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      analysisPlan.inputs.monthlyContributionAmount !== undefined
        ? +analysisPlan.inputs.monthlyContributionAmount
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.monthlyContribution = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.final_loan_upb,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdUpb = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.freedom_assets_created,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdTotalAssetsCreated =
        r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.total_cf_for_month,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdTotalCashflow = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.principal_invested,
    (compareAndRankResult, r) =>
      (compareAndRankResult.numbers.principalInvested = r),
    'less-is-better'
  );
  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      isNumberValid(analysisPlan.results.msgs.final_loan_upb) &&
      isNumberValid(analysisPlan.results.msgs.final_loc_size)
        ? analysisPlan.results.msgs.final_loan_upb -
          analysisPlan.results.msgs.final_loc_size
        : undefined,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.netWorth = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.loc_size,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.locSize = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.flip_count,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.flipCount = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.freedom_total_months,
    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.durationOfPassiveIncome = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) =>
      analysisPlan.results.msgs.contribution_date
        ? new Date(analysisPlan.results.msgs.contribution_date).getTime()
        : undefined,

    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.contributionEndsOn = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (analysisPlan) => analysisPlan.results.msgs.freedom_total_months,

    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.cashflowEndsOn = r),
    'more-is-better'
  );

  optimizerResultsWithAnalysis.forEach((or) => {
    const allScores = calculateResultScores(
      or.analysisPlan,
      or.compareAndRankResult
    );
    or.scores = {
      allScores,

      ffd: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.ffd))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.ffd ?? 0) - (r2.placesToDisplay.ffd ?? 0)
        ),
      collapsed_ffd: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_ffd))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_ffd ?? 0) -
            (r2.placesToDisplay.collapsed_ffd ?? 0)
        ),
      timeline: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.timeline))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.timeline ?? 0) -
            (r2.placesToDisplay.timeline ?? 0)
        ),
      collapsed_timeline: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_timeline))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_timeline ?? 0) -
            (r2.placesToDisplay.collapsed_timeline ?? 0)
        ),
      numbers: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.numbers))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.numbers ?? 0) -
            (r2.placesToDisplay.numbers ?? 0)
        ),
      collapsed_numbers: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_numbers))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_numbers ?? 0) -
            (r2.placesToDisplay.collapsed_numbers ?? 0)
        ),
      others: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.others))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.others ?? 0) - (r2.placesToDisplay.others ?? 0)
        ),
      collapsed_algorithm: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_algorithm))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_algorithm ?? 0) -
            (r2.placesToDisplay.collapsed_algorithm ?? 0)
        ),
      collapsed_client_goal: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_client_goal))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_client_goal ?? 0) -
            (r2.placesToDisplay.collapsed_client_goal ?? 0)
        ),
      collapsed_lending: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_lending))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_lending ?? 0) -
            (r2.placesToDisplay.collapsed_lending ?? 0)
        ),
    };
  });

  return optimizerResultsWithAnalysis;
};
