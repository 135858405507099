<nz-card>
    <div style="display: flex; align-items: center; justify-content: center; height: 50vh">
        <div style="display: flex; flex-direction: column; align-items: center">
            <p>{{ fromUser }} has initiated a transfer of their client {{ alias }} to you.</p>
            <p>To accept the transfer select Transfer.</p>
            <p>To reject the transfer select Reject.</p>
            <!--p>{{ transferId }}</p-->
            <button nz-button nzType="primary" (click)="transfer()" style="margin-top: 10px">Transfer</button>
            <button nz-button nzType="primary" (click)="cancel()" style="margin-top: 10px">Reject</button>
        </div>
    </div>
</nz-card>
