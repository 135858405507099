const RouteClientsPageBase = '/client';
export const RouteClientPage = (clientId?: string) =>
  `${RouteClientsPageBase}/${clientId}`;

export const RouteClientPlan = (clientId?: string) =>
  `${RouteClientPage(clientId)}/plan`;
export const RouteClientSetup = (clientId?: string) =>
  `${RouteClientPage(clientId)}/setup`;
export const RouteClientOptimizer = (clientId?: string) =>
  `${RouteClientPage(clientId)}/optimizer`;
export const RouteClientAnalysis = (clientId?: string) =>
  `${RouteClientPage(clientId)}/analysis`;
export const RouteClientInfo = (clientId?: string) =>
  `${RouteClientPage(clientId)}/info`;
