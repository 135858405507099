import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { AsyncOpData } from 'src/app/utils/common-utility-types';
import { getAlgorithmMethodName } from '../../home/client-utils';
import { AnalysisPlanWithCompareAndScores } from '../analysis-plan-ranking-util';

@Component({
  selector: 'app-comparative-analysis',
  templateUrl: './comparative-analysis.component.html',
  styleUrls: ['./comparative-analysis.component.scss'],
})
export class ComparativeAnalysisComponent {
  @Input() analysisPlans!: AnalysisPlan[];

  @Input()
  analysisPlanWithCompareAndScores?: AnalysisPlanWithCompareAndScores[];

  @Input() updateAnalysisIdToApiCallMap!: Record<string, AsyncOpData<void>>;

  initialCollapsed = false;

  getAlgorithmMethodName = getAlgorithmMethodName;

  showCompareAndRank = true;

  @Input() timelineSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() freedomDaySectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() numberSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() otherSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;

  @Output() analysisFormDataChanged = new EventEmitter<AnalysisPlan>();

  @Output() deleteAnalysisClicked = new EventEmitter<AnalysisPlan>();
  @Output() makeCurrentPlanClicked = new EventEmitter<AnalysisPlan>();
  @Output() copyCurrentPlanClicked = new EventEmitter();
  @Output() clonePlanPlanClicked = new EventEmitter<number>();

  constructor() {}

  onAnalysisFormDataChange(analysisPlan: AnalysisPlan) {
    this.analysisFormDataChanged.emit(analysisPlan);
  }

  onDeleteAnalysisClick(analysisPlan: AnalysisPlan) {
    this.deleteAnalysisClicked.emit(analysisPlan);
  }

  onMakeCurrentClick(analysisPlan: AnalysisPlan) {
    this.makeCurrentPlanClicked.emit(analysisPlan);
  }

  onCopyCurrentPlan() {
    this.copyCurrentPlanClicked.emit();
  }

  onCloneAnalysisClick(sourceAnalysisPlanIndex: number) {
    this.clonePlanPlanClicked.emit(sourceAnalysisPlanIndex);
  }
}
