import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StyleObjectLike } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-core-modal',
  templateUrl: './core-modal.component.html',
  styleUrls: ['./core-modal.component.css'],
})
export class CoreModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  @Input() closable = false;
  @Input() maskClosable = false;

  @Input() title!: string;

  @Input() size: 'large' | 'small' | 'default' = 'default';

  @Input() modalBodyStyle?: StyleObjectLike;
  @Input() modalAutofocus: 'ok' | 'cancel' | 'auto' | null = 'auto';

  constructor() {}

  ngOnInit(): void {}

  onCloseBtnClick(): void {
    this.closeModal.emit();
  }

  handleCancel(): void {
    this.closeModal.emit();
  }

  get modalSize() {
    return this.size === 'large' ? 800 : this.size === 'small' ? 400 : 520;
  }
}
