<span
  class="job-result-metric"
  [class.sm]="size === 'small'"
  [class.job-result-metric--with-compare-and-rank]="
    showCompareAndRank && compareAndRank
  "
  [style.border-color]="
    showCompareAndRank && compareAndRank ? compareAndRank.color : 'transparent'
  "
  [style.background-color]="
    showCompareAndRank && compareAndRank
      ? compareAndRank.colorLight
      : 'transparent'
  "
>
  <span
    *ngIf="showCompareAndRank && compareAndRank"
    class="job-result-metric__rank-badge"
    [style.background-color]="compareAndRank.color"
    [style.color]="compareAndRank.textColor"
  >
    {{ toCompareAndRankDisplayText(compareAndRank) }}
  </span>

  <span class="job-result-metric__content">
    <ng-content></ng-content>
  </span>
</span>
