import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { getClientFullName } from 'src/app/components/home/client-utils';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import { OptimizerResult } from 'src/app/models/optimizer-result';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  NotificationDurationMs,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

@Component({
  selector: 'app-confirm-mark-current-plan',
  templateUrl: './confirm-mark-current-plan.component.html',
  styleUrls: ['./confirm-mark-current-plan.component.scss'],
})
export class ConfirmMarkCurrentPlanComponent {
  @Input() result!: OptimizerResult;
  @Input() job!: Job;
  @Input() client!: Client;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<void>();

  clientAddApiCall: AsyncOpData<void>;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private notification: NzNotificationService
  ) {
    this.clientAddApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAddClient() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isAddInProgress() {
    return this.clientAddApiCall.state === AsyncOpState.Inprogress;
  }

  get addErrorMessage() {
    return this.clientAddApiCall.error;
  }

  get showAddErrorMessage() {
    return this.clientAddApiCall.state === AsyncOpState.Failed;
  }

  makeCurrent() {
    this.clientAddApiCall = {
      state: AsyncOpState.Inprogress,
    };

    if (!this.result.inputs) {
      return;
    }

    let inputs: PlanInputs = {
      simulationName: this.job.inputs?.simulationName,
      startDate: this.job.inputs?.startDate as Date,
      monthlyIncomeGoal: this.result?.inputs.incomeGoal,
      monthlyContributionAmount: this.result.inputs?.monthlyContribution,
      inflationRate:
        (this.result.inputs.inflationRateForPassiveIncome ?? 0) * 100,
      yearsToSimulate: this.result.inputs.numYearsToSimulate,
      loansRateOfReturn: (this.result.inputs.loansReturn ?? 0) * 100,
      lineOfCreditSize: this.result.inputs.locDrawAmount,
      lineOfCreditInterestRate: (this.result.inputs.locInterestRate ?? 0) * 100,
      loansTerm: this.result.inputs.loansNumberofYears,
      growthCapital: this.result.inputs.growthCapital,
      monthsToPayOffLOC: this.result.inputs.monthsToPayoffLOC,
      monthsToPayOffLOCPhase3: this.result.inputs.monthsToPayoffLOCPhase3,
      keepAdditionalIncomeInSystem:
        this.result.inputs.bKeepAdditionalIncomeInSystem,
      enableAutoIncrement: this.result.inputs.autoIncrementEnable,
      autoIncrementAmount: this.result.inputs.autoIncrementAmount,
      autoIncrementNumFlips: this.result.inputs.autoIncrementNumFlips,
      autoIncrementMax: this.result.inputs.autoIncrementMax,
      autoIncrementOneTimeMaxAmount:
        this.result.inputs.autoIncrementOneTimeMaxAmount,
      methodToUse: this.result.inputs.methodOfAdjustingLOC,
      scheduledTransactions: this.client!.transactions,
      deposits: this.client!.deposits,
    };
    this.httpClient
      .post('/engine/plan', inputs, {
        headers: new HttpHeaders().append('timeout', '60000'),
      })
      .subscribe(
        (results: any) => {
          let optimizerPlan: any = { inputs: inputs, results: results };

          if (!this.job?.outOfDate) {
            optimizerPlan.optimizer = this.job?.optimizer;
            if (this.job?.optimizerLevel) {
              optimizerPlan.optimizerLevel = this.job!.optimizerLevel!;
            }
          }
          this.httpClient
            .post('/api/clients/' + this.client!.id + '/plan', optimizerPlan, {
              headers: toApiAuthHeaders(this.authService),
            })
            .subscribe(
              (result) => {
                this.client!.plan = optimizerPlan;
                this.clientAddApiCall = {
                  state: AsyncOpState.Completed,
                };

                this.notification.create(
                  'success',
                  'Success! Client Plan Changed',
                  `Your action for marking a change in plan for client ${getClientFullName(
                    this.client
                  )} is completed.`,
                  {
                    nzDuration: NotificationDurationMs,
                  }
                );

                this.operationSuccess.emit();
              },
              (err) => {
                this.clientAddApiCall = {
                  state: AsyncOpState.Failed,
                  error: 'Failed to mark optimizer result as current plan.',
                };
              }
            );
        },
        (err) => {
          this.clientAddApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to mark optimizer result as current plan.',
          };
        }
      );
  }
}
