import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { getAlgorithmMethodName } from 'src/app/components/home/client-utils';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import { OptimizerResult } from 'src/app/models/optimizer-result';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import {
  RouteClientAnalysis,
  RouteClientPage,
  RouteClientPlan,
} from 'src/app/utils/route-constants';
import { OptimizerResultWithAnalysis } from './job-result-ranking-util';

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss'],
})
export class JobDetailComponent {
  @Input() client!: Client;
  @Input() job!: Job;
  @Input() optimizerResultsWithAnalysis!: OptimizerResultWithAnalysis[];
  getAlgorithmMethodName = getAlgorithmMethodName;

  showCompareAndRank = true;
  initialCollapsed = true;

  @Input() timelineSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() freedomDaySectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() numberSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  @Input() otherSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;

  constructor(private router: Router) {}

  get jobResultCount() {
    return this.job.results?.topresults?.length ?? 0;
  }

  get jobResults() {
    return this.job.results?.topresults;
  }

  markAsPlanConfirmModal: {
    open: boolean;
    result?: OptimizerResult;
  } = {
    open: false,
  };

  makeCurrentClick(result: OptimizerResult) {
    this.markAsPlanConfirmModal = {
      open: true,
      result,
    };
  }

  cancelMarkAsCurrent() {
    this.markAsPlanConfirmModal = {
      open: false,
    };
  }

  markAsCurrentSuccess() {
    this.markAsPlanConfirmModal = {
      open: false,
    };
    this.router.navigate([RouteClientPlan(this.client.id)]);
  }

  addToAnalysisConfirmModal: {
    open: boolean;
    result?: OptimizerResult;
  } = {
    open: false,
  };

  addToAnalysisClick(result: OptimizerResult) {
    this.addToAnalysisConfirmModal = {
      open: true,
      result,
    };
  }

  cancelAddToAnalysis() {
    this.addToAnalysisConfirmModal = {
      open: false,
    };
  }

  addToAnalysisSuccess() {
    this.addToAnalysisConfirmModal = {
      open: false,
    };
    this.router.navigate([RouteClientAnalysis(this.client.id)]);
  }

  toResultSmartKey(result: OptimizerResult) {
    const smartKey = result.arrayMsgs.engine_name;
    if (smartKey) {
      const searchString = 'my_favorite_optimizer <br>SmartKEY';
      const trimStringIndex = smartKey.indexOf(searchString);
      if (trimStringIndex !== -1) {
        return smartKey.substr(trimStringIndex + searchString.length);
      } else {
        return smartKey;
      }
    }
    return MissingValuePlaceholderText;
  }
}
