<div>
    <div *ngIf="!payments || payments.length === 0">
        No Payments
    </div>
    <table *ngIf="payments && payments.length > 0" style="width: 100%; overflow-x: scroll">
        <thead>
            <tr>
                <th>Flip No.</th>
                <th>Date</th>
                <th>Type</th>
                <th>Source</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tr *ngFor="let payment of payments">
            <td>{{ payment.flipNo }}</td>
            <td>{{ payment.date }}</td>
            <td>{{ payment.type }}</td>
            <td>{{ payment.source }}</td>
            <td>{{ payment.payment }}</td>
        </tr>
    </table>
    <div *ngIf="client && client.locSpreadsheetErrors">
        <h2>Spreadsheet Errors</h2>
        <div *ngFor="let error of client.locSpreadsheetErrors">
            {{ error }}
        </div>
    </div>
</div>