<nz-card style="height: 100%; margin-top: 20px" nzTitle="Debt-to-Wealth Income Stack" [nzExtra]="extraTemplate">
    <p>An installment loan is a type of agreement involving a loan that is repaid over time with a set number of scheduled payments. This includes auto loans, student loans, mortgage loans, and personal loans.</p>
    <div *ngIf="!client.loans || client.loans.length == 0">
        No Loans
    </div>
    <table *ngIf="client.loans && client.loans.length > 0" style="width: 100%">
        <thead>
            <th>Title</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Loan Constant</th>
            <th>Payment</th>
            <th>Payments</th>
        </thead>
        <tr *ngFor="let loan of client.loans">
            <td>{{ loan.title }}</td>
            <td>
                <nz-tag *ngIf="loan.type == 'REVOLVING'" nzColor="#060A2B">
                    Revolving
                </nz-tag>
                <nz-tag *ngIf="loan.type == 'LOAN'" nzColor="#06A7F5">
                    Loan
                </nz-tag>
            </td>
            <td>{{ loan.amount | currency }}</td>
            <td>{{ +loan.loanConstant / 100 | percent:"1.0-2" }}</td>
            <td>
                {{ loan.payment | currency: "USD":"$":"1.0-2" }}
            </td>
            <td>
                {{ loan.payments | number }}
            </td>
            <td style="text-align: right">
                <button nz-button nzType="primary" (click)="editLoan(loan)">
                    Edit
                </button>
            </td>
        </tr>
    </table>
</nz-card>
<ng-template #extraTemplate>
    <a (click)="addLoan()">Add Existing Installment Loan</a>&nbsp;|&nbsp;
    <a (click)="addLoanRevolving()">Add New Loan To Payoff Revolving Credit</a>
</ng-template>
<nz-modal [(nzVisible)]="addLoanVisible" 
    [nzTitle]="loanType=='LOAN'?'Existing Installment Loan':'Payoff Revolving Credit'" [nzFooter]="customFooter" (nzOnCancel)="cancelAddLoan()"
    (nzOnOk)="okAddLoan()">
    <ng-container *nzModalContent>
        <nz-form-item>
            <nz-form-label>Loan Title</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <input nz-input type="text" [(ngModel)]="loanTitle" />
            </nz-form-control>
        </nz-form-item>
        <!--nz-form-item>
            <nz-form-label>Loan Type</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-select [(ngModel)]="loanType" style="width: 120px">
                    <nz-option nzValue="LOAN" nzLabel="Loan"></nz-option>
                    <nz-option nzValue="REVOLVING" nzLabel="Revolving"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item-->
        <nz-form-item>
            <nz-form-label>Loan Amount</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number 
                    [(ngModel)]="loanAmount" 
                    [nzFormatter]="formatterDollar" 
                    [nzParser]="parserDollar"
                >
                </nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Interest Rate</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number
                    [(ngModel)]="loanInterestRate"
                    [nzFormatter]="formatterPercent"
                    [nzParser]="parserPercent"
                    (ngModelChange)="updateLoanInterestRate()"
                ></nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <!--nz-form-item>
            <nz-form-label>Monthly Payment</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number 
                    [(ngModel)]="loanPayment" 
                    [nzFormatter]="formatterDollar" 
                    [nzParser]="parserDollar"
                    (ngModelChange)="updateLoanPayment()">
                </nz-input-number>
            </nz-form-control>
        </nz-form-item-->
        <!--div>Payments:</div>
        <nz-radio-group [(ngModel)]="loanPaymentType" class="radios">
            <div>
                <label nz-radio nzValue="PAYMENT"
                >Payment
                <nz-input-number
                    [(ngModel)]="loanPayment"
                    [nzFormatter]="formatterDollar"
                    [nzParser]="parserDollar"
                    (ngModelChange)="updateLoanPayment()"
                ></nz-input-number>
                </label>
            </div>
            <div>
                <label nz-radio nzValue="INTEREST_RATE"
                >Interest Rate
                <nz-input-number
                    [(ngModel)]="loanInterestRate"
                    [nzFormatter]="formatterPercent"
                    [nzParser]="parserPercent"
                    (ngModelChange)="updateLoanInterestRate()"
                ></nz-input-number>
                </label>
            </div>
            <div>
                <label nz-radio nzValue="LOAN_CONSTANT"
                >Loan Constant
                <nz-input-number
                    [(ngModel)]="loanLoanConstant"
                    [nzFormatter]="formatterPercent"
                    [nzParser]="parserPercent"
                    (ngModelChange)="updateLoanLoanConstant()"
                ></nz-input-number>
                </label>
            </div>
        </nz-radio-group-->
        <nz-form-item>
            <nz-form-label>Months Left</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number [(ngModel)]="loanPayments"></nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <div>
            Payment: {{ loanPayment | currency }}
            Monthly Loan Constant: {{ (+loanLoanConstant / 100) | percent:"0.0-2" }}
          </div>
    </ng-container>
</nz-modal>
<ng-template #customFooter>
    <button *ngIf="isEdittingLoan" nz-button nzType="primary" nzDanger (click)="deleteLoan()">
        Delete
    </button>
    <button nz-button nzType="default" (click)="cancelAddLoan()">Cancel</button>
    <button nz-button nzType="primary" (click)="okAddLoan()"><span *ngIf="loanType=='LOAN'; else addRevolvingCredit">Add Existing Loan</span></button>
</ng-template>
<ng-template #addRevolvingCredit>
    Add Loan for Revolving Credit
  </ng-template>