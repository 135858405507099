import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, groupBy, mergeMap } from 'rxjs/operators';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { Client } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { RouteClientPlan } from 'src/app/utils/route-constants';
import {
  AnalysisPlanWithCompareAndScores,
  calculateCompareAndRankResultsForAnalysisPlans,
} from './analysis-plan-ranking-util';
import { ClonePlanSuccessData } from './comparative-analysis/confirm-clone-analysis-plan/confirm-clone-analysis-plan.component';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent implements OnInit, OnDestroy {
  client?: Client;
  fetchAnalysisApiCall: AsyncOpData<AnalysisPlan[]>;

  updateAnalysisIdToApiCallMap: Record<string, AsyncOpData<void>>;

  analysisPlanWithCompareAndScores?: AnalysisPlanWithCompareAndScores[];

  private analysisPlanDataChangeSubject: Subject<AnalysisPlan>;
  private analysisPlanChangeSubscription?: Subscription;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public advisorStateService: AdvisorStateService,
    private authService: AuthService
  ) {
    this.fetchAnalysisApiCall = {
      state: AsyncOpState.NotStarted,
    };

    this.updateAnalysisIdToApiCallMap = {};
    this.analysisPlanDataChangeSubject = new Subject<AnalysisPlan>();
  }

  ngOnInit(): void {
    this.client = this.advisorStateService.getClient();
    this.initializeAnalysisData();
  }

  ngOnDestroy() {
    this.analysisPlanChangeSubscription?.unsubscribe();
    this.analysisPlanDataChangeSubject.complete();
  }

  private initializeAnalysisData() {
    this.fetchAnalysisApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.httpClient
      .get<AnalysisPlan[]>('/api/clients/' + this.client?.id + '/analysis', {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (res) => {
          this.fetchAnalysisApiCall = {
            state: AsyncOpState.Completed,
            data: res,
          };
          this.computeCompareAndRank();
          this.setupAnalysisPlanChangeUpdateListener();
        },
        (err) => {
          this.fetchAnalysisApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error failed to fetch analysis data',
          };
        }
      );
  }

  timelineSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  freedomDaySectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  numberSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;
  otherSectionLabels?: Array<{
    label: string;
    tooltipMessage?: string;
  }>;

  private computeCompareAndRank() {
    this.analysisPlanWithCompareAndScores =
      calculateCompareAndRankResultsForAnalysisPlans(
        this.fetchAnalysisApiCall.data || []
      );

    if (this.analysisPlanWithCompareAndScores?.length > 0) {
      this.timelineSectionLabels =
        this.analysisPlanWithCompareAndScores[0].scores.timeline.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.freedomDaySectionLabels =
        this.analysisPlanWithCompareAndScores[0].scores.ffd.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.numberSectionLabels =
        this.analysisPlanWithCompareAndScores[0].scores.numbers.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
      this.otherSectionLabels =
        this.analysisPlanWithCompareAndScores[0].scores.others.map((s) => ({
          label: s.label,
          tooltipMessage: s.tooltipMessage,
        }));
    }
  }

  private setupAnalysisPlanChangeUpdateListener() {
    this.analysisPlanChangeSubscription = this.analysisPlanDataChangeSubject
      .asObservable()
      .pipe(
        // group stream of changes of every analysis plan by analysis Id
        groupBy((analysisPlan) => analysisPlan.id),
        // debounce emission from each group(aka analysis plan) thereby reducing total API call counts
        // This step avoids calling server on every keyboard event on inputs
        mergeMap((analysisPlanGroup) =>
          analysisPlanGroup.pipe(debounceTime(1000))
        )
      )
      .subscribe((updatedAnalysisPlan) => {
        this.updateAnalysisIdToApiCallMap[updatedAnalysisPlan.id] = {
          state: AsyncOpState.Inprogress,
        };

        this.httpClient
          .post('/engine/plan', updatedAnalysisPlan.inputs)
          .subscribe((results: any) => {
            const analysisPlanWithNewInputsAndNewResults = {
              id: updatedAnalysisPlan.id,
              inputs: updatedAnalysisPlan.inputs,
              results,
            };
            this.fetchAnalysisApiCall.data = (
              this.fetchAnalysisApiCall.data || []
            ).map((a) =>
              a.id === analysisPlanWithNewInputsAndNewResults.id
                ? analysisPlanWithNewInputsAndNewResults
                : a
            );
            this.computeCompareAndRank();

            this.httpClient
              .post(
                '/api/clients/' +
                  this.advisorStateService.getClientId() +
                  '/analysis/' +
                  analysisPlanWithNewInputsAndNewResults.id,
                {
                  inputs: analysisPlanWithNewInputsAndNewResults.inputs,
                  results: analysisPlanWithNewInputsAndNewResults.results,
                },
                {
                  headers: toApiAuthHeaders(this.authService),
                }
              )
              .subscribe(
                () => {
                  this.updateAnalysisIdToApiCallMap[updatedAnalysisPlan.id] = {
                    state: AsyncOpState.Completed,
                  };
                },
                (err) => {
                  this.updateAnalysisIdToApiCallMap[updatedAnalysisPlan.id] = {
                    state: AsyncOpState.Failed,
                    error: 'Updating analysis data failed.',
                  };
                }
              );
          });
      });
  }

  onAnalysisFormDataChanged(analysisPlan: AnalysisPlan) {
    this.analysisPlanDataChangeSubject.next(analysisPlan);
  }

  get analysisFetchInProgress() {
    return this.fetchAnalysisApiCall.state === AsyncOpState.Inprogress;
  }
  get analysisFetchSuccess() {
    return this.fetchAnalysisApiCall.state === AsyncOpState.Completed;
  }
  get analysisFetchFailed() {
    return this.fetchAnalysisApiCall.state === AsyncOpState.Failed;
  }
  get analysisFetchErrorMessage() {
    return this.fetchAnalysisApiCall.error;
  }

  deleteAnalysisPlanConfirmModal: {
    open: boolean;
    analysisPlan?: AnalysisPlan;
  } = {
    open: false,
  };

  openDeleteAnalysisConfirm(analysisPlan: AnalysisPlan) {
    this.deleteAnalysisPlanConfirmModal = {
      open: true,
      analysisPlan,
    };
  }

  cancelDeleteAnalysisPlan() {
    this.deleteAnalysisPlanConfirmModal = {
      open: false,
    };
  }

  deleteAnalysisPlanSuccess() {
    this.fetchAnalysisApiCall.data = (
      this.fetchAnalysisApiCall.data || []
    ).filter(
      (a) => a.id !== this.deleteAnalysisPlanConfirmModal.analysisPlan?.id
    );
    this.computeCompareAndRank();
    this.deleteAnalysisPlanConfirmModal = {
      open: false,
    };
  }

  markAsPlanConfirmModal: {
    open: boolean;
    analysisPlan?: AnalysisPlan;
  } = {
    open: false,
  };

  openMarkAnalysisAsCurrentPlanConfirm(analysisPlan: AnalysisPlan) {
    this.markAsPlanConfirmModal = {
      open: true,
      analysisPlan,
    };
  }

  cancelMarkAsCurrent() {
    this.markAsPlanConfirmModal = {
      open: false,
    };
  }

  markAsCurrentSuccess() {
    this.markAsPlanConfirmModal = {
      open: false,
    };
    this.router.navigate([RouteClientPlan(this.client?.id)]);
  }

  copyCurrentPlanConfirmModal: {
    open: boolean;
  } = {
    open: false,
  };

  openCopyCurrentPlanConfirm() {
    this.copyCurrentPlanConfirmModal = {
      open: true,
    };
  }

  cancelCopyCurrentPlan() {
    this.copyCurrentPlanConfirmModal = {
      open: false,
    };
  }

  copyCurrentPlanSuccess(newAnalysisPlan: AnalysisPlan) {
    this.copyCurrentPlanConfirmModal = {
      open: false,
    };
    (this.fetchAnalysisApiCall.data || []).push(newAnalysisPlan);
    this.computeCompareAndRank();
  }

  clonePlanConfirmModal: {
    open: boolean;
    data?: {
      sourceAnalysisPlan: AnalysisPlan;
      sourceAnalysisPlanIndex: number;
    };
  } = {
    open: false,
  };

  openClonePlanConfirm(sourceAnalysisPlanIndex: number) {
    this.clonePlanConfirmModal = {
      open: true,
      data: {
        sourceAnalysisPlanIndex,
        sourceAnalysisPlan: (this.fetchAnalysisApiCall.data || [])[
          sourceAnalysisPlanIndex
        ],
      },
    };
  }

  cancelClonePlan() {
    this.clonePlanConfirmModal = {
      open: false,
    };
  }

  clonePlanSuccess(data: ClonePlanSuccessData) {
    (this.fetchAnalysisApiCall.data || []).splice(
      data.targetAnalysisPlanIndex,
      0,
      data.targetAnalysisPlan
    );

    this.computeCompareAndRank();
    this.clonePlanConfirmModal = {
      open: false,
    };
  }
}
