import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { ClearComponent } from './components/clear/clear.component';
import { ClientComponent } from './components/client/client.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GenerateComponent } from './components/generate/generate.component';
import { HelpComponent } from './components/help/help.component';
import { HomeComponent } from './components/home/home.component';
import { InfoComponent } from './components/info/info.component';
import { LoginComponent } from './components/login/login.component';
import { OptimizerComponent } from './components/optimizer/optimizer.component';
import { PlanComponent } from './components/plan/plan.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SetupComponent } from './components/setup/setup.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { TransfersComponent } from './components/transfers/transfers.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'components',
    pathMatch: 'prefix',
    loadChildren: () =>
      import('./iris-components-demo/iris-components-demo.module').then(
        (m) => m.IrisComponentsDemoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'client/:clientId',
    component: ClientComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'setup',
        component: SetupComponent
      },
      {
        path: 'plan',
        component: PlanComponent,
      },
      {
        path: 'analysis',
        component: AnalysisComponent,
      },
      {
        path: 'optimizer',
        component: OptimizerComponent,
      },
      {
        path: 'info',
        component: InfoComponent,
      },
      {
        path: 'reporting',
        component: ReportingComponent,
      },
      {
        path: 'transfer',
        component: TransferComponent
      }
    ],
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'authenticate',
    component: AuthenticateComponent,
  },
  {
    path: 'generate',
    component: GenerateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'clear',
    component: ClearComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transfers/:id',
    component: TransfersComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
