<div style="margin-top: 60px" *ngIf="authService.user">
  <nz-card nzTitle="Settings" [nzExtra]="extraTemplate">
    <div *ngIf="!isEditting">
      <table>
        <tr>
          <td>First Name</td>
          <td>{{ authService.user.firstName }}</td>
        </tr>
        <tr>
          <td>Last Name</td>
          <td>{{ authService.user.lastName }}</td>
        </tr>
        <tr>
          <td>Email Address</td>
          <td>{{ authService.user.email }}</td>
        </tr>
        <tr>
          <td>Phone Number</td>
          <td>{{ authService.user.phoneNumber }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>
            {{ authService.user.type == "ADVISOR" ? "Advisor" : "Coach" }}
          </td>
        </tr>
      </table>
      <div style="margin-top: 20px">Update Password</div>
      <div style="margin-top: 10px">
        <a *ngIf="!authService.user.sfaActive" routerLink="/generate"
          >Setup Second Factor Authentication (2FA)</a
        >
        <a *ngIf="authService.user.sfaActive" routerLink="/clear">Clear 2FA</a>
      </div>
    </div>
    <div *ngIf="isEditting && user" nz-row nzGutter="16">
      <div
        nz-col
        [nzXs]="{ span: 24 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
      >
        <nz-form-item>
          <nz-form-label>First Name</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input type="text" [(ngModel)]="user.firstName" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div
        nz-col
        [nzXs]="{ span: 24 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
      >
        <nz-form-item>
          <nz-form-label>Last Name</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input type="text" [(ngModel)]="user.lastName" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div
        nz-col
        [nzXs]="{ span: 24 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
      >
        <nz-form-item>
          <nz-form-label>Email Address</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input type="text" [(ngModel)]="user.email" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div
        nz-col
        [nzXs]="{ span: 24 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
      >
        <nz-form-item>
          <nz-form-label>Phone Number</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <input nz-input type="text" [(ngModel)]="user.phoneNumber" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div
        nz-col
        [nzXs]="{ span: 24 }"
        [nzMd]="{ span: 12 }"
        [nzLg]="{ span: 12 }"
      >
        <nz-form-item>
          <nz-form-label>Type</nz-form-label>
          <nz-form-control [nzSpan]="24">
            <nz-radio-group [(ngModel)]="user.type">
              <label nz-radio nzValue="ADVISOR">Advisor</label>
              <label nz-radio nzValue="COACH">Coach</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </nz-card>
  <div *ngIf="isEditting" class="button-bar">
    <button nz-button nzType="default" (click)="cancel()">Cancel</button>
    <button
      nz-button
      nzType="primary"
      (click)="save()"
      style="margin-left: 5px"
    >
      Save
    </button>
  </div>
</div>
<ng-template #extraTemplate>
  <a *ngIf="!isEditting" (click)="edit()">Edit</a>
</ng-template>
