import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  client?: Client;

  loans?: any[];

  alias = '';
  firstName = '';
  lastName = '';
  emailAddress = '';
  phoneNumber = '';
  address = '';
  city = '';
  state = '';
  postalCode = '';
  spreadsheet = '';
  currency = 'USD';
  timeZone = 'Pacific';
  dateFormat = 'F Y';
  timeFormat = 'hh:mm a';
  advisor = '';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private advisorStateService: AdvisorStateService
  ) {}

  ngOnInit(): void {
    this.client = this.advisorStateService.getClient();
    if (this.client!.alias) {
      this.alias = this.client!.alias;
    }

    if (this.client!.firstName) {
      this.firstName = this.client!.firstName;
    }

    if (this.client!.lastName) {
      this.lastName = this.client!.lastName;
    }

    if (this.client!.emailAddress) {
      this.emailAddress = this.client!.emailAddress;
    }

    if (this.client!.phoneNumber) {
      this.phoneNumber = this.client!.phoneNumber;
    }

    if (this.client!.address) {
      this.address = this.client!.address;
    }

    if (this.client!.city) {
      this.city = this.client!.city;
    }

    if (this.client!.state) {
      this.state = this.client!.state;
    }

    if (this.client!.postalCode) {
      this.postalCode = this.client!.postalCode;
    }

    if (this.client!.spreadsheet) {
      this.spreadsheet = this.client!.spreadsheet;
    }

    if (this.client!.currency) {
      this.currency = this.client!.currency;
    }

    if (this.client!.timeZone) {
      this.timeZone = this.client!.timeZone;
    }

    if (this.client!.dateFormat) {
      this.dateFormat = this.client!.dateFormat;
    }

    if (this.client!.timeFormat) {
      this.timeFormat = this.client!.timeFormat;
    }

    if (this.client!.advisor) {
      this.advisor = this.client!.advisor;
    }
  }

  save() {
    let data = {
      alias: this.alias,
      firstName: this.firstName,
      lastName: this.lastName,
      emailAddress: this.emailAddress,
      phoneNumber: this.phoneNumber,
      address: this.address,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      spreadsheet: this.spreadsheet,
      currency: this.currency,
      timeZone: this.timeZone,
      dateFormat: this.dateFormat,
      timeFormat: this.timeFormat,
      advisor: this.advisor,
    };
    this.httpClient
      .post('/api/clients/' + this.client!.id, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('X-Access-Token', `Bearer ${this.authService.token}`),
      })
      .subscribe((result) => {
        this.client!.alias = this.alias;
        this.client!.firstName = this.firstName;
        this.client!.lastName = this.lastName;
        this.client!.emailAddress = this.emailAddress;
        this.client!.phoneNumber = this.phoneNumber;
        this.client!.address = this.address;
        this.client!.city = this.city;
        this.client!.state = this.state;
        this.client!.postalCode = this.postalCode;
        this.client!.spreadsheet = this.spreadsheet;
        this.client!.currency = this.currency;
        this.client!.timeZone = this.timeZone;
        this.client!.dateFormat = this.dateFormat;
        this.client!.timeFormat = this.timeFormat;
        this.client!.advisor = this.advisor;
      });
  }
}
