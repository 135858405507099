<div class="collapsible" [class.collapsed]="collapsed">
  <div
    class="collapsible__title"
    [class.no-border-top]="noTopBorder"
    role="button"
    tabindex="0"
    (click)="toggleCollapse()"
  >
    <div class="collapsible__title__caret-icon__wrapper">
      <svg
        class="collapsible__title__caret-icon"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div class="collapsible__title__content">
      <ng-content select="[collapsible-title]"></ng-content>
    </div>
  </div>
  <div class="collapsible__content">
    <ng-content select="[collapsible-content]"></ng-content>
  </div>
</div>
