import { scaleLinear } from 'd3-scale';
import randomcolor from 'randomcolor';
import { get as ColorGet } from 'color-string';
import { interpolateHcl } from 'd3-interpolate';

export const Colors = {
  blue: 'rgb(5, 56, 110)',
  white: 'rgb(255, 255, 255)',
  orange: 'rgb(255, 129, 68)',
  green: 'rgb(15, 157, 88)',
  greenWithOpacity: 'rgba(15, 157, 88, 0.5)',
  red: 'rgb(255, 99, 132)',
  redGradient: (context: CanvasRenderingContext2D): CanvasGradient => {
    const gradient = context.createLinearGradient(0, 300, 0, 0);
    const opacity = 0.65;
    gradient.addColorStop(0, `rgb(255, 233, 238, ${opacity})`);
    gradient.addColorStop(1, `rgb(255, 99, 132, ${opacity})`);
    return gradient;
  },
  blueGradient: (context: CanvasRenderingContext2D): CanvasGradient => {
    const gradient = context.createLinearGradient(0, 300, 0, 0);
    const opacity = 0.65;
    gradient.addColorStop(0, `rgb(249, 250, 251, ${opacity})`);
    gradient.addColorStop(1, `rgb(230, 235, 240, ${opacity})`);
    return gradient;
  },
  greenGradient: (context: CanvasRenderingContext2D): CanvasGradient => {
    const gradient = context.createLinearGradient(0, 300, 0, 0);
    const opacity = 0.65;
    gradient.addColorStop(0, `rgb(255, 255, 255, ${opacity})`);
    gradient.addColorStop(1, `rgb(34, 211, 125, ${opacity})`);
    return gradient;
  },
  yellowGradient: (context: CanvasRenderingContext2D): CanvasGradient => {
    const gradient = context.createLinearGradient(0, 300, 0, 0);
    const opacity = 0.65;
    gradient.addColorStop(0, `rgb(236, 201, 75, ${opacity})`);
    gradient.addColorStop(1, `rgb(254, 252, 191, ${opacity})`);
    return gradient;
  },

  yellow: 'rgb(236, 201, 75)',

  // background and borders from clarity.design color palette
  grey0: 'hsl(198, 0%, 100%)',
  grey1: 'hsl(198, 0%, 98%)',
  grey2: 'hsl(198, 0%, 91%)',
};

function generateCanvasGradientFromColor(
  context: CanvasRenderingContext2D,
  colorInRgb: string,
  opacitySteps: string[]
): CanvasGradient {
  const colorRgb = ColorGet.rgb(colorInRgb);
  const gradient = context.createLinearGradient(0, 300, 0, 0);
  opacitySteps.forEach((s) => {
    if (colorRgb) {
      gradient.addColorStop(
        0,
        `rgb(${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}, ${s})`
      );
    }
  });
  return gradient;
}

export const PresetColorsInRgb = [
  'rgb(94,98,181)',
  'rgb(42, 195, 190)',
  'rgb(242,114,111)',
  'rgb(255,197,51)',
  'rgb(115, 87, 72)',
  'rgb(148, 54, 166)',
  'rgb(72, 151, 223)',
  'rgb(65, 81, 172)',
  'rgb(104, 175, 89)',
  'rgb(65, 147, 138)',
  'rgb(151, 193, 92)',
  'rgb(205, 218, 88)',
  'rgb(250, 233, 97)',
  'rgb(246, 194, 68)',
  'rgb(236, 157, 57)',
  'rgb(157, 97, 114)',
];

export interface ColorWithGradient {
  color: string;
  gradient: CanvasGradient;
  gradientLight: CanvasGradient;
}

export function getVariousColors(
  context: CanvasRenderingContext2D,
  count: number
): ColorWithGradient[] {
  const colors =
    count < PresetColorsInRgb.length
      ? PresetColorsInRgb.slice(0, count)
      : PresetColorsInRgb.concat(
          randomcolor({
            count: count - PresetColorsInRgb.length,
            format: 'rgb',
            luminosity: 'dark',
          })
        );
  return colors.map((c) => ({
    color: c,
    gradient: generateCanvasGradientFromColor(context, c, ['1.00', '1.00']),
    gradientLight: generateCanvasGradientFromColor(context, c, [
      '0.85',
      '0.75',
    ]),
  }));
}

export const DollarAmountColor = '#004B6B';

export interface YellowColorRange {
  min: {
    value: number;
    inclusive: boolean;
  };
  max: {
    value: number;
    inclusive: boolean;
  };
}

export const RdYlGnColors = {
  red: {
    dark: '#6A0120',
    light: '#ED643F',
  },
  yellow: {
    dark: '#FDC273',
    light: '#F7F8AD',
  },
  green: {
    dark: '#006837',
    light: '#A0D76D',
  },
};

export const colorCodeRdYlGnRange = (
  yellowColorRange: YellowColorRange,
  values: number[],
  mode: 'higher-the-greener' | 'higher-the-redder'
): string[] => {
  if (values.length === 0) {
    return [];
  }

  const maxValue = Math.max(...values);

  const minValue = Math.min(...values);

  const smallDelta = 0.0001;

  const yellowRange = yellowColorRange.max.value - yellowColorRange.min.value;

  if (mode === 'higher-the-greener') {
    const redMin = Math.min(minValue, yellowColorRange.min.value - yellowRange);

    const redMax = yellowColorRange.min.inclusive
      ? yellowColorRange.min.value - smallDelta
      : yellowColorRange.min.value;
    const yellowMin = yellowColorRange.min.inclusive
      ? yellowColorRange.min.value
      : yellowColorRange.min.value + smallDelta;
    const yellowMax = yellowColorRange.max.inclusive
      ? yellowColorRange.max.value
      : yellowColorRange.max.value - smallDelta;
    const greenMin = yellowColorRange.max.inclusive
      ? yellowColorRange.max.value + smallDelta
      : yellowColorRange.max.value;
    const greenMax = Math.max(
      maxValue,
      yellowColorRange.max.value + yellowRange
    );

    const colorScale = scaleLinear<string>()
      .domain([redMin, redMax, yellowMin, yellowMax, greenMin, greenMax])
      .range([
        RdYlGnColors.red.dark,
        RdYlGnColors.red.light,
        RdYlGnColors.yellow.dark,
        RdYlGnColors.yellow.light,
        RdYlGnColors.green.light,
        RdYlGnColors.green.dark,
      ])
      .interpolate(interpolateHcl);
    return values.map((v) => colorScale(v) as unknown as string);
  } else if (mode === 'higher-the-redder') {
    const greenMin = Math.min(
      minValue,
      yellowColorRange.min.value - yellowRange
    );
    const greenMax = yellowColorRange.min.inclusive
      ? yellowColorRange.min.value - smallDelta
      : yellowColorRange.min.value;
    const yellowMin = yellowColorRange.min.inclusive
      ? yellowColorRange.min.value
      : yellowColorRange.min.value + smallDelta;
    const yellowMax = yellowColorRange.max.inclusive
      ? yellowColorRange.max.value
      : yellowColorRange.max.value - smallDelta;
    const redMin = yellowColorRange.max.inclusive
      ? yellowColorRange.max.value + smallDelta
      : yellowColorRange.max.value;
    const redMax = Math.max(maxValue, yellowColorRange.max.value + yellowRange);

    const colorScale = scaleLinear()
      .domain([greenMin, greenMax, yellowMin, yellowMax, redMin, redMax])
      .range([
        RdYlGnColors.green.dark,
        RdYlGnColors.green.light,
        RdYlGnColors.yellow.light,
        RdYlGnColors.yellow.dark,
        RdYlGnColors.red.light,
        RdYlGnColors.red.dark,
      ] as any[])
      .interpolate(interpolateHcl as any);
    return values.map((v) => colorScale(v) as unknown as string);
  } else {
    return [];
  }
};

export const FontColorMap = {
  'dollar-amount': DollarAmountColor,
  green: '#3C8500',
  red: '#DB2100',
  yellow: '#907300',
  'normal-text': '#333333',
};

export type IzcStatusColorType =
  | 'green'
  | 'red'
  | 'yellow'
  | 'dollar-amount'
  | 'normal-text';
export const toIzcStatusColorFromType = (color: IzcStatusColorType) => {
  return FontColorMap[color];
};

export const GaugeScoringColors = [
  '#3F874D',
  '#CED44F',
  '#F4E554',
  '#EFA647',
  '#CB3B3A',
];
export const GaugeScoringColorsInverse = GaugeScoringColors.slice().reverse();
