import { scaleLinear, scaleSequential } from 'd3-scale';
import { interpolateRdYlGn } from 'd3-scale-chromatic';
import { interpolateHcl } from 'd3-interpolate';

import { lab } from 'd3-color';
import { colorCodeRdYlGnRange, YellowColorRange } from './color-util';
import { CompareAndRank } from './common-app-types';
import { isNumberValid } from './number-utils';

const ScaleColorBlueLight = '#9FCAE2';
const ScaleColorBlueDark = '#0A306B';
const customInterpolateBlues = scaleLinear<string>()
  .domain([0, 1])
  .range([ScaleColorBlueLight, ScaleColorBlueDark])
  .interpolate(interpolateHcl);

export type RankingMode =
  | 'more-is-better'
  | 'less-is-better'
  | 'more-is-bluer'
  | 'less-is-bluer';

const colorLightAndTextColorFromColor = (
  color: string
): {
  textColor: string;
  colorLight: string;
} => {
  const labOfColor = lab(color);
  const textColor = labOfColor.l < 60 ? 'white' : 'black';
  const colorLight = labOfColor.copy({ opacity: 0.1 }).formatRgb();
  return {
    textColor,
    colorLight,
  };
};

export const rankAndColorNumbers = (
  values: number[],
  rankingMode: RankingMode
): CompareAndRank[] => {
  if (values.length <= 1 || values.every((v) => v === values[0])) {
    return [];
  }

  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const colorScale = scaleSequential(
    rankingMode === 'more-is-better'
      ? [minValue, maxValue]
      : rankingMode === 'less-is-better'
      ? [maxValue, minValue]
      : rankingMode === 'more-is-bluer'
      ? [minValue, maxValue]
      : rankingMode === 'less-is-bluer'
      ? [maxValue, minValue]
      : [],
    rankingMode === 'more-is-bluer' || rankingMode === 'less-is-bluer'
      ? customInterpolateBlues
      : interpolateRdYlGn
  );
  const valueToRankMap: Record<number, number> = values
    .slice()
    .sort(
      rankingMode === 'less-is-better'
        ? (a, b) => a - b
        : rankingMode === 'more-is-better'
        ? (a, b) => b - a
        : rankingMode === 'more-is-bluer'
        ? (a, b) => b - a
        : rankingMode === 'less-is-bluer'
        ? (a, b) => a - b
        : (a, b) => a - b
    )
    .reduce(
      (() => {
        let rank = 1;
        return (acc: Record<number, number>, cur: number) => {
          if (!isNumberValid(acc[cur])) {
            acc[cur] = rank++;
          }
          return acc;
        };
      })(),
      {} as Record<number, number>
    );
  return values.map((value) => {
    const color = colorScale(value);
    const { textColor, colorLight } = colorLightAndTextColorFromColor(color);
    return {
      color,
      colorLight,
      rank: valueToRankMap[value],
      textColor,
      value,
    };
  });
};

export type RankAndColorRdYlGnMode = 'higher-the-greener' | 'higher-the-redder';

export const rankAndColorRdYlGnBasedValues = (
  yellowColorRange: YellowColorRange,
  values: number[],
  mode: RankAndColorRdYlGnMode
): CompareAndRank[] => {
  if (values.length <= 1 || values.every((v) => v === values[0])) {
    return [];
  }
  const valueToRankMap: Record<number, number> = values
    .slice()
    .sort(mode === 'higher-the-greener' ? (a, b) => b - a : (a, b) => a - b)
    .reduce(
      (() => {
        let rank = 1;
        return (acc: Record<number, number>, cur: number) => {
          if (!isNumberValid(acc[cur])) {
            acc[cur] = rank++;
          }
          return acc;
        };
      })(),
      {} as Record<number, number>
    );
  return colorCodeRdYlGnRange(yellowColorRange, values, mode).map(
    (color, i) => {
      const { textColor, colorLight } = colorLightAndTextColorFromColor(color);
      return {
        value: values[i],
        color,
        textColor,
        colorLight,
        rank: valueToRankMap[values[i]],
      };
    }
  );
};
