<ng-container *ngIf="client">
  <h3 class="client-sub-page-header">Income Plan Optimizer</h3>
  <div class="optimizer__sub-header" *ngIf="hasJobsToShow">
    <app-core-button type="primary" (click)="openFindOptimizedPlanModal()">{{
      findOptimizedBtnText
    }}</app-core-button>
  </div>

  <div style="margin: 20px 0">
    <nz-spin
      class="optimizer__fetch-spinner"
      *ngIf="jobsFetchInProgress"
      nzSimple
      [nzSize]="'large'"
    ></nz-spin>

    <div
      class="client-page__fetch-failed-alert-wrapper"
      *ngIf="jobsFetchFailed && jobsFetchErrorMessage"
    >
      <nz-alert
        nzType="error"
        [nzMessage]="jobsFetchErrorMessage"
        nzShowIcon
      ></nz-alert>
    </div>

    <ng-container *ngIf="jobsFetchSuccess">
      <div
        class="optimizer__jobs-list"
        *ngIf="jobsToShow && jobsToShow.length > 0"
      >
        <app-optimizer-job-card
          *ngFor="let job of jobsToShow"
          [job]="job"
          [results]="job.results"
          (archiveJob)="openDeleteJobConfirm(job)"
          (stopJob)="openStopJobConfirm(job)"
          [client]="client"
        ></app-optimizer-job-card>
      </div>

      <app-empty-state-section
        *ngIf="!hasJobsToShow"
        [title]="emptyJobsTitle"
        [description]="emptyJobsDescription"
        [placeholderImage]="emptyPlaceholderImage"
      >
        <app-core-button
          emptyStateActions
          type="primary"
          (click)="openFindOptimizedPlanModal()"
          >{{ findOptimizedBtnText }}</app-core-button
        >
      </app-empty-state-section>
      <ng-template #emptyPlaceholderImage>
        <svg
          style="width: 210px; height: auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
        >
          <g id="freepik--background-simple--inject-137">
            <path
              d="M83.68,144.51C67.25,282.39,226.79,416.15,350.23,317.11c55.73-44.71,95.71-187.68,1.41-208.62-54.08-12-112.21,36-163.76,17.11-24.78-9.07-37.47-44.76-66.27-41C94.57,88.19,86.42,121.53,83.68,144.51Z"
              style="fill: #407bff"
            />
            <path
              d="M83.68,144.51C67.25,282.39,226.79,416.15,350.23,317.11c55.73-44.71,95.71-187.68,1.41-208.62-54.08-12-112.21,36-163.76,17.11-24.78-9.07-37.47-44.76-66.27-41C94.57,88.19,86.42,121.53,83.68,144.51Z"
              style="fill: #fff; opacity: 0.9"
            />
          </g>
          <g id="freepik--Shadow--inject-137">
            <ellipse
              cx="250"
              cy="416.24"
              rx="193.89"
              ry="11.32"
              style="fill: #f5f5f5"
            />
          </g>
          <g id="freepik--speech-bubbles--inject-137">
            <rect
              x="312.33"
              y="145.82"
              width="116.84"
              height="45.42"
              rx="9.37"
              style="fill: #407bff"
            />
            <rect
              x="322.07"
              y="153.48"
              width="79.91"
              height="5.08"
              rx="1.64"
              style="fill: #407bff"
            />
            <rect
              x="322.07"
              y="165.72"
              width="55.64"
              height="5.08"
              rx="1.64"
              style="fill: #407bff"
            />
            <rect
              x="322.07"
              y="177.96"
              width="41.6"
              height="5.08"
              rx="1.64"
              style="fill: #407bff"
            />
            <rect
              x="369.56"
              y="177.96"
              width="41.6"
              height="5.08"
              rx="1.64"
              style="fill: #407bff"
            />
            <g style="opacity: 0.5">
              <rect
                x="322.07"
                y="153.48"
                width="79.91"
                height="5.08"
                rx="1.64"
                style="fill: #fff"
              />
              <rect
                x="322.07"
                y="165.72"
                width="55.64"
                height="5.08"
                rx="1.64"
                style="fill: #fff"
              />
              <rect
                x="322.07"
                y="177.96"
                width="41.6"
                height="5.08"
                rx="1.64"
                style="fill: #fff"
              />
              <rect
                x="369.56"
                y="177.96"
                width="41.6"
                height="5.08"
                rx="1.64"
                style="fill: #fff"
              />
            </g>
            <rect
              x="216.13"
              y="135.23"
              width="138.83"
              height="66.6"
              rx="13.74"
              style="fill: #407bff"
            />
            <rect
              x="230.41"
              y="146.46"
              width="117.16"
              height="7.45"
              rx="2.41"
              style="fill: #407bff"
            />
            <rect
              x="230.41"
              y="164.41"
              width="81.57"
              height="7.45"
              rx="2.41"
              style="fill: #407bff"
            />
            <rect
              x="230.41"
              y="182.36"
              width="60.99"
              height="7.45"
              rx="2.41"
              style="fill: #407bff"
            />
            <rect
              x="300.04"
              y="182.36"
              width="51.62"
              height="7.45"
              rx="2.41"
              style="fill: #407bff"
            />
            <g style="opacity: 0.5">
              <rect
                x="230.41"
                y="146.46"
                width="117.16"
                height="7.45"
                rx="2.41"
                style="fill: #fff"
              />
              <rect
                x="230.41"
                y="164.41"
                width="81.57"
                height="7.45"
                rx="2.41"
                style="fill: #fff"
              />
              <rect
                x="230.41"
                y="182.36"
                width="60.99"
                height="7.45"
                rx="2.41"
                style="fill: #fff"
              />
              <rect
                x="300.04"
                y="182.36"
                width="51.62"
                height="7.45"
                rx="2.41"
                style="fill: #fff"
              />
            </g>
            <rect
              x="211.94"
              y="213.9"
              width="66.47"
              height="93.18"
              rx="6.42"
              style="fill: #407bff"
            />
            <rect
              x="211.94"
              y="213.9"
              width="66.47"
              height="93.18"
              rx="6.42"
              style="opacity: 0.4"
            />
            <rect
              x="219.63"
              y="223.62"
              width="51.08"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="233.56"
              width="35.57"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="243.51"
              width="51.08"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="253.45"
              width="20.47"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="244.97"
              y="253.45"
              width="20.47"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="263.4"
              width="51.08"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="273.34"
              width="51.08"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="283.29"
              width="42.44"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <rect
              x="219.63"
              y="293.23"
              width="21.06"
              height="4.13"
              rx="1.18"
              style="fill: #fafafa"
            />
            <path
              d="M286.5,213.33H212.11L245,239.5l50.43,19V222.24A8.93,8.93,0,0,0,286.5,213.33Z"
              style="fill: #407bff"
            />
            <path
              d="M286.5,213.33H212.11L245,239.5l50.43,19V222.24A8.93,8.93,0,0,0,286.5,213.33Z"
              style="opacity: 0.4"
            />
            <rect
              x="227.34"
              y="226.81"
              width="57.39"
              height="5.72"
              rx="1.48"
              style="fill: #fafafa"
            />
            <rect
              x="329.04"
              y="205.32"
              width="66.84"
              height="105.37"
              rx="7.26"
              transform="translate(620.47 -104.45) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="345.63"
              y="188.73"
              width="66.84"
              height="105.37"
              rx="7.26"
              transform="translate(620.47 -137.62) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="345.63"
              y="188.73"
              width="66.84"
              height="105.37"
              rx="7.26"
              transform="translate(620.47 -137.62) rotate(90)"
              style="fill: #fff; opacity: 0.5"
            />
            <rect
              x="385.1"
              y="215.48"
              width="38.67"
              height="36.15"
              rx="3.23"
              transform="translate(638 -170.88) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="385.1"
              y="215.48"
              width="38.67"
              height="36.15"
              rx="3.23"
              transform="translate(638 -170.88) rotate(90)"
              style="fill: #fff; opacity: 0.7000000000000001"
            />
            <rect
              x="352.04"
              y="239.04"
              width="29.64"
              height="27.71"
              rx="2.48"
              transform="translate(619.75 -113.96) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="352.04"
              y="239.04"
              width="29.64"
              height="27.71"
              rx="2.48"
              transform="translate(619.75 -113.96) rotate(90)"
              style="fill: #fff; opacity: 0.30000000000000004"
            />
            <rect
              x="223.36"
              y="-27.34"
              width="28.09"
              height="258.89"
              rx="7.38"
              transform="translate(339.51 -135.31) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="223.36"
              y="-27.34"
              width="28.09"
              height="258.89"
              rx="7.38"
              transform="translate(339.51 -135.31) rotate(90)"
              style="fill: #fff; opacity: 0.5"
            />
            <rect
              x="280.71"
              y="80.88"
              width="9.67"
              height="70.25"
              transform="translate(401.54 -169.54) rotate(90)"
              style="fill: #407bff"
            />
            <rect
              x="280.71"
              y="80.88"
              width="9.67"
              height="70.25"
              transform="translate(401.54 -169.54) rotate(90)"
              style="fill: #fff; opacity: 0.5"
            />
            <rect
              x="248.54"
              y="-68.85"
              width="28.09"
              height="258.89"
              rx="7.38"
              transform="translate(323.19 -201.99) rotate(90)"
              style="fill: #407bff"
            />
          </g>
          <g id="freepik--Character--inject-137">
            <path
              d="M130.36,194.26c-.88,2-1.85,4.16-2.7,6.27s-1.73,4.28-2.5,6.44a91.35,91.35,0,0,0-3.79,12.93l-.29,1.6-.1.6c0,.17,0,.39-.06.59a24.53,24.53,0,0,0,.06,2.92c.19,2.11.53,4.37,1,6.63.84,4.53,1.91,9.2,3,13.8l-5.13,2a92.65,92.65,0,0,1-6.09-13.59,57,57,0,0,1-2.12-7.47,26.81,26.81,0,0,1-.56-4.26c0-.4,0-.78,0-1.21s0-.91.07-1.19l.2-2a63,63,0,0,1,1.37-7.65c.56-2.5,1.28-4.93,2-7.33s1.62-4.76,2.57-7.08c.47-1.16,1-2.31,1.46-3.46s1-2.25,1.64-3.48Z"
              style="fill: #e4897b"
            />
            <path
              d="M124.71,244.69l3.9,3.81-8.51,4s-2.06-3.61-.63-6.87Z"
              style="fill: #e4897b"
            />
            <polygon
              points="131.76 254.17 124.57 257.11 120.11 252.46 128.61 248.5 131.76 254.17"
              style="fill: #e4897b"
            />
            <path
              d="M277.41,250.07A72,72,0,1,1,357,186.5,72,72,0,0,1,277.41,250.07Z"
              style="fill: #fafafa; opacity: 0.4"
            />
            <path
              d="M329.79,121.79l-109.5,87.49c-.46-1-.91-2-1.33-3a71.24,71.24,0,0,1-5.21-20.48h0l94.58-75.57A71.15,71.15,0,0,1,329.79,121.79Z"
              style="fill: #fafafa; opacity: 0.4"
            />
            <path
              d="M355.06,160.12,252.11,242.37a71.86,71.86,0,0,1-24.77-21.28l112.41-89.82A72,72,0,0,1,355.06,160.12Z"
              style="fill: #fafafa; opacity: 0.4"
            />
            <path
              d="M204.39,151l-.95-.32a87.68,87.68,0,0,1,6.18-14.05l.87.48A86.93,86.93,0,0,0,204.39,151Zm12.14-23.34-.81-.59a86.7,86.7,0,0,1,31.37-26.21l.45.9A85.74,85.74,0,0,0,216.53,127.69Zm109.59-24.53A83.6,83.6,0,0,0,316,98.54l.36-.94a85.62,85.62,0,0,1,10.22,4.68Z"
              style="fill: #407bff"
            />
            <path
              d="M204.39,151l-.95-.32a87.68,87.68,0,0,1,6.18-14.05l.87.48A86.93,86.93,0,0,0,204.39,151Zm12.14-23.34-.81-.59a86.7,86.7,0,0,1,31.37-26.21l.45.9A85.74,85.74,0,0,0,216.53,127.69Zm109.59-24.53A83.6,83.6,0,0,0,316,98.54l.36-.94a85.62,85.62,0,0,1,10.22,4.68Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M359.87,141.74a83,83,0,0,0-152.65,64.73c-6.68,2.34-13.28,4.85-19.84,7.44q-14.19,5.63-28.12,11.76c-9.28,4.11-18.52,8.3-27.67,12.68-4.59,2.15-9.12,4.42-13.69,6.62a19.79,19.79,0,0,0-6.34,4.35,19.15,19.15,0,0,0-2.57,3.37,17.25,17.25,0,0,0-2,4.63,12.72,12.72,0,0,0,7.33,14.85,17.56,17.56,0,0,0,4.86,1.26,18.88,18.88,0,0,0,4.25,0,19.76,19.76,0,0,0,7.3-2.39c4.53-2.29,9.09-4.5,13.59-6.85,9-4.59,18-9.38,26.89-14.24S189,240,197.68,234.78c6-3.63,12.06-7.34,18-11.22a83,83,0,0,0,144.21-81.82Zm-45.46,95.53a65.53,65.53,0,1,1,29.75-87.78A65.53,65.53,0,0,1,314.41,237.27Z"
              style="fill: #407bff"
            />
            <path
              d="M359.87,141.74a83,83,0,0,0-152.65,64.73c-6.68,2.34-13.28,4.85-19.84,7.44q-14.19,5.63-28.12,11.76c-9.28,4.11-18.52,8.3-27.67,12.68-4.59,2.15-9.12,4.42-13.69,6.62a19.79,19.79,0,0,0-6.34,4.35,19.15,19.15,0,0,0-2.57,3.37,17.25,17.25,0,0,0-2,4.63,12.72,12.72,0,0,0,7.33,14.85,17.56,17.56,0,0,0,4.86,1.26,18.88,18.88,0,0,0,4.25,0,19.76,19.76,0,0,0,7.3-2.39c4.53-2.29,9.09-4.5,13.59-6.85,9-4.59,18-9.38,26.89-14.24S189,240,197.68,234.78c6-3.63,12.06-7.34,18-11.22a83,83,0,0,0,144.21-81.82Zm-45.46,95.53a65.53,65.53,0,1,1,29.75-87.78A65.53,65.53,0,0,1,314.41,237.27Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M158,158.55c0,.59.31,1.07.7,1.07s.7-.48.7-1.07-.31-1.07-.7-1.07S158,158,158,158.55Z"
              style="fill: #263238"
            />
            <path
              d="M158.28,159.62a21.33,21.33,0,0,0,2.83,5.07,3.41,3.41,0,0,1-2.83.53Z"
              style="fill: #de5753"
            />
            <path
              d="M155.93,155.26a.37.37,0,0,1-.29-.14.36.36,0,0,1,.08-.49,3.43,3.43,0,0,1,3.13-.53.35.35,0,1,1-.25.65,2.73,2.73,0,0,0-2.47.45A.36.36,0,0,1,155.93,155.26Z"
              style="fill: #263238"
            />
            <path
              d="M137.8,163c1,5.37,2.1,15.22-1.66,18.81,0,0,1.47,5.45,11.46,5.45,11,0,5.24-5.45,5.24-5.45-6-1.44-5.83-5.88-4.79-10.06Z"
              style="fill: #e4897b"
            />
            <path
              d="M131.65,184.64c1.19-1.85-.81-6.34-.81-6.34s17.68-4.45,24.91,2.38c1.5,1.41-.16,3.49-.16,3.49Z"
              style="fill: #407bff"
            />
            <path
              d="M131.65,184.64c1.19-1.85-.81-6.34-.81-6.34s17.68-4.45,24.91,2.38c1.5,1.41-.16,3.49-.16,3.49Z"
              style="opacity: 0.1"
            />
            <path
              d="M102.4,388.67c-.93,0-2.42-1.55-2.86-2.13a.17.17,0,0,1,0-.19.18.18,0,0,1,.16-.09c.11,0,2.56.22,3.23,1.09a.81.81,0,0,1,.16.66.72.72,0,0,1-.52.65Zm-2.32-2c.73.84,1.9,1.79,2.4,1.67,0,0,.2-.05.26-.37a.45.45,0,0,0-.1-.38A4.67,4.67,0,0,0,100.08,386.65Z"
              style="fill: #407bff"
            />
            <path
              d="M100.19,386.65a2.68,2.68,0,0,1-.54,0,.16.16,0,0,1-.14-.11.2.2,0,0,1,0-.18c.07-.06,1.54-1.59,2.65-1.59h0a.94.94,0,0,1,.71.3.57.57,0,0,1,.14.7C102.72,386.33,101.19,386.65,100.19,386.65Zm-.13-.35c1,0,2.45-.31,2.69-.75,0,0,.07-.13-.09-.29a.59.59,0,0,0-.46-.19h0A4.23,4.23,0,0,0,100.06,386.3Z"
              style="fill: #407bff"
            />
            <path
              d="M168.39,411.37a13.25,13.25,0,0,1-2.26-.21A.16.16,0,0,1,166,411a.18.18,0,0,1,.08-.18c.11-.06,2.67-1.53,3.9-1.19a.86.86,0,0,1,.55.4.66.66,0,0,1,0,.76C170.24,411.24,169.33,411.37,168.39,411.37Zm-1.69-.47c1.36.2,3.22.19,3.56-.29.05-.06.09-.17,0-.38a.56.56,0,0,0-.34-.25C169.14,409.78,167.59,410.44,166.7,410.9Z"
              style="fill: #407bff"
            />
            <path
              d="M166.17,411.17a.18.18,0,0,1-.1,0A.16.16,0,0,1,166,411a4.88,4.88,0,0,1,1.14-2.83,1.25,1.25,0,0,1,1-.29c.48,0,.62.29.65.48.13.83-1.62,2.42-2.51,2.82Zm1.79-3a.91.91,0,0,0-.6.22,4,4,0,0,0-1,2.27c.9-.55,2.1-1.79,2-2.29,0,0,0-.17-.34-.2Z"
              style="fill: #407bff"
            />
            <polygon
              points="158.09 410.99 165.44 410.99 166.17 393.98 158.82 393.98 158.09 410.99"
              style="fill: #e4897b"
            />
            <polygon
              points="92.64 381.79 98.78 385.81 112.34 374.36 106.19 370.33 92.64 381.79"
              style="fill: #e4897b"
            />
            <path
              d="M99.79,385.43l-6.38-5.23a.63.63,0,0,0-.8,0l-5.26,4.13a1.08,1.08,0,0,0,0,1.69c2.26,1.79,3.45,2.55,6.26,4.85,1.73,1.41,6,5.73,8.41,7.68s4.62-.15,3.82-1.16c-3.59-4.55-5-8.44-5.41-10.85A1.81,1.81,0,0,0,99.79,385.43Z"
              style="fill: #263238"
            />
            <path
              d="M165.39,410.14h-8a.65.65,0,0,0-.63.5l-1.45,6.53a1.07,1.07,0,0,0,1.06,1.31c2.89-.05,7.07-.22,10.71-.22,4.25,0,7.93.23,12.91.23,3,0,3.86-3.05,2.59-3.32-5.74-1.26-10.44-1.39-15.4-4.46A3.44,3.44,0,0,0,165.39,410.14Z"
              style="fill: #263238"
            />
            <path
              d="M121.66,184c-6.37,3-10,23.1-10,23.1l15.57,6.21a115.83,115.83,0,0,0,5.51-17.15C134.88,186.9,128.18,180.9,121.66,184Z"
              style="fill: #263238"
            />
            <path
              d="M129.92,195.33c-3.85,3.9-5.61,11.54-6.38,16.51l3.73,1.49a116.7,116.7,0,0,0,5.4-16.65C132.34,194.56,131.52,193.71,129.92,195.33Z"
              style="fill: #203048"
            />
            <path
              d="M164.14,183.86s7.82,8.84.44,63.5H125.29c-.27-6,3.52-35.45-2.3-63.87a100.56,100.56,0,0,1,13.15-1.66,140.87,140.87,0,0,1,16.7,0A74.19,74.19,0,0,1,164.14,183.86Z"
              style="fill: #263238"
            />
            <path
              d="M167.73,205.08l-6.62-11.41a58.31,58.31,0,0,0-1.55,16.27c.18,3.92,3.77,18.44,7,20.56A230.84,230.84,0,0,0,167.73,205.08Z"
              style="fill: #203048"
            />
            <path
              d="M168.65,191.67c1,4.86,2,9.84,3.07,14.72s2.18,9.78,3.47,14.51c.33,1.17.64,2.37,1,3.49l.27.86.13.43,0-.05c-.07-.09-.15-.1-.09,0a3.35,3.35,0,0,0,1.52.95,17.84,17.84,0,0,0,2.91.81,77.79,77.79,0,0,0,14.3.93l.95,5.44c-1.34.43-2.59.74-3.91,1.05s-2.62.57-3.95.77a42.73,42.73,0,0,1-8.33.56,22.61,22.61,0,0,1-4.6-.6,13.39,13.39,0,0,1-5.28-2.45,9.65,9.65,0,0,1-2.47-3c-.16-.3-.3-.64-.43-.93l-.18-.47-.37-1c-.51-1.27-.93-2.52-1.39-3.77-1.69-5-3.1-10.05-4.34-15.09s-2.31-10.07-3.2-15.24Z"
              style="fill: #e4897b"
            />
            <path
              d="M154.33,189.22c-2.07,6.71,6.32,26.72,6.32,26.72l16.71-4.2s-.64-10.4-4.45-18.65C167.17,180.65,156.81,181.17,154.33,189.22Z"
              style="fill: #263238"
            />
            <path
              d="M195.09,228.32l8.32-.21-4,8.66s-3.39.45-6.17-4.12l-2.93-3.5,3.37-.67A7.52,7.52,0,0,1,195.09,228.32Z"
              style="fill: #e4897b"
            />
            <polygon
              points="208.66 229.72 203.9 236.9 199.41 236.77 203.41 228.11 208.66 229.72"
              style="fill: #e4897b"
            />
            <polygon
              points="166.17 393.98 165.79 402.75 158.44 402.75 158.82 393.98 166.17 393.98"
              style="fill: #ce6f64"
            />
            <polygon
              points="106.19 370.33 112.34 374.36 105.34 380.26 99.2 376.24 106.19 370.33"
              style="fill: #ce6f64"
            />
            <path
              d="M138.26,157.28c.44,7.27.33,11.56,4,15.29,5.52,5.63,14.51,2.3,16.25-5,1.57-6.54.59-17.34-6.53-20.24A10,10,0,0,0,138.26,157.28Z"
              style="fill: #e4897b"
            />
            <path
              d="M129.7,156c1.81,6.63,6.07,14.27,11.58,14.76,6.84.6,9.93-6.47,10.83-13.77,4.1-2.81,1.95-5.9,6.06-6.29,5.28-.5,3.59-10.16-3.36-11.71,0,0,2.33,4.35-2.56,2.15a28.22,28.22,0,0,0-14.94-2.59s6.24,3.12.14,4.51-7.92,5.15-5.11,7.42C132.34,150.49,128.51,151.64,129.7,156Z"
              style="fill: #263238"
            />
            <path
              d="M147.94,158.49a8.22,8.22,0,0,0,2.07,4.87c1.6,1.77,3.27.67,3.55-1.48.26-1.93-.27-5.18-2.23-6.16S147.81,156.31,147.94,158.49Z"
              style="fill: #e4897b"
            />
            <path
              d="M150.64,247.36s7.16,51.85,1.52,72.63c-8.94,32.9-43.12,60.67-43.12,60.67l-11.59-7.6s29.11-23.45,32.67-51c3.2-24.73-4.83-54.64-4.83-74.71Z"
              style="fill: #407bff"
            />
            <path
              d="M150.64,247.36s7.16,51.85,1.52,72.63c-8.94,32.9-43.12,60.67-43.12,60.67l-11.59-7.6s29.11-23.45,32.67-51c3.2-24.73-4.83-54.64-4.83-74.71Z"
              style="opacity: 0.1"
            />
            <path
              d="M153.6,277a48.4,48.4,0,0,0-6.27-6c1.22,16.83,2.22,41.76,1.11,59.47A76.41,76.41,0,0,0,152.16,320C154.91,309.85,154.62,292.32,153.6,277Z"
              style="fill: #407bff"
            />
            <path
              d="M153.6,277a48.4,48.4,0,0,0-6.27-6c1.22,16.83,2.22,41.76,1.11,59.47A76.41,76.41,0,0,0,152.16,320C154.91,309.85,154.62,292.32,153.6,277Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M113.71,378.07c.05,0-4.49,3-4.49,3l-12.66-8.3,4-3.31Z"
              style="fill: #407bff"
            />
            <path
              d="M113.71,378.07c.05,0-4.49,3-4.49,3l-12.66-8.3,4-3.31Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M164.58,247.36s11.06,46.9,11.84,69.31c.88,25.12-7.89,84.38-7.89,84.38H156.08s-1.53-59.72-2.08-82.6c-.61-24.95-15.25-71.09-15.25-71.09Z"
              style="fill: #407bff"
            />
            <path
              d="M164.58,247.36s11.06,46.9,11.84,69.31c.88,25.12-7.89,84.38-7.89,84.38H156.08s-1.53-59.72-2.08-82.6c-.61-24.95-15.25-71.09-15.25-71.09Z"
              style="opacity: 0.1"
            />
            <path
              d="M170.92,396.1c.06,0-.7,5.16-.7,5.16H155.08l-.41-4.61Z"
              style="fill: #407bff"
            />
            <path
              d="M170.92,396.1c.06,0-.7,5.16-.7,5.16H155.08l-.41-4.61Z"
              style="opacity: 0.30000000000000004"
            />
          </g>
          <g id="freepik--character--inject-137">
            <path
              d="M356,202.29c.85.8,1.52,1.48,2.22,2.24s1.36,1.5,2,2.27c1.3,1.53,2.52,3.14,3.7,4.76a76.27,76.27,0,0,1,6.27,10.26l.37.73a7.05,7.05,0,0,1,.45,1.1,9.12,9.12,0,0,1,.44,2.26,11,11,0,0,1-.55,4,20.16,20.16,0,0,1-3.21,5.92,42.68,42.68,0,0,1-8.8,8.46l-2.49-2.86c1.17-1.37,2.39-2.84,3.48-4.31a36.76,36.76,0,0,0,3-4.5,15.63,15.63,0,0,0,1.82-4.42,4.58,4.58,0,0,0,.07-1.7,2.22,2.22,0,0,0-.18-.55,1.59,1.59,0,0,0-.13-.22l-.32-.54a105.06,105.06,0,0,0-6.27-9.11c-1.11-1.47-2.3-2.88-3.5-4.27-.59-.7-1.2-1.38-1.81-2.06s-1.27-1.36-1.8-1.89Z"
              style="fill: #e4897b"
            />
            <path
              d="M357.36,240.59l-7.47-1.22,3.91,6.54s4.81-.17,6.18-3Z"
              style="fill: #e4897b"
            />
            <polygon
              points="344.37 242.39 347.93 247.7 353.8 245.91 349.89 239.37 344.37 242.39"
              style="fill: #e4897b"
            />
            <path
              d="M357,411.28a2.15,2.15,0,0,1-1.43-.39,1,1,0,0,1-.29-.94.59.59,0,0,1,.35-.5c.87-.39,3.15,1.07,3.41,1.24a.16.16,0,0,1,.07.18.16.16,0,0,1-.13.14A8.85,8.85,0,0,1,357,411.28Zm-1-1.55a.72.72,0,0,0-.22,0,.26.26,0,0,0-.15.23.67.67,0,0,0,.18.63c.38.34,1.36.39,2.67.13A6.59,6.59,0,0,0,356,409.73Z"
              style="fill: #407bff"
            />
            <path
              d="M359,411l-.09,0c-.71-.41-2.08-2-1.91-2.87a.65.65,0,0,1,.6-.5.9.9,0,0,1,.75.24c.82.72.83,2.88.83,3a.19.19,0,0,1-.09.15Zm-1.28-3h-.08c-.22,0-.27.12-.29.21-.1.51.75,1.73,1.46,2.3a4.09,4.09,0,0,0-.69-2.36A.57.57,0,0,0,357.67,408Z"
              style="fill: #407bff"
            />
            <path
              d="M322.92,411.28a2.77,2.77,0,0,1-1.81-.46,1,1,0,0,1-.33-.86.58.58,0,0,1,.3-.47c.93-.52,3.91,1,4.25,1.19a.2.2,0,0,1,.09.19.17.17,0,0,1-.14.14A12.47,12.47,0,0,1,322.92,411.28Zm-1.33-1.55a.62.62,0,0,0-.33.07.21.21,0,0,0-.12.19.65.65,0,0,0,.2.57c.46.41,1.69.48,3.35.2A9.73,9.73,0,0,0,321.59,409.73Z"
              style="fill: #407bff"
            />
            <path
              d="M325.25,411l-.08,0c-.89-.4-2.66-2-2.52-2.87,0-.2.18-.45.67-.5a1.32,1.32,0,0,1,1,.31c.94.78,1.12,2.8,1.12,2.89a.17.17,0,0,1-.07.16A.19.19,0,0,1,325.25,411Zm-1.79-3h-.11c-.32,0-.34.16-.35.2-.08.51,1.12,1.78,2,2.33a4.16,4.16,0,0,0-1-2.31A.93.93,0,0,0,323.46,408Z"
              style="fill: #407bff"
            />
            <path
              d="M337.07,179.3c-.29,4.52-1.07,13.7,2.4,16.24,0,0-.63,4.63-8.82,5.71-9,1.17-4.89-3.91-4.89-3.91,4.77-1.82,4.16-5.45,2.86-8.77Z"
              style="fill: #e4897b"
            />
            <path
              d="M318.44,171.31a.34.34,0,0,1-.26-.12,2.83,2.83,0,0,0-2.35-1,.35.35,0,0,1-.41-.3.36.36,0,0,1,.31-.4,3.51,3.51,0,0,1,3,1.26.36.36,0,0,1-.27.59Z"
              style="fill: #263238"
            />
            <path
              d="M316.88,175.74a16.28,16.28,0,0,1-1.63,4.1,2.58,2.58,0,0,0,2.19.12Z"
              style="fill: #de5753"
            />
            <path
              d="M317.28,174.64c.08.6-.17,1.12-.56,1.18s-.78-.4-.85-1,.17-1.12.56-1.17S317.21,174,317.28,174.64Z"
              style="fill: #263238"
            />
            <path
              d="M316.61,173.66l-1.49-.23S316,174.46,316.61,173.66Z"
              style="fill: #263238"
            />
            <polygon
              points="333.49 410.84 325.99 410.84 325.49 393.49 332.99 393.49 333.49 410.84"
              style="fill: #e4897b"
            />
            <polygon
              points="367.54 410.84 360.04 410.84 357.63 393.49 365.13 393.49 367.54 410.84"
              style="fill: #e4897b"
            />
            <path
              d="M359.58,410H368a.59.59,0,0,1,.59.51l1,6.67a1.2,1.2,0,0,1-1.2,1.33c-2.93-.05-4.35-.22-8.05-.22-2.28,0-5.61.23-8.75.23s-3.31-3.11-2-3.39c5.87-1.26,6.8-3,8.77-4.67A2,2,0,0,1,359.58,410Z"
              style="fill: #263238"
            />
            <path
              d="M325.74,410h8.41a.6.6,0,0,1,.6.51l1,6.67a1.2,1.2,0,0,1-1.2,1.33c-2.93-.05-4.35-.22-8.05-.22-2.28,0-7,.23-10.14.23s-3.31-3.11-2-3.39c5.87-1.26,8.18-3,10.16-4.67A2,2,0,0,1,325.74,410Z"
              style="fill: #263238"
            />
            <polygon
              points="325.49 393.49 325.75 402.44 333.25 402.44 332.99 393.49 325.49 393.49"
              style="fill: #ce6f64"
            />
            <polygon
              points="365.13 393.49 357.63 393.49 358.88 402.44 366.37 402.44 365.13 393.49"
              style="fill: #ce6f64"
            />
            <path
              d="M337.17,169.94c.51,7.42.88,10.55-2.34,14.82-4.85,6.41-14.07,5.42-16.79-1.7-2.45-6.41-2.87-17.46,3.95-21.34A10.15,10.15,0,0,1,337.17,169.94Z"
              style="fill: #e4897b"
            />
            <path
              d="M341.49,176.83c4.16,2.32,7.25-3.4,7.25-3.4s-7.46.81-6.79-3.63c.88-5.89-3.34-13.05-13.92-12.13-.59.05-1.15.12-1.7.21a5.44,5.44,0,0,0-6.58,1.52c-3,.25-7.05,3.83-4.3,8.56a4.35,4.35,0,0,1,.88-1.47c.23,2.77,2.65,7.17,5.67,7.46.65,3.79-1.87,8.18-1,12.14,1.29,5.71,10.92,7,10.92,7a4.17,4.17,0,0,1,.56-4.41c6.54,3,10.85.07,10.85.07-4.46-1.66-3.11-4.62-3.11-4.62a6.51,6.51,0,0,0,8.49-3.09A7.68,7.68,0,0,1,341.49,176.83Z"
              style="fill: #263238"
            />
            <path
              d="M324.61,175.09a5.4,5.4,0,0,1-1.5,3.87c-1.32,1.37-2.94.41-3.38-1.34-.39-1.58-.17-4.19,1.55-4.91A2.44,2.44,0,0,1,324.61,175.09Z"
              style="fill: #e4897b"
            />
            <path
              d="M330.84,244.22s1.9,49.36,6.42,78.34c3.64,23.42,19.81,78.14,19.81,78.14h10.22s-8.43-52.85-10.28-76c-4.68-58.59,7.47-71.17-4-83.37Z"
              style="fill: #263238"
            />
            <path
              d="M339.38,259.83s-4.62-.8-7.32,8c1,16.67,2.71,38.79,5.2,54.73.55,3.55,1.39,7.83,2.42,12.52C337.73,306.92,339.38,259.83,339.38,259.83Z"
              style="fill: #161f33"
            />
            <path
              d="M322.87,245.26s-8.42,54.61-7.93,77.46c.51,23.77,9.22,78,9.22,78h10.13s-1.6-52.82-.71-76.16c1-25.45,11.39-82.17,11.39-82.17Z"
              style="fill: #263238"
            />
            <polygon
              points="368.77 401.18 355.18 401.18 354.12 396.62 368.67 396.72 368.77 401.18"
              style="fill: #407bff"
            />
            <polygon
              points="368.77 401.18 355.18 401.18 354.12 396.62 368.67 396.72 368.77 401.18"
              style="opacity: 0.2"
            />
            <path
              d="M352.5,195.55c3.91.38,9.85,11.49,9.85,11.49l-9.87,7.57s-8.17-7.17-7.2-11.2C346.29,199.22,348.75,195.2,352.5,195.55Z"
              style="fill: #407bff"
            />
            <path
              d="M352.5,195.55c3.91.38,9.85,11.49,9.85,11.49l-9.87,7.57s-8.17-7.17-7.2-11.2C346.29,199.22,348.75,195.2,352.5,195.55Z"
              style="opacity: 0.1"
            />
            <path
              d="M349.55,200.33a10.13,10.13,0,0,0-3.06-.34,16.91,16.91,0,0,0-1.21,3.42c-1,4,7.2,11.2,7.2,11.2l.73-.56Z"
              style="fill: #407bff"
            />
            <path
              d="M349.55,200.33a10.13,10.13,0,0,0-3.06-.34,16.91,16.91,0,0,0-1.21,3.42c-1,4,7.2,11.2,7.2,11.2l.73-.56Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M319.52,211c-2.85,7.21-5.85,14.54-8.24,21.77-.16.46-.29.9-.43,1.35l-.34,1.11a1.83,1.83,0,0,0,0,1,7.52,7.52,0,0,0,2.59,3.1,42.19,42.19,0,0,0,9.69,5.19l-.9,3.68a34.69,34.69,0,0,1-12.08-3.94,12.93,12.93,0,0,1-5.39-5.46,8.37,8.37,0,0,1-.69-4.76,5.58,5.58,0,0,1,.1-.59l.1-.42.17-.76c.12-.51.24-1,.37-1.5,1-4,2.28-7.8,3.63-11.57s2.76-7.49,4.35-11.2Z"
              style="fill: #e4897b"
            />
            <path
              d="M313.45,200.88s-3.38,1.71,9.42,44.38l30.18-3.94c-2.12-12.22-3.12-19.79-.55-45.77a91,91,0,0,0-13,0,95.5,95.5,0,0,0-13.71,1.8C320,198.64,313.45,200.88,313.45,200.88Z"
              style="fill: #407bff"
            />
            <path
              d="M313.45,200.88s-3.38,1.71,9.42,44.38l30.18-3.94c-2.12-12.22-3.12-19.79-.55-45.77a91,91,0,0,0-13,0,95.5,95.5,0,0,0-13.71,1.8C320,198.64,313.45,200.88,313.45,200.88Z"
              style="opacity: 0.1"
            />
            <path
              d="M353.27,239.49,355,242c.13.2-.09.44-.43.49l-31.65,4.14c-.27,0-.52-.07-.56-.24l-.62-2.67c-.05-.18.17-.37.47-.41l30.56-4A.55.55,0,0,1,353.27,239.49Z"
              style="fill: #407bff"
            />
            <path
              d="M353.27,239.49,355,242c.13.2-.09.44-.43.49l-31.65,4.14c-.27,0-.52-.07-.56-.24l-.62-2.67c-.05-.18.17-.37.47-.41l30.56-4A.55.55,0,0,1,353.27,239.49Z"
              style="opacity: 0.2"
            />
            <path
              d="M349.61,243.44l.82-.11c.16,0,.27-.12.25-.22l-.84-3.46a.29.29,0,0,0-.34-.14l-.82.1c-.17,0-.28.12-.25.23l.84,3.45C349.29,243.4,349.45,243.46,349.61,243.44Z"
              style="fill: #263238"
            />
            <path
              d="M329.77,246l.82-.11c.16,0,.28-.12.25-.22l-.84-3.46a.31.31,0,0,0-.34-.15l-.82.11c-.16,0-.28.12-.25.22l.84,3.46C329.45,246,329.61,246.06,329.77,246Z"
              style="fill: #263238"
            />
            <path
              d="M314.86,215.36c.83,3.86,2,8.75,3.71,14.94l.37-15.69Z"
              style="fill: #407bff"
            />
            <path
              d="M314.86,215.36c.83,3.86,2,8.75,3.71,14.94l.37-15.69Z"
              style="opacity: 0.30000000000000004"
            />
            <path
              d="M313.45,200.88c-3.75,1.51-7,14.34-7,14.34l12.9,6.28s6.23-15.69,3.44-18.75S317.77,199.15,313.45,200.88Z"
              style="fill: #407bff"
            />
            <path
              d="M313.45,200.88c-3.75,1.51-7,14.34-7,14.34l12.9,6.28s6.23-15.69,3.44-18.75S317.77,199.15,313.45,200.88Z"
              style="opacity: 0.1"
            />
            <polygon
              points="336.02 401.18 322.43 401.18 321.37 396.62 336.5 396.72 336.02 401.18"
              style="fill: #407bff"
            />
            <polygon
              points="336.02 401.18 322.43 401.18 321.37 396.62 336.5 396.72 336.02 401.18"
              style="opacity: 0.2"
            />
            <path
              d="M323,243.28l-1.09-.45a1.64,1.64,0,0,0-2.24,1.76l.79,5.21a1.78,1.78,0,0,0,2.36,1.42,4,4,0,0,0,1.1-.61,3,3,0,0,0,1.08-2.48l-.11-2.16A3,3,0,0,0,323,243.28Z"
              style="fill: #e4897b"
            />
          </g>
        </svg>
      </ng-template>
    </ng-container>
  </div>

  <app-find-optimized-plan-modal
    *ngIf="optimizedPlanModalOpen"
    [client]="client"
    (cancelOperation)="closeFindOptimizedPlanModal()"
    (operationSubmitted)="onNewOptimizerJobSubmitted()"
  >
  </app-find-optimized-plan-modal>

  <app-confirm-delete-optimizer-task
    *ngIf="deleteJobConfirmModal.open && deleteJobConfirmModal.job"
    [client]="client"
    [job]="deleteJobConfirmModal.job"
    (cancelOperation)="cancelDeleteJob()"
    (operationSuccess)="deleteJobSuccess()"
  ></app-confirm-delete-optimizer-task>

  <app-confirm-stop-optimizer-task
    *ngIf="stopJobConfirmModal.open && stopJobConfirmModal.job"
    [client]="client"
    [job]="stopJobConfirmModal.job"
    (cancelOperation)="cancelStopJob()"
    (operationSuccess)="stopJobSuccess()"
  ></app-confirm-stop-optimizer-task>
</ng-container>
