import { ChartConfiguration, Chart, ChartOptions, ChartData } from 'chart.js';
import { Colors } from 'src/app/utils/color-util';
import { transformCurrency } from 'src/app/utils/transform-currency';

import {
  computeNthXAxisLabelToDisplay,
  computeNumberOfDisplayableLabels,
} from '../constants';

export interface LoanUnpaidBalanceChartConfig {
  labels: string[];
  data: number[];
  xAxisScaleLabel?: string;
  yAxisScaleLabel?: string;
  seriesLabel?: string;
  currency: string;
}

export function toChartCreationConfig(
  ctx: CanvasRenderingContext2D,
  config: LoanUnpaidBalanceChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartConfiguration {
  return {
    type: 'LoanUnpaidBalance',
    data: toChartData(ctx, config),
    options: toChartOptions(config, onResize, canvasWidth),
  };
}

export function toChartData(
  ctx: CanvasRenderingContext2D,
  config: LoanUnpaidBalanceChartConfig
): ChartData {
  const { labels, data, seriesLabel } = config;
  const backgroundColor = Colors.greenGradient(ctx);
  const borderColor = Colors.green;
  const pointRadius = 0;
  return {
    labels,
    datasets: [
      {
        label: seriesLabel || '',
        backgroundColor,
        borderColor,
        pointRadius,
        data,
        fill: 'start',
      },
    ],
  };
}

export function toChartOptions(
  config: LoanUnpaidBalanceChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartOptions {
  const numberOfDisplayableLabels =
    computeNumberOfDisplayableLabels(canvasWidth);
  const { xAxisScaleLabel, yAxisScaleLabel, currency, labels } = config;
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    onResize,
    tooltips: {
      backgroundColor: Colors.white,
      titleFontColor: Colors.white,
      bodyFontColor: Colors.blue,

      /*
        bodyFontStyle is shady - it is used here to set font weight
        https://github.com/chartjs/Chart.js/issues/4842#issuecomment-336745159
      */
      bodyFontStyle: '600',
      bodyFontSize: 15,
      borderColor: Colors.grey2,
      borderWidth: 1,
      xPadding: 12,
      yPadding: 12,
      displayColors: false,
      callbacks: {
        title: () => '',
        label: ({ yLabel }) =>
          transformCurrency(yLabel as number, currency, true) ?? '',
      },
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!xAxisScaleLabel,
            labelString: xAxisScaleLabel,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: (() => {
              const numberOfYears = Math.floor((labels.length - 1) / 12);
              const enoughWidthForAllLabels =
                numberOfDisplayableLabels >= numberOfYears;
              const nThLabelToDisplay = computeNthXAxisLabelToDisplay(
                numberOfYears,
                numberOfDisplayableLabels
              );
              return enoughWidthForAllLabels
                ? (value: string, index: number) =>
                    index % 12 !== 0 ? null : index / 12
                : (value: string, index: number) => {
                    const currentVal = Math.floor(index / 12);
                    const shouldDisplayThisLabel =
                      currentVal === 0 ||
                      currentVal === numberOfYears ||
                      (currentVal % nThLabelToDisplay === 0 &&
                        currentVal >= nThLabelToDisplay &&
                        numberOfYears - currentVal >= nThLabelToDisplay);
                    return shouldDisplayThisLabel
                      ? index % 12 !== 0
                        ? null
                        : currentVal
                      : null;
                  };
            })(),
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!yAxisScaleLabel,
            labelString: yAxisScaleLabel,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              return transformCurrency(value as number, currency, true);
            },
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)', // hide grid lines
          },
        },
      ],
    },
  };
}

function defineLoanUnpaidBalanceChartType(): void {
  Chart.defaults.LoanUnpaidBalance = Chart.defaults.line;
  Chart.controllers.LoanUnpaidBalance = Chart.controllers.line.extend({
    draw(ease: any): void {
      Chart.controllers.line.prototype.draw.call(this, ease);

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0];
        const ctx = this.chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const topY = this.chart.legend.bottom;
        const y = activePoint.tooltipPosition().y;
        const bottomY = this.chart.chartArea.bottom;

        // draw line
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = Colors.orange;
        ctx.stroke();
        ctx.closePath();

        // draw point

        ctx.fillStyle = Colors.orange;
        ctx.beginPath();
        ctx.arc(x, y, 9, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = Colors.white;
        ctx.beginPath();
        ctx.arc(x, y, 6, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = Colors.green;
        ctx.beginPath();
        ctx.arc(x, y, 3, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();

        ctx.restore();
      }
    },
  });
}

defineLoanUnpaidBalanceChartType();
