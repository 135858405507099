<nz-modal
  nzVisible="true"
  [nzTitle]="modalTitle"
  (nzOnCancel)="cancelAction()"
  (nzOnOk)="submitAction()"
  [nzOkText]="submitBtnText"
  [nzOkDanger]="submitBtnDanger"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isSubmitInProgress"
  [nzCancelDisabled]="isSubmitInProgress"
  [nzClosable]="!isSubmitInProgress"
  nzMaskClosable="false"
>
  <ng-container *nzModalContent>
    <p>
      Are you sure you want to delete this income plan report ? This cannot be
      undone.
    </p>

    <div class="confirm-delete-plan-report__info">
      <div class="confirm-delete-plan-report__info__prop">
        <span class="confirm-delete-plan-report__info__label"
          >Report Title</span
        >
        <span class="confirm-delete-plan-report__info__value">{{
          reportTitle
        }}</span>
      </div>
      <div class="confirm-delete-plan-report__info__prop">
        <span class="confirm-delete-plan-report__info__label"
          >Report Summary</span
        >
        <span class="confirm-delete-plan-report__info__value">{{
          reportSummary
        }}</span>
      </div>
      <div class="confirm-delete-plan-report__info__prop">
        <span class="confirm-delete-plan-report__info__label"
          >Report Generation Date</span
        >
        <span class="confirm-delete-plan-report__info__value">{{
          reportGenerationDateFormatted
        }}</span>
      </div>
    </div>

    <ng-container *ngIf="showSubmitFailErrorMessage && submitFailErrorMessage">
      <nz-alert
        nzType="error"
        [nzMessage]="submitFailErrorMessage"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </ng-container>
</nz-modal>
