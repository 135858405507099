import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { getClientFullName } from '../../client-utils';

@Component({
  selector: 'app-archive-client-dialog',
  templateUrl: './archive-client-dialog.component.html',
  styleUrls: ['./archive-client-dialog.component.scss'],
})
export class ArchiveClientDialogComponent {
  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<void>();

  @Input() client!: Client;

  operationApiCall: AsyncOpData<void>;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.operationApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelOperationBtnClick() {
    this.cancelOperation.emit();
  }

  confirmOperationBtnClick() {
    this.operationApiCall = {
      state: AsyncOpState.Inprogress,
    };

    this.httpClient
      .post<{ result: number }>(
        `/api/clients/${this.client.id}`,
        {
          archived: true,
        },
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        () => {
          this.operationApiCall = {
            state: AsyncOpState.Completed,
          };

          this.operationSuccess.emit();
        },
        () => {
          this.operationApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error! Failed to archive client. Please try again',
          };
        }
      );
  }

  get isOperationInProgress() {
    return this.operationApiCall.state === AsyncOpState.Inprogress;
  }

  get archiveErrorMessage() {
    return this.operationApiCall.error;
  }

  get showArchiveErrorMessage() {
    return this.operationApiCall.state === AsyncOpState.Failed;
  }

  get submitBtnDisabled() {
    return false;
  }

  toClientFullName() {
    return getClientFullName(this.client);
  }
}
