<button
  nz-button
  [disabled]="disabled"
  [nzGhost]="ghost"
  [nzLoading]="loading"
  [nzShape]="shape"
  [nzSize]="size"
  [nzType]="type"
  [nzBlock]="block"
  [nzDanger]="danger"
>
  <ng-content></ng-content>
</button>
