import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  checkIfClientHasIncomePlan,
  getClientFullName,
} from '../components/home/client-utils';
import { Client } from '../models/client';
import { toApiAuthHeaders } from '../utils/app-common';
import { AsyncOpData, AsyncOpState } from '../utils/common-utility-types';
import { RouteClientSetup } from '../utils/route-constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdvisorStateService {
  private clientFetchState: AsyncOpData<Client>;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {
    this.clientFetchState = {
      state: AsyncOpState.NotStarted,
    };
  }

  initializeClient(clientId: string) {
    this.clientFetchState = {
      state: AsyncOpState.Inprogress,
    };
    const url = '/api/clients/' + clientId;
    this.httpClient
      .get<Client>(url, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (client) => {
          this.clientFetchState = {
            state: AsyncOpState.Completed,
            data: client,
          };
          if (!checkIfClientHasIncomePlan(client)) {
            this.router.navigate([RouteClientSetup(client.id)]);
          }
          this.markClientVisited(client);
        },
        (err) => {
          this.clientFetchState = {
            state: AsyncOpState.Failed,
            error: 'Failed to fetch client details',
          };
        }
      );
  }

  private markClientVisited(client: Client) {
    this.httpClient
      .post(
        '/api/user/client',
        { id: client.id },
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(() => {});
  }

  isClientFetchSuccess() {
    return (
      this.clientFetchState.state === AsyncOpState.Completed &&
      this.clientFetchState.data
    );
  }

  isClientFetchInprogress() {
    return this.clientFetchState.state === AsyncOpState.Inprogress;
  }

  isClientFetchFailed() {
    return this.clientFetchState.state === AsyncOpState.Failed;
  }
  clientFetchErrorMessage() {
    return this.clientFetchState.error;
  }

  getClientFullName() {
    return this.clientFetchState.data
      ? getClientFullName(this.clientFetchState.data)
      : undefined;
  }

  getClientAlias() {
    return this.clientFetchState.data?.alias;
  }
  getClientId() {
    return this.clientFetchState.data?.id;
  }
  getClient() {
    return this.clientFetchState.data;
  }
  clientHasPlan() {
    return (
      this.clientFetchState.data &&
      checkIfClientHasIncomePlan(this.clientFetchState.data)
    );
  }
}
