export const DefaultChartHeight = 380;

export function computeNumberOfDisplayableLabels(canvasWidth: number, yAxisLabelAndLegendWidth = 200): number {
  const xAxisWidth = canvasWidth - yAxisLabelAndLegendWidth;
  const xAxisLabelMaxWidth = 35;
  return Math.floor(xAxisWidth / xAxisLabelMaxWidth);
}

export function computeNthXAxisLabelToDisplay(labelCount: number, numberOfDisplayableLabels: number): number {
  const nthLabel = Math.ceil(labelCount / numberOfDisplayableLabels);

  return nthLabel > 10 ? nthLabel : nthLabel > 5 ? 10 : nthLabel > 2 ? 5 : nthLabel;
}
