<nz-modal
  nzVisible="true"
  [nzTitle]="modalTitle"
  (nzOnCancel)="cancelAction()"
  (nzOnOk)="submitAction()"
  [nzOkText]="submitBtnText"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isSubmitInProgress"
  [nzCancelDisabled]="isSubmitInProgress"
  [nzClosable]="!isSubmitInProgress"
  nzMaskClosable="false"
>
  <ng-container *nzModalContent>
    <p>
      Are you sure you want to add this optimizer result to the 'Income Plan
      Analyzer' page?
    </p>

    <ng-container *ngIf="submitFailErrorMessage">
      <nz-alert
        nzType="error"
        [nzMessage]="submitFailErrorMessage"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </ng-container>
</nz-modal>
