import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { RouteClientPage } from 'src/app/utils/route-constants';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit {
  isCollapsed = false;
  
  constructor(
    private router: Router, //  for header
    private activatedRoute: ActivatedRoute,
    private advisorStateService: AdvisorStateService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((p) =>
      this.advisorStateService.initializeClient(p.clientId)
    );
  }

  get clientFetchInProgress() {
    return this.advisorStateService.isClientFetchInprogress();
  }
  get clientFetchSuccess() {
    return this.advisorStateService.isClientFetchSuccess();
  }
  get clientFetchFailed() {
    return this.advisorStateService.isClientFetchFailed();
  }
  get clientFetchErrorMessage() {
    return this.advisorStateService.clientFetchErrorMessage();
  }

  get clientDashboardPageRouterLink() {
    return RouteClientPage(this.advisorStateService.getClient()?.id);
  }

  get clientAlias() {
    return this.advisorStateService.getClientAlias();
  }
  get clientName() {
    return this.advisorStateService.getClientFullName();
  }
  get clientHasPlan() {
    return this.advisorStateService.clientHasPlan();
  }

  get myClientsPageRouterLink() {
    return ['../../'];
  }


  // Header stuff

  settings() {
    this.router.navigate(['/settings']);
  }

  logout() {
    this.authService.logout().then(() => {});
  }

  get userName() {
    return this.authService.user?.name;
  }
}
