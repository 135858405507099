<nz-modal
  nzVisible="true"
  nzTitle="Confirm Change to Current Plan"
  (nzOnCancel)="cancelAddClient()"
  (nzOnOk)="makeCurrent()"
  nzOkText="Mark as Current Plan"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isAddInProgress"
  [nzCancelDisabled]="isAddInProgress"
  [nzClosable]="!isAddInProgress"
  nzMaskClosable="false"
>
  <ng-container *nzModalContent>
    <p>Make this Optimizer Search Result as current plan ?</p>
    <nz-alert
      nzType="error"
      *ngIf="addErrorMessage"
      [nzMessage]="addErrorMessage"
      nzShowIcon
    ></nz-alert>
  </ng-container>
</nz-modal>
