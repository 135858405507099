<app-core-modal
  [closable]="!createAnalysisApiInprogress"
  (closeModal)="handleCancel()"
  size="large"
  [title]="modalTitle"
  [modalBodyStyle]="{ padding: '0px' }"
  [modalAutofocus]="null"
>
  <div izcModalBody class="find-optimized-plan-modal">
    <div class="find-optimized-plan-modal__navigation">
      <nz-steps
        nzType="navigation"
        nzSize="small"
        [nzCurrent]="stepState.activeStep"
        (nzIndexChange)="onIndexChange($event)"
      >
        <nz-step
          *ngFor="let step of stepState.steps; index as stepIndex"
          [nzTitle]="step.name"
          [nzStatus]="toStepStatus(stepIndex)"
          [nzDisabled]="step.disabled"
        >
        </nz-step>
      </nz-steps>
    </div>
    <div class="find-optimized-plan-modal__content" *ngIf="inputs">
      <app-step-client-goals
        *ngIf="clientGoalStepActive"
        [inputs]="inputs"
        (validityChange)="onActiveStepValidityChange($event)"
      >
      </app-step-client-goals>
      <app-step-lending-settings
        *ngIf="lendingSettingsStepActive"
        [inputs]="inputs"
        (validityChange)="onActiveStepValidityChange($event)"
      >
      </app-step-lending-settings>
      <app-step-algo-settings
        *ngIf="algoSettingsStepActive"
        [inputs]="inputs"
        (validityChange)="onActiveStepValidityChange($event)"
      >
      </app-step-algo-settings>
      <app-fop-step-optimizer-settings
        *ngIf="optimizerSettingsStepActive"
        [inputs]="inputs"
        [client]="client"
        (optimizerDataChange)="onOptimizerDataChange($event)"
        (validityChange)="onActiveStepValidityChange($event)"
      >
      </app-fop-step-optimizer-settings>

      <div
        class="find-optimized-plan-modal__create-error-alert"
        *ngIf="
          submitCreateApiCall.state === AsyncOpState.Failed &&
          submitCreateApiCall.error
        "
      >
        <app-core-alert
          [showIcon]="true"
          type="error"
          [message]="submitCreateApiCall.error"
        ></app-core-alert>
      </div>
    </div>
  </div>

  <div izcModalFooter class="find-optimized-plan-modal__footer">
    <app-core-button
      type="default"
      [disabled]="createAnalysisApiInprogress"
      (click)="handleCancel()"
      >Cancel</app-core-button
    >
    <app-core-button
      *ngIf="showNextBtn"
      type="primary"
      [disabled]="nextBtnDisabled"
      (click)="goToNextStep()"
    >
      Next
    </app-core-button>
    <app-core-button
      *ngIf="showCreateDaAnalysisBtn"
      type="primary"
      [disabled]="nextBtnDisabled || createAnalysisApiInprogress"
      (click)="handleSubmit()"
      [loading]="createAnalysisApiInprogress"
    >
      Submit
    </app-core-button>
  </div>
</app-core-modal>
