<nz-modal
  nzVisible="true"
  nzTitle="Add a New Client"
  (nzOnCancel)="cancelAddClient()"
  (nzOnOk)="okAddClient()"
  nzOkText="Add"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isAddInProgress"
  [nzCancelDisabled]="isAddInProgress"
  [nzClosable]="!isAddInProgress"
  nzMaskClosable="false"
>
  <ng-container *nzModalContent>
    <app-add-client-form
      (formDataChange)="onClientFormDataChange($event)"
    ></app-add-client-form>
    <ng-container *ngIf="showAddErrorMessage && addErrorMessage">
      <nz-alert
        nzType="error"
        [nzMessage]="addErrorMessage"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </ng-container>
</nz-modal>
