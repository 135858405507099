import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private http: HttpClient) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
    console.log('Checking auth state in auth guard');
    
    return this.checkLogin(next);;
  }

  checkLogin(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    if (this.authService.loggedIn) {
      return true;
    } else if (this.authService.needs2FA) {
      this.authService.redirectUrl = route.url.join('/');
      this.router.navigate(['/authenticate']);
      return false;
    } else {
      const token = this.getCookie('token');
      if (token) {
        this.authService.token = token;   

        let promise = new Promise<boolean>((resolve, reject) => {
          console.log('Fetching user info in auth guard');
          this.http.get('/api/user', { headers: new HttpHeaders().set('Content-Type', 'application/json').set('X-Access-Token', `Bearer ${this.authService.token}`) }).subscribe((result: any) => {
            if (result.result == 0) {
              this.authService.loggedIn = true;
              this.authService.user = result.user;
              resolve(true);
            } else {
              this.authService.needs2FA = true;
              this.authService.user = result.user;
              this.authService.redirectUrl = route.url.join('/');
              this.router.navigate(['/authenticate']);
              resolve(false);
            }
          }, (error) => {
            this.authService.loggedIn = false;
            this.authService.redirectUrl = route.url.join('/');
            this.router.navigate(['/login']);
            resolve(false);
          });
        });
        return promise;
      }
      //this.authService.redirectUrl = url;
      this.authService.redirectUrl = route.url.join('/');
      this.router.navigate(['/login']);
      return false;
    }
  }

  getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}
