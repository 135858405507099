<span
  class="key-metric-badge"
  [class.key-metric-badge--with-compare-and-rank]="
    showCompareAndRank && compareAndRank
  "
  [style.border-color]="
    showCompareAndRank && compareAndRank ? compareAndRank.color : 'transparent'
  "
  [style.background-color]="
    showCompareAndRank && compareAndRank
      ? compareAndRank.colorLight
      : 'transparent'
  "
>
  <span
    *ngIf="showCompareAndRank && compareAndRank"
    class="izc-compare-and-rank-badge-text"
    [style.background-color]="compareAndRank.color"
    [style.color]="compareAndRank.textColor"
  >
    {{ toCompareAndRankDisplayText(compareAndRank) }}
  </span>

  <span class="key-metric-badge__content">
    <ng-content></ng-content>

    <ng-container *ngIf="tooltipMessage"
      >&nbsp;<i
        class="fa fa-question-circle izc-fa-tooltip-icon"
        nz-tooltip
        [nzTooltipTitle]="tooltipMessage"
      ></i
    ></ng-container>
  </span>
</span>
