import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResult } from '../models/login-result';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = false;
  needs2FA = false;
  user?: User;
  token?: string;
  redirectUrl?: string;

  constructor(private httpClient: HttpClient, private router: Router) {}

  login(email: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient
        .post('/api/login', { email: email, password: password })
        .subscribe(
          (result: LoginResult) => {
            if (result.result == 0) {
              this.loggedIn = true;
              this.user = result.user;
              this.token = result.token;
              resolve(true);
            } else if (result.result == 1) {
              this.needs2FA = true;
              this.user = result.user;
              this.token = result.token;
              resolve(true);
            }

            resolve(false);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  authenticate(token: string) {
    return new Promise<number>((resolve, reject) => {
      this.httpClient
        .post(
          '/api/authenticate2fa',
          { token: token },
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.token}`),
          }
        )
        .subscribe((response: any) => {
          if (response.result == 0) {
            this.loggedIn = true;
            this.token = response.token;
            resolve(0);
          } else if (response.result == 1) {
            resolve(1);
          } else if (response.result == 2) {
            resolve(2);
          }
        });
    });
  }

  logout() {
    return new Promise<void>((resolve, reject) => {
      this.httpClient
        .post(
          '/api/logout',
          {},
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            this.loggedIn = false;
            this.user = undefined;
            this.token = undefined;
            this.redirectUrl = undefined;
            this.router.navigate(['/login']);
          }
          resolve();
        });
    });
  }
}
