import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AddClientFormData } from '../add-client-type';

const ClientAliasMinLength = 6;

@Component({
  selector: 'app-add-client-form',
  templateUrl: './add-client-form.component.html',
  styleUrls: ['./add-client-form.component.scss'],
})
export class AddClientFormComponent implements OnInit, OnDestroy {
  @Output() formDataChange = new EventEmitter<AddClientFormData>();

  addClientFormGroup: FormGroup;

  valueChangeSubscription?: Subscription;

  clientAliasTooltip =
    'Unique identifier for this client. Can also be name of entity or family bank if applicable. You can change this identifier in the future if needed. Example includes John Doe Family, John Doe Trust, ABC LLC, John Doe IRA, John Doe.';

  clientAliasLabel = 'Client Alias';

  constructor(private fb: FormBuilder) {
    this.addClientFormGroup = this.fb.group({
      clientAlias: [
        null,
        [Validators.required, Validators.minLength(ClientAliasMinLength)],
      ],
      clientFirstName: [null, [Validators.required]],
      clientMiddleName: [null, []],
      clientLastName: [null, [Validators.required]],
      clientEmail: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.valueChangeSubscription =
      this.addClientFormGroup.valueChanges.subscribe(() => {
        const newFormData = {
          valid: this.addClientFormGroup.valid,
          data: this.addClientFormGroup.value,
        };
        this.formDataChange.emit(newFormData);
      });
  }

  ngOnDestroy() {
    if (this.valueChangeSubscription && !this.valueChangeSubscription.closed) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  get clientAliasFieldError() {
    const errors = this.addClientFormGroup.get('clientAlias')?.errors;
    if (errors?.required === true) {
      return 'Client Alias is required';
    }
    if (errors?.minlength) {
      return `Must be at least ${ClientAliasMinLength} characters`;
    }
    return undefined;
  }

  get clientFirstNameFieldError() {
    const errors = this.addClientFormGroup.get('clientFirstName')?.errors;
    if (errors?.required === true) {
      return 'Client First Name is required';
    }

    return undefined;
  }

  get clientLastNameFieldError() {
    const errors = this.addClientFormGroup.get('clientLastName')?.errors;
    if (errors?.required === true) {
      return 'Client Last Name is required';
    }

    return undefined;
  }

  get clientEmailFieldError() {
    const errors = this.addClientFormGroup.get('clientEmail')?.errors;
    if (errors?.required === true) {
      return 'Email is required';
    }
    if (errors?.email === true) {
      return 'Must be a valid email';
    }

    return undefined;
  }
}
