<ng-container *ngIf="client">
  <div>
    <nz-card nzTitle="Client Info">
      <div nz-row nzGutter="16">
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Client Alias</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="alias" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>First Name</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="firstName" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Last Name</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="lastName" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Email Address</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="emailAddress" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Phone Number</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="phoneNumber" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Address</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="address" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>City</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="city" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>State</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="state" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Zip Code</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="postalCode" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-card>
    <nz-card nzTitle="Client Settings" style="margin-top: 20px">
      <div nz-row nzGutter="16">
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Currency</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="currency" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Time Zone</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="timeZone" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Date Format</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="dateFormat" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Time Format</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="timeFormat" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          [nzXs]="{ span: 24 }"
          [nzMd]="{ span: 12 }"
          [nzLg]="{ span: 8 }"
        >
          <nz-form-item>
            <nz-form-label>Advisor</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="advisor" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div class="button-bar">
        <button nz-button nzType="primary" (click)="save()">Save</button>
      </div>
    </nz-card>
    <nz-card nzTitle="Transfer Client" style="height: 100%; margin-top: 20px">
      <a [href]="'/client/' + client.id + '/transfer'">Transfer Client to Another Advisor/Coach</a>
    </nz-card>
    <app-shares [client]="client"></app-shares>
    <nz-card nzTitle="Tracking Spreadsheet" style="height: 100%; margin-top: 20px">
      <div
        >
        <p>To add a tracking spreadsheet do the following:</p>
        <ul>
          <li>Copy the template spreadsheet by selecting "Make A Copy" from the File Menu of the Google Sheets.</li>
          <li>Select the new spreadsheet copy.</li>
          <li>Click "Share" in the upper right of the Google Sheet and add the email address: sheets@fynanc-iris.iam.gserviceaccount.com</li>
          <li>Copy the Google Sheet URL to the input box below and click "Save"</li>
        </ul>
        <p>The Google Sheet will be scanned at least every hour and the tracking data will be updated in the client's account and displayed on the dashboard.</p>
          <nz-form-item>
            <nz-form-label>Spreadsheet</nz-form-label>
            <nz-form-control [nzSpan]="24">
              <input nz-input type="text" [(ngModel)]="spreadsheet" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <app-loans [client]="client"></app-loans>
        <app-loc [client]="client"></app-loc>
    </nz-card>
    
  </div>
</ng-container>
