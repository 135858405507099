import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  ClockCircleOutline,
  DeleteOutline,
  FilterOutline,
  FilterTwoTone,
  InboxOutline,
  InfoCircleOutline,
  LockOutline,
  LoginOutline,
  LogoutOutline,
  MailOutline,
  PlusOutline,
  PushpinOutline,
  PushpinTwoTone,
  SettingOutline,
  StopOutline,
  SyncOutline,
  WarningOutline,
  DownloadOutline,
} from '@ant-design/icons-angular/icons';
import { QrCodeModule } from 'ng-qrcode';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalysisFormAlgorithmComponent } from './components/analysis/analysis-form-algorithm/analysis-form-algorithm.component';
import { AnalysisFormClientGoalComponent } from './components/analysis/analysis-form-client-goal/analysis-form-client-goal.component';
import { AnalysisFormLendingComponent } from './components/analysis/analysis-form-lending/analysis-form-lending.component';
import { AnalysisComponent } from './components/analysis/analysis.component';
import { ComparativeAnalysisComponent } from './components/analysis/comparative-analysis/comparative-analysis.component';
import { ConfirmCloneAnalysisPlanComponent } from './components/analysis/comparative-analysis/confirm-clone-analysis-plan/confirm-clone-analysis-plan.component';
import { ConfirmCopyCurrentPlanComponent } from './components/analysis/comparative-analysis/confirm-copy-current-plan/confirm-copy-current-plan.component';
import { ConfirmDeleteFromAnalysisComponent } from './components/analysis/comparative-analysis/confirm-delete-from-analysis/confirm-delete-from-analysis.component';
import { ConfirmMakeCurrentFromAnalysisComponent } from './components/analysis/comparative-analysis/confirm-make-current-from-analysis/confirm-make-current-from-analysis.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { CashFlowComponent } from './components/cash-flow/cash-flow.component';
import { ClearComponent } from './components/clear/clear.component';
import { ClientComponent } from './components/client/client.component';
import { StepAlgoSettingsComponent } from './components/common/step-algo-settings/step-algo-settings.component';
import { StepClientGoalsComponent } from './components/common/step-client-goals/step-client-goals.component';
import { StepLendingSettingsComponent } from './components/common/step-lending-settings/step-lending-settings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmptyStateSectionComponent } from './components/empty-state-section/empty-state-section.component';
import { GenerateComponent } from './components/generate/generate.component';
import { HelpComponent } from './components/help/help.component';
import { AddClientFormComponent } from './components/home/add-client-modal/add-client-form/add-client-form.component';
import { AddClientModalComponent } from './components/home/add-client-modal/add-client-modal.component';
import { ArchiveClientDialogComponent } from './components/home/clients-table/archive-client-dialog/archive-client-dialog.component';
import { ClientsTableComponent } from './components/home/clients-table/clients-table.component';
import { HomeComponent } from './components/home/home.component';
import { InfoComponent } from './components/info/info.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmDeleteOptimizerTaskComponent } from './components/optimizer/confirm-delete-optimizer-task/confirm-delete-optimizer-task.component';
import { ConfirmStopOptimizerTaskComponent } from './components/optimizer/confirm-stop-optimizer-task/confirm-stop-optimizer-task.component';
import { FindOptimizedPlanModalComponent } from './components/optimizer/find-optimized-plan-modal/find-optimized-plan-modal.component';
import { FopStepOptimizerSettingsComponent } from './components/optimizer/find-optimized-plan-modal/steps/fop-step-optimizer-settings/fop-step-optimizer-settings.component';
import { ConfirmAddToAnalysisComponent } from './components/optimizer/optimizer-job-card/job-detail/confirm-add-to-analysis/confirm-add-to-analysis.component';
import { ConfirmMarkCurrentPlanComponent } from './components/optimizer/optimizer-job-card/job-detail/confirm-mark-current-plan/confirm-mark-current-plan.component';
import { JobDetailComponent } from './components/optimizer/optimizer-job-card/job-detail/job-detail.component';
import { JobResultMetricComponent } from './components/optimizer/optimizer-job-card/job-detail/job-result-metric/job-result-metric.component';
import { OptimizerJobCardComponent } from './components/optimizer/optimizer-job-card/optimizer-job-card.component';
import { OptimizerComponent } from './components/optimizer/optimizer.component';
import { PlanInputsComponent } from './components/plan-inputs/plan-inputs.component';
import { PlanComponent } from './components/plan/plan.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SetupIncomeStacksComponent } from './components/setup-income-stacks/setup-income-stacks.component';
import { SetupLoansComponent } from './components/setup-loans/setup-loans.component';
import { SetupRevolvingDebtStacksComponent } from './components/setup-revolving-debt-stacks/setup-revolving-debt-stacks.component';
import { SetupTransactionsComponent } from './components/setup-transactions/setup-transactions.component';
import { COUStepFinishComponent } from './components/setup/plan-settings/create-or-update-plan-modal/cou-step-finish/cou-step-finish.component';
import { CreateOrUpdatePlanModalComponent } from './components/setup/plan-settings/create-or-update-plan-modal/create-or-update-plan-modal.component';
import { PlanSettingsComponent } from './components/setup/plan-settings/plan-settings.component';
import { SetupComponent } from './components/setup/setup.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TransferComponent } from './components/transfer/transfer.component';
import { TransfersComponent } from './components/transfers/transfers.component';
import { IrisComponentsModule } from './iris-components/iris-components.module';
import { DashboardDataComponent } from './components/dashboard-data/dashboard-data.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { ConfirmGeneratePlanReportComponent } from './components/reporting/confirm-generate-plan-report/confirm-generate-plan-report.component';
import { ConfirmDeletePlanReportComponent } from './components/reporting/confirm-delete-plan-report/confirm-delete-plan-report.component';
import { SetupDepositsComponent } from './components/setup-deposits/setup-deposits.component';
import { SetupPeertopeerComponent } from './components/setup-peertopeer/setup-peertopeer.component';
import { SharesComponent } from './components/shares/shares.component';
import { SetupShadowLoansComponent } from './components/setup-shadow-loans/setup-shadow-loans.component';
import { LoansComponent } from './components/loans/loans.component';
import { LocComponent } from './components/loc/loc.component';
import { StepIncomeStreamsComponent } from './components/common/step-income-streams/step-income-streams.component';
import { FlowOfMoneyComponent } from './components/flow-of-money/flow-of-money.component';

const icons: IconDefinition[] = [
  InboxOutline,
  PlusOutline,
  LockOutline,
  SettingOutline,
  LogoutOutline,
  SyncOutline,
  ClockCircleOutline,
  WarningOutline,
  InfoCircleOutline,
  MailOutline,
  DeleteOutline,
  FilterOutline,
  FilterTwoTone,
  PushpinOutline,
  PushpinTwoTone,
  LoginOutline,
  StopOutline,
  DownloadOutline,
];

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'test.helloiris.ai',
  port: 8083,
  path: '/mqtt',
  protocol: 'wss'
};

@NgModule({
  declarations: [
    AppComponent,
    PlanComponent,
    DashboardComponent,
    AnalysisComponent,
    OptimizerComponent,
    InfoComponent,
    ReportingComponent,
    HomeComponent,
    ClientComponent,
    HelpComponent,

    SettingsComponent,
    LoginComponent,
    PlanInputsComponent,
    TransferComponent,
    TransfersComponent,
    LoadingComponent,

    ClientsTableComponent,
    AddClientModalComponent,
    AddClientFormComponent,
    ArchiveClientDialogComponent,
    AuthenticateComponent,
    GenerateComponent,
    ClearComponent,
    FindOptimizedPlanModalComponent,
    StepClientGoalsComponent,
    StepLendingSettingsComponent,
    StepAlgoSettingsComponent,
    FopStepOptimizerSettingsComponent,
    OptimizerJobCardComponent,
    JobDetailComponent,
    JobResultMetricComponent,
    ConfirmMarkCurrentPlanComponent,
    SetupComponent,
    SetupTransactionsComponent,
    SetupLoansComponent,
    SetupRevolvingDebtStacksComponent,
    SetupIncomeStacksComponent,
    AnalysisFormClientGoalComponent,
    AnalysisFormLendingComponent,
    AnalysisFormAlgorithmComponent,

    ComparativeAnalysisComponent,
    ConfirmAddToAnalysisComponent,
    ConfirmDeleteFromAnalysisComponent,
    ConfirmMakeCurrentFromAnalysisComponent,
    EmptyStateSectionComponent,
    ConfirmCopyCurrentPlanComponent,
    ConfirmCloneAnalysisPlanComponent,
    TimelineComponent,
    CashFlowComponent,
    ConfirmDeleteOptimizerTaskComponent,
    ConfirmStopOptimizerTaskComponent,
    PlanSettingsComponent,
    CreateOrUpdatePlanModalComponent,
    COUStepFinishComponent,
    DashboardDataComponent,
    MainHeaderComponent,
    MainFooterComponent,
    ConfirmGeneratePlanReportComponent,
    ConfirmDeletePlanReportComponent,
    SetupDepositsComponent,
    SetupPeertopeerComponent,
    SharesComponent,
    SetupShadowLoansComponent,
    LoansComponent,
    LocComponent,
    StepIncomeStreamsComponent,
    FlowOfMoneyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    IrisComponentsModule,
    QrCodeModule,
    NzStatisticModule,
    NzDropDownModule,
    NzFormModule,
    NzButtonModule,
    NzTabsModule,
    NzCardModule,
    NzGridModule,
    NzInputModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzModalModule,
    NzTagModule,
    NzNotificationModule,
    NzAlertModule,
    NzSwitchModule,
    NzCollapseModule,
    NzRadioModule,
    NzCheckboxModule,
    NzSelectModule,
    NzSpinModule,
    NzProgressModule,
    NzTableModule,
    NzMessageModule,
    NzToolTipModule,
    NzIconModule.forRoot(icons),
    NzBreadCrumbModule,
    NzPageHeaderModule,
    NzStepsModule,
    NzLayoutModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
