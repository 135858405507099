import { Component, Input, OnInit } from '@angular/core';
import { toCompareAndRankDisplayText } from 'src/app/utils/app-common';
import { CompareAndRank } from 'src/app/utils/common-app-types';

@Component({
  selector: 'app-job-result-metric',
  templateUrl: './job-result-metric.component.html',
  styleUrls: ['./job-result-metric.component.scss'],
})
export class JobResultMetricComponent {
  @Input() compareAndRank?: CompareAndRank;
  @Input() showCompareAndRank?: boolean;
  @Input() size: 'default' | 'small' = 'default';

  toCompareAndRankDisplayText = toCompareAndRankDisplayText;

  constructor() {}
}
