<div style="display: flex; align-items: center; justify-content: center; height: 75vh">
    <nz-card>
        <div *ngIf="!sfaActive" style="display: flex; flex-direction: column; align-items: center;">
            <div>
                <p>Use a mobile app like Google Authenticator for <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Android</a> or <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">iOS</a> or
                    Authy for <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank">Android</a> or <a href="https://apps.apple.com/us/app/twilio-authy/id494168017" target="_blank">iOS</a> to scan the QRCode below.</p>
                <p>The mobile app will continuously generate a new 6-digit code every 30 seconds.</p>
                <p>You will need to enter this code to login to IRIS.</p>
                <p>Click Activate 2FA once you have scanned the QRCode and confirmed it has been accepted by the mobile app.</p>
            </div>
            <qr-code [value]="keyuri" size="300" errorCorrectionLevel="M"></qr-code>
            <button nz-button nzType="primary" (click)="activate()" style="margin-top: 10px">Activate 2FA</button>
        </div>
        <p *ngIf="sfaActive">You have already activated 2FA on your account</p>
    </nz-card>
</div>