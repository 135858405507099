import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent {
  constructor(private router: Router, public authService: AuthService) {}

  settings() {
    this.router.navigate(['/settings']);
  }

  logout() {
    this.authService.logout().then(() => {});
  }

  get userName() {
    return this.authService.user?.name;
  }
}
