import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanInputs } from 'src/app/models/plan-inputs';
import {
  formatterDollar,
  formatterPercent,
  parserDollar,
  parserPercent,
} from 'src/app/utils/app-common';

@Component({
  selector: 'app-step-algo-settings',
  templateUrl: './step-algo-settings.component.html',
  styleUrls: ['./step-algo-settings.component.scss'],
})
export class StepAlgoSettingsComponent implements OnInit {
  @Input() inputs!: PlanInputs;

  @Output() validityChange = new EventEmitter<boolean>();

  formatterDollar = formatterDollar;
  parserDollar = parserDollar;
  formatterPercent = formatterPercent;
  parserPercent = parserPercent;

  algorithmAdvanced = false;

  constructor() {}

  ngOnInit(): void {
    this.validityChange.emit(true);
  }

  handleDataChange() {}
}
