<nz-card
  style="height: 100%; margin-top: 20px"
  nzTitle="Draws"
  [nzExtra]="extraTemplate"
>
  <div *ngIf="!client.transactions || client.transactions.length == 0">
    No Draws
  </div>
  <table
    *ngIf="client.transactions && client.transactions.length > 0"
    style="width: 100%"
  >
    <thead>
      <th>Date</th>
      <th>Event</th>
      <th>Type</th>
      <th>Amount</th>
    </thead>
    <tr *ngFor="let transaction of client.transactions">
      <td>{{ transaction.startDate | date: "mediumDate" }}</td>
      <td>{{ transaction.title }}</td>
      <td>
        <nz-tag *ngIf="transaction.type == 'DIVERT'" nzColor="#060A2B">
          Divert
        </nz-tag>
        <nz-tag *ngIf="transaction.type == 'DEPOSIT'" nzColor="#06A7F5">
          Deposit
        </nz-tag>
        <nz-tag *ngIf="transaction.type == 'DRAW'" nzColor="#060A2B">
          Draw
        </nz-tag>
      </td>
      <td>
        {{ transaction.amount | currency: "USD":"$":"1.0-2" }}
      </td>
      <td style="text-align: right">
        <button nz-button nzType="primary" (click)="editEvent(transaction)">
          Edit
        </button>
      </td>
    </tr>
  </table>
</nz-card>
<ng-template #extraTemplate>
  <a (click)="addEvent()">Add Draw</a>
</ng-template>
<nz-modal
  [(nzVisible)]="addEventVisible"
  [nzTitle]="extraHeader"
  [nzFooter]="customFooter"
  (nzOnCancel)="cancelAddEvent()"
  (nzOnOk)="okAddEvent()"
>
  <ng-container *nzModalContent>
    <nz-form-item>
      <nz-form-label>Transaction Type</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-select [(ngModel)]="transactionType" style="width: 120px">
          <nz-option nzValue="DRAW" nzLabel="Draw"></nz-option>
          <nz-option nzValue="DEPOSIT" nzLabel="Deposit"></nz-option>
          <nz-option nzValue="DIVERT" nzLabel="Divert"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Transaction Title</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <input nz-input type="text" [(ngModel)]="transactionTitle" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label
        >Amount to &nbsp;<span *ngIf="transactionType == 'DIVERT'">Divert</span
        ><span *ngIf="transactionType == 'DEPOSIT'">Deposit</span
        ><span *ngIf="transactionType == 'DRAW'">Draw</span></nz-form-label
      >
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="transactionAmount"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Start Date</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-date-picker
          [(ngModel)]="transactionStartDate"
          style="margin-bottom: 0px"
        ></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Repeat Every</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number [(ngModel)]="transactionRepeatEvery"></nz-input-number>
        <nz-select
          *ngIf="transactionRepeatEvery > 0"
          [(ngModel)]="transactionRepeatUnits"
          style="width: 120px; margin-left: 20px"
        >
          <nz-option
            nzValue="months"
            [nzLabel]="transactionRepeatEvery > 1 ? 'Months' : 'Month'"
          ></nz-option>
          <nz-option
            nzValue="years"
            [nzLabel]="transactionRepeatEvery > 1 ? 'Years' : 'Year'"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <div>Ends:</div>
    <nz-radio-group [(ngModel)]="transactionEnding" class="radios">
      <label nz-radio nzValue="NE">Never Ending</label>
      <label nz-radio nzValue="EO"
        >End On
        <nz-date-picker
          *ngIf="transactionEnding === 'EO'"
          [(ngModel)]="transactionEndDate"
          style="margin-left: 20px; margin-bottom: 10px"
        ></nz-date-picker
      ></label>
      <label nz-radio nzValue="EA"
        >End After
        <span *ngIf="transactionEnding === 'EA'"
          ><input type="text" nz-input [(ngModel)]="transactionOccurences" />
          occurences</span
        ></label
      >
    </nz-radio-group>
  </ng-container>
</nz-modal>
<ng-template #customFooter>
  <button
    *ngIf="isEdittingEvent"
    nz-button
    nzType="primary"
    nzDanger
    (click)="deleteEvent()"
  >
    Delete
  </button>
  <button nz-button nzType="default" (click)="cancelAddEvent()">Cancel</button>
  <button nz-button nzType="primary" (click)="okAddEvent()">OK</button>
</ng-template>
<ng-template #extraHeader>
  <span *ngIf="transactionType == 'DEPOSIT'">Deposit Money</span
  ><span *ngIf="transactionType == 'DIVERT'">Divert Money</span
  ><span *ngIf="transactionType == 'DRAW'">Draw Money</span>
</ng-template>
<ng-template #extraTitle>
  <nz-tag
    *ngIf="client.plan && !client.plan.optimizer"
    [nzColor]="'red'"
    style="margin-left: 5px"
    >Plan Not Optimized</nz-tag
  ><!--nz-tag *ngIf="client.plan && client.plan.optimizer === 'QUICK'" [nzColor]="'green'" style="margin-left: 5px">Quick Optimized</nz-tag--><nz-tag
    *ngIf="client.plan && client.plan.optimizer === 'THOROUGH'"
    [nzColor]="'green'"
    style="margin-left: 5px"
    >Optimized</nz-tag
  >
  <nz-tag
    *ngIf="client.plan && client.plan.optimizerLevel == 'HIGH'"
    [nzColor]="'green'"
    style="margin-left: 5px"
    >Quick Optimized: High</nz-tag
  ><nz-tag
    *ngIf="client.plan && client.plan.optimizerLevel === 'MEDIUM'"
    [nzColor]="'yellow'"
    style="margin-left: 5px"
    >Quick Optimized: Medium</nz-tag
  ><nz-tag
    *ngIf="client.plan && client.plan.optimizerLevel === 'LOW'"
    [nzColor]="'red'"
    style="margin-left: 5px"
    >Quick Optimized: Low</nz-tag
  >
</ng-template>
