<nz-spin
  class="client-page__fetch-spinner"
  *ngIf="clientFetchInProgress"
  nzSimple
  [nzSize]="'large'"
></nz-spin>
<nz-layout *ngIf="!clientFetchInProgress" class="client-page">
  <nz-sider nzCollapsible nzWidth="160px" [(nzCollapsed)]="isCollapsed">
    <div class="logo"></div>
    <nz-tabset
      nzLinkRouter
      [nzTabPosition]="'left'"
      class="client-page__left-nav"
    >
      <nz-tab *ngIf="clientHasPlan">
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="clientDashboardPageRouterLink"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/category.svg"
          />
          <div class="tab-item-text">Dashboard</div></a
        >
      </nz-tab>
      <nz-tab>
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['setup']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/setup.svg"
          />
          <div class="tab-item-text">Setup</div></a
        >
      </nz-tab>
      <nz-tab *ngIf="clientHasPlan">
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['plan']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/results.svg"
          />
          <div class="tab-item-text">Results</div></a
        >
      </nz-tab>

      <nz-tab *ngIf="clientHasPlan">
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['optimizer']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/optimize.svg"
          />
          <div class="tab-item-text">Optimizer</div>
        </a>
      </nz-tab>
      <nz-tab *ngIf="clientHasPlan">
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['analysis']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/analyze.svg"
          />
          <div class="tab-item-text">Analyze</div></a
        >
      </nz-tab>
      <nz-tab>
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['info']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/profile.svg"
            style="margin: 0 2px"
          />
          <div class="tab-item-text">Client</div></a
        >
      </nz-tab>
      <nz-tab *ngIf="clientHasPlan">
        <a
          *nzTabLink
          class="client-page__left-nav-link"
          nz-tab-link
          [routerLink]="['reporting']"
        >
          <img
            class="client-page__left-nav-icon-img"
            src="/assets/img/sidebar/report.svg"
          />
          <div class="tab-item-text">Reporting</div></a
        >
      </nz-tab>
    </nz-tabset>
  </nz-sider>
  <nz-layout>
    <nz-header style="display: flex; align-items: center; padding: 30px 30px">
      <nz-breadcrumb nz-page-header-breadcrumb>
        <nz-breadcrumb-item
          ><a [routerLink]="myClientsPageRouterLink"
            >My Clients</a
          ></nz-breadcrumb-item
        >
        <nz-breadcrumb-item>
          <a [routerLink]="clientDashboardPageRouterLink">
            {{ clientAlias }}
          </a>
        </nz-breadcrumb-item>
      </nz-breadcrumb>
      <ul class="top-nav__actions" style="margin-left: auto">
        <!-- <li *ngIf="authService.loggedIn" class="top-nav__link-item">
          <a routerLink="/help" routerLinkActive="active-link">Help</a>
        </li>
        <li *ngIf="authService.loggedIn" class="top-nav-divider"></li> -->
        <li *ngIf="authService.loggedIn" class="top-nav__link-item">
          <a nz-dropdown [nzDropdownMenu]="menu" class="settings"
            >{{ userName }}
            <div class="settings-icon"></div
          ></a>
        </li>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="settings()">
              <i nz-icon [nzType]="'setting'"></i>Settings
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="authService.logout()">
              <i nz-icon [nzType]="'logout'"></i>Logout
            </li>
          </ul>
        </nz-dropdown-menu>
      </ul>
    </nz-header>
    <nz-content style="margin: 0 30px; padding-bottom: 70px; margin-top: 30px">
      <div
        class="client-page__fetch-failed-alert-wrapper"
        *ngIf="clientFetchFailed && clientFetchErrorMessage"
      >
        <nz-alert
          nzType="error"
          [nzMessage]="clientFetchErrorMessage"
          nzShowIcon
        ></nz-alert>
      </div>

      <ng-container *ngIf="clientFetchSuccess">
        <div class="client-page__content">
          <router-outlet></router-outlet>
        </div>
      </ng-container>
    </nz-content>
  </nz-layout>
</nz-layout>
