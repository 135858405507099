export enum AsyncOpState {
  NotStarted,
  Inprogress,
  Completed,
  Failed,
}

export interface AsyncOpData<T = void, E = string> {
  state: AsyncOpState;
  data?: T;
  error?: E;
}
