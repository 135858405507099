import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-setup-loans',
  templateUrl: './setup-loans.component.html',
  styleUrls: ['./setup-loans.component.scss'],
})
export class SetupLoansComponent implements OnInit {
  @Input() client!: Client;

  startDate?: Date;
  todayDate = new Date();

  addLoanVisible = false;
  isAddingLoan = false;
  isEdittingLoan = false;
  loanId?: string;
  loanTitle = '';
  loanType = 'LOAN';
  loanPaymentType = 'INTEREST_RATE';
  loanAmount = '';
  loanLoanConstant = '';
  loanInterestRate = '';
  loanPayment = '';
  loanPayments = 36;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  addLoan() {
    if (!this.isEdittingLoan) {
      this.loanType = 'LOAN';
      this.isAddingLoan = true;
      this.addLoanVisible = true;
    }
  }

  addLoanRevolving() {
    if (!this.isEdittingLoan) {
      this.loanType = 'REVOLVING';
      this.isAddingLoan = true;
      this.addLoanVisible = true;
    }
  }

  editLoan(loan: any) {
    if (!this.isAddingLoan) {
      this.isEdittingLoan = true;
      this.loanId = loan.id;
      this.loanTitle = loan.title;
      this.loanType = loan.type;
      this.loanAmount = loan.amount;
      this.loanPayment = loan.payment;
      this.loanLoanConstant = loan.loanConstant;
      this.loanPayments = loan.payments;
      this.addLoanVisible = true;
    }
  }

  cancelAddLoan() {
    this.addLoanVisible = false;
    this.isAddingLoan = false;
    this.isEdittingLoan = false;
  }

  okAddLoan() {
    if (this.isAddingLoan) {
      this.addLoanVisible = false;
      this.isAddingLoan = false;
      let loan = {
        id: generateUuidV4(),
        title: this.loanTitle,
        type: this.loanType,
        amount: this.loanAmount,
        payment: this.loanPayment,
        loanConstant: this.loanLoanConstant,
        payments: this.loanPayments,
      };
      this.httpClient
        .post('/api/clients/' + this.client!.id + '/loans', loan, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        })
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            if (!this.client!.loans) {
              this.client!.loans = [];
            }
            this.client!.loans.push(result.loan);
          }
        });
    } else if (this.isEdittingLoan) {
      this.addLoanVisible = false;
      this.isEdittingLoan = false;
      let loan = {
        id: this.loanId,
        title: this.loanTitle,
        type: this.loanType,
        amount: this.loanAmount,
        loanConstant: this.loanLoanConstant,
        payment: this.loanPayment,
        payments: this.loanPayments,
      };
      this.httpClient
        .post('/api/clients/' + this.client!.id + '/loans/' + loan.id, loan, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        })
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let l of this.client!.loans!) {
              if (l.id && l.id === loan.id) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.loans!.splice(found, 1, loan);
            }
          }
        });
    }
  }

  deleteLoan() {
    if (this.isEdittingLoan) {
      this.addLoanVisible = false;
      this.isEdittingLoan = false;
      this.httpClient
        .delete('/api/clients/' + this.client!.id + '/loans/' + this.loanId, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        })
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let l of this.client!.loans!) {
              if (l.id && l.id === this.loanId) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.loans!.splice(found, 1);
            }
          }
        });
    }
  }

  updateLoanPayment() {
    if (this.loanPaymentType == 'PAYMENT') {
      this.calculateLoanConstant();
    }
  }

  updateLoanInterestRate() {
    if (this.loanPaymentType == 'INTEREST_RATE') {
      this.calculateLoanConstant();
      this.calculatePayment();
   }
  }

  updateLoanLoanConstant() {
    if (this.loanPaymentType == 'LOAN_CONSTANT') {
      this.calculatePayment();
    }
  }

  updateFrequency() {
    if (this.loanPaymentType == 'PAYMENT') {
      this.calculateLoanConstant();
    } else if (this.loanPaymentType == 'INTEREST_RATE') {
       this.calculateLoanConstant();
       this.calculatePayment();
    } else if (this.loanPaymentType == 'LOAN_CONSTANT') {
      this.calculatePayment();
    }
  }

  calculateLoanConstant() {
    if (this.loanPaymentType == 'INTEREST_RATE') {
        let monthlyInterestRate = +this.loanInterestRate / (100 * (12 / this.getFrequencyInMonths() ));
        let payment = +this.loanAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, - +this.loanPayments));
        this.loanLoanConstant =
          '' +
          (payment / this.getFrequencyInMonths() / +this.loanAmount) *
            100;
    } else if (this.loanPaymentType == 'PAYMENT') {
      console.log('Frequency in months is ' + this.getFrequencyInMonths());
      this.loanLoanConstant =
        '' +
        (+this.loanPayment / this.getFrequencyInMonths() / +this.loanAmount) *
          100;
    }
  }

  calculatePayment() {
    if (this.loanPaymentType == 'INTEREST_RATE') {
      let monthlyInterestRate = +this.loanInterestRate / (100 * (12 / this.getFrequencyInMonths() )) ;
      this.loanPayment = '' + +this.loanAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, - +this.loanPayments));
    } else if (this.loanPaymentType == 'LOAN_CONSTANT') {
      console.log('Frequency in months is ' + this.getFrequencyInMonths());
      this.loanPayment =
        '' +
        this.getFrequencyInMonths() *
          (+this.loanLoanConstant / 100) *
          +this.loanAmount;
    }
  }

  getFrequencyInMonths() {
    return 1;
  }

  clearPlanOptimization() {
    if (this.client!.plan) {
      this.client!.plan!.optimizer = undefined;
      this.client!.plan!.optimizerLevel = undefined;
    }
    if (this.client!.jobs) {
      for (let job of this.client!.jobs) {
        job.outOfDate = true;
      }
    }
  }
}
