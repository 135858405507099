<nz-modal
  nzVisible="true"
  nzTitle="Archive Client"
  (nzOnCancel)="cancelOperationBtnClick()"
  (nzOnOk)="confirmOperationBtnClick()"
  nzOkText="Yes, Archive"
  [nzOkDisabled]="submitBtnDisabled"
  [nzOkLoading]="isOperationInProgress"
  [nzCancelDisabled]="isOperationInProgress"
  [nzClosable]="!isOperationInProgress"
  nzMaskClosable="false"
  nzOkDanger
>
  <ng-container *nzModalContent>
    <div class="archive-client-dialog__body__details">
      <h3 class="archive-client-dialog__body__details__title">
        Client Details
      </h3>
      <p class="archive-client-dialog__body__details__prop">
        Client Alias: <b>{{ client.alias }}</b>
      </p>
      <p class="archive-client-dialog__body__details__prop">
        Client Name: <b>{{ toClientFullName() }}</b>
      </p>
      <p
        class="archive-client-dialog__body__details__prop"
        *ngIf="client.emailAddress"
      >
        Client Email: <b>{{ client.emailAddress }}</b>
      </p>
    </div>
    <p class="archive-client-dialog__body__alert-text">
      Are you sure you want to archive this client?
    </p>
    <ng-container *ngIf="showArchiveErrorMessage && archiveErrorMessage">
      <nz-alert
        nzType="error"
        [nzMessage]="archiveErrorMessage"
        nzShowIcon
      ></nz-alert>
    </ng-container>
  </ng-container>
</nz-modal>
