import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-setup-transactions',
  templateUrl: './setup-transactions.component.html',
  styleUrls: ['./setup-transactions.component.scss'],
})
export class SetupTransactionsComponent implements OnInit {
  @Input() client!: Client;

  startDate?: Date;
  todayDate = new Date();

  addEventVisible = false;
  isAddingEvent = false;
  isEdittingEvent = false;
  transactionId?: string;
  transactionTitle = '';
  transactionStartDate = '';
  transactionType = 'DRAW';
  transactionAmount = '';
  transactionRepeatEvery = 0;
  transactionRepeatUnits = 'months';
  transactionEnding = 'EA';
  transactionEndDate = '';
  transactionOccurences = '1';

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  addEvent() {
    if (!this.isEdittingEvent) {
      this.isAddingEvent = true;
      this.addEventVisible = true;
    }
  }

  editEvent(transaction: any) {
    if (!this.isAddingEvent) {
      this.isEdittingEvent = true;
      this.transactionId = transaction.id;
      this.transactionTitle = transaction.title;
      this.transactionStartDate = transaction.startDate;
      this.transactionType = transaction.type;
      this.transactionAmount = transaction.amount;
      if (transaction.repeatEvery) {
        this.transactionRepeatEvery = transaction.repeatEvery;
      }
      if (transaction.repeatUnits) {
        this.transactionRepeatUnits = transaction.repeatUnits;
      }
      if (transaction.ending) {
        this.transactionEnding = transaction.ending;
      }
      if (transaction.endDate) {
        this.transactionEndDate = transaction.endDate;
      }
      if (transaction.occurences) {
        this.transactionOccurences = transaction.occurences;
      }
      this.addEventVisible = true;
    }
  }

  cancelAddEvent() {
    this.addEventVisible = false;
    this.isAddingEvent = false;
    this.isEdittingEvent = false;
  }

  okAddEvent() {
    if (this.isAddingEvent) {
      this.addEventVisible = false;
      this.isAddingEvent = false;
      let transaction = {
        id: generateUuidV4(),
        title: this.transactionTitle,
        startDate: this.transactionStartDate,
        type: this.transactionType,
        amount: this.transactionAmount,
        repeatEvery: this.transactionRepeatEvery,
        repeatUnits: this.transactionRepeatUnits,
        ending: this.transactionEnding,
        endDate: this.transactionEndDate,
        occurences: this.transactionOccurences,
      };
      this.httpClient
        .post(
          '/api/clients/' + this.client!.id + '/transactions',
          transaction,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            this.clearPlanOptimization();

            if (!this.client!.transactions) {
              this.client!.transactions = [];
            }
            this.client!.transactions.push(result.transaction);
          }
        });
    } else if (this.isEdittingEvent) {
      this.addEventVisible = false;
      this.isEdittingEvent = false;
      let transaction = {
        id: this.transactionId,
        title: this.transactionTitle,
        startDate: this.transactionStartDate,
        type: this.transactionType,
        amount: this.transactionAmount,
        repeatEvery: this.transactionRepeatEvery,
        repeatUnits: this.transactionRepeatUnits,
        ending: this.transactionEnding,
        endDate: this.transactionEndDate,
        occurences: this.transactionOccurences,
      };
      this.httpClient
        .post(
          '/api/clients/' + this.client!.id + '/transactions/' + transaction.id,
          transaction,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let tr of this.client!.transactions!) {
              if (tr.id && tr.id === transaction.id) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.transactions!.splice(found, 1, transaction);
            }
          }
        });
    }
  }

  deleteEvent() {
    if (this.isEdittingEvent) {
      this.addEventVisible = false;
      this.isEdittingEvent = false;
      this.httpClient
        .delete(
          '/api/clients/' +
            this.client!.id +
            '/transactions/' +
            this.transactionId,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let tr of this.client!.transactions!) {
              if (tr.id && tr.id === this.transactionId) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.transactions!.splice(found, 1);
            }
          }
        });
    }
  }

  clearPlanOptimization() {
    if (this.client!.plan) {
      this.client!.plan!.optimizer = undefined;
      this.client!.plan!.optimizerLevel = undefined;
    }
    if (this.client!.jobs) {
      for (let job of this.client!.jobs) {
        job.outOfDate = true;
      }
    }
  }
}
