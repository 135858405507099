import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { RouteClientPlan } from 'src/app/utils/route-constants';
import { checkIfClientHasIncomePlan } from '../home/client-utils';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit {
  client?: Client;

  editting = true;
  loading = false;

  inputs?: any;

  editPlanModalOpen = false;

  isVisibleInfo = false;

  constructor(private router: Router, private advisorStateService: AdvisorStateService) {}

  ngOnInit(): void {
    this.client = this.advisorStateService.getClient();
  }

  openInfo() {
    this.isVisibleInfo = true;
  }

  handleOk() {
    this.isVisibleInfo = false;
  }

  handleCancel() {
    this.isVisibleInfo = false;
  }

  openEditPlanModal() {
    this.editPlanModalOpen = true;
  }
  closeEditPlanModal() {
    this.editPlanModalOpen = false;
  }

  onEditPlanSuccess() {
    this.router.navigate([RouteClientPlan(this.client?.id)]);
  }

  get clientHasPlan() {
    return this.client! && checkIfClientHasIncomePlan(this.client);
  }
  
  /*get freedomDate() : Date {
    return new Date(this.client!.plan!.results!.msgs["freedom_date"]);
  }*/

  get startDate() : Date {
    return new Date(this.client!.plan!.inputs!.startDate);
  }

  get planDuration() {
    return this.client!.plan!.inputs!.yearsToSimulate;
  }

  get incomeStreams() {
    return 1;
  }
}
