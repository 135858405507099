<div class="job-detail">
  <div class="job-detail__section">
    <div class="job-detail__section__body">
      <div class="jbrc">
        <div class="jbrc__header">
          <div class="jbrc__header__title-col">
            <h4 class="jbrc__header__title-col__heading">
              Optimizer Results ({{ jobResultCount }})
            </h4>
            <p>
              Compare and contrast various income plans suggested by this
              search.
            </p>
          </div>
          <div
            class="jbrc__header__summary-col"
            *ngFor="let result of jobResults; index as resultIndex"
          >
            <h4 class="jbrc__header__summary-col__title">
              #{{ resultIndex + 1 }} Income Plan
            </h4>
            <p class="jbrc__header__summary-col__smart-key">
              <span class="jbrc__header__summary-col__smart-key__label">
                SMART KEY
              </span>
              {{ toResultSmartKey(result) }}
            </p>

            <div class="jbrc__header__summary-col__actions">
              <button
                nz-button
                nzType="primary"
                (click)="makeCurrentClick(result)"
                nzBlock
              >
                Make Current
              </button>
              <button nz-button (click)="addToAnalysisClick(result)" nzBlock>
                Add to Analysis
              </button>
            </div>
          </div>
        </div>
        <div class="jbrc__content">
          <app-collapsible [collapsed]="initialCollapsed">
            <div collapsible-title class="jbrc__c__t">
              <div class="jbrc__c__t__label-col">Plan Timeline</div>

              <div
                class="jbrc__c__t__tags"
                *ngFor="
                  let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                  index as resultIndex
                "
              >
                <app-job-result-metric
                  *ngFor="
                    let score of optimizerResultWithAnalysis.scores
                      .collapsed_timeline
                  "
                  [showCompareAndRank]="showCompareAndRank"
                  [compareAndRank]="score.compareAndRank"
                  size="small"
                >
                  {{ score.label }}
                  {{ score.value }}
                </app-job-result-metric>
              </div>
            </div>
            <div collapsible-content>
              <div class="jbrc__c__c">
                <div class="jbrc__c__c__label-col">
                  <div
                    class="jbrc__c__c__label-col__row"
                    *ngFor="let labelWithTooltip of timelineSectionLabels"
                  >
                    {{ labelWithTooltip.label }}
                    <app-tooltip-icon
                      *ngIf="labelWithTooltip.tooltipMessage"
                      [tooltipMessage]="labelWithTooltip.tooltipMessage"
                    ></app-tooltip-icon>
                  </div>
                </div>
                <div
                  class="jbrc__c__c__value-col"
                  *ngFor="
                    let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                    index as resultIndex
                  "
                >
                  <div
                    class="jbrc__c__c__value-col__row"
                    *ngFor="
                      let score of optimizerResultWithAnalysis.scores.timeline
                    "
                  >
                    <app-job-result-metric
                      [showCompareAndRank]="showCompareAndRank"
                      [compareAndRank]="score.compareAndRank"
                      >{{ score.value }}</app-job-result-metric
                    >
                  </div>
                </div>
              </div>
            </div>
          </app-collapsible>
          <app-collapsible [collapsed]="initialCollapsed">
            <div collapsible-title class="jbrc__c__t">
              <div class="jbrc__c__t__label-col">Freedom Day Numbers</div>
              <div
                class="jbrc__c__t__tags"
                *ngFor="
                  let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                  index as resultIndex
                "
              >
                <app-job-result-metric
                  *ngFor="
                    let score of optimizerResultWithAnalysis.scores
                      .collapsed_ffd
                  "
                  [showCompareAndRank]="showCompareAndRank"
                  [compareAndRank]="score.compareAndRank"
                  size="small"
                >
                  {{ score.label }}
                  {{ score.value }}
                </app-job-result-metric>
              </div>
            </div>
            <div collapsible-content>
              <div class="jbrc__c__c">
                <div class="jbrc__c__c__label-col">
                  <div
                    class="jbrc__c__c__label-col__row"
                    *ngFor="let labelWithTooltip of freedomDaySectionLabels"
                  >
                    {{ labelWithTooltip.label }}
                    <app-tooltip-icon
                      *ngIf="labelWithTooltip.tooltipMessage"
                      [tooltipMessage]="labelWithTooltip.tooltipMessage"
                    ></app-tooltip-icon>
                  </div>
                </div>
                <div
                  class="jbrc__c__c__value-col"
                  *ngFor="
                    let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                    index as resultIndex
                  "
                >
                  <div
                    class="jbrc__c__c__value-col__row"
                    *ngFor="let score of optimizerResultWithAnalysis.scores.ffd"
                  >
                    <app-job-result-metric
                      [showCompareAndRank]="showCompareAndRank"
                      [compareAndRank]="score.compareAndRank"
                      >{{ score.value }}</app-job-result-metric
                    >
                  </div>
                </div>
              </div>
            </div>
          </app-collapsible>
          <app-collapsible [collapsed]="initialCollapsed">
            <div collapsible-title class="jbrc__c__t">
              <div class="jbrc__c__t__label-col">Numbers</div>
              <div
                class="jbrc__c__t__tags"
                *ngFor="
                  let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                  index as resultIndex
                "
              >
                <app-job-result-metric
                  *ngFor="
                    let score of optimizerResultWithAnalysis.scores
                      .collapsed_numbers
                  "
                  [showCompareAndRank]="showCompareAndRank"
                  [compareAndRank]="score.compareAndRank"
                  size="small"
                >
                  {{ score.label }}
                  {{ score.value }}
                </app-job-result-metric>
              </div>
            </div>
            <div collapsible-content>
              <div class="jbrc__c__c">
                <div class="jbrc__c__c__label-col">
                  <div
                    class="jbrc__c__c__label-col__row"
                    *ngFor="let labelWithTooltip of numberSectionLabels"
                  >
                    {{ labelWithTooltip.label }}
                    <app-tooltip-icon
                      *ngIf="labelWithTooltip.tooltipMessage"
                      [tooltipMessage]="labelWithTooltip.tooltipMessage"
                    ></app-tooltip-icon>
                  </div>
                </div>
                <div
                  class="jbrc__c__c__value-col"
                  *ngFor="
                    let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                    index as resultIndex
                  "
                >
                  <div
                    class="jbrc__c__c__value-col__row"
                    *ngFor="
                      let score of optimizerResultWithAnalysis.scores.numbers
                    "
                  >
                    <app-job-result-metric
                      [showCompareAndRank]="showCompareAndRank"
                      [compareAndRank]="score.compareAndRank"
                      >{{ score.value }}</app-job-result-metric
                    >
                  </div>
                </div>
              </div>
            </div>
          </app-collapsible>
          <app-collapsible [collapsed]="initialCollapsed">
            <div collapsible-title class="jbrc__c__t">
              <div class="jbrc__c__t__label-col">Others</div>
            </div>
            <div collapsible-content>
              <div class="jbrc__c__c">
                <div class="jbrc__c__c__label-col">
                  <div
                    class="jbrc__c__c__label-col__row"
                    *ngFor="let labelWithTooltip of otherSectionLabels"
                  >
                    {{ labelWithTooltip.label }}
                    <app-tooltip-icon
                      *ngIf="labelWithTooltip.tooltipMessage"
                      [tooltipMessage]="labelWithTooltip.tooltipMessage"
                    ></app-tooltip-icon>
                  </div>
                </div>
                <div
                  class="jbrc__c__c__value-col"
                  *ngFor="
                    let optimizerResultWithAnalysis of optimizerResultsWithAnalysis;
                    index as resultIndex
                  "
                >
                  <div
                    class="jbrc__c__c__value-col__row"
                    *ngFor="
                      let score of optimizerResultWithAnalysis.scores.others
                    "
                  >
                    <app-job-result-metric
                      [showCompareAndRank]="showCompareAndRank"
                      [compareAndRank]="score.compareAndRank"
                      >{{ score.value }}</app-job-result-metric
                    >
                  </div>
                </div>
              </div>
            </div>
          </app-collapsible>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-mark-current-plan
  *ngIf="markAsPlanConfirmModal.open && markAsPlanConfirmModal.result"
  [client]="client"
  [job]="job"
  [result]="markAsPlanConfirmModal.result"
  (cancelOperation)="cancelMarkAsCurrent()"
  (operationSuccess)="markAsCurrentSuccess()"
></app-confirm-mark-current-plan>

<app-confirm-add-to-analysis
  *ngIf="addToAnalysisConfirmModal.open && addToAnalysisConfirmModal.result"
  [client]="client"
  [job]="job"
  [result]="addToAnalysisConfirmModal.result"
  (cancelOperation)="cancelAddToAnalysis()"
  (operationSuccess)="addToAnalysisSuccess()"
></app-confirm-add-to-analysis>
