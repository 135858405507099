export class Timeline {
    static unsustainedMonthsPercentThreshold = 2;

    static timelineResults(results: any) {
        
      let freedomMonth = results.msgs!.freedom_months;
      let simMonths = results.msgs!.sim_years * 12;
      let unsustainedMonthsThreshold = Math.ceil(
        (simMonths - freedomMonth + 1) *
          (this.unsustainedMonthsPercentThreshold / 100)
      );
      let passiveIncome = results.msgs!.passive_income;
      let index = freedomMonth;
      let unsustained = true;
      let unsustainedMonths = 0;
      while (index < simMonths) {
        let data = results.data[index];
        if (data.passive < passiveIncome) {
          unsustainedMonths++;
          if (unsustainedMonths >= unsustainedMonthsThreshold) {
            unsustained = true;
          }
        } else if (unsustainedMonths === 0) {
          unsustained = false;
        }
        index++;
      }

      let message;
      let progress = 0;
      let color;
      if (!unsustained && unsustainedMonths > 0) {
          message = 'Partly Unsustained';
          progress = 66;
          color = '#FFFF00';
      } else if (unsustained) {
          message = 'Unsustained';
          progress = 33;
          color = '#FF0000';
      } else {
          message = 'Sustained';
          progress = 100;
          color = '#00FF00';
      }

      return { message: message, progress: progress, color: color, unsustainedMonths: unsustainedMonths };
    }
}