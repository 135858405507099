<nz-card nzTitle="Client Settings" [nzExtra]="clientExtraTemplate">
    <div nz-row nzGutter="16">
        <!--div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Simulation Name</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <input nz-input  type="text" [(ngModel)]="inputs.simulationName">
                </nz-form-control>
            </nz-form-item>
        </div-->
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Monthly Income Goal</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.monthlyIncomeGoal"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Monthly Contribution Amount</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.monthlyContributionAmount"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Start Date</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-date-picker [(ngModel)]="inputs.startDate" style="margin-bottom: 0px"></nz-date-picker>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div *ngIf="clientAdvanced" nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Inflation Rate for Income Goal</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.inflationRate"
                        [nzMin]="0"
                        [nzMax]="100"
                        [nzStep]="1"
                        [nzFormatter]="formatterPercent"
                        [nzParser]="parserPercent"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div *ngIf="clientAdvanced" nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Years to Simulate</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.yearsToSimulate"
                        [nzMin]="1"
                        [nzMax]="100"
                        [nzStep]="1"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</nz-card>
<nz-card nzTitle="Lending Settings" style="margin-top: 16px" [nzExtra]="lendingExtraTemplate">
    <div nz-row nzGutter="16">
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Size of Line of Credit (LOC)</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.lineOfCreditSize"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 12 }">
            <nz-form-item>
                <nz-form-label>Line of Credit Interest Rate</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.lineOfCreditInterestRate"
                        [nzMin]="0"
                        [nzMax]="100"
                        [nzStep]="1"
                        [nzFormatter]="formatterPercent"
                        [nzParser]="parserPercent"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div *ngIf="lendingAdvanced" nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Projected Return from Loans</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.loansRateOfReturn"
                        [nzMin]="0"
                        [nzMax]="100"
                        [nzStep]="1"
                        [nzFormatter]="formatterPercent"
                        [nzParser]="parserPercent"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div *ngIf="lendingAdvanced" nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>Loan Term in Years</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.loansTerm"
                        [nzMin]="1"
                        [nzMax]="100"
                        [nzStep]="1"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
</nz-card>
<nz-card nzTitle="Algorithm Settings" style="margin-top: 16px" [nzExtra]="algorithmExtraTemplate">
    <div *ngIf="!algorithmAdvanced">Click the Advanced link on the right to adjust algorithm settings.</div>
    <div *ngIf="algorithmAdvanced" nz-row nzGutter="16">
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 24}" [nzLg]="{ span: 24 }">
            <nz-form-item>
                <nz-form-label>Algorithm (TY)</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-select [(ngModel)]="inputs.methodToUse" style="width: 200px">
                        <nz-option [nzValue]="0" nzLabel="One"></nz-option>
                        <nz-option [nzValue]="1" nzLabel="Two"></nz-option>
                        <nz-option [nzValue]="2" nzLabel="Three"></nz-option>
                        <nz-option [nzValue]="3" nzLabel="Four"></nz-option>
                        <nz-option [nzValue]="4" nzLabel="Five"></nz-option>
                        <nz-option [nzValue]="5" nzLabel="Six"></nz-option>
                        <nz-option [nzValue]="6" nzLabel="Seven"></nz-option>
                      </nz-select>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>GR</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.growthCapital"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>MGR</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.monthsToPayOffLOC"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item>
                <nz-form-label>MP3</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.monthsToPayOffLOCPhase3"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }" style="margin: 10px 0">
            Keep Additional Income in System
            <nz-switch [(ngModel)]="inputs.keepAdditionalIncomeInSystem" style="margin-left: 20px"></nz-switch>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 24}" [nzLg]="{ span: 24 }" style="margin: 10px 0">
            Enable Auto Increment 
            <nz-switch [(ngModel)]="inputs.enableAutoIncrement" style="margin-left: 20px"></nz-switch>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item *ngIf="inputs.enableAutoIncrement" >
                <nz-form-label>AA</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.autoIncrementAmount"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item *ngIf="inputs.enableAutoIncrement" >
                <nz-form-label>NumFlips</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.autoIncrementNumFlips"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item *ngIf="inputs.enableAutoIncrement" >
                <nz-form-label>Max</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.autoIncrementMax"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col [nzXs]="{ span: 24 }" [nzMd]="{ span: 12}" [nzLg]="{ span: 8 }">
            <nz-form-item *ngIf="inputs.enableAutoIncrement" >
                <nz-form-label>One-Time Max Amount</nz-form-label>
                <nz-form-control [nzSpan]="24">
                    <nz-input-number
                        [(ngModel)]="inputs.autoIncrementOneTimeMaxAmount"
                        [nzFormatter]="formatterDollar"
                        [nzParser]="parserDollar"
                        ></nz-input-number>
                </nz-form-control>
            </nz-form-item>
        </div>
        
    </div>
</nz-card>
<ng-template #clientExtraTemplate>
    <a (click)="clientAdvanced = !clientAdvanced"><span *ngIf="!clientAdvanced">Advanced</span><span *ngIf="clientAdvanced">Basic</span></a>
</ng-template>
<ng-template #lendingExtraTemplate>
    <a (click)="lendingAdvanced = !lendingAdvanced"><span *ngIf="!lendingAdvanced">Advanced</span><span *ngIf="lendingAdvanced">Basic</span></a>
</ng-template>
<ng-template #algorithmExtraTemplate>
    <a (click)="algorithmAdvanced = !algorithmAdvanced"><span *ngIf="!algorithmAdvanced">Advanced</span><span *ngIf="algorithmAdvanced">Basic</span></a>
</ng-template>