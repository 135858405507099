import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transfers',
  templateUrl: './transfers.component.html',
  styleUrls: ['./transfers.component.scss']
})
export class TransfersComponent implements OnInit {

  transferId?: string;

  alias?: string;
  fromUser?: string;

  constructor(private httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private message: NzMessageService, private authService: AuthService) { 
      this.transferId = route.snapshot.params.id;
  }

  ngOnInit(): void {
    /*this.route.params.subscribe((p: any) => {
      this.transferId = p.id;
    });*/
    this.httpClient.get('/api/transfers/' + this.transferId, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Access-Token', `Bearer ${this.authService.token}`),
    }).subscribe((transferResult: any) => {
      if (transferResult.result === 0) {
        this.alias = transferResult.transfer.clientAlias;
        this.fromUser = transferResult.transfer.fromUser;
      }
    });
  }

  transfer() {
    this.httpClient.post('/api/transfers/' + this.transferId, {},{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Access-Token', `Bearer ${this.authService.token}`),
    }).subscribe((result) => {
      this.message.create('success', 'Transfer complete.');
      this.router.navigate(['/']);
    });
  }

  cancel() {
    this.router.navigate(['/']);
  }

}
