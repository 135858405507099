import { DatePipe } from '@angular/common';
import { getAlgorithmMethodName } from 'src/app/components/home/client-utils';
import { Job } from 'src/app/models/job';
import { OptimizerResult } from 'src/app/models/optimizer-result';
import {
  rankAndColorNumbers,
  RankingMode,
} from 'src/app/utils/color-ranking-util';
import { CompareAndRank } from 'src/app/utils/common-app-types';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import { toMMMDDYYYYDateFormat } from 'src/app/utils/date-utils';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformUSDCurrencyNoDecimals } from 'src/app/utils/transform-currency';

export interface SuggestedPlanJobCompareAndRankResult {
  summary: {
    financialFreedomDay?: CompareAndRank;
    netWorthAtEndOfPlan?: CompareAndRank;
    contributionSummary?: CompareAndRank;
    lendingStat?: CompareAndRank;
  };
  timeline: {
    planStartDate?: CompareAndRank;
    durationOfPassiveIncome?: CompareAndRank;
    contributionEndsOn?: CompareAndRank;
    cashflowEndsOn?: CompareAndRank;
  };
  financialFreedomDayNumbers: {
    ffdLocSize?: CompareAndRank;
    ffdUpb?: CompareAndRank;
    ffdTotalCashflow?: CompareAndRank;
    ffdTotalAssetsCreated?: CompareAndRank;
  };
  numbers: {
    principalInvested?: CompareAndRank;
    locSize?: CompareAndRank;
    flipCount?: CompareAndRank;
    netWorth?: CompareAndRank;
  };
}

export interface OptimizerResultWithAnalysis {
  optimizerResult: OptimizerResult;
  compareAndRankResult: SuggestedPlanJobCompareAndRankResult;
  scores: {
    allScores: OptimizerResultScore[];

    ffd: OptimizerResultScore[];
    collapsed_ffd: OptimizerResultScore[];
    timeline: OptimizerResultScore[];
    collapsed_timeline: OptimizerResultScore[];
    numbers: OptimizerResultScore[];
    collapsed_numbers: OptimizerResultScore[];
    others: OptimizerResultScore[];
  };
}

export type StatusColorType =
  | 'green'
  | 'red'
  | 'yellow'
  | 'dollar-amount'
  | 'normal-text';

export interface OptimizerResultScore {
  label: string;
  value: string | null | undefined;
  color?: StatusColorType;
  compareAndRank: CompareAndRank | undefined;
  tooltipMessage?: string;
  placesToDisplay: Partial<
    Record<
      | 'numbers'
      | 'timeline'
      | 'ffd'
      | 'others'
      | 'collapsed_timeline'
      | 'collapsed_numbers'
      | 'collapsed_ffd',
      number
    >
  >;
}

const rankInvestmentParameter = (
  optimizerResultsWithAnalysis: OptimizerResultWithAnalysis[],
  valueExtractor: (investmentState: OptimizerResult) => number | undefined,
  rankAssigner: (
    compareAndRankResult: SuggestedPlanJobCompareAndRankResult,
    rank: CompareAndRank
  ) => void,
  rankingMode: RankingMode
) => {
  const jobResultsWithValue = optimizerResultsWithAnalysis.filter((o) =>
    isNumberValid(valueExtractor(o.optimizerResult))
  );
  return rankAndColorNumbers(
    jobResultsWithValue.map((i) => valueExtractor(i.optimizerResult) as number),
    rankingMode
  ).forEach((r, index) => {
    rankAssigner(jobResultsWithValue[index].compareAndRankResult, r);
  });
};

const calculateResultScores = (
  job: Job,
  optimizerResult: OptimizerResult,
  compareAndRankResult: SuggestedPlanJobCompareAndRankResult
): OptimizerResultScore[] => {
  const financialFreedomDay = optimizerResult.arrayMsgs.freedom_date;
  const financialFreedomIn = optimizerResult.arrayMsgs.freedom_in;

  const freedomTotalMonths = optimizerResult.arrayMsgs.freedom_total_months;
  const freedomTotalYears = optimizerResult.arrayMsgs.freedom_total_years;
  const contributionIn = optimizerResult.arrayMsgs.contribution_in;
  const contributionDate = optimizerResult.arrayMsgs.contribution_date;

  const ffdLocSize = optimizerResult.arrayMsgs.freedom_loc_size;
  const ffdUpb = optimizerResult.arrayMsgs.freedom_upb;
  const ffdCashflow = optimizerResult.arrayMsgs.total_cf_for_month;
  const ffdAssetsCreated = optimizerResult.arrayMsgs.freedom_assets_created;
  const locSize = optimizerResult.arrayMsgs.loc_size;
  const principalInvested = optimizerResult.arrayMsgs.principal_invested;
  const flipCount = optimizerResult.arrayMsgs.flip_count;

  const finalLoanUpb = optimizerResult.arrayMsgs.final_loan_upb;
  const finalLocSize = optimizerResult.arrayMsgs.final_loc_size;
  const finalNetWorth = optimizerResult.arrayMsgs.final_net_worth;
  /*const finalNetWorth =
    isNumberValid(finalLoanUpb) && isNumberValid(finalLocSize)
      ? finalLoanUpb - finalLocSize
      : undefined;*/

  const algorithmMethod = getAlgorithmMethodName(
    optimizerResult.inputs?.methodOfAdjustingLOC
  );

  const planStartDate = job.inputs?.startDate
    ? new Date(job.inputs?.startDate)
    : undefined;

  const pcCounters = `${optimizerResult.arrayMsgs.phase1_count}, ${optimizerResult.arrayMsgs.phase2_count}, ${optimizerResult.arrayMsgs.phase3_count}`;

  const scores: OptimizerResultScore[] = [
    {
      label: 'Plan Start Date',
      value: planStartDate
        ? toMMMDDYYYYDateFormat(planStartDate)
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'The day when you this income plan starts',
      placesToDisplay: { timeline: 1 },
      compareAndRank: compareAndRankResult.timeline.planStartDate,
    },
    {
      label: 'Financial Freedom Day',
      value: financialFreedomDay
        ? `${financialFreedomDay} (${financialFreedomIn})`
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'The day when you can retire',
      placesToDisplay: { timeline: 1, collapsed_timeline: 1 },
      compareAndRank: compareAndRankResult.summary.financialFreedomDay,
    },
    {
      label: 'Duration of Passive Income',
      value:
        freedomTotalYears && isNumberValid(freedomTotalMonths)
          ? `${freedomTotalYears} (${freedomTotalMonths}
        months)`
          : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Duration of retirement that this plan supports',
      placesToDisplay: { timeline: 2 },
      compareAndRank: compareAndRankResult.timeline.durationOfPassiveIncome,
    },
    {
      label: 'Contribution Ends On',
      value:
        contributionIn && contributionDate
          ? `${contributionDate} (${contributionIn})`
          : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Date at which contribution ends',
      placesToDisplay: { timeline: 3 },
      compareAndRank: compareAndRankResult.timeline.contributionEndsOn,
    },
    {
      label: 'Cashflow Ends On',
      value: freedomTotalYears
        ? freedomTotalYears
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'Date at which cashflow ends on',
      placesToDisplay: { timeline: 4 },
      compareAndRank: compareAndRankResult.timeline.cashflowEndsOn,
    },

    {
      label: 'Financial Freedom Day LOC Size',
      value: isNumberValid(ffdLocSize)
        ? transformUSDCurrencyNoDecimals(ffdLocSize)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 1 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdLocSize,
    },
    {
      label: 'Financial Freedom Day UPB',
      value: isNumberValid(ffdUpb)
        ? transformUSDCurrencyNoDecimals(ffdUpb)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 2, collapsed_ffd: 1 },
      compareAndRank: compareAndRankResult.financialFreedomDayNumbers.ffdUpb,
    },
    {
      label: 'Financial Freedom Day Cashflow',
      value: isNumberValid(ffdCashflow)
        ? transformUSDCurrencyNoDecimals(ffdCashflow)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 3 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdTotalCashflow,
    },
    {
      label: 'Financial Freedom Day Assets Created',
      value: isNumberValid(ffdAssetsCreated)
        ? transformUSDCurrencyNoDecimals(ffdAssetsCreated)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { ffd: 4 },
      compareAndRank:
        compareAndRankResult.financialFreedomDayNumbers.ffdTotalAssetsCreated,
    },
    {
      label: 'Principal Invested',
      value: isNumberValid(principalInvested)
        ? transformUSDCurrencyNoDecimals(principalInvested)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { numbers: 1, collapsed_numbers: 1 },
      compareAndRank: compareAndRankResult.numbers.principalInvested,
    },
    {
      label: 'Starting LOC Size',
      value: isNumberValid(locSize)
        ? transformUSDCurrencyNoDecimals(locSize)
        : MissingValuePlaceholderText,
      color: 'dollar-amount',
      tooltipMessage: 'Starting Line of Credit Size',
      placesToDisplay: { numbers: 2 },
      compareAndRank: compareAndRankResult.numbers.locSize,
    },
    {
      label: 'Flip Count',
      value: isNumberValid(flipCount)
        ? `${flipCount}`
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { numbers: 3 },
      compareAndRank: compareAndRankResult.numbers.flipCount,
    },
    {
      label: 'Final Net worth',
      value: isNumberValid(finalNetWorth)
        ? transformUSDCurrencyNoDecimals(finalNetWorth)
        : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: `TODO // TODO Final Net Worth = (Final UPB - LOC Balance) i.e, ${
        isNumberValid(finalNetWorth)
          ? transformUSDCurrencyNoDecimals(finalNetWorth)
          : MissingValuePlaceholderText
      } = ${
        isNumberValid(finalLoanUpb)
          ? transformUSDCurrencyNoDecimals(finalLoanUpb)
          : MissingValuePlaceholderText
      } - ${
        isNumberValid(finalLocSize)
          ? transformUSDCurrencyNoDecimals(finalLocSize)
          : MissingValuePlaceholderText
      }`,
      placesToDisplay: { numbers: 6 },
      compareAndRank: compareAndRankResult.numbers.netWorth,
    },
    {
      label: 'Algorithm Method',
      value: algorithmMethod ? algorithmMethod : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: 'TODO // TODO',
      placesToDisplay: { others: 1 },
      compareAndRank: undefined,
    },
    {
      label: 'PCounters',
      value: pcCounters ? pcCounters : MissingValuePlaceholderText,
      color: 'normal-text',
      tooltipMessage: '(For internal use only)',
      placesToDisplay: { others: 2 },
      compareAndRank: undefined,
    },
  ];
  return scores;
};

export const calculateCompareAndRankResultsForOptimizerResults = (job: Job) => {
  const optimizerResultsWithAnalysis: OptimizerResultWithAnalysis[] = (
    job.results?.topresults ?? []
  ).map((r) => {
    return {
      optimizerResult: r,
      compareAndRankResult: {
        timeline: {},
        summary: {},
        numbers: {},
        financialFreedomDayNumbers: {},
      },
      scores: {
        allScores: [],

        ffd: [],
        collapsed_ffd: [],
        timeline: [],
        collapsed_timeline: [],
        numbers: [],
        collapsed_numbers: [],
        others: [],
      },
    };
  });

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) =>
      result.arrayMsgs.freedom_date
        ? new Date(result.arrayMsgs.freedom_date).getTime()
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.financialFreedomDay = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.final_net_worth,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.netWorthAtEndOfPlan = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.contribution_months,
    (compareAndRankResult, r) =>
      (compareAndRankResult.summary.contributionSummary = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.flip_count,
    (compareAndRankResult, r) => (compareAndRankResult.summary.lendingStat = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.final_loc_size,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdLocSize = r),
    'more-is-better'
  );
  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.final_loan_upb,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdUpb = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.freedom_assets_created,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdTotalAssetsCreated =
        r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.total_cf_for_month,
    (compareAndRankResult, r) =>
      (compareAndRankResult.financialFreedomDayNumbers.ffdTotalCashflow = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.principal_invested,
    (compareAndRankResult, r) =>
      (compareAndRankResult.numbers.principalInvested = r),
    'less-is-better'
  );
  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) =>
      isNumberValid(result.arrayMsgs.final_loan_upb) &&
      isNumberValid(result.arrayMsgs.final_loc_size)
        ? result.arrayMsgs.final_loan_upb - result.arrayMsgs.final_loc_size
        : undefined,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.netWorth = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.loc_size,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.locSize = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.flip_count,
    (compareAndRankResult, r) => (compareAndRankResult.numbers.flipCount = r),
    'more-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.freedom_total_months,
    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.durationOfPassiveIncome = r),
    'more-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result) =>
      job.inputs?.startDate
        ? new Date(job.inputs?.startDate).getTime()
        : undefined,
    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.planStartDate = r),
    'less-is-bluer'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) =>
      result.arrayMsgs.contribution_date
        ? new Date(result.arrayMsgs.contribution_date).getTime()
        : undefined,

    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.contributionEndsOn = r),
    'less-is-better'
  );

  rankInvestmentParameter(
    optimizerResultsWithAnalysis,
    (result: OptimizerResult) => result.arrayMsgs.freedom_total_months,

    (compareAndRankResult, r) =>
      (compareAndRankResult.timeline.cashflowEndsOn = r),
    'more-is-better'
  );

  optimizerResultsWithAnalysis.forEach((or) => {
    const allScores = calculateResultScores(
      job,
      or.optimizerResult,
      or.compareAndRankResult
    );
    or.scores = {
      allScores,

      ffd: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.ffd))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.ffd ?? 0) - (r2.placesToDisplay.ffd ?? 0)
        ),
      collapsed_ffd: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_ffd))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_ffd ?? 0) -
            (r2.placesToDisplay.collapsed_ffd ?? 0)
        ),
      timeline: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.timeline))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.timeline ?? 0) -
            (r2.placesToDisplay.timeline ?? 0)
        ),
      collapsed_timeline: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_timeline))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_timeline ?? 0) -
            (r2.placesToDisplay.collapsed_timeline ?? 0)
        ),
      numbers: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.numbers))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.numbers ?? 0) -
            (r2.placesToDisplay.numbers ?? 0)
        ),
      collapsed_numbers: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.collapsed_numbers))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.collapsed_numbers ?? 0) -
            (r2.placesToDisplay.collapsed_numbers ?? 0)
        ),
      others: allScores
        .filter((r) => isNumberValid(r.placesToDisplay.others))
        .sort(
          (r1, r2) =>
            (r1.placesToDisplay.others ?? 0) - (r2.placesToDisplay.others ?? 0)
        ),
    };
  });

  return optimizerResultsWithAnalysis;
};
