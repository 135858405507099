<ng-container *ngIf="client && client.plan && client.plan.inputs">
  <h3 class="client-sub-page-header">Dashboard</h3>

  <div nz-row [nzGutter]="[16, 16]">
    <div nz-col nzSpan="8">
      <nz-card [nzTitle]="client.alias + ' Plan'" [nzExtra]="extraTitle">
        <table>
          <tr>
            <td class="c1-label">Contribution</td>
            <td class="c1-value">
              {{
                client.plan.inputs.monthlyContributionAmount
                  | currency: "USD":"$":"1.0-2"
              }}/month
            </td>
          </tr>
          <tr>
            <td class="c1-label">Time</td>
            <td class="c1-value">{{ clientFreedomIn }}</td>
          </tr>
          <tr>
            <td class="c1-label">Financial Freedom Date</td>
            <td class="c1-value">
              {{ clientFreedomDate | date: "mediumDate" }}
            </td>
          </tr>
          <tr>
            <td class="c1-label">Income Goal</td>
            <td class="c1-value">
              {{
                client.plan.inputs.monthlyIncomeGoal
                  | currency: "USD":"$":"1.0-2"
              }}/month
            </td>
          </tr>
        </table>
      </nz-card>
    </div>
    <div nz-col nzSpan="16">
      <app-timeline [client]="client"></app-timeline>
      <!--nz-card
        [nzBorderless]="true"
        style="height: 100%; background-color: #060a2b; color: white"
      >
        <h5 style="color: white">Financial Freedom in</h5>
        <div class="c2-freedom-date">
          {{ clientFreedomIn }}
        </div>
        <div
          style="
            display: flex;
            width: 100%;
            height: 100%;
            align-items: flex-end;
            color: white;
          "
        >
          <div style="flex-grow: 1">
            <div class="c2-date-label">
              <span *ngIf="todayAfterStart; else today">Start Date</span>
            </div>
            <div class="c2-date-value">
              <span *ngIf="todayAfterStart; else todayD">{{
                client.plan.inputs.startDate | date: "mediumDate"
              }}</span>
            </div>
            <div class="bar-phase-1"></div>
          </div>
          <ng-template #today> Today </ng-template>
          <ng-template #todayD>
            {{ todayDate | date: "mediumDate" }}
          </ng-template>
          <div style="flex-grow: 1">
            <div class="c2-date-label">
              <span *ngIf="!todayAfterStart; else today">Start Date</span>
            </div>
            <div class="c2-date-value">
              <span *ngIf="!todayAfterStart; else todayD">{{
                client.plan.inputs.startDate | date: "mediumDate"
              }}</span>
            </div>
            <div class="bar-phase-2"></div>
          </div>
          <div style="flex-grow: 1">
            <div class="c2-date-label">Freedom Date</div>
            <div class="c2-date-value">
              {{ clientFreedomDate | date: "mediumDate" }}
            </div>
            <div class="bar-phase-3"></div>
          </div>
        </div>
      </nz-card-->
    </div>
    <div nz-col nzSpan="16">
      <nz-card style="height: 100%">
        <div nz-row>
          <div nz-col nzSpan="12">
            <h3>Financial Freedom Date</h3>
            <div class="c3-date">
              {{ clientFreedomDate | date: "mediumDate" }}
            </div>
            <p class="c3-paragraph">
              Your Financial Freedom Date is
              {{ clientFreedomIn }} from today. This is when your projected cash
              flow will generate the desired amount of
              {{ clientMonthlyIncomeGoal | currency: "USD":"$":"1.0-2" }}
              per month in passive income to cover your living expenses.
            </p>
          </div>
          <div nz-col nzSpan="11" nzOffset="1" class="c3-rightside">
            <h5>Financial Freedom Day</h5>
            <table style="width: 100%">
              <tr>
                <td class="c3-label">Unpaid Balance</td>
                <td class="c3-value">
                  {{ clientFreedomUpb | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
              <tr>
                <td class="c3-label">Assets</td>
                <td class="c3-value">
                  {{ clientFreedomAssetsCreated | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
              <tr>
                <td class="c3-label">LOC Size</td>
                <td class="c3-value">
                  {{ clientFreedomLocSize | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
              <tr>
                <td class="c3-label">Total Cash Flow</td>
                <td class="c3-value">
                  {{ clientTotalCashflow | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
              <tr>
                <td class="c3-label">Principal Invested</td>
                <td class="c3-value">
                  {{ clientPrincipalInvested | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
              <tr>
                <td class="c3-label">Income Goal</td>
                <td class="c3-value">
                  {{ clientIncomeGoal | currency: "USD":"$":"1.0-2" }}
                </td>
              </tr>
            </table>
            <div style="text-align: right">
              <a
                routerLink="clientPlanRouterLink"
                [queryParams]="{ mode: 'view' }"
                >More</a
              >
            </div>
          </div>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="8">
      <nz-card style="height: 100%">
        <div style="display: flex; align-items: top">
          <h5>Your Next Five Flips</h5>
          <div class="c4-righttitle">
            Projected to have {{ clientFlipCount }} flips in
            {{ clientSimYears }} years
          </div>
        </div>
        <div *ngIf="client.plan.results">
          <table style="width: 100%">
            <thead>
              <tr>
                <th>Flip No.</th>
                <th>Date</th>
                <th style="text-align: right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let flip of clientFlipSchedule; let i = index"
              >
                <tr *ngIf="i < 5">
                  <td>{{ i + 1 | number: "3.0-0" }}</td>
                  <td>{{ flip.date | date: "mediumDate" }}</td>
                  <td style="text-align: right">
                    {{ flip.loc_size | currency: "USD":"$":"1.0-2" }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div style="text-align: right">
            <a routerLink="/plan" [queryParams]="{ mode: 'view' }">More</a>
          </div>
        </div>
      </nz-card>
    </div>
    <div nz-col nzSpan="12">
      <nz-card [nzTitle]="'Plan Monthly Cashflow'" style="height: 100%">
        <app-cash-flow [client]="client"></app-cash-flow>
      </nz-card>
      <!--nz-card style="height: 100%">

        <div>
          <h5>Cash Flow</h5>
        </div>
        <div>
          <h5>LOC</h5>
        </div>
      </nz-card-->
    </div>
    <div nz-col nzSpan="12">
      <nz-card
        style="height: 100%"
        nzTitle="Scheduled Transactions"
        [nzExtra]="extraTemplate"
      >
        <div *ngIf="!client.transactions || client.transactions.length == 0">
          No Transactions
        </div>
        <table
          *ngIf="client.transactions && client.transactions.length > 0"
          style="width: 100%"
        >
          <thead>
            <th>Date</th>
            <th>Event</th>
            <th>Type</th>
            <th>Amount</th>
          </thead>
          <tr *ngFor="let transaction of client.transactions">
            <td>{{ transaction.startDate | date: "mediumDate" }}</td>
            <td>{{ transaction.title }}</td>
            <td>
              <nz-tag *ngIf="transaction.type == 'DIVERT'" nzColor="#060A2B">
                Divert
              </nz-tag>
              <nz-tag *ngIf="transaction.type == 'DEPOSIT'" nzColor="#06A7F5">
                Deposit
              </nz-tag>
              <nz-tag *ngIf="transaction.type == 'DRAW'" nzColor="#060A2B">
                Draw
              </nz-tag>
            </td>
            <td>
              {{ transaction.amount | currency: "USD":"$":"1.0-2" }}
            </td>
            <td>
              <button
                nz-button
                nzType="primary"
                (click)="editEvent(transaction)"
              >
                Edit
              </button>
            </td>
          </tr>
        </table>
      </nz-card>
      <ng-template #extraTemplate>
        <a (click)="addEvent()">Schedule New Event</a>
      </ng-template>
    </div>
    <div nz-col nzSpan="24">
      <app-dashboard-data [client]="client"></app-dashboard-data>
    </div>
  </div>

  <nz-modal
    [(nzVisible)]="addEventVisible"
    [nzTitle]="extraHeader"
    [nzFooter]="customFooter"
    (nzOnCancel)="cancelAddEvent()"
    (nzOnOk)="okAddEvent()"
  >
    <ng-container *nzModalContent>
      <nz-form-item>
        <nz-form-label>Transaction Type</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-select [(ngModel)]="transactionType" style="width: 120px">
            <nz-option nzValue="DRAW" nzLabel="Draw"></nz-option>
            <nz-option nzValue="DEPOSIT" nzLabel="Deposit"></nz-option>
            <nz-option nzValue="DIVERT" nzLabel="Divert"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Transaction Title</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <input nz-input type="text" [(ngModel)]="transactionTitle" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label
          >Amount to <span *ngIf="transactionType == 'DIVERT'">Divert</span
          ><span *ngIf="transactionType == 'DEPOSIT'">Deposit</span
          ><span *ngIf="transactionType == 'DRAW'">Draw</span></nz-form-label
        >
        <nz-form-control [nzSpan]="24">
          <nz-input-number
            [(ngModel)]="transactionAmount"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Start Date</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-date-picker
            [(ngModel)]="transactionStartDate"
            style="margin-bottom: 0px"
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Repeat Every</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-input-number
            [(ngModel)]="transactionRepeatEvery"
          ></nz-input-number>
          <nz-select
            *ngIf="transactionRepeatEvery > 0"
            [(ngModel)]="transactionRepeatUnits"
            style="width: 120px; margin-left: 20px"
          >
            <nz-option
              nzValue="months"
              [nzLabel]="transactionRepeatEvery > 1 ? 'Months' : 'Month'"
            ></nz-option>
            <nz-option
              nzValue="years"
              [nzLabel]="transactionRepeatEvery > 1 ? 'Years' : 'Year'"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div>Ends:</div>
      <nz-radio-group [(ngModel)]="transactionEnding" class="radios">
        <label nz-radio nzValue="NE">Never Ending</label>
        <label nz-radio nzValue="EO"
          >End On
          <nz-date-picker
            *ngIf="transactionEnding === 'EO'"
            [(ngModel)]="transactionEndDate"
            style="margin-left: 20px; margin-bottom: 10px"
          ></nz-date-picker
        ></label>
        <label nz-radio nzValue="EA"
          >End After
          <span *ngIf="transactionEnding === 'EA'"
            ><input type="text" nz-input [(ngModel)]="transactionOccurences" />
            occurences</span
          ></label
        >
      </nz-radio-group>
    </ng-container>
  </nz-modal>
  <ng-template #customFooter>
    <button
      *ngIf="isEdittingEvent"
      nz-button
      nzType="primary"
      nzDanger
      (click)="deleteEvent()"
    >
      Delete
    </button>
    <button nz-button nzType="default" (click)="cancelAddEvent()">
      Cancel
    </button>
    <button nz-button nzType="primary" (click)="okAddEvent()">OK</button>
  </ng-template>
  <ng-template #extraHeader>
    <span *ngIf="transactionType == 'DEPOSIT'">Deposit Money</span
    ><span *ngIf="transactionType == 'DIVERT'">Divert Money</span
    ><span *ngIf="transactionType == 'DRAW'">Draw Money</span>
  </ng-template>
  <ng-template #extraTitle>
    <nz-tag
      *ngIf="client.plan && !client.plan.optimizer"
      [nzColor]="'red'"
      style="margin-left: 5px"
      >Plan Not Optimized</nz-tag
    ><!--nz-tag *ngIf="client.plan && client.plan.optimizer === 'QUICK'" [nzColor]="'green'" style="margin-left: 5px">Quick Optimized</nz-tag--><nz-tag
      *ngIf="client.plan && client.plan.optimizer === 'THOROUGH'"
      [nzColor]="'green'"
      style="margin-left: 5px"
      >Optimized</nz-tag
    >
    <nz-tag
      *ngIf="client.plan && client.plan.optimizerLevel == 'HIGH'"
      [nzColor]="'green'"
      style="margin-left: 5px"
      >Quick Optimized: High</nz-tag
    ><nz-tag
      *ngIf="client.plan && client.plan.optimizerLevel === 'MEDIUM'"
      [nzColor]="'yellow'"
      style="margin-left: 5px"
      >Quick Optimized: Medium</nz-tag
    ><nz-tag
      *ngIf="client.plan && client.plan.optimizerLevel === 'LOW'"
      [nzColor]="'red'"
      style="margin-left: 5px"
      >Quick Optimized: Low</nz-tag
    >
  </ng-template>
</ng-container>
