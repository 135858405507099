import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-shares',
  templateUrl: './shares.component.html',
  styleUrls: ['./shares.component.scss']
})
export class SharesComponent implements OnInit {

  @Input() client?: Client;

  shares?: any[];

  addShareVisible = false;
  isAddingShare = false;

  deleteShareVisible = false;
  isDeletingShare = false;
  shareToDelete: any = null;

  shareEmail = "";

  constructor(private httpClient: HttpClient, private message: NzMessageService, public authService: AuthService) { 
  }

  ngOnInit(): void {
    this.httpClient.get('/api/clients/' + this.client!.id + '/shares',{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Access-Token', `Bearer ${this.authService.token}`),
    }).subscribe((result: any)=> {
      this.shares = result;
    });
  }

  addShare() {
    this.addShareVisible = true;
    this.isAddingShare = true;
  }

  cancelAddShare() {
    this.addShareVisible = false;
    this.isAddingShare = false;
  }

  okAddShare() {
    if (this.isAddingShare) {
      this.addShareVisible = false;
      this.isAddingShare = false;
      let share = {
        email: this.shareEmail
      };
      this.httpClient
        .post(
          '/api/clients/' + this.client!.id + '/shares',
          share,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            if (!this.shares || !Array.isArray(this.shares)) {
              this.shares = [];
            }
            console.log("Pushing the share");
            this.shares.push(result.share);
            console.log(JSON.stringify(this.shares));
            this.message.create('success', 'Added share for coach/advisor with the provided email'); //'Email sent to initiate transfer to coach/advisor.');
          } else {
            this.message.create('error', 'Could not find any coach/advisor with the provided email!');
          }
          if (result.result === 0) {
            
          }
        });
    }
  }

  deleteShare(share: any) {
    this.shareToDelete = share;
    this.deleteShareVisible = true;
  }

  cancelDeleteShare() {
    this.deleteShareVisible = false;
  }

  okDeleteShare() {
    this.deleteShareVisible = false;
    this.httpClient
    .delete(
      '/api/clients/' +
        this.client!.id +
        '/shares/' +
        this.shareToDelete.id,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('X-Access-Token', `Bearer ${this.authService.token}`),
      }
    )
    .subscribe((result: any) => {
      if (result.result == 0) {

        let found = -1;
        let index = 0;
        for (let sh of this.shares!) {
          if (sh.id && sh.id === this.shareToDelete.id) {
            found = index;
          }
          index++;
        }
        if (found !== -1) {
          this.shares!.splice(found, 1);
        }
      }
    });
  }

}
