<div class="empty-state-section">
  <div *ngIf="placeholderImage" class="empty-state-section__placeholder-image">
    <ng-container *ngTemplateOutlet="placeholderImage">{{
      placeholderImage
    }}</ng-container>
  </div>

  <div class="empty-state-section__heading">
    <i
      *ngIf="showPrefixTitleInfoIcon"
      nz-icon
      nzType="info-circle"
      nzTheme="outline"
      class="empty-state-section__heading__icon"
    ></i>

    <h3 class="empty-state-section__heading__title">{{ title }}</h3>
  </div>
  <p *ngIf="description" class="empty-state-section__description">
    {{ description }}
  </p>
  <div class="empty-state-section__actions">
    <ng-content select="[emptyStateActions]"></ng-content>
  </div>
</div>
