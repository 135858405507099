import { PlanReport } from 'src/app/models/client';

export const openReportPdfInNewTab = (report: PlanReport) => {
  const reportUrlSearchParams = new URLSearchParams();
  reportUrlSearchParams.set('reportTile', report.title ?? '');
  reportUrlSearchParams.set(
    'reportDate',
    report.date ? new Date(report.date).toISOString() : ''
  );
  const reportUrl = `/reports/${
    report.id
  }.pdf?${reportUrlSearchParams.toString()}`;
  window.open(reportUrl, '_blank');
};
