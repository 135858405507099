import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/client';
import { OptimizerSettings } from 'src/app/models/optimizer-settings';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  DefaultSimulationName,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { OptimizerStepData } from './steps/fop-step-optimizer-settings/fop-step-optimizer-settings.component';

interface Step {
  name: string;
  disabled: boolean;
  valid: boolean;
}

@Component({
  selector: 'app-find-optimized-plan-modal',
  templateUrl: './find-optimized-plan-modal.component.html',
  styleUrls: ['./find-optimized-plan-modal.component.scss'],
})
export class FindOptimizedPlanModalComponent implements OnInit {
  modalTitle = 'Find Optimized Income Plan';

  @Input() client!: Client;

  inputs?: PlanInputs;

  @Output() cancelOperation = new EventEmitter<void>();
  @Output() operationSubmitted = new EventEmitter<void>();

  submitCreateApiCall: AsyncOpData<void>;

  AsyncOpState = AsyncOpState;

  stepState: {
    steps: Step[];
    activeStep: number;
  };

  optimizerStepData?: OptimizerStepData;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {
    this.submitCreateApiCall = {
      state: AsyncOpState.NotStarted,
    };

    this.stepState = {
      steps: [`Goals`, 'Lending', 'Algorithm', 'Optimizer'].map(
        (stepName, i) => ({
          name: stepName,
          valid: false,
          disabled: i !== 0,
        })
      ),
      activeStep: 0,
    };
  }

  ngOnInit() {
    this.setupInputs();
  }

  setupInputs() {
    if (this.client?.plan?.inputs) {
      this.inputs = Object.assign({}, this.client.plan.inputs);
      if (this.inputs.enableAutoIncrement === undefined) {
        this.inputs.enableAutoIncrement = false;
      }
    }
  }

  get nextBtnDisabled() {
    const { stepState } = this;
    return !stepState.steps[stepState.activeStep].valid;
  }

  get showNextBtn() {
    const { optimizerSettingsStepActive } = this;
    return !optimizerSettingsStepActive;
  }

  get showCreateDaAnalysisBtn() {
    const { stepState } = this;
    return stepState.activeStep === stepState.steps.length - 1;
  }

  get clientGoalStepActive() {
    return this.stepState.activeStep === 0;
  }

  get lendingSettingsStepActive() {
    return this.stepState.activeStep === 1;
  }

  get algoSettingsStepActive() {
    return this.stepState.activeStep === 2;
  }

  get optimizerSettingsStepActive() {
    return this.stepState.activeStep === 3;
  }

  onIndexChange(newIndex: number) {
    this.stepState.activeStep = newIndex;
  }

  handleCancel() {
    this.cancelOperation.emit();
  }

  onOptimizerDataChange(newOptimizerData: OptimizerStepData) {
    this.optimizerStepData = newOptimizerData;
  }

  handleSubmit() {
    this.submitCreateApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.client.searchCounter = (this.client.searchCounter ?? 0) + 1;
    this.httpClient
      .post(
        '/api/clients/' + this.client!.id + '/jobs',
        this.optimizerStepData?.job,
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (result) => {
          this.httpClient
            .post('/engine/optimizer2', this.optimizerStepData?.optimizerBody)
            .subscribe(
              () => {
                this.submitCreateApiCall = {
                  state: AsyncOpState.Completed,
                };
                this.operationSubmitted.emit();
              },
              () => {
                this.submitCreateApiCall = {
                  state: AsyncOpState.Failed,
                  error: 'Error! Submitting optimizer job failed',
                };
              }
            );
        },
        () => {
          this.submitCreateApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error! Submitting optimizer job failed',
          };
        }
      );
  }

  goToNextStep() {
    const { stepState } = this;

    stepState.steps[stepState.activeStep].valid = true;
    stepState.steps[stepState.activeStep + 1].disabled = false;
    stepState.activeStep += 1;
  }

  get createAnalysisApiInprogress() {
    return this.submitCreateApiCall.state === AsyncOpState.Inprogress;
  }

  toStepStatus(stepIndex: number) {
    const { activeStep } = this.stepState;
    return activeStep > stepIndex
      ? 'finish'
      : activeStep === stepIndex
      ? 'process'
      : 'wait';
  }

  onActiveStepValidityChange(valid: boolean) {
    const { stepState } = this;
    stepState.steps[stepState.activeStep].valid = valid;
  }
}
