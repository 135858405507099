<div>
    <div *ngIf="!loans || loans.length === 0">
        No Loans
    </div>
    <table *ngIf="loans && loans.length > 0" style="width: 100%; overflow-x: scroll">
        <thead>
            <tr>
                <th>Flip No.</th>
                <th>Loan No.</th>
                <th>Terms</th>
                <th>Payments</th>
            </tr>
        </thead>
        <tr *ngFor="let loan of loans">
            <td><span *ngIf="loan.flipNo">{{ loan.flipNo }}</span></td>
            <td><span *ngIf="loan.loanNo">{{ loan.loanNo }}</span></td>
            <td>{{ loan.termsInYear }} years at {{ (loan.interest / 100) | percent }}</td>
            <td *ngFor="let payment of loan.payments">
                {{ payment.month }}: {{ payment.amount | currency }}
            </td>
        </tr>
    </table>
    <div *ngIf="client && client.spreadsheetErrors">
        <h2>Spreadsheet Errors</h2>
        <div *ngFor="let error of client.spreadsheetErrors">
            {{ error }}
        </div>
    </div>
</div>