import { Component, Input } from '@angular/core';
import { toCompareAndRankDisplayText } from 'src/app/utils/app-common';
import { CompareAndRank } from 'src/app/utils/common-app-types';

@Component({
  selector: 'app-key-metric-badge',
  templateUrl: './key-metric-badge.component.html',
  styleUrls: ['./key-metric-badge.component.scss'],
})
export class KeyMetricBadgeComponent {
  @Input() compareAndRank?: CompareAndRank;

  @Input() showCompareAndRank?: boolean;

  @Input() tooltipMessage?: string;

  toCompareAndRankDisplayText = toCompareAndRankDisplayText;

  constructor() {}
}
