import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import {
  formatDifferenceForDate,
  toMMMDDYYYYDateFormat,
} from 'src/app/utils/date-utils';
import { isNumberValid } from 'src/app/utils/number-utils';
import { RouteClientPlan } from 'src/app/utils/route-constants';
import {
  transformPercentToDisplayString,
  transformUSDCurrency,
  transformUSDCurrencyNoDecimals,
} from 'src/app/utils/transform-currency';
import {
  checkIfClientHasIncomePlan,
  getAlgorithmMethodName,
} from '../../home/client-utils';

@Component({
  selector: 'app-plan-settings',
  templateUrl: './plan-settings.component.html',
  styleUrls: ['./plan-settings.component.scss'],
})
export class PlanSettingsComponent {
  @Input() client!: Client;

  editPlanModalOpen = false;

  constructor(private router: Router) {}

  get clientPassiveIncomeFormatted() {
    const monthlyIncomeGoal =
      this.client?.plan?.inputs?.monthlyIncomeGoal !== undefined
        ? +this.client?.plan?.inputs?.monthlyIncomeGoal
        : undefined;
    return isNumberValid(monthlyIncomeGoal)
      ? transformUSDCurrencyNoDecimals(monthlyIncomeGoal)
      : MissingValuePlaceholderText;
  }

  get clientMonthlyContributionFormatted() {
    const monthlyContributionAmount =
      this.client?.plan?.inputs?.monthlyContributionAmount !== undefined
        ? +this.client?.plan?.inputs?.monthlyContributionAmount
        : undefined;
    return isNumberValid(monthlyContributionAmount)
      ? transformUSDCurrencyNoDecimals(monthlyContributionAmount)
      : MissingValuePlaceholderText;
  }

  get clientPlanStartDateFormatted() {
    const planStartDate = this.client?.plan?.inputs?.startDate
      ? typeof this.client?.plan?.inputs?.startDate === 'string'
        ? new Date(this.client?.plan?.inputs?.startDate)
        : this.client?.plan?.inputs?.startDate
      : undefined;

    return planStartDate
      ? `${toMMMDDYYYYDateFormat(planStartDate)} (${formatDifferenceForDate(
          planStartDate
        )})`
      : MissingValuePlaceholderText;
  }

  get showInflationRateValue() {
    return (
      this.client?.plan?.inputs?.clientAdvanced === true &&
      this.client?.plan?.inputs?.inflationRate !== undefined &&
      isNumberValid(+this.client?.plan?.inputs?.inflationRate) &&
      +this.client?.plan?.inputs?.inflationRate > 0
    );
  }

  get clientPassiveIncomeInflationRateFormatted() {
    const inflationRate =
      this.client.plan?.inputs?.inflationRate !== undefined
        ? +this.client.plan?.inputs?.inflationRate
        : undefined;

    return isNumberValid(inflationRate)
      ? transformPercentToDisplayString(inflationRate / 100)
      : MissingValuePlaceholderText;
  }

  get clientPlanDurationFormatted() {
    const yearsToSimulate =
      this.client.plan?.inputs?.yearsToSimulate !== undefined
        ? +this.client.plan?.inputs?.yearsToSimulate
        : undefined;

    return isNumberValid(yearsToSimulate)
      ? `${yearsToSimulate} Years`
      : MissingValuePlaceholderText;
  }

  get locSizeFormatted() {
    const lineOfCreditSize =
      this.client.plan?.inputs?.lineOfCreditSize !== undefined
        ? +this.client.plan?.inputs?.lineOfCreditSize
        : undefined;
    const lineOfCreditInterestRate =
      this.client.plan?.inputs?.lineOfCreditInterestRate !== undefined
        ? +this.client.plan?.inputs?.lineOfCreditInterestRate
        : undefined;
    return isNumberValid(lineOfCreditSize)
      ? `${transformUSDCurrency(lineOfCreditSize)} at ${
          isNumberValid(lineOfCreditInterestRate)
            ? transformPercentToDisplayString(lineOfCreditInterestRate / 100)
            : MissingValuePlaceholderText
        } interest rate`
      : MissingValuePlaceholderText;
  }

  get showLendingAdvanced() {
    return this.client.plan?.inputs?.lendingAdvanced === true;
  }

  get projectedReturnFromLoansFormatted() {
    const loansRateOfReturn =
      this.client.plan?.inputs?.loansRateOfReturn !== undefined
        ? +this.client.plan?.inputs?.loansRateOfReturn
        : undefined;
    return isNumberValid(loansRateOfReturn)
      ? transformPercentToDisplayString(loansRateOfReturn / 100)
      : MissingValuePlaceholderText;
  }

  get loanTermFormatted() {
    const loansTermYears =
      this.client.plan?.inputs?.loansTerm !== undefined
        ? +this.client.plan?.inputs?.loansTerm
        : undefined;
    return `${
      isNumberValid(loansTermYears)
        ? loansTermYears
        : MissingValuePlaceholderText
    } years`;
  }

  get algorithmSelected() {
    const methodToUse =
      this.client.plan?.inputs?.methodToUse !== undefined
        ? +this.client.plan?.inputs?.methodToUse
        : undefined;
    return isNumberValid(methodToUse)
      ? getAlgorithmMethodName(methodToUse)
      : MissingValuePlaceholderText;
  }

  openEditPlanModal() {
    this.editPlanModalOpen = true;
  }
  closeEditPlanModal() {
    this.editPlanModalOpen = false;
  }

  get clientHasPlan() {
    return checkIfClientHasIncomePlan(this.client);
  }

  noPlanTitle = 'Income Plan is Not Setup';
  setupPlanBtnTxt = 'Setup Income Plan';
  noPlanDescription = `This client does not have an income plan configured yet.`;

  onEditPlanSuccess() {
    this.router.navigate([RouteClientPlan(this.client?.id)]);
  }
}
