import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import { OptimizerResults } from 'src/app/models/optimizer-results';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { compareDate } from 'src/app/utils/date-utils';
import { isNumberValid } from 'src/app/utils/number-utils';

@Component({
  selector: 'app-optimizer',
  templateUrl: './optimizer.component.html',
  styleUrls: ['./optimizer.component.scss'],
})
export class OptimizerComponent implements OnInit, OnDestroy {
  client?: Client;

  job?: Job;
  results?: any;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  format = (percent: number) => `${Math.floor(percent)}%`;

  mqqtSubscription?: Subscription;

  constructor(
    private mqttService: MqttService,
    private httpClient: HttpClient,
    private authService: AuthService,
    private advisorStateService: AdvisorStateService
  ) {
    this.fetchJobsApiCall = {
      state: AsyncOpState.NotStarted,
    };
    this.client = this.advisorStateService.getClient();
  }

  get jobsToShow() {
    return this.fetchJobsApiCall.data;
  }
  get hasJobsToShow() {
    return this.jobsToShow && this.jobsToShow.length > 0;
  }

  findOptimizedBtnText = 'Find Optimized Income Plan';
  emptyJobsTitle = 'No Optimizer Tasks to Show';
  emptyJobsDescription = `Let's optimize this client's current plan`;

  ngOnInit(): void {
    this.fetchJobs();
    this.setupJobInProgressPoll();
  }

  ngOnDestroy() {
    if (this.mqqtSubscription && !this.mqqtSubscription.closed) {
      this.mqqtSubscription.unsubscribe();
    }
  }

  private setupJobInProgressPoll() {
    this.mqqtSubscription = this.mqttService
      .observe(
        'USERS/' +
          this.authService.user!.id +
          '/CLIENTS/' +
          this.client!.id +
          '/JOBS/+'
      )
      .subscribe((message: IMqttMessage) => {
        let jobId = message.topic.substring(message.topic.lastIndexOf('/') + 1);
        let messageBody;
        try {
          messageBody = JSON.parse(message.payload.toString());
        } catch (e) {
          console.warn('Optimizer MQTT body json is not parsable', e);
          return;
        }

        const jobsShown = this.fetchJobsApiCall.data;
        const messageRelatedToJob = (jobsShown || []).find(
          (j) => j.id === jobId
        );

        if (messageRelatedToJob) {
          if (isNumberValid(messageBody.progress)) {
            const newProgress = isNumberValid(
              messageRelatedToJob.expectedIterations
            )
              ? Math.round(
                  100 *
                    (messageBody.progress /
                      messageRelatedToJob.expectedIterations)
                )
              : 0;
            messageRelatedToJob.progress = newProgress;
          } else {
            const results: OptimizerResults = messageBody;
            messageRelatedToJob.progress = 100;
            messageRelatedToJob.results = results;

            // TODO UI is assume completion time same as received time
            messageRelatedToJob.completed = new Date().toISOString();
          }
        } else {
          // Update for unknown job
        }
      });
  }

  onNewOptimizerJobSubmitted() {
    this.closeFindOptimizedPlanModal();
    this.fetchJobs(true);
  }

  get jobsFetchInProgress() {
    return this.fetchJobsApiCall.state === AsyncOpState.Inprogress;
  }
  get jobsFetchSuccess() {
    return this.fetchJobsApiCall.state === AsyncOpState.Completed;
  }
  get jobsFetchFailed() {
    return this.fetchJobsApiCall.state === AsyncOpState.Failed;
  }
  get jobsFetchErrorMessage() {
    return this.fetchJobsApiCall.error;
  }

  fetchJobsApiCall: AsyncOpData<Job[]>;

  fetchJobs(silentMode = false) {
    if (!silentMode) {
      this.fetchJobsApiCall = {
        state: AsyncOpState.Inprogress,
      };
    }
    this.httpClient
      .get<Job[] | undefined>('/api/clients/' + this.client?.id + '/jobs', {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (res) => {
          this.fetchJobsApiCall = {
            state: AsyncOpState.Completed,
            data: (res || []).sort((j1, j2) =>
              j1.started && j2.started
                ? compareDate(new Date(j2.started), new Date(j1.started))
                : 0
            ),
          };
        },
        (err) => {
          this.fetchJobsApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error failed to fetch optimizer jobs',
          };
        }
      );
  }

  deleteJobConfirmModal: {
    open: boolean;
    job?: Job;
  } = {
    open: false,
  };

  openDeleteJobConfirm(job: Job) {
    this.deleteJobConfirmModal = {
      open: true,
      job,
    };
  }

  cancelDeleteJob() {
    this.deleteJobConfirmModal = {
      open: false,
    };
  }

  deleteJobSuccess() {
    this.fetchJobsApiCall.data = (this.fetchJobsApiCall.data || []).filter(
      (a) => a.id !== this.deleteJobConfirmModal.job?.id
    );
    this.deleteJobConfirmModal = {
      open: false,
    };
    this.fetchJobs(true);
  }

  stopJobConfirmModal: {
    open: boolean;
    job?: Job;
  } = {
    open: false,
  };

  openStopJobConfirm(job: Job) {
    this.stopJobConfirmModal = {
      open: true,
      job,
    };
  }

  cancelStopJob() {
    this.stopJobConfirmModal = {
      open: false,
    };
  }

  stopJobSuccess() {
    this.fetchJobsApiCall.data = (this.fetchJobsApiCall.data || []).map((a) =>
      a.id !== this.stopJobConfirmModal.job?.id ? a : { ...a, cancelled: true }
    );
    this.stopJobConfirmModal = {
      open: false,
    };
    this.fetchJobs(true);
  }

  optimizedPlanModalOpen = false;

  openFindOptimizedPlanModal() {
    this.optimizedPlanModalOpen = true;
  }

  closeFindOptimizedPlanModal() {
    this.optimizedPlanModalOpen = false;
  }
}
