import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-empty-state-section',
  templateUrl: './empty-state-section.component.html',
  styleUrls: ['./empty-state-section.component.scss'],
})
export class EmptyStateSectionComponent {
  @Input() title!: string;
  @Input() description?: string;
  @Input() placeholderImage?: TemplateRef<any>;
  @Input() showPrefixTitleInfoIcon = false;

  constructor() {}
}
