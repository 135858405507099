import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setup-shadow-loans',
  templateUrl: './setup-shadow-loans.component.html',
  styleUrls: ['./setup-shadow-loans.component.scss']
})
export class SetupShadowLoansComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
