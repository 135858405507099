import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { getClientFullName } from 'src/app/components/home/client-utils';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import {
  NotificationDurationMs,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

@Component({
  selector: 'app-confirm-make-current-from-analysis',
  templateUrl: './confirm-make-current-from-analysis.component.html',
  styleUrls: ['./confirm-make-current-from-analysis.component.scss'],
})
export class ConfirmMakeCurrentFromAnalysisComponent {
  submitBtnDanger = false;

  @Input() client!: Client;
  @Input() analysisPlan!: AnalysisPlan;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Make Current Plan';
  modalTitle = 'Make Analysis Plan as Current Plan';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private notification: NzNotificationService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };

    this.httpClient
      .post('/api/clients/' + this.client!.id + '/plan', this.analysisPlan, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe(
        (result: any) => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.client!.plan = this.analysisPlan;

          this.notification.create(
            'success',
            'Success! Client Plan Changed',
            `Your action for marking a change in plan for client ${getClientFullName(
              this.client
            )} is completed.`,
            {
              nzDuration: NotificationDurationMs,
            }
          );

          this.operationSuccess.emit();
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to make this analysis plan as current plan',
          };
        }
      );
  }
}
