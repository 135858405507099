import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-inputs',
  templateUrl: './plan-inputs.component.html',
  styleUrls: ['./plan-inputs.component.scss'],
})
export class PlanInputsComponent {
  @Input() inputs: any;

  clientAdvanced = false;
  lendingAdvanced = false;
  algorithmAdvanced = false;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');
  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');

  constructor() {}
}
