import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss']
})
export class CashFlowComponent implements OnInit {

  @Input() client?: Client;
  @Input() pageSize = 4;

  cashFlows?: any[];

  constructor() { }

  ngOnInit(): void {
    if (this.client!.plan && this.client!.plan.results) {
      let results: any = this.client!.plan!.results;
      //let freedomMonth = results.msgs!.freedom_months;
      let simYears = results.msgs!.sim_years;
      //let simMonths = results.msgs!.sim_years * 12;
      //let passiveIncome = results.msgs!.passive_income;
      let index = 0;
      let cashFlows = [];
      while (index < simYears) {
        let data = results.data[(index * 12) + 6];
        cashFlows.push({year:index, cashFlow: data.cashflow, upb: data.upb_start});
        index ++ ;
      }
      this.cashFlows = cashFlows;
    }
  }

}
