import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client, PlanReport } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformUSDCurrencyNoDecimals } from 'src/app/utils/transform-currency';
import { getClientFullName } from '../../home/client-utils';
import { openReportPdfInNewTab } from '../report-utils';

@Component({
  selector: 'app-confirm-generate-plan-report',
  templateUrl: './confirm-generate-plan-report.component.html',
  styleUrls: ['./confirm-generate-plan-report.component.scss'],
})
export class ConfirmGeneratePlanReportComponent {
  submitBtnDanger = false;

  @Input() client!: Client;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<PlanReport>();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Generate Report';

  get modalTitle() {
    return `Generate Latest Income Plan Report for ${getClientFullName(
      this.client
    )}`;
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };

    let inputs = this.client!.plan!.inputs;
    inputs!.scheduledTransactions = this.client!.transactions;
    inputs!.deposits = this.client!.deposits;
    let client = {
      firstName: this.client!.firstName,
      lastName: this.client!.lastName,
      emailAddress: this.client!.emailAddress,
      address: this.client!.address,
      city: this.client!.city,
      state: this.client!.state,
      postalCode: this.client!.postalCode,
      dateFormat: this.client!.dateFormat ? this.client!.dateFormat : 'F Y',
    };
    this.httpClient
      .post(
        '/engine/report2',
        { inputs: inputs, client: client } /*,
        { responseType: "arraybuffer" }*/
      )
      .subscribe(
        (report: any) => {
          report.date = new Date().toISOString();
          report.title = `${getClientFullName(this.client)} Income Plan Report`;
          const monthlyContributionAmount =
            this.client?.plan?.inputs?.monthlyContributionAmount !== undefined
              ? +this.client?.plan?.inputs?.monthlyContributionAmount
              : undefined;
          const monthlyIncomeGoal =
            this.client?.plan?.inputs?.monthlyIncomeGoal !== undefined
              ? +this.client?.plan?.inputs?.monthlyIncomeGoal
              : undefined;
          const monthlyIncomeGoalFormatted = isNumberValid(monthlyIncomeGoal)
            ? transformUSDCurrencyNoDecimals(monthlyIncomeGoal)
            : MissingValuePlaceholderText;
          const monthlyContributionAmountFormatted = isNumberValid(
            monthlyContributionAmount
          )
            ? transformUSDCurrencyNoDecimals(monthlyContributionAmount)
            : MissingValuePlaceholderText;
          report.summary = `Income Plan with monthly contribution of ${monthlyContributionAmountFormatted} and passive monthly income goal of ${monthlyIncomeGoalFormatted}`;

          this.httpClient
            .post('/api/clients/' + this.client?.id + '/reports', report, {
              headers: toApiAuthHeaders(this.authService),
            })
            .subscribe(
              (result: any) => {
                if (result.result == 0) {
                  openReportPdfInNewTab(report);
                  this.operationSuccess.emit(report as PlanReport);
                } else {
                  this.actionSubmitApiCall = {
                    state: AsyncOpState.Failed,
                    error:
                      'Failed to generate report for the current income plan',
                  };
                }
              },
              (err) => {
                this.actionSubmitApiCall = {
                  state: AsyncOpState.Failed,
                  error:
                    'Failed to generate report for the current income plan',
                };
              }
            );
        },
        (err) => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to generate report for the current income plan',
          };
        }
      );
  }
}
