import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import {
  formatterDollar,
  formatterPercent,
  parserDollar,
  parserPercent,
} from 'src/app/utils/app-common';

@Component({
  selector: 'app-analysis-form-algorithm',
  templateUrl: './analysis-form-algorithm.component.html',
  styleUrls: ['./analysis-form-algorithm.component.scss'],
})
export class AnalysisFormAlgorithmComponent implements OnInit {
  @Input() analysisPlan!: AnalysisPlan;

  @Output() formDataChange = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  formatterDollar = formatterDollar;
  parserDollar = parserDollar;
  formatterPercent = formatterPercent;
  parserPercent = parserPercent;

  emitDataChange() {
    this.formDataChange.emit();
  }
}
