<div>
  <nz-form-item>
    <nz-form-label>Size of Line of Credit (LOC)</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.lineOfCreditSize"
        (ngModelChange)="emitDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>Line of Credit Interest Rate</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.lineOfCreditInterestRate"
        (ngModelChange)="emitDataChange()"
        [nzMin]="0"
        [nzMax]="100"
        [nzStep]="1"
        [nzFormatter]="formatterPercent"
        [nzParser]="parserPercent"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-control>
      <label
        nz-checkbox
        [(ngModel)]="analysisPlan.inputs.lendingAdvanced"
        (ngModelChange)="emitDataChange()"
      >
        <span>Enable Advanced Settings</span>
      </label>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="analysisPlan.inputs.lendingAdvanced">
    <nz-form-label>Projected Return from Loans</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.loansRateOfReturn"
        (ngModelChange)="emitDataChange()"
        [nzMin]="0"
        [nzMax]="100"
        [nzStep]="1"
        [nzFormatter]="formatterPercent"
        [nzParser]="parserPercent"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="analysisPlan.inputs.lendingAdvanced">
    <nz-form-label>Loan Term in Years</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.loansTerm"
        (ngModelChange)="emitDataChange()"
        [nzMin]="1"
        [nzMax]="100"
        [nzStep]="1"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
</div>
