import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

export interface ClonePlanSuccessData {
  targetAnalysisPlan: AnalysisPlan;
  targetAnalysisPlanIndex: number;
}

@Component({
  selector: 'app-confirm-clone-analysis-plan',
  templateUrl: './confirm-clone-analysis-plan.component.html',
  styleUrls: ['./confirm-clone-analysis-plan.component.scss'],
})
export class ConfirmCloneAnalysisPlanComponent {
  submitBtnDanger = false;

  @Input() client!: Client;
  @Input() sourceAnalysisPlan!: AnalysisPlan;
  @Input() sourceAnalysisPlanIndex!: number;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter<ClonePlanSuccessData>();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Clone Analysis Plan';
  modalTitle = 'Clone and Compare Selected Plan';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };

    const { sourceAnalysisPlan, sourceAnalysisPlanIndex } = this;

    let newInputs = Object.assign({}, sourceAnalysisPlan.inputs);
    let newResults = Object.assign({}, sourceAnalysisPlan.results);
    const newAnalysisPlan = {
      id: generateUuidV4(),
      inputs: newInputs,
      results: newResults,
    };
    const targetAnalysisPlanIndex = sourceAnalysisPlanIndex + 1;

    this.httpClient
      .post(
        '/api/clients/' +
          this.client!.id +
          '/analysis?position=' +
          targetAnalysisPlanIndex,
        newAnalysisPlan,
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.operationSuccess.emit({
            targetAnalysisPlan: newAnalysisPlan,
            targetAnalysisPlanIndex,
          });
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to clone selected analysis plan',
          };
        }
      );
  }
}
