import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

  email = '';

  constructor(private httpClient: HttpClient, private advisorStateService: AdvisorStateService, private message: NzMessageService, private authService: AuthService) { }

  ngOnInit(): void {
  }

  transfer() {
    this.httpClient.post('/api/transfer',{client:this.advisorStateService.getClientId(),email:this.email},
    {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Access-Token', `Bearer ${this.authService.token}`),
    }).subscribe((result: any) => {
      if (result.result == 0) {
        this.message.create('success', 'Email sent to initiate transfer to coach/advisor.');
      } else {
        this.message.create('error', 'Could not find any coach/advisor with the provided email!');
      }

    });
  }

}
