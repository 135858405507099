<div class="jbrc">
  <div class="jbrc__header">
    <div class="jbrc__header__title-col">
      <h4 class="jbrc__header__title-col__heading">
        Compare and Analyze Plans
      </h4>
      <p>Compare and contrast various income plans.</p>
    </div>
    <div
      class="jbrc__header__summary-col"
      *ngFor="let analysisPlan of analysisPlans; index as analysisPlanIndex"
    >
      <div class="jbrc__header__summary-col__title">
        <h4 class="jbrc__header__summary-col__title__heading">
          #{{ analysisPlanIndex + 1 }} {{ analysisPlan.inputs.simulationName }}
        </h4>
        <div class="jbrc__header__summary-col__title__actions">
          <button
            nz-button
            style="border: none"
            (click)="onCloneAnalysisClick(analysisPlanIndex)"
            [nzTooltipTitle]="'Clone and Compare'"
            nz-tooltip
            nzSize="small"
          >
            <i nz-icon nzType="copy" nzTheme="outline"></i>
          </button>
          <button
            nz-button
            style="border: none"
            (click)="onDeleteAnalysisClick(analysisPlan)"
            [nzTooltipTitle]="'Delete'"
            nz-tooltip
            nzSize="small"
          >
            <i nz-icon nzType="delete" nzTheme="outline"></i>
          </button>
        </div>
      </div>
      <p class="jbrc__header__summary-col__smart-key">
        <span class="jbrc__header__summary-col__smart-key__label">
          SMART KEY
        </span>
        {{ analysisPlan.results.smartkey }}
      </p>
      <div class="jbrc__header__summary-col__actions">
        <button
          nz-button
          nzType="primary"
          (click)="onMakeCurrentClick(analysisPlan)"
          nzBlock
        >
          Make As Current Plan
        </button>
      </div>
    </div>
    <div
      class="jbrc__header__summary-col jbrc__header__summary-col--placeholder"
    >
      <div class="jbrc__header__summary-col__placeholder">
        <h4 class="jbrc__header__summary-col__placeholder__title">
          Add an Income Plan
        </h4>
        <p class="jbrc__header__summary-col__placeholder__description">
          Start comparing by adding an income plan.
          <ng-container *ngIf="analysisPlans.length === 0">
            You can start by copying current plan or adding a income plan result
            from income plan optimizer tab.
          </ng-container>
          <ng-container *ngIf="analysisPlans.length > 0">
            You can even clone and modify other plans
          </ng-container>
        </p>

        <button
          class="jbrc__header__summary-col__placeholder__action"
          nz-button
          nzType="primary"
          (click)="onCopyCurrentPlan()"
          nzBlock
        >
          Copy Current Income Plan
        </button>
      </div>
    </div>
  </div>
  <div class="jbrc__content">
    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Client Goal</div>

        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores
                .collapsed_client_goal
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            Client goals of attaining financial freedom
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let analysisPlan of analysisPlans;
              index as analysisPlanIndex
            "
          >
            <app-analysis-form-client-goal
              [analysisPlan]="analysisPlan"
              (formDataChange)="onAnalysisFormDataChange(analysisPlan)"
            >
            </app-analysis-form-client-goal>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Client Goals</h5>
          </div>
        </div>
      </div>
    </app-collapsible>

    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Lending</div>
        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores.collapsed_lending
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            Lending settings that are used for the income plan
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let analysisPlan of analysisPlans;
              index as analysisPlanIndex
            "
          >
            <app-analysis-form-lending
              [analysisPlan]="analysisPlan"
              (formDataChange)="onAnalysisFormDataChange(analysisPlan)"
            ></app-analysis-form-lending>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Lending Settings</h5>
          </div>
        </div>
      </div>
    </app-collapsible>

    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Algorithm</div>
        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores
                .collapsed_algorithm
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            Algorithm settings that are used for the income plan
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let analysisPlan of analysisPlans;
              index as analysisPlanIndex
            "
          >
            <app-analysis-form-algorithm
              [analysisPlan]="analysisPlan"
              (formDataChange)="onAnalysisFormDataChange(analysisPlan)"
            ></app-analysis-form-algorithm>
          </div>

          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Algorithm Settings</h5>
          </div>
        </div>
      </div>
    </app-collapsible>
  </div>
  <h3 style="margin: 2rem 1.5rem; font-size: var(--iris-font-size-l)">
    Plan Results
  </h3>

  <div class="jbrc__content" *ngIf="analysisPlanWithCompareAndScores">
    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Plan Timeline</div>

        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores.collapsed_timeline
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            <div
              class="jbrc__c__c__label-col__row"
              *ngFor="let labelWithTooltip of timelineSectionLabels"
            >
              {{ labelWithTooltip.label }}
              <app-tooltip-icon
                *ngIf="labelWithTooltip.tooltipMessage"
                [tooltipMessage]="labelWithTooltip.tooltipMessage"
              ></app-tooltip-icon>
            </div>
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
              index as analysisPlanIndex
            "
          >
            <div
              class="jbrc__c__c__value-col__row"
              *ngFor="let score of optimizerResultWithAnalysis.scores.timeline"
            >
              <app-job-result-metric
                [showCompareAndRank]="showCompareAndRank"
                [compareAndRank]="score.compareAndRank"
                >{{ score.value }}</app-job-result-metric
              >
            </div>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Plan Timeline</h5>
          </div>
        </div>
      </div>
    </app-collapsible>
    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Freedom Day Numbers</div>
        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores.collapsed_ffd
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            <div
              class="jbrc__c__c__label-col__row"
              *ngFor="let labelWithTooltip of freedomDaySectionLabels"
            >
              {{ labelWithTooltip.label }}
              <app-tooltip-icon
                *ngIf="labelWithTooltip.tooltipMessage"
                [tooltipMessage]="labelWithTooltip.tooltipMessage"
              ></app-tooltip-icon>
            </div>
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
              index as analysisPlanIndex
            "
          >
            <div
              class="jbrc__c__c__value-col__row"
              *ngFor="let score of optimizerResultWithAnalysis.scores.ffd"
            >
              <app-job-result-metric
                [showCompareAndRank]="showCompareAndRank"
                [compareAndRank]="score.compareAndRank"
                >{{ score.value }}</app-job-result-metric
              >
            </div>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Freedom Day Numbers</h5>
          </div>
        </div>
      </div>
    </app-collapsible>
    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Numbers</div>
        <div
          class="jbrc__c__t__tags"
          *ngFor="
            let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
            index as analysisPlanIndex
          "
        >
          <app-job-result-metric
            *ngFor="
              let score of optimizerResultWithAnalysis.scores.collapsed_numbers
            "
            [showCompareAndRank]="showCompareAndRank"
            [compareAndRank]="score.compareAndRank"
            size="small"
          >
            {{ score.label }}
            {{ score.value }}
          </app-job-result-metric>
        </div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            <div
              class="jbrc__c__c__label-col__row"
              *ngFor="let labelWithTooltip of numberSectionLabels"
            >
              {{ labelWithTooltip.label }}
              <app-tooltip-icon
                *ngIf="labelWithTooltip.tooltipMessage"
                [tooltipMessage]="labelWithTooltip.tooltipMessage"
              ></app-tooltip-icon>
            </div>
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
              index as analysisPlanIndex
            "
          >
            <div
              class="jbrc__c__c__value-col__row"
              *ngFor="let score of optimizerResultWithAnalysis.scores.numbers"
            >
              <app-job-result-metric
                [showCompareAndRank]="showCompareAndRank"
                [compareAndRank]="score.compareAndRank"
                >{{ score.value }}</app-job-result-metric
              >
            </div>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Numbers</h5>
          </div>
        </div>
      </div>
    </app-collapsible>
    <app-collapsible [collapsed]="initialCollapsed">
      <div collapsible-title class="jbrc__c__t">
        <div class="jbrc__c__t__label-col">Others</div>
      </div>
      <div collapsible-content>
        <div class="jbrc__c__c">
          <div class="jbrc__c__c__label-col">
            <div
              class="jbrc__c__c__label-col__row"
              *ngFor="let labelWithTooltip of otherSectionLabels"
            >
              {{ labelWithTooltip.label }}
              <app-tooltip-icon
                *ngIf="labelWithTooltip.tooltipMessage"
                [tooltipMessage]="labelWithTooltip.tooltipMessage"
              ></app-tooltip-icon>
            </div>
          </div>
          <div
            class="jbrc__c__c__value-col"
            *ngFor="
              let optimizerResultWithAnalysis of analysisPlanWithCompareAndScores;
              index as analysisPlanIndex
            "
          >
            <div
              class="jbrc__c__c__value-col__row"
              *ngFor="let score of optimizerResultWithAnalysis.scores.others"
            >
              <app-job-result-metric
                [showCompareAndRank]="showCompareAndRank"
                [compareAndRank]="score.compareAndRank"
                >{{ score.value }}</app-job-result-metric
              >
            </div>
          </div>
          <div class="jbrc__c__c__value-col jbrc__c__c__value-col--placeholder">
            <i
              nz-icon
              nzType="info-circle"
              nzTheme="outline"
              class="jbrc__c__c__value-col__icon"
            ></i>
            <h5 class="jbrc__c__c__value-col__title">Other</h5>
          </div>
        </div>
      </div>
    </app-collapsible>
  </div>
</div>
