import { DatePipe } from '@angular/common';
import { formatDistance, compareAsc } from 'date-fns';

export const formatDifferenceForDate = (pastDate: Date) => {
  return formatDistance(pastDate, new Date(), { addSuffix: true });
};

export const compareDate = (date1: Date, date2: Date) => {
  return compareAsc(date1, date2);
};

export const dateIsInPast = (date: Date) => {
  return compareAsc(date, new Date()) === -1;
};
export const dateIsInFuture = (date: Date) => {
  return compareAsc(date, new Date()) === 1;
};

/**
 *
  formateDurationSeconds(50) // 'less than a minute'
  formateDurationSeconds(1000) // '17 minutes'
 */
export const formateDurationSeconds = (s: number) =>
  formatDistance(0, s * 1000, { includeSeconds: true });

/**
 *
 * Format date to 'Aug 1, 2021'
 *
 * @param date
 * @returns
 */
export const toMMMDDYYYYDateFormat = (date: Date) => {
  return `${new DatePipe('en-US').transform(date, 'mediumDate')}`;
};

/**
 * Formate to 'Monday, June 15, 2015 at 9:03:01 AM GMT+01:00'
 * @param date
 */
export const toFullDateFormat = (date: Date) => {
  return `${new DatePipe('en-US').transform(date, 'full')}`;
};
