<div>
  <nz-form-item>
    <nz-form-control>
      <label
        nz-checkbox
        [(ngModel)]="analysisPlan.inputs.algorithmAdvanced"
        (ngModelChange)="emitDataChange()"
      >
        <span>Enable Advanced Algorithm Settings</span>
      </label>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="analysisPlan.inputs.algorithmAdvanced">
    <nz-form-label>Algorithm (TY)</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-select
        [(ngModel)]="analysisPlan.inputs.methodToUse"
        (ngModelChange)="emitDataChange()"
        style="width: 200px"
      >
        <nz-option [nzValue]="0" nzLabel="One"></nz-option>
        <nz-option [nzValue]="1" nzLabel="Two"></nz-option>
        <nz-option [nzValue]="2" nzLabel="Three"></nz-option>
        <nz-option [nzValue]="3" nzLabel="Four"></nz-option>
        <nz-option [nzValue]="4" nzLabel="Five"></nz-option>
        <nz-option [nzValue]="5" nzLabel="Six"></nz-option>
        <nz-option [nzValue]="6" nzLabel="Seven"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="analysisPlan.inputs.algorithmAdvanced">
    <nz-form-label>GR</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.growthCapital"
        (ngModelChange)="emitDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="analysisPlan.inputs.algorithmAdvanced">
    <nz-form-label>MGR</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.monthsToPayOffLOC"
        (ngModelChange)="emitDataChange()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item *ngIf="analysisPlan.inputs.algorithmAdvanced">
    <nz-form-label>MP3</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.monthsToPayOffLOCPhase3"
        (ngModelChange)="emitDataChange()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <div *ngIf="analysisPlan.inputs.algorithmAdvanced" style="margin: 10px 0">
    Keep Additional Income in System
    <nz-switch
      [(ngModel)]="analysisPlan.inputs.keepAdditionalIncomeInSystem"
      (ngModelChange)="emitDataChange()"
      style="margin-left: 20px"
    ></nz-switch>
  </div>
  <div *ngIf="analysisPlan.inputs.algorithmAdvanced" style="margin: 10px 0">
    Enable Auto Increment
    <nz-switch
      [(ngModel)]="analysisPlan.inputs.enableAutoIncrement"
      (ngModelChange)="emitDataChange()"
      style="margin-left: 20px"
    ></nz-switch>
  </div>
  <nz-form-item
    *ngIf="
      analysisPlan.inputs.algorithmAdvanced &&
      analysisPlan.inputs.enableAutoIncrement
    "
  >
    <nz-form-label>AA</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.autoIncrementAmount"
        (ngModelChange)="emitDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    *ngIf="
      analysisPlan.inputs.algorithmAdvanced &&
      analysisPlan.inputs.enableAutoIncrement
    "
  >
    <nz-form-label>NumFlips</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.autoIncrementNumFlips"
        (ngModelChange)="emitDataChange()"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    *ngIf="
      analysisPlan.inputs.algorithmAdvanced &&
      analysisPlan.inputs.enableAutoIncrement
    "
  >
    <nz-form-label>Max</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.autoIncrementMax"
        (ngModelChange)="emitDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item
    *ngIf="
      analysisPlan.inputs.algorithmAdvanced &&
      analysisPlan.inputs.enableAutoIncrement
    "
  >
    <nz-form-label>One-Time Max Amount</nz-form-label>
    <nz-form-control [nzSpan]="24">
      <nz-input-number
        [(ngModel)]="analysisPlan.inputs.autoIncrementOneTimeMaxAmount"
        (ngModelChange)="emitDataChange()"
        [nzFormatter]="formatterDollar"
        [nzParser]="parserDollar"
      ></nz-input-number>
    </nz-form-control>
  </nz-form-item>
</div>
