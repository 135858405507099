<nz-card [nzTitle]="'Plan Upcoming Data'">
    <nz-table #dataTable *ngIf="dataItems" [nzData]="dataItems" [nzPageSize]="pageSize" [nzScroll]="{ x: '1500px' }">
        <thead>
            <tr>
                <th nzLeft>Date</th>
                <th>LOC Flips</th>
                <th>Cash Flips</th>
                <th>Loan Size</th>
                <th>Total Loans Created</th>
                <th>Loans Start UPB</th>
                <th>Total CF</th>
                <th>Contribution</th>
                <th>LOC Size</th>
                <th>LOC Start Balance</th>
                <th>LOC End Balance</th>
                <th>Passive Income</th>
                <th>Divert Amount</th>
                <th>Divert Title</th>
                <th>Draw Amount</th>
                <th>Draw Title</th>
                <th>Pay Down</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of dataTable.data">
                <td nzLeft [ngStyle]="{
              'background-color':
                data.phase == 3
                  ? 'green'
                  : data.phase == 2
                  ? 'yellow'
                  : 'red'
            }">
                    {{ data.date | date: "mediumDate" }}
                </td>
                <td>{{ data.loc_flips }}</td>
                <td>{{ data.cash_flips }}</td>
                <td>{{ data["loan size"] | currency: "USD":"$":"0.0-2" }}</td>
                <td>{{ data["total loans"] | currency: "USD":"$":"0.0-2" }}</td>
                <td>{{ data.upb_start | currency: "USD":"$":"0.0-2" }}</td>
                <td>
                    {{ data["total loans CF"] | currency: "USD":"$":"0.0-2" }}
                </td>
                <td>{{ data.contribution | currency: "USD":"$":"0.0-2" }}</td>
                <td>{{ data["loc size"] | currency: "USD":"$":"0.0-2" }}</td>
                <td>
                    {{ data.loc_start_balance | currency: "USD":"$":"0.0-2" }}
                </td>
                <td>
                    {{ data.loc_end_balance | currency: "USD":"$":"0.0-2" }}
                </td>
                <td>{{ data.passive | currency: "USD":"$":"0.0-2" }}</td>
                <td>
                    {{ data.diverted_amount | currency: "USD":"$":"0.0-2" }}
                </td>
                <td>{{ data.diverted_title }}</td>
                <td>{{ data.locdraw_amount | currency: "USD":"$":"0.0-2" }}</td>
                <td>{{ data.locdraw_title }}</td>
                <td>
                    {{ data.locpaydown_amount | currency: "USD":"$":"0.0-2" }}
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-card>