import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Client } from 'src/app/models/client';
import { Job } from 'src/app/models/job';
import {
  OptimizerSettings,
  PlanOptimizerLevel,
} from 'src/app/models/optimizer-settings';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  formatterDollar,
  formatterPercent,
  parserDollar,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

export interface OptimizerAPIBody {
  plan: PlanInputs;
  settings: OptimizerSettings;
  user: string;
  client: string;
  id: string;
}

export interface OptimizerStepData {
  job: Job;
  optimizerBody: OptimizerAPIBody;
}

@Component({
  selector: 'app-fop-step-optimizer-settings',
  templateUrl: './fop-step-optimizer-settings.component.html',
  styleUrls: ['./fop-step-optimizer-settings.component.scss'],
})
export class FopStepOptimizerSettingsComponent implements OnInit {
  @Input() client?: Client;
  @Input() inputs!: PlanInputs;

  @Output() optimizerDataChange = new EventEmitter<OptimizerStepData>();

  performThoroughSearch = false;
  locSet = true;
  locStartingValue = '3000';
  locEndingValue = '6000';
  locIncrement = '1000';
  growthCapitalSet = true;
  growthCapitalStartingValue = '2000';
  growthCapitalEndingValue = '8000';
  growthCapitalIncrement = '1000';
  autoIncrementSet = true;
  autoIncrementStartingValue = '1000';
  autoIncrementEndingValue = '5000';
  autoIncrementIncrement = '1000';
  monthsToFlipDuringGrowthSet = true;
  monthsToFlipDuringGrowthStartingValue = '3';
  monthsToFlipDuringGrowthEndingValue = '6';
  monthsToFlipDuringPhase3Set = true;
  monthsToFlipDuringPhase3StartingValue = '6';
  monthsToFlipDuringPhase3EndingValue = '24';

  methodOne = false;
  methodTwo = false;
  methodThree = false;
  methodFour = false;
  methodFive = true;
  methodSix = false;
  methodSeven = true;
  searchName = 'Search';
  numberOfResults = 6;
  numberOfIterations = 500;

  formatterDollar = formatterDollar;
  parserDollar = parserDollar;
  format = formatterPercent;

  fetchOptimizerDataCall: AsyncOpData<void>;

  subscription: any;

  @Output() validityChange = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.fetchOptimizerDataCall = {
      state: AsyncOpState.NotStarted,
    };
  }
  ngOnInit(): void {
    this.setUpInitialData();
  }

  setUpInitialData(): void {
    this.fetchOptimizerDataCall = {
      state: AsyncOpState.Inprogress,
    };
    if (!this.client!.searchCounter) {
      this.client!.searchCounter = 0;
    }
    this.searchName = 'Search #' + (this.client!.searchCounter + 1);
    this.httpClient.post('/engine/optimizerinputs', this.inputs).subscribe(
      (results: any) => {
        this.growthCapitalStartingValue = results.growthCapitalStart;
        this.growthCapitalEndingValue = results.growthCapitalEnd;
        this.growthCapitalIncrement = results.growthCapitalInc;
        this.locStartingValue = results.locDrawAmountStart;
        this.locEndingValue = results.locDrawAmountEnd;
        this.locIncrement = results.locDrawAmountInc;
        this.autoIncrementStartingValue = results.autoIncrementAmountStart;
        this.autoIncrementEndingValue = results.autoIncrementAmountEnd;
        this.autoIncrementIncrement = results.autoIncrementAmountInc;
        this.monthsToFlipDuringGrowthStartingValue =
          results.monthsToPayoffLOCStart;
        this.monthsToFlipDuringGrowthEndingValue = results.monthsToPayoffLOCEnd;
        this.monthsToFlipDuringPhase3StartingValue =
          results.monthsToPayoffLOCPhase3Start;
        this.monthsToFlipDuringPhase3EndingValue =
          results.monthsToPayoffLOCPhase3End;

        this.emitOptimizerDataChange();
        this.fetchOptimizerDataCall = {
          state: AsyncOpState.Completed,
        };
        this.validityChange.emit(true);
      },
      (err) => {
        this.fetchOptimizerDataCall = {
          state: AsyncOpState.Failed,
          error: 'Failed to fetch optimization defaults',
        };
      }
    );
  }

  emitOptimizerDataChange() {
    let settings: OptimizerSettings = {
      searchName: this.searchName,
      performThoroughSearch: this.performThoroughSearch,
      locSet: this.locSet,
      locStartingValue: this.locStartingValue,
      locEndingValue: this.locEndingValue,
      locIncrement: this.locIncrement,
      growthCapitalSet: this.growthCapitalSet,
      growthCapitalStartingValue: this.growthCapitalStartingValue,
      growthCapitalEndingValue: this.growthCapitalEndingValue,
      growthCapitalIncrement: this.growthCapitalIncrement,
      autoIncrementSet: this.autoIncrementSet,
      autoIncrementStartingValue: this.autoIncrementStartingValue,
      autoIncrementEndingValue: this.autoIncrementEndingValue,
      autoIncrementIncrement: this.autoIncrementIncrement,
      monthsToFlipDuringGrowthSet: this.monthsToFlipDuringGrowthSet,
      monthsToFlipDuringGrowthStartingValue:
        this.monthsToFlipDuringGrowthStartingValue,
      monthsToFlipDuringGrowthEndingValue:
        this.monthsToFlipDuringGrowthEndingValue,
      monthsToFlipDuringPhase3Set: this.monthsToFlipDuringPhase3Set,
      monthsToFlipDuringPhase3StartingValue:
        this.monthsToFlipDuringPhase3StartingValue,
      monthsToFlipDuringPhase3EndingValue:
        this.monthsToFlipDuringPhase3EndingValue,
      methodOne: this.methodOne,
      methodTwo: this.methodTwo,
      methodThree: this.methodThree,
      methodFour: this.methodFour,
      methodFive: this.methodFive,
      methodSix: this.methodSix,
      methodSeven: this.methodSeven,
      numberOfResults: this.numberOfResults,
      numberOfIterations: this.numberOfIterations,
    };

    if (this.inputs && this.client) {
      this.inputs.scheduledTransactions = this.client.transactions;
      this.inputs.deposits = this.client.deposits;
    }

    let id = generateUuidV4();
    let expectedIterations = this.iterations();
    let maximumIterations = this.maximumIterations();
    let optimizerLevel = undefined;
    if (!this.performThoroughSearch) {
      if (expectedIterations >= maximumIterations * 0.1) {
        optimizerLevel = 'HIGH';
      } else if (expectedIterations >= maximumIterations * 0.05) {
        optimizerLevel = 'MEDIUM';
      } else {
        optimizerLevel = 'LOW';
      }
    }

    const job: Job = {
      id,
      expectedIterations,
      progress: 0,
      started: new Date(),
      name: this.searchName,
      optimizer: this.performThoroughSearch ? 'THOROUGH' : 'QUICK',
      optimizerLevel: optimizerLevel as PlanOptimizerLevel,
      inputs: this.inputs,
      settings: settings
    };

    const optimizerBody: OptimizerAPIBody = {
      plan: this.inputs as PlanInputs,
      settings: settings,
      user: this.authService.user!.id as string,
      client: this.client!.id as string,
      id,
    };

    this.optimizerDataChange.emit({ job, optimizerBody });
  }

  iterations() {
    let iterations = 1;

    if (this.performThoroughSearch) {
      iterations = this.thoroughIterations();

      //iterations *= this.inputs.yearsToSimulate * 12;
    } else {
      iterations = +this.numberOfIterations;
    }
    return iterations;
  }

  thoroughIterations(): number {
    let iterations = 1;

    if (this.monthsToFlipDuringGrowthSet) {
      let start = +this.monthsToFlipDuringGrowthStartingValue;
      let end = +this.monthsToFlipDuringGrowthEndingValue;
      let inc = 1;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    if (this.monthsToFlipDuringPhase3Set) {
      let start = +this.monthsToFlipDuringPhase3StartingValue;
      let end = +this.monthsToFlipDuringPhase3EndingValue;
      let inc = 1;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    if (this.autoIncrementSet) {
      let start = +this.autoIncrementStartingValue;
      let end = +this.autoIncrementEndingValue;
      let inc = +this.autoIncrementIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    if (this.locSet) {
      let start = +this.locStartingValue;
      let end = +this.locEndingValue;
      let inc = +this.locIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    if (this.growthCapitalSet) {
      let start = +this.growthCapitalStartingValue;
      let end = +this.growthCapitalEndingValue;
      let inc = +this.growthCapitalIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    /*if ($this->startWithCashSet) {
        $start = $this->startWithCashStart;
        $end = $this->startWithCashEnd;
        $inc = $this->startWithCashInc;
        $iter = floor(((abs($end - $start)/$inc)+1));
        // echo "ITER is $iter<br>";
        $iterations *= $iter;
    }*/

    let methods = 0;
    if (this.methodOne) {
      methods += 1;
    }
    if (this.methodTwo) {
      methods += 1;
    }
    if (this.methodThree) {
      methods += 1;
    }
    if (this.methodFour) {
      methods += 1;
    }
    if (this.methodFive) {
      methods += 1;
    }
    if (this.methodSix) {
      methods += 1;
    }
    if (this.methodSeven) {
      methods += 1;
    }

    iterations *= Math.max(1, methods);
    return iterations;
  }

  maximumIterations(): number {
    let iterations = 1;
    //if (this.monthsToFlipDuringGrowthSet) {
    if (true) {
      let start = +this.monthsToFlipDuringGrowthStartingValue;
      let end = +this.monthsToFlipDuringGrowthEndingValue;
      let inc = 1;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    //if (this.monthsToFlipDuringPhase3Set) {
    if (true) {
      let start = +this.monthsToFlipDuringPhase3StartingValue;
      let end = +this.monthsToFlipDuringPhase3EndingValue;
      let inc = 1;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    //if (this.autoIncrementSet) {
    if (true) {
      let start = +this.autoIncrementStartingValue;
      let end = +this.autoIncrementEndingValue;
      let inc = +this.autoIncrementIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    //if (this.locSet) {
    if (true) {
      let start = +this.locStartingValue;
      let end = +this.locEndingValue;
      let inc = +this.locIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    //if (this.growthCapitalSet) {
    if (true) {
      let start = +this.growthCapitalStartingValue;
      let end = +this.growthCapitalEndingValue;
      let inc = +this.growthCapitalIncrement;
      iterations *= Math.floor(Math.abs(end - start) / inc + 1);
    }

    /*if ($this->startWithCashSet) {
        $start = $this->startWithCashStart;
        $end = $this->startWithCashEnd;
        $inc = $this->startWithCashInc;
        $iter = floor(((abs($end - $start)/$inc)+1));
        // echo "ITER is $iter<br>";
        $iterations *= $iter;
    }*/

    let methods = 2;
    /*if (this.methodOne) {
      methods += 1;
    }
    if (this.methodTwo) {
      methods += 1;
    }
    if (this.methodThree) {
      methods += 1;
    }
    if (this.methodFour) {
      methods += 1;
    }
    if (this.methodFive) {
      methods += 1;
    }
    if (this.methodSix) {
      methods += 1;
    }
    if (this.methodSeven) {
      methods += 1;
    }*/

    iterations *= Math.max(1, methods);
    return iterations;
  }

  get minRecommendedIterations(): number {
    return Math.floor(this.thoroughIterations() * 0.05);
  }

  get maxRecommendedIterations(): number {
    return Math.floor(this.thoroughIterations() * 0.1);
  }

  get optimizationSetupInProgress() {
    return this.fetchOptimizerDataCall.state === AsyncOpState.Inprogress;
  }
  get optimizationSetupInSuccess() {
    return this.fetchOptimizerDataCall.state === AsyncOpState.Completed;
  }
  get optimizationSetupFailed() {
    return this.fetchOptimizerDataCall.state === AsyncOpState.Failed;
  }
  get optimizationSetupErrorMessage() {
    return this.fetchOptimizerDataCall.error;
  }
}
