<!--app-core-modal
  [closable]="!createAnalysisApiInprogress"
  (closeModal)="handleCancel()"
  size="large"
  [title]="modalTitle"
  [modalBodyStyle]="{ padding: '0px' }"
  [modalAutofocus]="null"
-->
<nz-card nzTitle="Setup Rapid Plan">
  <div class="create-or-update-plan-modal">
    <div nz-row class="create-or-update-plan-modal__content" *ngIf="inputs">
      <div *ngIf="stepState.steps.length > 1" nz-col nzSpan="6" class="create-or-update-plan-modal__navigation">
        <nz-steps 
          nzSize="small"
          nzProgressDot
          nzDirection="vertical"
          [nzCurrent]="stepState.activeStep"
          (nzIndexChange)="onIndexChange($event)"
        >
          <nz-step
            *ngFor="let step of stepState.steps; index as stepIndex"
            [nzTitle]="step.name"
            [nzStatus]="toStepStatus(stepIndex)"
            [nzDisabled]="step.disabled"
          >
          </nz-step>
        </nz-steps>
      </div>
      <div nz-col [nzSpan]="(stepState.steps.length > 1)?10:16">
        <app-step-client-goals
          *ngIf="clientGoalStepActive"
          [inputs]="inputs"
          (validityChange)="onActiveStepValidityChange($event)"
          [showStartDateField]="true"
        >
        </app-step-client-goals>
        <app-step-lending-settings
          *ngIf="lendingSettingsStepActive"
          [inputs]="inputs"
          (validityChange)="onActiveStepValidityChange($event)"
        >
        </app-step-lending-settings>
        <app-step-income-streams
          *ngIf="incomeStreamsStepActive"
          [inputs]="inputs"
          (validityChange)="onActiveStepValidityChange($event)"
        >
        </app-step-income-streams>
        <app-step-algo-settings
          *ngIf="algoSettingsStepActive"
          [inputs]="inputs"
          (validityChange)="onActiveStepValidityChange($event)"
        >
        </app-step-algo-settings>
        <app-cou-step-finish
          *ngIf="finishStepActive"
          [client]="client"
          (validityChange)="onActiveStepValidityChange($event)"
          [updateMode]="updateMode"
        >
        </app-cou-step-finish>
        <div
          class="create-or-update-plan-modal__create-error-alert"
          *ngIf="
            submitCreateApiCall.state === AsyncOpState.Failed &&
            submitCreateApiCall.error
          "
        >
          <app-core-alert
            [showIcon]="true"
            type="error"
            [message]="submitCreateApiCall.error"
          ></app-core-alert>
        </div>
      </div>
      <div nz-col nzSpan="8">
        <nz-card *ngIf="!lineOfCreditSettingsEnabled" class="action-cards" [nzBorderless]="true">
          <h3><img src="/assets/img/plan-settings/DangerCircle.svg">&nbsp;Line of Credit Settings</h3>
          <p>Line of Credit has been set at <b>{{ (+inputs.lineOfCreditInterestRate! / 100) | percent  }} by default</b>.  To change it accordingly click the button given below.</p>
          <div><a (click)="enableLineOfCreditSettings()">CONFIGURE LINE OF CREDIT</a></div>
        </nz-card>
        <nz-card *ngIf="!incomeStreamSettingsEnabled" class="action-cards" [nzBorderless]="true">
          <h3><img src="/assets/img/plan-settings/DangerCircle.svg">&nbsp;Income Stream Settings</h3>
          <p>Income Stream has been set at <b>{{ (+inputs.loansRateOfReturn! / 100) | percent  }}, {{ (+inputs.loansTerm!) }} Years and 100% Allocation by default</b>.  To change it accordingly click the button given below.</p>
          <div><a (click)="enableIncomeStreamSettings()">CONFIGURE INCOME STREAM</a></div>
        </nz-card>
        <nz-card *ngIf="!algorithmSettingsEnabled" class="action-cards" [nzBorderless]="true">
          <h3><img src="/assets/img/plan-settings/DangerCircle.svg">&nbsp;Algorithm Settings</h3>
          <p>Algorithm has been set at <b>{{ inputs.methodToUse! }} by default</b>.  To change it accordingly click the button given below.</p>
          <div><a (click)="enableAlgorithmSettings()">CONFIGURE ALGORITHM</a></div>
        </nz-card>
      </div>
    </div>
  </div>

  <div class="create-or-update-plan-modal__footer">
    <app-core-button
      type="default"
      [disabled]="createAnalysisApiInprogress"
      (click)="handleCancel()"
      >Cancel</app-core-button
    >
    <app-core-button
      *ngIf="showNextBtn"
      type="primary"
      [disabled]="nextBtnDisabled"
      (click)="goToNextStep()"
    >
      Next
    </app-core-button>
    <!--app-core-button
      *ngIf="showCreateDaAnalysisBtn"
      type="primary"
      [disabled]="nextBtnDisabled || createAnalysisApiInprogress"
      (click)="handleSubmit()"
      [loading]="createAnalysisApiInprogress"
    >
      Submit
    </app-core-button-->
  </div>
</nz-card>
<!--/app-core-modal-->
