import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { generateUuidV4 } from 'src/app/utils/uuid-utils';

@Component({
  selector: 'app-setup-revolving-debt-stacks',
  templateUrl: './setup-revolving-debt-stacks.component.html',
  styleUrls: ['./setup-revolving-debt-stacks.component.scss'],
})
export class SetupRevolvingDebtStacksComponent implements OnInit {
  @Input() client!: Client;

  startDate?: Date;
  todayDate = new Date();

  addStackVisible = false;
  isAddingStack = false;
  isEdittingStack = false;
  stackId?: string;
  stackTitle = '';
  stackType = 'CC';
  stackAmount = '';
  stackPayment = '';
  stackPayments = 36;

  formatterDollar = (value: number) => `$ ${value}`;
  parserDollar = (value: string) => value.replace('$ ', '');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  addStack() {
    if (!this.isEdittingStack) {
      this.isAddingStack = true;
      this.addStackVisible = true;
    }
  }

  editStack(stack: any) {
    if (!this.isAddingStack) {
      this.isEdittingStack = true;
      this.stackId = stack.id;
      this.stackTitle = stack.title;
      this.stackType = stack.type;
      this.stackAmount = stack.amount;
      this.stackPayment = stack.payment;
      this.stackPayments = stack.payments;
      this.addStackVisible = true;
    }
  }

  cancelAddStack() {
    this.addStackVisible = false;
    this.isAddingStack = false;
    this.isEdittingStack = false;
  }

  okAddStack() {
    if (this.isAddingStack) {
      this.addStackVisible = false;
      this.isAddingStack = false;
      let stack = {
        id: generateUuidV4(),
        title: this.stackTitle,
        type: this.stackType,
        amount: this.stackAmount,
        payment: this.stackPayment,
        payments: this.stackPayments,
      };
      this.httpClient
        .post(
          '/api/clients/' + this.client!.id + '/revolvingdebtstacks',
          stack,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            if (!this.client!.revolvingDebtStacks) {
              this.client!.revolvingDebtStacks = [];
            }
            this.client!.revolvingDebtStacks.push(result.stack);
          }
        });
    } else if (this.isEdittingStack) {
      this.addStackVisible = false;
      this.isEdittingStack = false;
      let stack = {
        id: this.stackId,
        title: this.stackTitle,
        type: this.stackType,
        amount: this.stackAmount,
        payment: this.stackPayment,
        payments: this.stackPayments,
      };
      this.httpClient
        .post(
          '/api/clients/' +
            this.client!.id +
            '/revolvingdebtstacks/' +
            stack.id,
          stack,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let s of this.client!.revolvingDebtStacks!) {
              if (s.id && s.id === stack.id) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.revolvingDebtStacks!.splice(found, 1, stack);
            }
          }
        });
    }
  }

  deleteStack() {
    if (this.isEdittingStack) {
      this.addStackVisible = false;
      this.isEdittingStack = false;
      this.httpClient
        .delete(
          '/api/clients/' +
            this.client!.id +
            '/revolvingdebtstacks/' +
            this.stackId,
          {
            headers: new HttpHeaders()
              .set('Content-Type', 'application/json')
              .set('X-Access-Token', `Bearer ${this.authService.token}`),
          }
        )
        .subscribe((result: any) => {
          if (result.result == 0) {
            //this.clearPlanOptimization();

            let found = -1;
            let index = 0;
            for (let s of this.client!.revolvingDebtStacks!) {
              if (s.id && s.id === this.stackId) {
                found = index;
              }
              index++;
            }
            if (found !== -1) {
              this.client!.loans!.splice(found, 1);
            }
          }
        });
    }
  }

  clearPlanOptimization() {
    if (this.client!.plan) {
      this.client!.plan!.optimizer = undefined;
      this.client!.plan!.optimizerLevel = undefined;
    }
    if (this.client!.jobs) {
      for (let job of this.client!.jobs) {
        job.outOfDate = true;
      }
    }
  }
}
