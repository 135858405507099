import {
  Component,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Chart } from 'chart.js';

import { DefaultChartHeight } from '../constants';
import {
  toChartCreationConfig,
  PlanCashflowChartConfig,
  toChartOptions,
  toChartData,
} from './chart-config';
import { isNumberValid } from 'src/app/utils/number-utils';
import { generateRandomDomElementId } from 'src/app/utils/random';

@Component({
  selector: 'app-cashflow-chart',
  templateUrl: './cashflow-chart.component.html',
  styleUrls: ['./cashflow-chart.component.scss'],
})
export class CashflowChartComponent implements AfterViewInit, OnChanges {
  @Input() config!: PlanCashflowChartConfig;
  @ViewChild('canvasElement') canvas?: ElementRef;

  @Input() height?: number;

  chart?: Chart;
  ctx?: CanvasRenderingContext2D | null;

  constructor() {}

  ngAfterViewInit(): void {
    this.createOrUpdateChart();
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (
      Object.entries(simpleChanges).some(([key, value]) => !value.firstChange)
    ) {
      this.createOrUpdateChart();
    }
  }
  setChartCanvasDimensions(): void {
    if (this.canvas) {
      this.canvas.nativeElement.style.width = '100%';
      this.canvas.nativeElement.style.height = `${
        isNumberValid(this.height) ? this.height : DefaultChartHeight
      }px`;
    }
  }

  createOrUpdateChart(): void {
    const { ctx, config, chart } = this;
    const onResize = () => this.createOrUpdateChart();
    this.setChartCanvasDimensions();
    const canvasDomElement = this.canvas?.nativeElement as HTMLCanvasElement;
    const canvasWidth = (canvasDomElement as HTMLCanvasElement).offsetWidth;
    if (ctx && chart) {
      // mutate chart object and call update() method to update chart data
      chart.options = toChartOptions(config, onResize, canvasWidth);
      chart.data = toChartData(ctx, config);
      chart.update();
    } else {
      this.ctx = canvasDomElement.getContext('2d');
      if (this.ctx) {
        this.chart = new Chart(
          this.ctx,
          toChartCreationConfig(this.ctx, config, onResize, canvasWidth)
        );
      }
    }
  }
}
