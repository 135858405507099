import { Component, Input } from '@angular/core';
import {
  NzButtonShape,
  NzButtonSize,
  NzButtonType,
} from 'ng-zorro-antd/button';

@Component({
  selector: 'app-core-button',
  templateUrl: './core-button.component.html',
  styleUrls: ['./core-button.component.css'],
})
export class CoreButtonComponent {
  @Input() disabled = false;
  @Input() ghost = false;
  @Input() loading = false;
  @Input() shape: NzButtonShape = null;
  @Input() size: NzButtonSize = 'default';
  @Input() type: NzButtonType = 'default';
  @Input() block = false;
  @Input() danger = false;

  constructor() {}
}
