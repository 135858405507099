<div class="clients-table__header">
  <h3 class="clients-table__header__title">My Clients</h3>

  <div class="clients-table__header__actions">
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input
        type="text"
        nz-input
        placeholder="Search Clients"
        [(ngModel)]="tableData.tableOptions.searchQuery"
        (ngModelChange)="onSearchQueryChange()"
      />
    </nz-input-group>

    <button
      nz-button
      nz-dropdown
      [nzDropdownMenu]="menu"
      nzTrigger="click"
      nzSize="large"
    >
      <i nz-icon nzType="filter" [nzTheme]="filterIconType"></i>
      {{ filterBtnText }}
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item style="line-height: 36px">
          <label
            nz-checkbox
            [(ngModel)]="tableData.tableOptions.filters.showPinned"
            (ngModelChange)="onTableFilterChange()"
            >Pinned</label
          >
        </li>
        <li nz-menu-item style="line-height: 36px">
          <label
            nz-checkbox
            [(ngModel)]="tableData.tableOptions.filters.showArchived"
            (ngModelChange)="onTableFilterChange()"
            >Archived</label
          >
        </li>
      </ul>
    </nz-dropdown-menu>

    <button
      nz-button
      nzType="primary"
      (click)="addClientVisible = true"
      nzSize="large"
    >
      <i nz-icon nzType="plus" nzTheme="outline"></i>
      Add a New Client
    </button>
  </div>
</div>

<nz-table
  #clientsTable
  nzShowSizeChanger
  [nzData]="tableData.listOfClients"
  [nzFrontPagination]="false"
  [nzLoading]="tableData.loading"
  [nzTotal]="tableData.total"
  [nzPageSize]="tableData.tableOptions.pageSize"
  [nzPageIndex]="tableData.tableOptions.pageIndex"
  (nzQueryParams)="onQueryParamsChange($event)"
  [nzFooter]="footerMessage"
  [nzScroll]="{ x: '1100px' }"
>
  <thead>
    <tr>
      <th nzLeft nzWidth="50px" nzColumnKey="index">#</th>
      <th nzLeft nzWidth="150px" nzColumnKey="alias" [nzSortFn]="true">
        Client Alias
      </th>
      <th nzWidth="150px" nzColumnKey="name">Client Name</th>
      <th nzWidth="150px" nzColumnKey="planState" [nzSortFn]="true">
        Income Plan State
      </th>
      <th nzWidth="200px" nzColumnKey="optimizerDetails">
        Income Plan Optimized State
      </th>
      <th nzWidth="150px" nzColumnKey="lastAccessed" [nzSortFn]="true">
        Last Accessed
      </th>
      <th nzRight nzWidth="200px" nzColumnKey="actions">Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let client of tableData.listOfClients; index as clientIndex">
      <td nzLeft>
        {{
          (clientsTable.nzPageIndex - 1) * clientsTable.nzPageSize +
            clientIndex +
            1
        }}
      </td>

      <td nzLeft>
        {{ client.alias }}
        <nz-tag *ngIf="client.archived" [nzColor]="'red'">Archived</nz-tag>
      </td>
      <td>{{ toClientFullName(client) }}</td>
      <td>
        <div
          *ngIf="planIsNotConfigured(client)"
          class="clients-table__item__plan-status__not-configured"
        >
          <nz-tag class="clients-table__item__plan-status__not-configured__tag">
            <i nz-icon nzType="warning"></i>
            Plan Not Configured</nz-tag
          >
        </div>
        <div
          *ngIf="planHasStarted(client)"
          class="clients-table__item__plan-status__inprogress"
        >
          <nz-tag
            nzColor="processing"
            class="clients-table__item__plan-status__inprogress__tag"
            nz-tooltip
            [nzTooltipTitle]="toClientPlanStartedFormatted(client)"
          >
            <i nz-icon nzType="sync"></i>

            <span>Plan Inprogress</span>
          </nz-tag>
        </div>
        <div
          *ngIf="planAboutToStart(client)"
          class="clients-table__item__plan-status__upcoming"
        >
          <nz-tag
            [nzColor]="'cyan'"
            class="clients-table__item__plan-status__upcoming__tag"
            [nzTooltipTitle]="toClientPlanStartedFormatted(client)"
            nz-tooltip
          >
            <i nz-icon nzType="clock-circle"></i>
            <span>Plan will start soon</span>
          </nz-tag>
        </div>
      </td>
      <td>
        <ng-container *ngIf="planOptimizerState(client)">
          <nz-tag
            [nzColor]="planOptimizerState(client)?.color"
            class="clients-table__item__optimzer-status__tag"
            [nzTooltipTitle]="planOptimizerState(client)?.tooltipMessage"
            nz-tooltip
          >
            {{ planOptimizerState(client)?.text }}
          </nz-tag>
        </ng-container>
      </td>
      <td>{{ toClientLastAccessFormatted(client) }}</td>

      <td nzRight>
        <div class="clients-table__item__more-actions">
          <a
            [routerLink]="clientPageRouterLink(client)"
            nz-button
            nzType="default"
            nzShape="circle"
            nzTooltipTitle="View Details"
            nz-tooltip
          >
            <i nz-icon nzType="edit"></i>
          </a>

          <a
            *ngIf="client.emailAddress"
            [href]="toClientEmailLink(client.emailAddress)"
            nz-button
            nzType="default"
            nzShape="circle"
            [nzTooltipTitle]="client.emailAddress"
            nz-tooltip
          >
            <i nz-icon nzType="mail"></i>
          </a>

          <a
            (click)="toggleClientTheme(client)"
            nz-button
            nzType="default"
            nzShape="circle"
            nz-tooltip
            [nzTooltipTitle]="toClientPinTooltip(client)"
            [class.pinned-circle-btn]="client.pinned"
            [class.not-pinned-circle-btn]="!client.pinned"
          >
            <i
              nz-icon
              nzType="pushpin"
              [nzTheme]="toClientPinTheme(client)"
            ></i>
          </a>

          <button
            *ngIf="!client.archived"
            nz-button
            nzType="default"
            nzShape="circle"
            nzTooltipTitle="Archive"
            nz-tooltip
            nzDanger
            (click)="openArchiveConfirmation(client)"
          >
            <i nz-icon nzType="delete"></i>
          </button>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>

<app-add-client-modal
  *ngIf="addClientVisible"
  (cancelOperation)="onCancelAddClient()"
  (operationSuccess)="onAddClientSuccess($event)"
></app-add-client-modal>

<app-archive-client-dialog
  *ngIf="archiveOperation.open && archiveOperation.data"
  [client]="archiveOperation.data"
  (operationSuccess)="onArchiveOperationSuccess()"
  (cancelOperation)="onArchiveOperationCancel()"
>
</app-archive-client-dialog>
