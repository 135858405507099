import { ChartConfiguration, Chart, ChartOptions, ChartData } from 'chart.js';
import { Colors } from 'src/app/utils/color-util';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformCurrency } from 'src/app/utils/transform-currency';

import {
  computeNthXAxisLabelToDisplay,
  computeNumberOfDisplayableLabels,
} from '../constants';

export interface LoansCreatedChartConfig {
  labels: string[];
  data: number[];
  contributions: number[];
  xAxisScaleLabel?: string;
  yAxisScaleLabel?: string;
  seriesLabel?: string;
  currency: string;
}

export function toChartCreationConfig(
  ctx: CanvasRenderingContext2D,
  config: LoansCreatedChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartConfiguration {
  return {
    type: 'LoanUnpaidBalance',
    data: toChartData(ctx, config),
    options: toChartOptions(config, onResize, canvasWidth),
  };
}

const PassiveIncomeDatasetIndex = 0;
const MonthlyContributionDatasetIndex = 1;

export function toChartData(
  ctx: CanvasRenderingContext2D,
  config: LoansCreatedChartConfig
): ChartData {
  const { labels, data, contributions, seriesLabel } = config;
  const backgroundColor = Colors.greenGradient(ctx);
  const borderColor = Colors.green;
  const pointRadius = 0;
  const datasets: Chart.ChartDataSets[] = [];
  datasets[PassiveIncomeDatasetIndex] = {
    label: 'Loans Created',
    borderColor: Colors.green,
    backgroundColor: Colors.greenGradient(ctx),
    pointRadius,
    data: data,
  };
  datasets[MonthlyContributionDatasetIndex] = {
    label: 'Cumulative Contributions',
    borderColor: Colors.red,
    backgroundColor: Colors.redGradient(ctx),
    pointRadius,
    data: contributions,
  };
  return {
    labels,
    datasets
  };
}

export function toChartOptions(
  config: LoansCreatedChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartOptions {
  const numberOfDisplayableLabels =
    computeNumberOfDisplayableLabels(canvasWidth);
  const { xAxisScaleLabel, yAxisScaleLabel, currency, labels } = config;
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    onResize,
    tooltips: {
      backgroundColor: Colors.white,
      titleFontColor: Colors.white,
      bodyFontColor: Colors.blue,

      /*
        bodyFontStyle is shady - it is used here to set font weight
        https://github.com/chartjs/Chart.js/issues/4842#issuecomment-336745159
      */
        bodyFontFamily: 'monospace',
      bodyFontStyle: '600',
      bodyFontSize: 15,
      borderColor: Colors.grey2,
      borderWidth: 1,
      xPadding: 12,
      yPadding: 12,
      displayColors: false,
      callbacks: {
        title: () => '',
        label: ({ datasetIndex, index, yLabel }, { datasets }) => {
          const datasetPassiveIncome =
            datasets && datasets.length > 0
              ? datasets[PassiveIncomeDatasetIndex].data
              : undefined;
          const passiveIncomeValue =
            datasetPassiveIncome && isNumberValid(index)
              ? (datasetPassiveIncome[index] as number)
              : undefined;
          const datasetMonthlyContribution =
            datasets && datasets.length > 0
              ? datasets[MonthlyContributionDatasetIndex].data
              : undefined;
          const datasetMonthlyContributionValue =
          datasetMonthlyContribution && isNumberValid(index)
              ? (datasetMonthlyContribution[index] as number)
              : undefined;


          if (
            isNumberValid(passiveIncomeValue) &&
            isNumberValid(datasetMonthlyContributionValue) 
          ) {
            const monthlyContributionStr =
              transformCurrency(datasetMonthlyContributionValue, currency, true) ?? '';
            const passiveIncomeValueStr =
              transformCurrency(passiveIncomeValue, currency, true) ?? '';

            return datasetIndex === MonthlyContributionDatasetIndex
              ? `Cumulative Contributions  ${monthlyContributionStr}`
              : datasetIndex === PassiveIncomeDatasetIndex
              ? `Loans Created             ${passiveIncomeValueStr}`
              : '';
          }
          return '';
        },
      },
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!xAxisScaleLabel,
            labelString: xAxisScaleLabel,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: (() => {
              const numberOfYears = Math.floor((labels.length - 1) / 12);
              const enoughWidthForAllLabels =
                numberOfDisplayableLabels >= numberOfYears;
              const nThLabelToDisplay = computeNthXAxisLabelToDisplay(
                numberOfYears,
                numberOfDisplayableLabels
              );
              return enoughWidthForAllLabels
                ? (value: string, index: number) =>
                    index % 12 !== 0 ? null : index / 12
                : (value: string, index: number) => {
                    const currentVal = Math.floor(index / 12);
                    const shouldDisplayThisLabel =
                      currentVal === 0 ||
                      currentVal === numberOfYears ||
                      (currentVal % nThLabelToDisplay === 0 &&
                        currentVal >= nThLabelToDisplay &&
                        numberOfYears - currentVal >= nThLabelToDisplay);
                    return shouldDisplayThisLabel
                      ? index % 12 !== 0
                        ? null
                        : currentVal
                      : null;
                  };
            })(),
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!yAxisScaleLabel,
            labelString: yAxisScaleLabel,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              return transformCurrency(value as number, currency, true);
            },
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)', // hide grid lines
          },
        },
      ],
    },
  };
}

function defineLoansCreatedChartType(): void {
  Chart.defaults.LoanUnpaidBalance = Chart.defaults.line;
  Chart.controllers.LoanUnpaidBalance = Chart.controllers.line.extend({
    draw(ease: any): void {
      Chart.controllers.line.prototype.draw.call(this, ease);

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const activePoint = this.chart.tooltip._active[0];
        const ctx = this.chart.ctx;
        ctx.save();
        this.chart.tooltip._active.forEach((a: any, i: number) => {
          const activePoint = this.chart.tooltip._active[i];
          const x = activePoint.tooltipPosition().x;
          const topY = this.chart.legend.bottom;
          const y = activePoint.tooltipPosition().y;
          const bottomY = this.chart.chartArea.bottom;

          if (i === 0) {
            // draw vertical line
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = Colors.orange;
            ctx.stroke();
            ctx.closePath();
          }

          // draw point

          const pointThemeColor =
            i === MonthlyContributionDatasetIndex
              ? Colors.red
              : i === PassiveIncomeDatasetIndex
              ? Colors.blue
              : undefined;
          ctx.fillStyle = Colors.orange;
          ctx.beginPath();
          ctx.arc(x, y, 9, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();

          ctx.fillStyle = Colors.white;
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();

          ctx.fillStyle = pointThemeColor;
          ctx.beginPath();
          ctx.arc(x, y, 3, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();
        });
        ctx.restore();
      }
    },
  });
}

defineLoansCreatedChartType();
