<div class="cou-step-finish">
  <h5 class="cou-step-finish__success">{{ finishTitle }}</h5>
  <div class="cou-step-finish__alert">
    <nz-alert
      nzType="warning"
      [nzMessage]="finishWarning"
      nzShowIcon
    ></nz-alert>
    <ng-template #finishWarning>
      <b>Must Read:</b> The new plan result will not be well optimized. Visit
      'Income Plan Optimizer' tab to optimize for the new plan settings.
    </ng-template>
  </div>
  <div class="cou-step-finish__info">
    <p class="cou-step-finish__info__tertiary">
      {{ finishDescription }}
    </p>
  </div>
</div>
