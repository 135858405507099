<ng-container *ngIf="client">
  <nz-spin
    class="analysis__fetch-spinner"
    *ngIf="analysisFetchInProgress"
    nzSimple
    [nzSize]="'large'"
  ></nz-spin>

  <div
    class="client-page__fetch-failed-alert-wrapper"
    *ngIf="analysisFetchFailed && analysisFetchErrorMessage"
  >
    <nz-alert
      nzType="error"
      [nzMessage]="analysisFetchErrorMessage"
      nzShowIcon
    ></nz-alert>
  </div>

  <div
    class="analysis__content"
    *ngIf="analysisFetchSuccess && fetchAnalysisApiCall.data"
  >
    <app-comparative-analysis
      [analysisPlans]="fetchAnalysisApiCall.data"
      [analysisPlanWithCompareAndScores]="analysisPlanWithCompareAndScores"
      [updateAnalysisIdToApiCallMap]="updateAnalysisIdToApiCallMap"
      [timelineSectionLabels]="timelineSectionLabels"
      [freedomDaySectionLabels]="freedomDaySectionLabels"
      [numberSectionLabels]="numberSectionLabels"
      [otherSectionLabels]="otherSectionLabels"
      (analysisFormDataChanged)="onAnalysisFormDataChanged($event)"
      (deleteAnalysisClicked)="openDeleteAnalysisConfirm($event)"
      (makeCurrentPlanClicked)="openMarkAnalysisAsCurrentPlanConfirm($event)"
      (copyCurrentPlanClicked)="openCopyCurrentPlanConfirm()"
      (clonePlanPlanClicked)="openClonePlanConfirm($event)"
    ></app-comparative-analysis>
  </div>

  <app-confirm-delete-from-analysis
    *ngIf="
      deleteAnalysisPlanConfirmModal.open &&
      deleteAnalysisPlanConfirmModal.analysisPlan
    "
    [client]="client"
    [analysisPlan]="deleteAnalysisPlanConfirmModal.analysisPlan"
    (cancelOperation)="cancelDeleteAnalysisPlan()"
    (operationSuccess)="deleteAnalysisPlanSuccess()"
  ></app-confirm-delete-from-analysis>

  <app-confirm-make-current-from-analysis
    *ngIf="markAsPlanConfirmModal.open && markAsPlanConfirmModal.analysisPlan"
    [client]="client"
    [analysisPlan]="markAsPlanConfirmModal.analysisPlan"
    (cancelOperation)="cancelMarkAsCurrent()"
    (operationSuccess)="markAsCurrentSuccess()"
  ></app-confirm-make-current-from-analysis>

  <app-confirm-copy-current-plan
    *ngIf="copyCurrentPlanConfirmModal.open"
    [client]="client"
    (cancelOperation)="cancelCopyCurrentPlan()"
    (operationSuccess)="copyCurrentPlanSuccess($event)"
  ></app-confirm-copy-current-plan>

  <app-confirm-clone-analysis-plan
    *ngIf="clonePlanConfirmModal.open && clonePlanConfirmModal.data"
    [client]="client"
    [sourceAnalysisPlan]="clonePlanConfirmModal.data.sourceAnalysisPlan"
    [sourceAnalysisPlanIndex]="
      clonePlanConfirmModal.data.sourceAnalysisPlanIndex
    "
    (cancelOperation)="cancelClonePlan()"
    (operationSuccess)="clonePlanSuccess($event)"
  ></app-confirm-clone-analysis-plan>
</ng-container>
