import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'iris-frontend';

  constructor(
    public authService: AuthService,
    private i18n: NzI18nService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.i18n.setLocale(en_US);
  }

  get userName() {
    return this.authService.user?.name;
  }
}
