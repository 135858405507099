import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  NzTableComponent,
  NzTableQueryParams,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { debounce } from 'src/app/common/debounce';
import { Client } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  toApiAuthHeaders,
  toClientPlanStartedFormatted,
} from 'src/app/utils/app-common';
import {
  dateIsInFuture,
  dateIsInPast,
  formatDifferenceForDate,
} from 'src/app/utils/date-utils';
import { RouteClientPage } from 'src/app/utils/route-constants';
import { extractPlanOptimizerState, getClientFullName } from '../client-utils';

interface TableOptions {
  pageSize: number;
  pageIndex: number;
  searchQuery: string | null;
  sortField: string | null;
  sortOrder: NzTableSortOrder;
  filters: {
    showArchived: boolean;
    showPinned: boolean;
  };
}

const FirstPageIndex = 1;

@Component({
  selector: 'app-clients-table',
  templateUrl: './clients-table.component.html',
  styleUrls: ['./clients-table.component.scss'],
})
export class ClientsTableComponent implements OnInit {
  addClientVisible = false;
  clientName = '';

  @ViewChild('clientsTable') clientsTable!: NzTableComponent;

  archiveOperation: {
    open: boolean;
    data?: Client;
  };

  tableData: {
    total: number;
    listOfClients: Client[];
    loading: boolean;
    tableOptions: TableOptions;
  };

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private advisorStateService: AdvisorStateService,
    public authService: AuthService
  ) {
    this.archiveOperation = {
      open: false,
    };
    this.tableData = {
      total: 1,
      listOfClients: [],
      loading: false,
      tableOptions: {
        pageSize: 10,
        pageIndex: FirstPageIndex,
        searchQuery: '',
        sortField: null,
        sortOrder: null,
        filters: {
          showArchived: false,
          showPinned: false,
        },
      },
    };
    this.onSearchQueryChange = debounce(this.onSearchQueryChange, 500);
  }

  gotoClient(client: Client) {
    this.router.navigate([]);
  }

  clientPageRouterLink(client: Client) {
    return [RouteClientPage(client.id)];
  }

  cancelAddClient() {
    this.addClientVisible = false;
  }

  onAddClientSuccess(client: Client) {
    this.addClientVisible = false;
    this.router.navigate([RouteClientPage(client.id)]);
  }

  onCancelAddClient() {
    this.addClientVisible = false;
  }

  loadDataFromServer(): void {
    this.tableData.loading = true;
    const { sortField, searchQuery, sortOrder, pageIndex, pageSize, filters } =
      this.tableData.tableOptions;

    const queryParams = new URLSearchParams();
    if (sortField && sortOrder) {
      if (sortField === 'lastAccessed') {
        queryParams.set('sort', 'accessed');
      }
      if (sortField === 'alias') {
        queryParams.set('sort', 'alias');
      }
      if (sortField === 'planState') {
        queryParams.set('sort', 'start');
      }

      queryParams.set('order', sortOrder === 'ascend' ? 'asc' : 'desc');
    }
    if (searchQuery) {
      queryParams.set('search', searchQuery);
    }

    if (filters.showArchived) {
      queryParams.set('archived', 'true');
    }
    if (filters.showPinned) {
      queryParams.set('pinned', 'true');
    }
    queryParams.set('start', `${pageSize * (pageIndex - 1)}`);
    queryParams.set('limit', `${pageSize}`);

    const url = `/api/clients?${queryParams.toString()}`;

    this.httpClient
      .get<{ clients: Client[]; count: number }>(url, {
        headers: toApiAuthHeaders(this.authService),
      })
      .subscribe((res) => {
        this.tableData.loading = false;
        this.tableData.total = res.count;
        this.tableData.listOfClients = res.clients;
      });
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find((item) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;

    this.tableData.tableOptions.sortField = sortField;
    this.tableData.tableOptions.sortOrder = sortOrder;
    this.tableData.tableOptions.pageIndex = pageIndex;
    this.tableData.tableOptions.pageSize = pageSize;

    this.loadDataFromServer();
  }

  ngOnInit(): void {
    this.loadDataFromServer();
  }

  onSearchQueryChange() {
    this.setTablePageToFirstPage();
    this.loadDataFromServer();
  }

  toClientFullName(client: Client) {
    return getClientFullName(client);
  }

  toClientEmailLink(emailAddress: string) {
    return `mailto:${emailAddress}`;
  }

  toClientPlanStartedFormatted = toClientPlanStartedFormatted;

  toggleClientTheme(client: Client) {
    client.pinned = !client.pinned;
    const url = `/api/clients/${client.id}`;

    this.httpClient
      .post(
        url,
        { pinned: client.pinned },
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (res) => {},
        (err) => {}
      );
  }

  planHasStarted(client: Client) {
    if (client.plan?.inputs) {
      const planStartDate = new Date(client.plan.inputs.startDate);
      return dateIsInPast(planStartDate);
    }
    return false;
  }
  planIsNotConfigured(client: Client) {
    return !client.plan;
  }

  planAboutToStart(client: Client) {
    if (client.plan?.inputs) {
      const planStartDate = new Date(client.plan.inputs.startDate);
      return dateIsInFuture(planStartDate);
    }
    return false;
  }

  planOptimizerState(client: Client) {
    if (client.plan) {
      return extractPlanOptimizerState(
        client.plan.optimizer,
        client.plan.optimizerLevel
      );
    }
    return undefined;
  }

  toClientLastAccessFormatted(client: Client) {
    if (client.lastAccessed) {
      const lastAccessedDate = new Date(client.lastAccessed);
      return `${formatDifferenceForDate(lastAccessedDate)}`;
    } else {
      return undefined;
    }
  }

  onArchiveOperationCancel() {
    this.archiveOperation = {
      open: false,
    };
  }

  onArchiveOperationSuccess() {
    this.archiveOperation = {
      open: false,
    };
    this.loadDataFromServer();
  }

  openArchiveConfirmation(client: Client) {
    this.archiveOperation = {
      open: true,
      data: client,
    };
  }

  get footerMessage() {
    const totalElements = this.tableData.total;
    return this.tableData.loading
      ? '...'
      : totalElements <= 0
      ? 'No Clients'
      : `Total ${totalElements} Clients`;
  }

  get howManyFiltersApplied() {
    let filterCount = 0;
    filterCount +=
      this.tableData.tableOptions.filters.showArchived === true ? 1 : 0;
    filterCount +=
      this.tableData.tableOptions.filters.showPinned === true ? 1 : 0;
    return filterCount;
  }

  get filterBtnText() {
    return 'Filters';
  }

  get filterIconType() {
    return this.howManyFiltersApplied > 0 ? 'twotone' : 'outline';
  }

  setTablePageToFirstPage() {
    this.tableData.tableOptions.pageIndex = FirstPageIndex;
  }

  onTableFilterChange() {
    this.setTablePageToFirstPage();
    this.loadDataFromServer();
  }

  toClientPinTheme(client: Client) {
    return client.pinned ? 'twotone' : 'outline';
  }
  toClientPinTooltip(client: Client) {
    return `${
      client.pinned ? 'Pinned, click to Unpin' : 'Not Pinned, click to Pin'
    }.  Pinned client can be filtered using filters.`;
  }
}
