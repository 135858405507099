import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  @Input() client?: Client;

  todayDate = new Date();

  unsustainedMonthsPercentThreshold = 2; // 2%
  unsustainedMonths = 0;
  unsustained = false;
  partlyUnsustained = false;

  constructor() {}

  ngOnInit(): void {
    if (this.client!.plan && this.client!.plan.results) {
      let results: any = this.client!.plan!.results;
      let freedomMonth = results.msgs!.freedom_months;
      let simMonths = results.msgs!.sim_years * 12;
      let unsustainedMonthsThreshold = Math.ceil(
        (simMonths - freedomMonth + 1) *
          (this.unsustainedMonthsPercentThreshold / 100)
      );
      let passiveIncome = results.msgs!.passive_income;
      let index = freedomMonth;
      let unsustained = true;
      let unsustainedMonths = 0;
      while (index < simMonths) {
        let data = results.data[index];
        if (data.passive < passiveIncome) {
          unsustainedMonths++;
          if (unsustainedMonths >= unsustainedMonthsThreshold) {
            unsustained = true;
          }
        } else if (unsustainedMonths === 0) {
          unsustained = false;
        }
        index++;
      }

      this.unsustainedMonths = unsustainedMonths;
      this.unsustained = unsustained;
      this.partlyUnsustained = !unsustained && unsustainedMonths > 0;
    }
  }

  get clientFreedomDate() {
    return this.client?.plan?.results?.msgs.freedom_date;
  }
  get clientFreedomIn() {
    return this.client?.plan?.results?.msgs.freedom_in;
  }

  get clientPlanStartDate() {
    return this.client?.plan?.inputs?.startDate;
  }

  get todayAfterStart() {
    let startDate = new Date(this.client!.plan!.inputs!.startDate);
    if (startDate && this.todayDate > startDate) {
      return true;
    }

    return false;
  }
}
