import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-loc',
  templateUrl: './loc.component.html',
  styleUrls: ['./loc.component.scss']
})
export class LocComponent implements OnInit {

  @Input() client?: Client;

  payments?: any[];

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  ngOnInit(): void {
    this.httpClient.get('/api/clients/' + this.client!.id + '/payments',{
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('X-Access-Token', `Bearer ${this.authService.token}`),
    }).subscribe((result: any)=> {
      this.payments = result.payments;
    });
  }

}
