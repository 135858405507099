import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  email?: string;
  password?: string;

  loginApiCall: AsyncOpData;

  validation = {
    email: {
      message: '',
      status: '',
    },
    password: {
      message: '',
      status: '',
    },
  };

  submittedAtLeastOnce = false;

  constructor(
    private router: Router,

    private authService: AuthService
  ) {
    this.loginApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  checkValidationStatus() {
    const { email, password, submittedAtLeastOnce } = this;
    if (!submittedAtLeastOnce) {
      return;
    }
    if (!email) {
      this.validation.email.message = 'Email is required';
      this.validation.email.status = 'error';
    } else {
      this.validation.email.status = '';
    }

    if (!password) {
      this.validation.password.message = 'Password is required';
      this.validation.password.status = 'error';
    } else {
      this.validation.password.status = '';
    }
  }

  login() {
    this.submittedAtLeastOnce = true;
    this.checkValidationStatus();
    const { email, password } = this;

    if (!email || !password) {
      return;
    }

    this.loginApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.authService
      .login(email, password)
      .then((result: boolean) => {
        if (result) {
          this.loginApiCall = {
            state: AsyncOpState.Completed,
          };
          if (this.authService.redirectUrl) {
            this.router.navigate(['/' + this.authService.redirectUrl]);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.loginApiCall = {
            state: AsyncOpState.Failed,
            error: 'Your email and password do not match our records.',
          };
        }
      })
      .catch((err) => {
        this.loginApiCall = {
          state: AsyncOpState.Failed,
          error: 'Could not log you in. Something is not right.',
        };
      });
  }

  get loginInProgress() {
    return this.loginApiCall.state === AsyncOpState.Inprogress;
  }

  get loginFailed() {
    return this.loginApiCall.state === AsyncOpState.Failed;
  }
  get loginErrorMessage() {
    return this.loginApiCall.error;
  }
}
