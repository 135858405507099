import { Chart, ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import { Colors } from 'src/app/utils/color-util';
import { isNumberValid } from 'src/app/utils/number-utils';
import { transformCurrency } from 'src/app/utils/transform-currency';
import {
  computeNthXAxisLabelToDisplay,
  computeNumberOfDisplayableLabels,
} from '../constants';

export interface PlanCashflowChartConfig {
  labels: string[];
  contributionValues: number[];
  passiveIncomeValues: number[];
  totalLoansCfValues: number[];
  xAxisScaleLabel: string;
  yAxisScaleLabel: string;
  currency: string;
}

export function toChartCreationConfig(
  ctx: CanvasRenderingContext2D,
  config: PlanCashflowChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartConfiguration {
  return {
    type: 'IrisCashflowChart',
    data: toChartData(ctx, config),
    options: toChartOptions(config, onResize, canvasWidth),
  };
}

const PassiveIncomeDatasetIndex = 0;
const MonthlyContributionDatasetIndex = 1;
const LoanCashflowDatasetIndex = 2;

export function toChartData(
  ctx: CanvasRenderingContext2D,
  config: PlanCashflowChartConfig
): ChartData {
  const {
    labels,
    contributionValues,
    passiveIncomeValues,
    totalLoansCfValues,
  } = config;

  const pointRadius = 0;

  const datasets: Chart.ChartDataSets[] = [];
  datasets[PassiveIncomeDatasetIndex] = {
    label: 'Passive Income',
    borderColor: Colors.green,
    backgroundColor: Colors.greenGradient(ctx),
    pointRadius,
    data: passiveIncomeValues,
  };
  datasets[MonthlyContributionDatasetIndex] = {
    label: 'Monthly Contribution',
    borderColor: Colors.red,
    backgroundColor: Colors.redGradient(ctx),
    pointRadius,
    data: contributionValues,
  };
  datasets[LoanCashflowDatasetIndex] = {
    label: 'Monthly Cash Flow',
    borderColor: Colors.blue,
    backgroundColor: Colors.blueGradient(ctx),
    pointRadius,
    data: totalLoansCfValues,
  };

  return {
    labels,
    datasets,
  };
}

export function toChartOptions(
  config: PlanCashflowChartConfig,
  onResize: () => void,
  canvasWidth: number
): ChartOptions {
  const numberOfDisplayableLabels =
    computeNumberOfDisplayableLabels(canvasWidth);
  const { xAxisScaleLabel, yAxisScaleLabel, currency, labels } = config;
  return {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: false,
    },
    onResize,
    tooltips: {
      backgroundColor: Colors.white,

      bodyFontColor: Colors.blue,

      /*
        bodyFontStyle is shady - it is used here to set font weight
        https://github.com/chartjs/Chart.js/issues/4842#issuecomment-336745159
      */
      // bodyFontStyle: '500',
      bodyFontSize: 14,
      borderColor: Colors.grey2,
      titleFontSize: 15,
      titleFontStyle: '600',
      titleFontColor: Colors.blue,

      bodyFontFamily: 'monospace',

      borderWidth: 1,
      xPadding: 12,
      yPadding: 12,
      displayColors: false,
      callbacks: {
        title: (items) => {
          const year = Math.floor((items[0].xLabel as number) / 12);
          const month = Math.floor((items[0].xLabel as number) % 12);
          return `Year ${year} ${month > 0 ? `Month ${month}` : ''}`;
        },
        label: ({ datasetIndex, index, yLabel }, { datasets }) => {
          const datasetPassiveIncome =
            datasets && datasets.length > 0
              ? datasets[PassiveIncomeDatasetIndex].data
              : undefined;
          const passiveIncomeValue =
            datasetPassiveIncome && isNumberValid(index)
              ? (datasetPassiveIncome[index] as number)
              : undefined;
          const datasetMonthlyContribution =
            datasets && datasets.length > 0
              ? datasets[MonthlyContributionDatasetIndex].data
              : undefined;
          const monthlyContributionValue =
            datasetMonthlyContribution && isNumberValid(index)
              ? (datasetMonthlyContribution[index] as number)
              : undefined;

          const datasetLoanCashflow =
            datasets && datasets.length > 0
              ? datasets[LoanCashflowDatasetIndex].data
              : undefined;
          const loanCashflowValue =
            datasetLoanCashflow && isNumberValid(index)
              ? (datasetLoanCashflow[index] as number)
              : undefined;

          if (
            isNumberValid(passiveIncomeValue) &&
            isNumberValid(monthlyContributionValue) &&
            isNumberValid(loanCashflowValue)
          ) {
            const monthlyContributionStr =
              transformCurrency(monthlyContributionValue, currency, true) ?? '';
            const passiveIncomeValueStr =
              transformCurrency(passiveIncomeValue, currency, true) ?? '';
            const loanCashflowValueStr =
              transformCurrency(loanCashflowValue, currency, true) ?? '';

            return datasetIndex === MonthlyContributionDatasetIndex
              ? `Monthly Contribution    ${monthlyContributionStr}`
              : datasetIndex === PassiveIncomeDatasetIndex
              ? `Passive Income          ${passiveIncomeValueStr}`
              : datasetIndex === LoanCashflowDatasetIndex
              ? `Monthly Cash Flow       ${loanCashflowValueStr}`
              : '';
          }
          return '';
        },
      },
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!xAxisScaleLabel,
            labelString: xAxisScaleLabel,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: (() => {
              const numberOfYears = Math.floor((labels.length - 1) / 12);
              const enoughWidthForAllLabels =
                numberOfDisplayableLabels >= numberOfYears;
              const nThLabelToDisplay = computeNthXAxisLabelToDisplay(
                numberOfYears,
                numberOfDisplayableLabels
              );
              return enoughWidthForAllLabels
                ? (value: string, index: number) =>
                    index % 12 !== 0 ? null : index / 12
                : (value: string, index: number) => {
                    const currentVal = Math.floor(index / 12);
                    const shouldDisplayThisLabel =
                      currentVal === 0 ||
                      currentVal === numberOfYears ||
                      (currentVal % nThLabelToDisplay === 0 &&
                        currentVal >= nThLabelToDisplay &&
                        numberOfYears - currentVal >= nThLabelToDisplay);
                    return shouldDisplayThisLabel
                      ? index % 12 !== 0
                        ? null
                        : currentVal
                      : null;
                  };
            })(),
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: !!yAxisScaleLabel,
            labelString: yAxisScaleLabel,
          },
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              return transformCurrency(value as number, currency, true);
            },
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0)', // hide grid lines
          },
        },
      ],
    },
  };
}

function defineIrisCashflowChartChartType(): void {
  Chart.defaults.IrisCashflowChart = Chart.defaults.line;
  Chart.controllers.IrisCashflowChart = Chart.controllers.line.extend({
    draw(ease: any): void {
      Chart.controllers.line.prototype.draw.call(this, ease);

      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
        const ctx = this.chart.ctx;
        ctx.save();
        this.chart.tooltip._active.forEach((a: any, i: number) => {
          const activePoint = this.chart.tooltip._active[i];
          const x = activePoint.tooltipPosition().x;
          const topY = this.chart.legend.bottom;
          const y = activePoint.tooltipPosition().y;
          const bottomY = this.chart.chartArea.bottom;

          if (i === 0) {
            // draw vertical line
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = Colors.orange;
            ctx.stroke();
            ctx.closePath();
          }

          // draw point

          const pointThemeColor =
            i === MonthlyContributionDatasetIndex
              ? Colors.red
              : i === PassiveIncomeDatasetIndex
              ? Colors.green
              : i === LoanCashflowDatasetIndex
              ? Colors.blue
              : undefined;
          ctx.fillStyle = Colors.orange;
          ctx.beginPath();
          ctx.arc(x, y, 9, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();

          ctx.fillStyle = Colors.white;
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();

          ctx.fillStyle = pointThemeColor;
          ctx.beginPath();
          ctx.arc(x, y, 3, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();
        });
        ctx.restore();
      }
    },
  });
}

defineIrisCashflowChartChartType();
