import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AnalysisPlan } from 'src/app/models/analysis-plan';
import { Client } from 'src/app/models/client';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

@Component({
  selector: 'app-confirm-delete-from-analysis',
  templateUrl: './confirm-delete-from-analysis.component.html',
  styleUrls: ['./confirm-delete-from-analysis.component.scss'],
})
export class ConfirmDeleteFromAnalysisComponent {
  submitBtnDanger = true;

  @Input() client!: Client;
  @Input() analysisPlan!: AnalysisPlan;

  @Output() cancelOperation = new EventEmitter();
  @Output() operationSuccess = new EventEmitter();

  actionSubmitApiCall: AsyncOpData<void>;

  submitBtnText = 'Delete';
  modalTitle = 'Delete Analysis Plan';

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.actionSubmitApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  cancelAction() {
    this.cancelOperation.emit();
  }

  get submitBtnDisabled() {
    return false;
  }

  get isSubmitInProgress() {
    return this.actionSubmitApiCall.state === AsyncOpState.Inprogress;
  }

  get submitFailErrorMessage() {
    return this.actionSubmitApiCall.error;
  }

  get showSubmitFailErrorMessage() {
    return this.actionSubmitApiCall.state === AsyncOpState.Failed;
  }

  submitAction() {
    this.actionSubmitApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.httpClient
      .delete(
        '/api/clients/' + this.client!.id + '/analysis/' + this.analysisPlan.id,
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (result: any) => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Completed,
          };
          this.operationSuccess.emit();
        },
        () => {
          this.actionSubmitApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to delete analysis plan',
          };
        }
      );
  }
}
