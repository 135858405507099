<nz-table #dataTable *ngIf="cashFlows" [nzData]="cashFlows" [nzPageSize]="pageSize">
  <thead>
    <tr>
      <th>Year</th>
      <th>Cash Flow</th>
      <th>UPB</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cashFlow of dataTable.data">
      <td>{{ cashFlow.year + 1 }}</td>
      <td>{{ cashFlow.cashFlow | currency: "USD":"$":"0.0-0" }}</td>
      <td>{{ cashFlow.upb | currency: "USD":"$":"0.0-0" }}</td>
    </tr>
  </tbody>
</nz-table>