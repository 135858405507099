import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnChanges {
  @Input() progress?: number;
  @Input() started?: Date;

  hoursRemaining?: number;
  minutesRemaining?: number;
  secondsRemaining?: number;

  format = (percent: number) => `${Math.floor(percent)}%`;

  constructor() {}

  ngOnChanges(): void {
    let currentDate = new Date();

    if (this.started && this.progress !== undefined) {
      let elapsedSeconds =
        (currentDate.getTime() - this.started.getTime()) / 1000;
      let remaining = (elapsedSeconds / this.progress) * (100 - this.progress);
      this.hoursRemaining = Math.floor(remaining / 3600);
      remaining -= this.hoursRemaining * 3600;
      this.minutesRemaining = Math.floor(remaining / 60);
      remaining -= this.minutesRemaining * 60;
      this.secondsRemaining = remaining;
    }
  }
}
