import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  user?: User;

  isEditting = false;

  constructor(
    private httpClient: HttpClient,
    public authService: AuthService
  ) {}

  edit() {
    this.user = Object.assign({}, this.authService.user);
    this.isEditting = true;
  }

  save() {
    this.isEditting = false;

    this.httpClient
      .post(
        '/api/user',
        {
          firstName: this.user!.firstName,
          lastName: this.user!.lastName,
          email: this.user!.email,
          phoneNumber: this.user!.phoneNumber,
          type: this.user!.type,
        },
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-Access-Token', `Bearer ${this.authService.token}`),
        }
      )
      .subscribe((result: any) => {
        this.authService.user = this.user;
      });
  }

  cancel() {
    this.isEditting = false;
  }
}
