import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { Client, PlanReport } from 'src/app/models/client';
import { AdvisorStateService } from 'src/app/services/advisor-state.service';
import { AuthService } from 'src/app/services/auth.service';
import { toApiAuthHeaders } from 'src/app/utils/app-common';
import { MissingValuePlaceholderText } from 'src/app/utils/common-display-text';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';
import {
  compareDate,
  formatDifferenceForDate,
  toFullDateFormat,
} from 'src/app/utils/date-utils';
import { openReportPdfInNewTab } from './report-utils';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {
  client?: Client;

  generateLatestReportBtnTxt = 'Generate Latest Plan Report';

  @ViewChild('basicTable') basicTable!: NzTableComponent;
  pageSizeOptions = [5, 10, 15, 25];
  DefaultTablePageSize = this.pageSizeOptions[1];

  sendEmailApiCall: AsyncOpData<void>;

  constructor(
    private httpClient: HttpClient,
    private advisorStateService: AdvisorStateService,
    private message: NzMessageService,
    private authService: AuthService
  ) {
    this.fetchListItemsApiCall = {
      state: AsyncOpState.NotStarted,
    };
    this.sendEmailApiCall = {
      state: AsyncOpState.NotStarted,
    };
  }

  ngOnInit(): void {
    this.client = this.advisorStateService.getClient() as Client;
    this.fetchReports();
  }

  emptyListPlaceholderTitle = 'No Reports to Show';
  emptyListPlaceholderDescription = `Let's generate an awesome plan report for the current income plan for this client.`;

  generateReportModalOpen = false;
  openGenerateReportModal() {
    this.generateReportModalOpen = true;
  }
  closeGenerateReportModal() {
    this.generateReportModalOpen = false;
  }
  onGenerateReportSuccess(newReport: PlanReport) {
    this.closeGenerateReportModal();
    this.fetchListItemsApiCall.data = [newReport].concat(
      this.fetchListItemsApiCall.data || []
    );
    /* navigate table to first page where the new report is added. */
    this.basicTable.onPageIndexChange(1);
    this.fetchReports(true);
  }

  fetchListItemsApiCall: AsyncOpData<PlanReport[]>;

  fetchReports(silentMode = false) {
    if (!silentMode) {
      this.fetchListItemsApiCall = {
        state: AsyncOpState.Inprogress,
      };
    }
    this.httpClient
      .get<PlanReport[] | undefined>(
        '/api/clients/' + this.client?.id + '/reports',
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (res) => {
          this.fetchListItemsApiCall = {
            state: AsyncOpState.Completed,
            data: (res || []).sort((r1, r2) => {
              if (r1.date && r2.date) {
                return compareDate(new Date(r2.date), new Date(r1.date));
              } else if (r2.date) {
                return 1;
              } else {
                return -1;
              }
            }),
          };
        },
        (err) => {
          this.fetchListItemsApiCall = {
            state: AsyncOpState.Failed,
            error: 'Error failed to fetch reports',
          };
        }
      );
  }

  get listItemsEmpty() {
    return !(
      this.fetchListItemsApiCall.data &&
      this.fetchListItemsApiCall.data.length > 0
    );
  }

  get listItemsFetchInProgress() {
    return this.fetchListItemsApiCall.state === AsyncOpState.Inprogress;
  }
  get listItemsFetchSuccess() {
    return this.fetchListItemsApiCall.state === AsyncOpState.Completed;
  }
  get listItemsFetchFailed() {
    return this.fetchListItemsApiCall.state === AsyncOpState.Failed;
  }
  get listItemsFetchErrorMessage() {
    return this.fetchListItemsApiCall.error;
  }

  get footerMessage() {
    const reportsCount = (this.fetchListItemsApiCall.data ?? []).length;
    return reportsCount <= 0 ? 'No reports' : `Total ${reportsCount} reports`;
  }

  downloadReport(report: PlanReport) {
    openReportPdfInNewTab(report);
  }

  sendReport(report: PlanReport) {
    this.sendEmailApiCall = {
      state: AsyncOpState.Inprogress,
    };
    this.httpClient
      .post(
        '/api/clients/' + this.client!.id + '/reports/' + report.id + '/emails',{},
        {
          headers: toApiAuthHeaders(this.authService),
        }
      )
      .subscribe(
        (result: any) => {
          this.sendEmailApiCall = {
            state: AsyncOpState.Completed,
          };
          this.message.create('success', 'Plan sent to the client email'); 
          //this.operationSuccess.emit();
        },
        () => {
          this.sendEmailApiCall = {
            state: AsyncOpState.Failed,
            error: 'Failed to send income plan report',
          };
        }
      );
  }

  toReportDateDiffFormatted(date?: string | Date) {
    if (date) {
      return formatDifferenceForDate(new Date(date));
    } else {
      return MissingValuePlaceholderText;
    }
  }
  toReportFullDateFormatted(date?: string | Date) {
    if (date) {
      return toFullDateFormat(new Date(date));
    } else {
      return 'Unknown Date';
    }
  }

  deleteListItemConfirmModal: {
    open: boolean;
    data?: PlanReport;
  } = {
    open: false,
  };

  openDeleteListItemConfirmModal(data: PlanReport) {
    this.deleteListItemConfirmModal = {
      open: true,
      data,
    };
  }

  closeDeleteListItemConfirmModal() {
    this.deleteListItemConfirmModal = {
      open: false,
    };
  }

  onDeleteListItemSuccess() {
    this.fetchListItemsApiCall.data = (
      this.fetchListItemsApiCall.data || []
    ).filter((a) => a.id !== this.deleteListItemConfirmModal.data?.id);
    this.closeDeleteListItemConfirmModal();
  }
}
