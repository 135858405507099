<nz-card
  style="height: 100%; margin-top: 20px"
  nzTitle="Black Box Income Stack"
  [nzExtra]="extraTemplate"
>
  <div *ngIf="!client.incomeStacks || client.incomeStacks.length == 0">
    No Income Stacks
  </div>
  <table
    *ngIf="client.incomeStacks && client.incomeStacks.length > 0"
    style="width: 100%"
  >
    <thead>
      <th>Title</th>
      <th>Type</th>
      <th>Amount</th>
      <th>Loan Constant</th>
      <th>Income</th>
      <th>Term</th>
    </thead>
    <tr *ngFor="let stack of client.incomeStacks">
      <td>{{ stack.title }}</td>
      <td>
        <nz-tag *ngIf="stack.type == 'PPL'" nzColor="#060A2B">
          Peer to Peer Lending
        </nz-tag>
        <nz-tag *ngIf="stack.type == 'BB'" nzColor="#06A7F5">
          Black Box
        </nz-tag>
      </td>
      <td>{{ stack.amount | currency }}</td>
      <td>{{ stack.loanConstant / 100 | percent:"1.0-2" }}</td>
      <td>
        {{ stack.payment | currency: "USD":"$":"1.0-2" }}
      </td>
      <td>
        {{ stack.payments | number }}
      </td>
      <td style="text-align: right">
        <button nz-button nzType="primary" (click)="editStack(stack)">
          Edit
        </button>
      </td>
    </tr>
  </table>
</nz-card>
<ng-template #extraTemplate>
  <a (click)="addStack()">Add Income Stack</a>
</ng-template>
<nz-modal
  [(nzVisible)]="addStackVisible"
  nzTitle="Income Stack"
  [nzFooter]="customFooter"
  (nzOnCancel)="cancelAddStack()"
  (nzOnOk)="okAddStack()"
>
  <ng-container *nzModalContent>
    <nz-form-item>
      <nz-form-label>Title</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <input nz-input type="text" [(ngModel)]="stackTitle" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Type</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-select [(ngModel)]="stackType" style="width: 120px">
          <!--nz-option nzValue="PPL" nzLabel="Peer to Peer Lending"></nz-option-->
          <nz-option nzValue="BB" nzLabel="Black Box"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Amount to Invest</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="stackAmount"
          [nzFormatter]="formatterDollar"
          [nzParser]="parserDollar"
        >
        </nz-input-number>
      </nz-form-control>
    </nz-form-item>
    <!--nz-form-item>
            <nz-form-label>Monthly Income</nz-form-label>
            <nz-form-control [nzSpan]="24">
                <nz-input-number [(ngModel)]="stackPayment" [nzFormatter]="formatterDollar" [nzParser]="parserDollar">
                </nz-input-number>
            </nz-form-control>
        </nz-form-item-->
    <div>Payments:</div>
    <nz-radio-group [(ngModel)]="stackPaymentType" class="radios">
      <div>
        <label nz-radio nzValue="PAYMENT"
          >Payment
          <nz-input-number
            [(ngModel)]="stackPayment"
            [nzFormatter]="formatterDollar"
            [nzParser]="parserDollar"
            (ngModelChange)="updateStackPayment()"
          ></nz-input-number>
        </label>
      </div>
      <div>
        <label nz-radio nzValue="LOAN_CONSTANT"
          >Loan Constant
          <nz-input-number
            [(ngModel)]="stackLoanConstant"
            [nzFormatter]="formatterPercent"
            [nzParser]="parserPercent"
            (ngModelChange)="updateStackLoanConstant()"
          ></nz-input-number>
        </label>
      </div>
    </nz-radio-group>
    <nz-form-item>
      <nz-form-label>Payment Frequency</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number
          [(ngModel)]="stackFrequency"
          (ngModelChange)="updateFrequency()"
        ></nz-input-number>
        <nz-select
          *ngIf="stackFrequencyGreaterThanZero"
          [(ngModel)]="stackFrequencyUnits"
          style="width: 120px; margin-left: 20px"
          (ngModelChange)="updateFrequency()"
        >
          <nz-option
            nzValue="months"
            [nzLabel]="stackFrequencyMonthsLabel"
          ></nz-option>
          <nz-option
            nzValue="years"
            [nzLabel]="stackFrequencyYearsLabel"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>Term</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <nz-input-number [(ngModel)]="stackPayments"></nz-input-number>
      </nz-form-control>
    </nz-form-item>
  </ng-container>
</nz-modal>
<ng-template #customFooter>
  <button
    *ngIf="isEdittingStack"
    nz-button
    nzType="primary"
    nzDanger
    (click)="deleteStack()"
  >
    Delete
  </button>
  <button nz-button nzType="default" (click)="cancelAddStack()">Cancel</button>
  <button nz-button nzType="primary" (click)="okAddStack()">OK</button>
</ng-template>
