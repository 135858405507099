import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { getClientFullName } from 'src/app/components/home/client-utils';
import { Client } from 'src/app/models/client';
import { PlanInputs } from 'src/app/models/plan-inputs';
import { AuthService } from 'src/app/services/auth.service';
import {
  DefaultSimulationName,
  toApiAuthHeaders,
} from 'src/app/utils/app-common';
import { AsyncOpData, AsyncOpState } from 'src/app/utils/common-utility-types';

interface Step {
  name: string;
  disabled: boolean;
  valid: boolean;
}

@Component({
  selector: 'app-create-or-update-plan-modal',
  templateUrl: './create-or-update-plan-modal.component.html',
  styleUrls: ['./create-or-update-plan-modal.component.scss'],
})
export class CreateOrUpdatePlanModalComponent implements OnInit {
  get modalTitle() {
    return `${
      this.updateMode ? 'Update' : 'Setup'
    } Income Plan for ${getClientFullName(this.client)}`;
  }

  @Input() updateMode = false;

  @Input() client!: Client;

  inputs?: PlanInputs;

  @Output() cancelOperation = new EventEmitter<void>();
  @Output() operationSubmitted = new EventEmitter<void>();

  submitCreateApiCall: AsyncOpData<void>;

  AsyncOpState = AsyncOpState;

  stepState: {
    steps: Step[];
    activeStep: number;
  };

  lineOfCreditSettingsEnabled = false;
  incomeStreamSettingsEnabled = false;
  algorithmSettingsEnabled = false;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {
    this.submitCreateApiCall = {
      state: AsyncOpState.NotStarted,
    };

    if (false) {
      this.stepState = {
        steps: ['Client Goals', 'Lending', 'Income Streams', 'Algorithm', 'Finish'].map(
          (stepName, i) => ({
            name: stepName,
            valid: false,
            disabled: i !== 0,
          })
        ),
        activeStep: 0,
      };
    } else {
      this.stepState = {
        steps: ['Client Goals'].map(
          (stepName, i) => ({
            name: stepName,
            valid: false,
            disabled: i !== 0,
          })
        ),
        activeStep: 0,
      };
    }
  }

  ngOnInit() {
    this.setupInputs();
  }

  setupInputs() {
    if (this.client?.plan?.inputs) {
      this.inputs = Object.assign({}, this.client.plan.inputs);
    } else {
      const now = new Date();
      const firstDayNextMonth = new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        1
      );
      this.inputs = {
        simulationName: DefaultSimulationName,
        startDate: firstDayNextMonth,
        monthlyIncomeGoal: '5000',
        monthlyContributionAmount: '1500',
        inflationRate: '0',
        yearsToSimulate: '10',
        loansRateOfReturn: '8',
        lineOfCreditSize: '10000',
        lineOfCreditInterestRate: '0',
        loansTerm: '3',

        growthCapital: '8000',
        monthsToPayOffLOC: '4',
        monthsToPayOffLOCPhase3: '6',
        keepAdditionalIncomeInSystem: true,
        enableAutoIncrement: true,
        autoIncrementAmount: '5000',
        autoIncrementNumFlips: '0',
        autoIncrementMax: '0',
        autoIncrementOneTimeMaxAmount: '0',
        methodToUse: 4,
        clientAdvanced: false,
        algorithmAdvanced: false,
        lendingAdvanced: false,
        optimizerAdvanced: false,
      };
    }
  }

  enableLineOfCreditSettings() {
    this.stepState.steps.push(
      {
        name: 'Lending',
        valid: false,
        disabled: false
      }
    );
    this.lineOfCreditSettingsEnabled = true;
  }

  enableIncomeStreamSettings() {
    this.stepState.steps.push(
      {
        name: 'Income Streams',
        valid: false,
        disabled: false
      }
    );
    this.incomeStreamSettingsEnabled = true;
  }

  enableAlgorithmSettings() {
    this.stepState.steps.push(
      {
        name: 'Algorithm',
        valid: false,
        disabled: false
      }
    );
    this.algorithmSettingsEnabled = true;
  }

  get nextBtnDisabled() {
    const { stepState } = this;
    return !stepState.steps[stepState.activeStep].valid;
  }

  get showNextBtn() {
    const { finishStepActive } = this;
    return !finishStepActive;
  }

  get showCreateDaAnalysisBtn() {
    const { stepState } = this;
    return stepState.activeStep === stepState.steps.length - 1;
  }

  get clientGoalStepActive() {
    return this.stepState.steps[this.stepState.activeStep]?.name == 'Client Goals'
  }

  get lendingSettingsStepActive() {
    return this.stepState.steps[this.stepState.activeStep]?.name == 'Lending';
  }

  get incomeStreamsStepActive() {
    return this.stepState.steps[this.stepState.activeStep]?.name == 'Income Streams';
  }

  get algoSettingsStepActive() {
    return this.stepState.steps[this.stepState.activeStep]?.name == 'Algorithm';
  }

  get finishStepActive() {
    return this.stepState.activeStep === 4;
  }

  onIndexChange(newIndex: number) {
    this.stepState.activeStep = newIndex;
  }

  handleCancel() {
    this.cancelOperation.emit();
  }

  handleSubmit() {
    if (!this.inputs) {
      return;
    }

    this.submitCreateApiCall = {
      state: AsyncOpState.Inprogress,
    };

    const inputs: PlanInputs = {
      clientAdvanced: this.inputs.clientAdvanced,
      algorithmAdvanced: this.inputs.algorithmAdvanced,
      lendingAdvanced: this.inputs.lendingAdvanced,
      optimizerAdvanced: this.inputs.optimizerAdvanced,
      simulationName: this.inputs.simulationName,
      startDate: this.inputs.startDate,
      monthlyIncomeGoal:
        this.inputs.monthlyIncomeGoal !== undefined
          ? +this.inputs.monthlyIncomeGoal
          : undefined,
      monthlyContributionAmount:
        this.inputs.monthlyContributionAmount !== undefined
          ? +this.inputs.monthlyContributionAmount
          : undefined,
      inflationRate:
        this.inputs.inflationRate !== undefined
          ? +this.inputs.inflationRate
          : undefined,
      yearsToSimulate:
        this.inputs.yearsToSimulate !== undefined
          ? +this.inputs.yearsToSimulate
          : undefined,
      loansRateOfReturn:
        this.inputs.loansRateOfReturn !== undefined
          ? +this.inputs.loansRateOfReturn
          : undefined,
      lineOfCreditSize:
        this.inputs.lineOfCreditSize !== undefined
          ? +this.inputs.lineOfCreditSize
          : undefined,
      lineOfCreditInterestRate:
        this.inputs.lineOfCreditInterestRate !== undefined
          ? +this.inputs.lineOfCreditInterestRate
          : undefined,
      loansTerm:
        this.inputs.loansTerm !== undefined
          ? +this.inputs.loansTerm
          : undefined,
      growthCapital:
        this.inputs.growthCapital !== undefined
          ? +this.inputs.growthCapital
          : undefined,
      monthsToPayOffLOC:
        this.inputs.monthsToPayOffLOC !== undefined
          ? +this.inputs.monthsToPayOffLOC
          : undefined,
      monthsToPayOffLOCPhase3:
        this.inputs.monthsToPayOffLOCPhase3 !== undefined
          ? +this.inputs.monthsToPayOffLOCPhase3
          : undefined,
      keepAdditionalIncomeInSystem: this.inputs.keepAdditionalIncomeInSystem,
      enableAutoIncrement: this.inputs.enableAutoIncrement,
      autoIncrementAmount:
        this.inputs.autoIncrementAmount !== undefined
          ? +this.inputs.autoIncrementAmount
          : undefined,
      autoIncrementNumFlips:
        this.inputs.autoIncrementNumFlips !== undefined
          ? +this.inputs.autoIncrementNumFlips
          : undefined,
      autoIncrementMax:
        this.inputs.autoIncrementMax !== undefined
          ? +this.inputs.autoIncrementMax
          : undefined,
      autoIncrementOneTimeMaxAmount:
        this.inputs.autoIncrementOneTimeMaxAmount !== undefined
          ? +this.inputs.autoIncrementOneTimeMaxAmount
          : undefined,
      methodToUse: this.inputs.methodToUse,
      scheduledTransactions: this.client!.transactions,
      deposits: this.client!.deposits,
    };
    this.httpClient.post('/engine/plan', inputs).subscribe(
      (results) => {
        this.httpClient
          .post(
            '/api/clients/' + this.client!.id + '/plan',
            { inputs: inputs, results: results },
            {
              headers: toApiAuthHeaders(this.authService),
            }
          )
          .subscribe(
            (result) => {
              this.client.plan = { inputs: inputs, results: results };
              this.submitCreateApiCall = {
                state: AsyncOpState.Completed,
              };
              this.operationSubmitted.emit();
            },
            () => {
              this.submitCreateApiCall = {
                state: AsyncOpState.Failed,
                error: 'Error! Submitting Plan Data failed',
              };
            }
          );
      },
      () => {
        this.submitCreateApiCall = {
          state: AsyncOpState.Failed,
          error: 'Error! Submitting Plan Data failed',
        };
      }
    );
  }

  goToNextStep() {
    const { stepState } = this;

    if (stepState.activeStep < stepState.steps.length - 1) {
      stepState.steps[stepState.activeStep].valid = true;
      stepState.steps[stepState.activeStep + 1].disabled = false;
      stepState.activeStep += 1;
    } else {
      stepState.steps[stepState.activeStep].valid = true;
      this.handleSubmit();
    }
  }

  get createAnalysisApiInprogress() {
    return this.submitCreateApiCall.state === AsyncOpState.Inprogress;
  }

  toStepStatus(stepIndex: number) {
    const { activeStep } = this.stepState;
    return activeStep > stepIndex
      ? 'finish'
      : activeStep === stepIndex
      ? 'process'
      : 'wait';
  }

  onActiveStepValidityChange(valid: boolean) {
    const { stepState } = this;
    stepState.steps[stepState.activeStep].valid = valid;
  }
}
